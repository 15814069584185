import { useBrandRequestContext } from '@/layouts/Brand/BrandRequestContext';
import { ArchivedNone } from '@/routes/Brand/Requests/Request/components/shared/ArchivedNone';
import UnhandledStep from '@/routes/Brand/Requests/Request/components/shared/UnhandledStep';

import Analysis from './components/Analysis';
import Archived from './components/Archived';
import Completed from './components/Completed';
import Delivery from './components/Delivery';
import Estimation from './components/Estimation';
import Payment from './components/Payment';
import Repair from './components/Repair';
import Transit from './components/Transit';
import Validation from './components/Validation';

const ClientRequestView = () => {
  const { request } = useBrandRequestContext();

  if (request.isArchived && !request.isArchivedAndExportedToZendesk) {
    const shouldRequestFeedback = request.articles.some((article) => !!article.repairedAt);
    const isServiceChoiceNone = request.allArticles.every(
      (article) => article.serviceChoice === 'none'
    );

    if (shouldRequestFeedback) {
      return <Completed request={request} />;
    } else if (isServiceChoiceNone) {
      return <ArchivedNone request={request} />;
    } else {
      return <Archived />;
    }
  }

  if (
    request.isInDraftStep ||
    request.isInFirstServiceChoiceStep ||
    request.isArchivedAndExportedToZendesk
  ) {
    return <Estimation request={request} />;
  }

  if (request.isInValidationStep) {
    return <Validation request={request} />;
  }

  if (request.isInInitialTransitStep) {
    return <Transit request={request} />;
  }

  const isRequestInTransitAfterRequalificationRefused = request.articles.some(
    (article) => !!article.requalificationRefusedAt
  );

  if (
    request.isInAnalysisStep ||
    request.isInLaterServiceChoiceStep ||
    isRequestInTransitAfterRequalificationRefused
  ) {
    return <Analysis request={request} />;
  }

  if (request.isInPaymentStep) {
    return <Payment request={request} />;
  }

  if (request.isInRepairStep) {
    return <Repair request={request} />;
  }

  if (
    request.isInTransitStep ||
    request.isInLegacyPendingClientDeliveryStep ||
    request.isInLegacyReceivedDeliveryStep
  ) {
    return <Delivery request={request} />;
  }

  // If we reach this point, it means that the request is in an unknown step
  // We are still showing something as not to scare the user
  // But we are also sending an error to Sentry
  return <UnhandledStep request={request} />;
};

export default ClientRequestView;
