import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router';
import { Trans, useLingui } from '@lingui/react/macro';
import { parseISO } from 'date-fns';

import { PriceDetails } from '@/components/PriceDetails/PriceDetails';
import { usePriceDetailsRows } from '@/components/PriceWithDetails/usePriceDetailsRows';
import config from '@/config';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Drawer, { DrawerBody, DrawerHeader } from '@/design_system/Drawer';
import Stack from '@/design_system/Stack';
import { Body, Cell, Column, Header, Row, Table } from '@/design_system/Table/Table';
import Tooltip from '@/design_system/Tooltip';
import IconCalendar from '@/icons/Calendar.svg';
import IconDownload from '@/icons/Download.svg';
import IconStripe from '@/icons/Stripe.svg';
import { useGetArticleName } from '@/models/article';
import { useInvoiceLineName } from '@/models/invoiceLine';
import { useRequest } from '@/models/request';
import { TransactionWithInvoiceWithRelations, useTransaction } from '@/models/transaction';
import { formatDate } from '@/utils/date';
import { formatCurrency } from '@/utils/number';
import useViewPort from '@/utils/useViewport';

export const Transaction = () => {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const { isMobile } = useViewPort();

  const { transactionId } = useParams();
  const { data: transaction } = useTransaction(transactionId);

  if (!transaction) {
    return null;
  }

  const close = () => {
    setIsOpen(false);

    // Wait for the Drawer CSS exit animation to finish before navigating away
    setTimeout(() => navigate(`/accounting?tab=transactions`, { replace: true }), 150);
  };

  const origin = transaction.sourceClient?.name ?? transaction.onBehalfOfOrganizationCountry?.name;
  const destination =
    transaction.destinationWorkshop?.name ?? transaction.onBehalfOfOrganizationCountry?.name;

  const isWorkshopPayout = !!transaction.destinationWorkshop;

  return (
    <Drawer isOpen={isOpen} onOpenChange={close}>
      <DrawerHeader>
        <Stack gap="0.25rem">
          <Stack className="headline-300-medium" row gap="0.5rem" alignItems="center">
            <Trans id="accounting.transaction.invoices.header.title">
              <span className="headline-300-bold">{origin}</span> to{' '}
              <span className="headline-300-bold">{destination}</span>
            </Trans>
          </Stack>
          <p className="paragraph-50-medium">
            {formatCurrency(transaction.amount, transaction.currency)}
          </p>
        </Stack>
      </DrawerHeader>
      <DrawerBody>
        <Stack padding={isMobile ? '1.5rem 1rem' : '1.5rem 2rem'} gap="2rem">
          {isWorkshopPayout && <PaymentMethod />}
          <OperationDate transaction={transaction} />
          <Invoices transaction={transaction} />
          <InvoiceLines transaction={transaction} />
          {!!transaction.sourceClient && <Price requestId={transaction.sourceClient.requestId} />}
        </Stack>
      </DrawerBody>
    </Drawer>
  );
};

const PaymentMethod = () => {
  return (
    <Stack gap="0.5rem">
      <h2 className="headline-400-bold">
        <Trans id="accounting.transaction.payment-method.title">Payment method</Trans>
      </h2>
      <Stack row gap="0.5rem" alignItems="center">
        <IconStripe style={{ fontSize: '1.5rem' }} />
        <p className="paragraph-100-regular">
          <Trans id="accounting.transaction.payment-method.via-prolong-stripe">
            Prolong (Stripe)
          </Trans>
        </p>
      </Stack>
    </Stack>
  );
};

const OperationDate = ({ transaction }: { transaction: TransactionWithInvoiceWithRelations }) => {
  const operationDate = transaction.startedAt
    ? formatDate(new Date(transaction.startedAt), { dateStyle: 'medium' })
    : '-';

  return (
    <Stack gap="0.5rem">
      <h2 className="headline-400-bold">
        <Trans id="accounting.transaction.operation-date.title">Operation date</Trans>
      </h2>

      <Stack row gap="0.5rem" alignItems="center">
        <IconCalendar style={{ fontSize: '1.5rem' }} />
        <p className="paragraph-100-regular">{operationDate}</p>
      </Stack>
    </Stack>
  );
};

const Invoices = ({ transaction }: { transaction: TransactionWithInvoiceWithRelations }) => {
  const { t } = useLingui();

  const columnWidths = {
    invoice: 'minmax(120px, 1fr)',
    servicesAmount: 'minmax(120px, 1fr)',
    action: '60px',
  };

  return (
    <Stack gap="0.5rem">
      <h2 className="headline-400-bold">
        <Trans id="accounting.transaction.invoices.title">Invoices</Trans>
      </h2>
      <Box padding="0" gap="1rem">
        <Table
          ariaLabel={t({
            id: 'accounting.transaction.invoices.table.label',
            message: 'Invoices table',
          })}
          columnWidths={[columnWidths.invoice, columnWidths.servicesAmount, columnWidths.action]}
        >
          <Header>
            <Row>
              <Column>
                <Trans id="accounting.transaction.invoices.table.column.invoice.label">
                  Invoice
                </Trans>
              </Column>

              <Column>
                <Trans id="accounting.transaction.invoices.table.column.services-amount.label">
                  Services amount
                </Trans>
              </Column>
              <Column></Column>
            </Row>
          </Header>

          <Body>
            {transaction.invoices.map((invoice) => (
              <InvoiceRow key={invoice.id} invoice={invoice} transaction={transaction} />
            ))}
          </Body>
        </Table>
      </Box>
    </Stack>
  );
};

const InvoiceRow = ({
  invoice,
  transaction,
}: {
  invoice: TransactionWithInvoiceWithRelations['invoices'][number];
  transaction: TransactionWithInvoiceWithRelations;
}) => {
  const totalServicesAmount = transaction.invoiceLines
    .filter((line) => line.invoiceId === invoice.id)
    .reduce((sum, line) => sum + line.price.amount, 0);

  return (
    <Row>
      <Column>
        <Stack alignItems="flex-start">
          <Button
            variant="link-subtle"
            to={`/accounting/invoices/${invoice.id}`}
            className="text-primary"
          >
            {invoice.number}
          </Button>
          <Tooltip
            content={
              invoice.paymentDueDate ? (
                <Trans id="accounting.transaction.invoices.table.column.payment-due.date">
                  Payment due date
                </Trans>
              ) : (
                <Trans id="accounting.transaction.invoices.table.column.payment-due.no-date">
                  No payment due date
                </Trans>
              )
            }
          >
            <Button variant="style-less" size="small" className="paragraph-200-regular">
              {invoice.paymentDueDate
                ? formatDate(parseISO(invoice.paymentDueDate), {
                    dateStyle: 'medium',
                  })
                : '-'}
            </Button>
          </Tooltip>
        </Stack>
      </Column>
      <Column>
        <div className="paragraph-100-regular">
          {formatCurrency(totalServicesAmount, invoice.currency)}
        </div>
      </Column>
      <Column>
        {!!invoice.media.length && (
          <Button
            iconOnly
            variant="secondary"
            tooltip={
              <Trans id="accounting.transaction.invoices.table.column.download-invoice.tooltip">
                Download invoice
              </Trans>
            }
            href={`${config.apiUrl}/invoices/${invoice.id}/media`}
            target="_blank"
          >
            <IconDownload />
          </Button>
        )}
      </Column>
    </Row>
  );
};

const InvoiceLines = ({ transaction }: { transaction: TransactionWithInvoiceWithRelations }) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();

  const columnWidths = {
    service: 'minmax(180px, auto)',
    article: isMobile ? false : 'minmax(180px, 1fr)',
    request: isMobile ? false : 'minmax(100px, 1fr)',
    amount: 'minmax(100px, auto)',
  };

  return (
    <Stack gap="0.5rem">
      <h2 className="headline-400-bold">
        <Trans id="accounting.transaction.services.title">Services associated</Trans>
      </h2>
      <Box padding="0" gap="1rem">
        <Table
          ariaLabel={t({
            id: 'accounting.transaction.services.table.label',
            message: 'Associated services',
          })}
          columnWidths={[
            columnWidths.service,
            columnWidths.article,
            columnWidths.request,
            columnWidths.amount,
          ]}
        >
          <Header>
            <Row>
              <Column>
                <Trans id="accounting.transaction.services.table.column.service.label">
                  Service
                </Trans>
              </Column>
              {!isMobile && (
                <Column>
                  <Trans id="accounting.transaction.services.table.column.article.label">
                    Item
                  </Trans>
                </Column>
              )}
              {!isMobile && (
                <Column>
                  <Trans id="accounting.transaction.services.table.column.request.label">
                    Request
                  </Trans>
                </Column>
              )}
              <Column justifyContent="flex-end">
                <Trans id="accounting.transaction.services.table.column.amount.label">Amount</Trans>
              </Column>
            </Row>
          </Header>

          <Body>
            {transaction.invoiceLines.map((invoiceLine) => (
              <InvoiceLineRow key={invoiceLine.id} invoiceLine={invoiceLine} />
            ))}
          </Body>
        </Table>
      </Box>
    </Stack>
  );
};

const InvoiceLineRow = ({
  invoiceLine,
}: {
  invoiceLine: TransactionWithInvoiceWithRelations['invoiceLines'][number];
}) => {
  const { isMobile } = useViewPort();
  const name = useInvoiceLineName(invoiceLine);
  const getArticleName = useGetArticleName();
  const request = invoiceLine.request;

  return (
    <Row>
      <Cell>
        <Stack>
          {name}
          {isMobile && (
            <Stack row gap="0.25rem" className="paragraph-200-regular">
              {!!invoiceLine.article && (
                <>
                  <Link
                    to={`/requests/${request.id}/articles/${invoiceLine.article.id}`}
                    className="reset-link"
                  >
                    {getArticleName({ article: invoiceLine.article })}
                  </Link>{' '}
                  •{' '}
                </>
              )}
              <Link to={`/requests/${request.id}`} className="reset-link">
                {request.reference}
              </Link>
            </Stack>
          )}
        </Stack>
      </Cell>
      {!isMobile && (
        <Cell>
          {invoiceLine.article ? (
            <Button
              variant="link-subtle"
              to={`/requests/${request.id}/articles/${invoiceLine.article.id}`}
              className="text-primary"
            >
              {getArticleName({ article: invoiceLine.article })}
            </Button>
          ) : (
            '-'
          )}
        </Cell>
      )}
      {!isMobile && (
        <Cell>
          <Button variant="link-subtle" to={`/requests/${request.id}`} className="text-primary">
            {request?.reference}
          </Button>
        </Cell>
      )}
      <Cell justifyContent="flex-end">
        {formatCurrency(invoiceLine.price.amount, invoiceLine.price.currency)}
      </Cell>
    </Row>
  );
};

const Price = ({ requestId }: { requestId: string }) => {
  const { data: request } = useRequest(requestId);
  const price = request?.price?.amountPerCurrency[0];

  const priceDetailsRows = usePriceDetailsRows(price, {
    showTaxDetails: true,
  });
  if (!request || !price) {
    return null;
  }
  return (
    <Stack gap="0.5rem">
      <h2 className="headline-400-bold">
        <Trans id="accounting.transaction.price.title">Price</Trans>
      </h2>

      <Box padding="0.75rem 1rem">
        <PriceDetails priceDetailsRows={priceDetailsRows} currency={price.currency} />
      </Box>
    </Stack>
  );
};
