import { ReactNode, useState } from 'react';
import { Trans } from '@lingui/react/macro';
import cn from 'classnames';

import RefashionLogo from '@/components/RefashionLogo';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import Tooltip from '@/design_system/Tooltip';
import IconBandage from '@/icons/Bandage.svg';
import IconChevron from '@/icons/Chevron.svg';
import IconCross from '@/icons/Cross.svg';
import IconInfo from '@/icons/Info.svg';
import IconTask from '@/icons/Task.svg';
import { computeDuplicateActionNumber, useGetActionName } from '@/models/actionType';
import { computeDuplicateDefectNumber, useGetDefectName } from '@/models/defectType';
import {
  ArticleActionWithRelations,
  ArticleDefectWithRelations,
  ClientArticleWithRelations,
} from '@/models/request';
import { useClientToken, useCurrentOrganization, useCurrentSession } from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';

import './ArticleActionsCell.css';

const { block, element } = createBEMClasses('article-actions-cell');

const ArticleActionsCell = ({
  article,
  mode,
  showBonus,
}: {
  article: ClientArticleWithRelations;
  mode: 'defect' | 'action';
  showBonus?: boolean;
}) => {
  const clientToken = useClientToken();
  const storeToken = useClientToken();

  const defects = article.snapshot.articleDefects;
  const actions = article.snapshot.articleActions;

  const priceWithPossibleBonus = clientToken || storeToken ? 'cost' : 'price';

  const notToBeRepairedDefects = defects.filter((defect) => !defect.toBeRepaired);

  if (mode === 'defect') {
    return (
      <ul className={block()}>
        {defects.map((defect) => (
          <Defect key={defect.id} defect={defect} defects={defects} />
        ))}
      </ul>
    );
  } else {
    if (article.serviceChoice === 'exchange') {
      return (
        <ul className={block()}>
          <li>
            <Trans id="service-choice.radio.exchange.label">Exchange</Trans>
          </li>
        </ul>
      );
    } else if (article.serviceChoice === 'refund') {
      return (
        <ul className={block()}>
          <li>
            <Trans id="service-choice.radio.refund.label">Refund</Trans>
          </li>
        </ul>
      );
    } else if (article.serviceChoice === 'none') {
      return (
        <ul className={block()}>
          <li>
            <div className={element('icon')}>
              <IconCross />
            </div>
            <p>
              <Trans id="service-choice.radio.none.sub-text">No service can be provided</Trans>
            </p>
          </li>
        </ul>
      );
    } else {
      return (
        <ul className={block()}>
          {actions.map((action) => (
            <Action
              key={action.id}
              action={action}
              actions={actions}
              showBonus={
                showBonus && action[`${priceWithPossibleBonus}RefashionStatus`] === 'applied'
              }
            />
          ))}
          {notToBeRepairedDefects.map((defect) => (
            <Defect
              key={defect.id}
              defect={defect}
              defects={notToBeRepairedDefects}
              wontBeRepaired
            />
          ))}
        </ul>
      );
    }
  }
};

const ActionIcon = ({ brandResponsibility }: { brandResponsibility?: boolean }) => {
  const [organization] = useCurrentOrganization();
  const { currentSession } = useCurrentSession();

  const isFromCurrentOrganization = !!currentSession?.organizations.find(
    ({ id }) => id === organization?.id
  );

  if (brandResponsibility && !!organization && isFromCurrentOrganization) {
    return (
      <Tooltip
        content={
          <Trans id="article-actions.brand-responsibility">
            Under {organization.name} responsibility
          </Trans>
        }
        className={element('tooltip')}
      >
        <Button variant="style-less" className={element('icon')}>
          {<IconTask />}
          <div className={element('brand-responsibility')} />
        </Button>
      </Tooltip>
    );
  }

  return <div className={element('icon')}>{<IconTask />}</div>;
};

export default ArticleActionsCell;

const Action = ({
  action,
  actions,
  showBonus,
}: {
  action: ArticleActionWithRelations;
  actions: ArticleActionWithRelations[];
  showBonus?: boolean;
}) => {
  const getActionName = useGetActionName();
  const duplicateNumber = computeDuplicateActionNumber(action, actions);

  return (
    <li>
      <ActionIcon brandResponsibility={action.brandResponsibility} />
      <WithContext context={action.context}>
        <Stack row gap="4px">
          <p>
            {getActionName({ action })}
            {actions.length > 1 && duplicateNumber ? ` #${duplicateNumber}` : ''}
          </p>
          {showBonus && <RefashionLogo />}
        </Stack>
      </WithContext>
    </li>
  );
};

const Defect = ({
  defect,
  defects,
  wontBeRepaired,
}: {
  defect: ArticleDefectWithRelations;
  defects: ArticleDefectWithRelations[];
  wontBeRepaired?: boolean;
}) => {
  const getDefectName = useGetDefectName();
  const duplicateNumber = computeDuplicateDefectNumber(defect, defects);

  return (
    <li
      key={defect.id}
      className={cn({
        'text-disabled': wontBeRepaired,
      })}
    >
      <div className={element('icon')}>
        <IconBandage />
      </div>
      <WithContext context={defect.context}>
        <Stack row gap="4px">
          <p style={{ textDecoration: wontBeRepaired ? 'line-through' : undefined }}>
            {getDefectName(defect)}
            {defects.length > 1 && duplicateNumber ? ` #${duplicateNumber}` : ''}
          </p>
          {wontBeRepaired && (
            <Tooltip
              content={
                <Trans id="article-actions.not-to-be-repaired-defect">
                  This defect won&apos;t be repaired
                </Trans>
              }
            >
              <Button variant="style-less" style={{ alignSelf: 'center' }}>
                <IconInfo style={{ fontSize: '1rem' }} />
              </Button>
            </Tooltip>
          )}
        </Stack>
      </WithContext>
    </li>
  );
};

const WithContext = ({ context, children }: { context: string | null; children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Stack>
      {children}
      {context && (
        <Stack alignItems="flex-start" gap="4px">
          <Button variant="link-subtle" size="small" onPress={() => setIsOpen((prev) => !prev)}>
            {isOpen ? (
              <>
                <Trans id="article-actions.defect-context.hide-context">Hide context</Trans>
                <IconChevron up />
              </>
            ) : (
              <>
                <Trans id="article-actions.defect-context.view-context">View context</Trans>
                <IconChevron down />
              </>
            )}
          </Button>
          {isOpen && (
            <p style={{ whiteSpace: 'pre-wrap', padding: '4px 0' }}>&ldquo;{context}&rdquo;</p>
          )}
        </Stack>
      )}
    </Stack>
  );
};
