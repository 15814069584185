import { Plural, Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticlesNames,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconValidation from '@/icons/Validation.svg';

export const JobAcceptedActivity = ({
  activities,
}: {
  activities: ActivityOfType<'job_accepted'>[];
}) => {
  const firstActivity = activities[0];
  const workshopName = firstActivity.workshop.name;
  const creatorName = firstActivity.data.onBehalfOfWorkshop
    ? firstActivity.creator.name
    : `${firstActivity.creator.name} (${workshopName})`;

  return (
    <BaseActivity
      createdAt={firstActivity.createdAt}
      type="primary"
      icon={<IconValidation />}
      message={
        <BaseActivityText>
          {firstActivity.data.onBehalfOfWorkshop ? (
            <Trans id="activities.job_accepted.message.on-behalf">
              <strong>{creatorName}</strong> accepted the job on behalf of {workshopName}{' '}
              <Plural value={activities.length} other="with items:" one="" />
              {activities.length > 1 && (
                <BaseActivityArticlesNames activities={activities} displayArticleName />
              )}
            </Trans>
          ) : (
            <Trans id="activities.job_accepted.message">
              <strong>{creatorName}</strong> accepted the job{' '}
              <Plural value={activities.length} other="with items:" one="" />
              <BaseActivityArticlesNames activities={activities} displayArticleName />
            </Trans>
          )}
        </BaseActivityText>
      }
    />
  );
};
