import { Trans, useLingui } from '@lingui/react/macro';

import { ProductL1, ProductL2, ProductL3 } from '@/api';
import ProductSearchSelect from '@/components/ProductSearchSelect';
import config from '@/config';
import { Label } from '@/design_system/Label/Label';
import PhotoCard from '@/design_system/PhotoCard';
import {
  RadioCard,
  RadioCardGroup,
  RadioCardYesNo,
} from '@/design_system/Radio/RadioCard/RadioCard';
import Stack from '@/design_system/Stack';
import { useBrandRequestContext } from '@/layouts/Brand/BrandRequestContext';
import { Product, PRODUCT_CATEGORIES, useProductOptions } from '@/models/product';
import { useCurrentOrganization } from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';
import { useScrollIntoView } from '@/utils/useScrollIntoView';
import useViewPort from '@/utils/useViewport';

import './ProductInfo.css';

const { block, element } = createBEMClasses('client-product-info');

const Reference = ({
  hasReference,
  setHasReference,
  product,
  setProduct,
  productL1,
  setProductL1,
  productL2,
  setProductL2,
  productL3,
  setProductL3,
  disabled,
}: {
  hasReference?: 'yes' | 'no';
  setHasReference: (hasReference: 'yes' | 'no') => void;
  product?: Product;
  setProduct: (product?: Product) => void;
  productL1?: ProductL1;
  setProductL1: (productL1?: ProductL1) => void;
  productL2?: ProductL2;
  setProductL2: (productL2?: ProductL2) => void;
  productL3?: ProductL3;
  setProductL3: (productL3?: ProductL3) => void;
  disabled: boolean;
}) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();
  const { request } = useBrandRequestContext();

  const [productL2Ref, scrollToProductL2] = useScrollIntoView<HTMLDivElement>();
  const [productL3Ref, scrollToProductL3] = useScrollIntoView<HTMLDivElement>();

  const { enabledProductL2 } = request.organization;

  const { productL1Options, productL2Options, productL3Options } = useProductOptions(
    request.organization,
    productL1
  );

  const l1Options = productL1Options.map((l1) => ({
    value: l1.id,
    text: l1.text,
    imageUrl: `/product/l1/${l1.id}.png`,
    className: element('category', { type: l1.id }),
    color: l1.color,
  }));

  const l2Options = productL2Options.map((l2) => ({
    value: l2.id,
    text: l2.text,
    imageUrl: `/product/l2/${l2.id}.png`,
    className: element('category', { type: productL1 }),
  }));

  const l3Options = productL3Options.map((l3) => ({
    value: l3.id,
    text: l3.text,
    imageUrl: `/product/l3/${l3.id}.png`,
    className: element('category', { type: 'material' }),
  }));

  return (
    <Stack gap="1.5rem" className={block()}>
      <Label
        label={t({
          id: 'client.new.article.form.has-reference.label',
          message: 'Do you have the item reference?',
        })}
        size="large"
      >
        <RadioCardYesNo
          name="has-reference"
          theme="brand"
          value={hasReference}
          onChange={(value) => {
            setHasReference(value);
            setProduct(undefined);
            setProductL2(undefined);
            setProductL3(undefined);

            if (value === 'no' && l1Options.length === 1) {
              setProductL1(l1Options[0].value);
              scrollToProductL2();
            } else {
              setProductL1(undefined);
            }
          }}
          disabled={disabled}
          scrollToOnMount
        />
      </Label>
      {hasReference === 'yes' && (
        <ProductSearchSelect
          value={product}
          onChange={(product) => {
            setProduct(product);
            setProductL1(product?.productL1);
            setProductL2(product?.productL2 ?? undefined);
            setProductL3(product?.productL3 ?? undefined);
          }}
          variant="brand"
          showLabel={false}
          placeholder={t({
            id: 'client.new.article.form.reference.placeholder',
            message: 'Enter your item reference number or the name of the item',
          })}
          isDisabled={disabled}
          focusOnRender
          noOptionsMessage={
            <Stack
              className="paragraph-100-regular"
              padding="0.5rem"
              style={{ background: 'var(--color-neutral-0)' }}
            >
              <Trans id="client.new.article.form.reference.no-options">
                Your reference could not be found. Please try another reference or proceed without
                one by clicking &quot;No&quot;
              </Trans>
            </Stack>
          }
        />
      )}
      {hasReference !== 'no' && <ReferenceHelp />}
      {hasReference === 'no' && (
        <Stack gap="3rem" className={block()}>
          {l1Options.length > 1 && (
            <Label
              label={t({
                id: 'client.new.article.form.productl1.label',
                message: 'Select a type of item',
              })}
              size="large"
            >
              <RadioCardGroup
                name="product-l1"
                theme="brand"
                direction="column"
                value={productL1}
                onChange={(value) => {
                  const productL3s = PRODUCT_CATEGORIES.find(
                    (category) => category.id === value
                  )?.subCategories;

                  setProductL1(value as ProductL1);
                  setProductL3(productL3s?.length === 1 ? productL3s[0].id : undefined);

                  const l2Options = PRODUCT_CATEGORIES.find(
                    (l1) => l1.id === value
                  )?.categories.filter((l2) => enabledProductL2.includes(l2.id));

                  if (l2Options?.length === 1) {
                    setProductL2(l2Options[0].id);
                    scrollToProductL3();
                  } else {
                    setProductL2(undefined);
                    scrollToProductL2();
                  }
                }}
                disabled={disabled}
                scrollToOnMount
              >
                {l1Options.map((l1) => (
                  <RadioCard
                    key={l1.value}
                    value={l1.value}
                    ariaLabel={l1.text}
                    picture={l1.imageUrl ? <img src={l1.imageUrl} alt="" /> : null}
                    backgroundColor={l1.color}
                  >
                    {l1.text}
                  </RadioCard>
                ))}
              </RadioCardGroup>
            </Label>
          )}
          {!!productL1 && l2Options.length > 1 && (
            <Label
              label={t({
                id: 'client.new.article.form.productl2.label',
                message: 'What category of item is it?',
              })}
              size="large"
            >
              <RadioCardGroup
                name="product-l2"
                theme="brand"
                direction={isMobile ? 'column' : 'row'}
                value={productL2}
                onChange={(value) => {
                  const productL3s = PRODUCT_CATEGORIES.find(
                    (category) => category.id === productL1
                  )?.subCategories;

                  setProductL2(value as ProductL2);
                  setProductL3(productL3s?.length === 1 ? productL3s[0].id : undefined);
                  scrollToProductL3();
                }}
                ref={productL2Ref}
                disabled={disabled}
                scrollToOnMount
                style={
                  isMobile
                    ? undefined
                    : { gridTemplateColumns: 'repeat(auto-fit, minmax(12.5rem, 1fr))' }
                }
              >
                {l2Options.map((l2) => (
                  <RadioCard
                    key={l2.value}
                    value={l2.value}
                    ariaLabel={l2.text}
                    picture={l2.imageUrl ? <img src={l2.imageUrl} alt="" /> : null}
                  >
                    {l2.text}
                  </RadioCard>
                ))}
              </RadioCardGroup>
            </Label>
          )}
          {!!productL2 && productL3Options.length > 1 && (
            <Label
              label={t({
                id: 'client.new.article.form.productl3.label',
                message: 'What kind of material is it made of?',
              })}
              size="large"
              optional
            >
              <RadioCardGroup
                name="product-l3"
                theme="brand"
                direction={isMobile ? 'column' : 'row'}
                value={productL3}
                onChange={setProductL3}
                ref={productL3Ref}
                disabled={disabled}
                scrollToOnMount
                style={
                  isMobile
                    ? undefined
                    : { gridTemplateColumns: 'repeat(auto-fit, minmax(12.5rem, 1fr))' }
                }
              >
                {l3Options.map((l3) => (
                  <RadioCard
                    key={l3.value}
                    value={l3.value}
                    ariaLabel={l3.text}
                    picture={
                      l3.imageUrl ? (
                        <div
                          style={{
                            backgroundImage: `url("${l3.imageUrl}")`,
                            backgroundSize: 'cover',
                            height: '100%',
                            width: '100%',
                          }}
                        />
                      ) : null
                    }
                  >
                    {l3.text}
                  </RadioCard>
                ))}
              </RadioCardGroup>
            </Label>
          )}
        </Stack>
      )}
    </Stack>
  );
};

const ReferenceHelp = () => {
  const [organization] = useCurrentOrganization();
  const { t } = useLingui();

  const referencePhotoAlt = t({
    id: 'client-new.article.form.has-reference.photo-alt',
    message: `Photo of an item's reference`,
  });

  return (
    <Stack
      row
      gap="1rem"
      flexWrap="nowrap"
      alignItems="center"
      className={element('reference-help')}
    >
      <PhotoCard
        url={`${config.appUrl}/brands/reference/${
          import.meta.env.VITE_DEFAULT_ORGANIZATION_LOGO ? 'prolong-acme' : organization?.slug
        }.webp`}
        name={referencePhotoAlt}
        alt={referencePhotoAlt}
        size="xlarge"
      />
      <Stack gap="0.5rem">
        <p className="paragraph-50-medium is-hidden-mobile">
          <Trans id="client-new.article.form.has-reference.help-title">Where can I find it?</Trans>
        </p>
        <p className="paragraph-50-regular paragraph-100-regular-mobile">
          <Trans id="client-new.article.form.has-reference.help">
            The item reference is located on the item&apos;s care label and consists of five digits,
            e.g., 12345.
          </Trans>
        </p>
      </Stack>
    </Stack>
  );
};

export default Reference;
