import { useState } from 'react';
import { useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconEdit from '@/icons/Edit.svg';
import IconTrash from '@/icons/Trash.svg';
import { useBrandRequestContext } from '@/layouts/Brand/BrandRequestContext';
import { ClientArticleWithRelations } from '@/models/request';
import { ArticleSummary } from '@/routes/Brand/Requests/New/components/Article/components/ArticleSummary/ArticleSummary';
import DeleteArticleModal from '@/routes/Brand/Requests/New/components/DeleteArticleModal';
import { createBEMClasses } from '@/utils/classname';

import './ArticleCard.css';

const { block } = createBEMClasses('client-actions-article-card');

const ArticleCard = ({
  article,
  onEdit,
  onDelete,
}: {
  article: ClientArticleWithRelations;
  onEdit: () => void;
  onDelete: () => void;
}) => {
  const { t } = useLingui();
  const { request } = useBrandRequestContext();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <>
      <Stack
        row
        gap="0.5rem"
        justifyContent="space-between"
        alignItems="center"
        className={block()}
      >
        <ArticleSummary article={article} />
        <Stack row gap="1rem">
          <Button
            iconOnly
            size="medium"
            variant="secondary-brand"
            onPress={onEdit}
            ariaLabel={t({ id: 'client.new.actions.article.edit', message: 'Edit item' })}
            tooltip={t({ id: 'client.new.actions.article.edit', message: 'Edit item' })}
          >
            <IconEdit />
          </Button>
          <Button
            iconOnly
            size="medium"
            variant="secondary-brand"
            onPress={() => setShowDeleteModal(true)}
            ariaLabel={t({ id: 'client.new.actions.article.delete', message: 'Delete item' })}
            tooltip={t({ id: 'client.new.actions.article.delete', message: 'Delete item' })}
          >
            <IconTrash />
          </Button>
        </Stack>
      </Stack>
      <DeleteArticleModal
        lastArticle={request.articles.length === 1}
        isOpen={showDeleteModal}
        onOpenChange={setShowDeleteModal}
        onDelete={onDelete}
        articleId={article.id}
      />
    </>
  );
};

export default ArticleCard;
