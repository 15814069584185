import { useEffect } from 'react';

import { BrandRequestMainContent } from '@/layouts/Brand/BrandRequestLayout/BrandRequestLayout';
import { ClientRequestWithRelations } from '@/models/request';
import ClientArticlesTable from '@/routes/Brand/Requests/Request/components/shared/ClientArticlesTable';
import { captureException } from '@/services/sentry';

const UnhandledStep = ({ request }: { request: ClientRequestWithRelations }) => {
  useEffect(() => {
    captureException(
      new Error(
        `Unhandled step for request ${request.id}: ${request.articles
          ?.map((article) => `${article.id} - ${article.step?.step}`)
          .join(', ')}`
      )
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BrandRequestMainContent>
      <ClientArticlesTable request={request} showPrice={!!request.client} showArticleComment />
    </BrandRequestMainContent>
  );
};

export default UnhandledStep;
