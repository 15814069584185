import { Trans } from '@lingui/react/macro';

import { CardItem } from '@/components/Card/Card';
import Stack from '@/design_system/Stack';
import IconTools from '@/icons/Tools.svg';
import { getGroupedActions, useGetActionName } from '@/models/actionType';
import { ArticleWithRelations } from '@/models/request';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';

export const ArticleServiceChoiceCell = ({ article }: { article: ArticleWithRelations }) => {
  const { articles } = useRequestContext();
  const displayService = (articles[0].view.services.choice.serviceOptions ?? []).length > 1;

  const getActionName = useGetActionName();
  const actions = getGroupedActions(article.snapshot.articleActions);

  if (
    !(displayService && article.serviceChoice) &&
    !(article.serviceChoice === 'care-repair' && actions.length)
  ) {
    return <span className="paragraph-100-regular text-secondary">-</span>;
  }

  return (
    <Stack>
      {displayService && article.serviceChoice && (
        <span className="paragraph-100-regular">{SERVICE_CHOICE_LABEL[article.serviceChoice]}</span>
      )}
      {article.serviceChoice === 'care-repair' && actions.length && (
        <ul className="paragraph-100-regular">
          {actions.map((action) => (
            <li key={action.id}>
              {getActionName({ action })}
              {action.quantity > 1 && <> (x{action.quantity})</>}
            </li>
          ))}
        </ul>
      )}
    </Stack>
  );
};

export const ArticleServiceChoiceCardItems = ({ article }: { article: ArticleWithRelations }) => {
  const { articles } = useRequestContext();
  const displayService = (articles[0].view.services.choice.serviceOptions ?? []).length > 1;

  const getActionName = useGetActionName();
  const actions = getGroupedActions(article.snapshot.articleActions);

  return (
    <>
      {displayService && !!article.serviceChoice && article.serviceChoice !== 'care-repair' && (
        <CardItem key={article.serviceChoice}>
          <IconTools style={{ fontSize: '1rem' }} />
          {SERVICE_CHOICE_LABEL[article.serviceChoice]}
        </CardItem>
      )}
      {article.serviceChoice === 'care-repair' &&
        actions.length &&
        actions.map((action) => (
          <CardItem key={action.id}>
            <IconTools style={{ fontSize: '1rem' }} />
            {getActionName({ action })}
            {action.quantity > 1 && <> (x{action.quantity})</>}
          </CardItem>
        ))}
    </>
  );
};

const SERVICE_CHOICE_LABEL = {
  'care-repair': (
    <Trans id="request.articles.table.column.service-type.care-and-repair">Care and repair</Trans>
  ),
  exchange: <Trans id="request.articles.table.column.service-type.exchange">Exchange</Trans>,
  refund: <Trans id="request.articles.table.column.service-type.refund">Refund</Trans>,
  none: <Trans id="request.articles.table.column.service-type.none">None</Trans>,
};
