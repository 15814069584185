import { Trans, useLingui } from '@lingui/react/macro';

import FileUpload from '@/components/FileUpload';
import IconPicture from '@/icons/Picture.svg';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';

export const ArticlePhotoUpload = () => {
  const { t } = useLingui();

  const { article, errors, view } = useArticleContext();

  return (
    <FileUpload
      uploadData={{
        type: 'article-photo',
        articleId: article.id,
      }}
      type="photo"
      label={
        <>
          <Trans id="article.form.article-photo.label">Full item picture</Trans>
          {!view.details.articlePhoto.required && (
            <span className="text-disabled paragraph-100-regular">
              {' '}
              <Trans id="article.form.article-photo.label.optional">(optional)</Trans>
            </span>
          )}
        </>
      }
      ariaLabel={t({
        id: 'article.form.article-photo.label',
        message: 'Full item picture',
      })}
      prompt={t({
        id: 'article.form.article-photo.prompt',
        message: 'Add a full view of the item',
      })}
      icon={<IconPicture />}
      maxNumberOfMedia={1}
      allowsMultiple={false}
      media={article.articlePhoto ? [article.articlePhoto] : []}
      size="xxlarge"
      error={
        errors.details?.missingArticlePhoto
          ? t({
              id: 'requests.articles.form.error.photo',
              message: 'Please add a full item picture',
            })
          : undefined
      }
      deleteWithApi
    />
  );
};
