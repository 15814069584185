import { Trans } from '@lingui/react/macro';

import AlertBar from '@/design_system/AlertBar';
import Stack from '@/design_system/Stack';
import { ArticleWithRelations, RequestWithRelations } from '@/models/request';
import { PendingClientActionReminder } from '@/routes/Requests/Request/components/PendingClientActionReminder/PendingClientActionReminder';
import { useCurrentSession } from '@/services/auth';

export const ValidationStepMessage = ({
  request,
  article: inputArticle,
}: {
  request: RequestWithRelations;
  article?: ArticleWithRelations;
}) => {
  const article =
    inputArticle ?? request.articles.find((article) => article.step?.step === 'validation');

  const { currentSession } = useCurrentSession();

  if (!article || article.step?.step !== 'validation') {
    return null;
  }

  const isResponsibleWorkshop = currentSession?.isResponsibleWorkshop(article);

  const hasSendValidationReminderPermission = currentSession?.hasPermission(
    'write_client_communication',
    [
      {
        organizationId: request.organizationId,
        storeId: null,
      },
      {
        organizationId: request.organizationId,
        storeId: request.storeId,
      },
    ]
  );

  const requireShippingChoice =
    !request.store && !!request.client && !!article.step.config.requireShippingChoice;
  const isValidating = !article.validationCompletedAt;
  const hasValidatedAndIsInStoreDropoff =
    !!article.quoteAcceptedAt &&
    !!article.validationCompletedAt &&
    requireShippingChoice &&
    request.client?.shippingChoice === 'store';
  // Legacy - To remove in TECH-1144
  const hasValidatedAndIsChoosingShippingOption =
    !!article.quoteAcceptedAt &&
    !!article.validationCompletedAt &&
    requireShippingChoice &&
    !request.client?.shippingChoice;

  const canSendValidationReminder = isValidating && hasSendValidationReminderPermission;

  let messageContent;
  if (hasValidatedAndIsChoosingShippingOption) {
    messageContent = (
      <Trans id="request.client-validation.waiting-shipping-choice.text">
        The client has validated the estimate. They now need to choose a shipping option.
      </Trans>
    );
  } else if (hasValidatedAndIsInStoreDropoff) {
    messageContent = (
      <Trans id="request.client-validation.waiting-store-dropoff.text">
        The client has validated the estimate. They now need to bring the items to a store.
      </Trans>
    );
  } else if (isValidating) {
    if (isResponsibleWorkshop) {
      messageContent = (
        <Trans id="request.client-validation.waiting-workshop.text">
          The estimate has been sent to the client for validation. Once they validate it, the items
          will be sent to you.
        </Trans>
      );
    } else if (canSendValidationReminder) {
      messageContent = (
        <Trans id="request.client-validation.waiting-with-reminder.text">
          The estimate has been sent to the client for validation. If they don&apos;t respond, feel
          free to send them an email reminder.
        </Trans>
      );
    } else {
      messageContent = (
        <Trans id="request.client-validation.waiting.text">
          The estimate has been sent to the client for validation.
        </Trans>
      );
    }
  }

  return (
    <AlertBar
      title={<Trans id="request.client-validation.waiting.title">Waiting for client</Trans>}
      type="info"
      size="large"
    >
      <Stack gap="0.5rem">
        {messageContent}
        {canSendValidationReminder && (
          <PendingClientActionReminder id={request.id} type="validation" />
        )}
      </Stack>
    </AlertBar>
  );
};
