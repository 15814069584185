import { RequestDueDate } from '@/components/DueDate';
import { RequestWithRelationsFromRequestsList } from '@/models/request';

export const RequestDueAtCell = ({
  request,
  variant,
}: {
  request: RequestWithRelationsFromRequestsList;
  variant: 'card' | 'row';
}) => {
  if (!request.dueAtDate) {
    return null;
  }

  return (
    <RequestDueDate
      date={request.dueAtDate}
      estimatedDate={request.estimatedDueAtDate}
      variant={variant}
      displayDayMonthOnly={variant === 'card'}
    />
  );
};
