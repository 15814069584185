import { Plural, Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticlesNames,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconValidationWaiting from '@/icons/ValidationWaiting.svg';

export const JobSentForAcceptationActivity = ({
  activities,
}: {
  activities: ActivityOfType<'job_sent_for_acceptation'>[];
}) => {
  const firstActivity = activities[0];
  const creatorName = firstActivity.creator?.name;
  const workshopName = firstActivity.workshop?.name;

  return (
    <BaseActivity
      createdAt={firstActivity.createdAt}
      icon={<IconValidationWaiting />}
      message={
        <BaseActivityText>
          {creatorName ? (
            <Trans id="activities.job_sent_for_acceptation.message">
              <strong>{creatorName}</strong> sent a job to <strong>{workshopName}</strong>{' '}
              <Plural value={activities.length} other="with items:" one="" />
              {activities.length > 1 && (
                <BaseActivityArticlesNames activities={activities} displayArticleName />
              )}
            </Trans>
          ) : (
            <Trans id="activities.job_sent_for_acceptation.message-no-creator">
              A job was sent to <strong>{workshopName}</strong>{' '}
              <Plural value={activities.length} other="with items:" one="" />
              {activities.length > 1 && (
                <BaseActivityArticlesNames activities={activities} displayArticleName />
              )}
            </Trans>
          )}
        </BaseActivityText>
      }
    />
  );
};
