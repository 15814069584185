import { Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconEdit from '@/icons/Edit.svg';

export const ArticleWorkshopUpdatedActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_workshop_updated'>;
  displayArticleName: boolean;
}) => {
  if (!activity.workshop) {
    return null;
  }

  const workshopName = activity.workshop.name;
  const creatorName = activity.creator?.name;

  return (
    <BaseActivity
      key={activity.id}
      createdAt={activity.createdAt}
      icon={<IconEdit />}
      message={
        <BaseActivityText>
          {creatorName ? (
            <Trans id="activities.article_workshop_updated.message">
              <strong>{creatorName}</strong> assigned the item{' '}
              <BaseActivityArticleName
                activity={activity}
                displayArticleName={displayArticleName}
              />{' '}
              to the workshop <strong>{workshopName}</strong>
            </Trans>
          ) : (
            <Trans id="activities.article_workshop_updated.message-no-creator">
              The item{' '}
              <BaseActivityArticleName
                activity={activity}
                displayArticleName={displayArticleName}
              />{' '}
              was automatically assigned to the workshop: <strong>{workshopName}</strong>
            </Trans>
          )}
        </BaseActivityText>
      }
    />
  );
};
