import { useMemo, useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';
import debounce from 'lodash.debounce';

import DueAtType from '@/components/DueAtType';
import Box from '@/design_system/Box';
import FoldableSection from '@/design_system/FoldableSection';
import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import InputText from '@/design_system/InputText/InputText';
import { Label } from '@/design_system/Label/Label';
import { RadioBox, RadioBoxGroup } from '@/design_system/Radio/RadioBox/RadioBox';
import Stack from '@/design_system/Stack';
import { useUpdateDraftRequest, useUpdateRequestType } from '@/models/request';
import { useStore } from '@/models/store';
import { useCurrentSession } from '@/services/auth';

import { useRequestContext } from '../../contexts/RequestContext';

const SettingsForm = () => {
  const { t } = useLingui();
  const { currentSession } = useCurrentSession();
  const { request, workflow } = useRequestContext();

  const canCreateClientRequest = currentSession?.hasPermission('create_client_request', {
    organizationId: request.organizationId,
  });

  const canCreateStoreRequest = currentSession?.hasPermission('create_store_request', {
    organizationId: request.organizationId,
  });

  const { mutate: updateRequest } = useUpdateDraftRequest(request.id);
  const { mutate: updateRequestType } = useUpdateRequestType(request.id);

  const debouncedUpdateRequest = useMemo(() => {
    return debounce(updateRequest, 500);
  }, [updateRequest]);

  const dueAtConfig = workflow?.config.requestDueAt;

  const { data: store } = useStore(request.storeId);
  const isExternalStore = store?.organizationConfig?.external;

  const [externalReference, setExternalReference] = useState(request.externalReference ?? '');

  return (
    <FoldableSection
      label={
        <h2 className="headline-400-bold">
          <Trans id="requests.new.settings.title">Request information</Trans>
        </h2>
      }
    >
      <Box padding="16px" gap="1rem" style={{ flex: 'initial' }}>
        <Stack gap="1rem">
          {canCreateClientRequest && canCreateStoreRequest && (
            <Label label={t({ id: 'requests.new.requestor-type.label', message: 'Type' })}>
              <RadioBoxGroup
                name="request-type"
                value={request.client ? 'client' : 'store'}
                onChange={(type) => updateRequestType({ type })}
                disabled={!request.draft}
              >
                <RadioBox
                  value="client"
                  ariaLabel={t({
                    id: 'requests.new.requestor-type.client',
                    message: 'Client request',
                  })}
                  title={t({ id: 'requests.new.requestor-type.client', message: 'Client request' })}
                >
                  <Trans id="requests.new.requestor-type.client.sub-text">
                    For client-owned items with issues
                  </Trans>
                </RadioBox>
                <RadioBox
                  value="store"
                  ariaLabel={t({
                    id: 'requests.new.requestor-type.store',
                    message: 'Store damaged request',
                  })}
                  title={t({
                    id: 'requests.new.requestor-type.store',
                    message: 'Store damaged request',
                  })}
                >
                  <Trans id="requests.new.requestor-type.store.sub-text">
                    For brand-owned items damaged in-store
                  </Trans>
                </RadioBox>
              </RadioBoxGroup>
            </Label>
          )}

          {dueAtConfig && (
            <InputSelect
              variant="select"
              label={t({ id: 'requests.new.due-date.label', message: 'Due Date' })}
              isDisabled={dueAtConfig.length === 1}
              isSearchable={false}
              value={dueAtConfig.find((dueAtOption) => dueAtOption.type === request.dueAtType)}
              options={dueAtConfig}
              getOptionValue={(dueAtOption) => dueAtOption.type}
              getOptionLabel={(dueAtOption) => dueAtOption.type}
              formatOptionLabel={(dueAtOption) => (
                <DueAtType type={dueAtOption.type} days={dueAtOption.days} />
              )}
              hideSelectedOptions={false}
              onChange={(dueAtOption) => {
                if (dueAtOption) {
                  updateRequest({ dueAtType: dueAtOption.type });
                }
              }}
              size="medium"
              style={{ flex: 1 }}
            />
          )}

          {isExternalStore && (
            <InputText
              label={
                <>
                  <Trans id="requests.new.external-reference.label">Internal reference</Trans>
                  <span className="text-disabled paragraph-100-regular">
                    {' '}
                    <Trans id="requests.new.external-reference.label.optional">(optional)</Trans>
                  </span>
                </>
              }
              placeholder={t({
                id: 'requests.new.external-reference.placeholder',
                message: 'Add a reference',
              })}
              size="medium"
              value={externalReference}
              onChange={(value) => {
                setExternalReference(value);
                debouncedUpdateRequest({ externalReference: value });
              }}
            />
          )}
        </Stack>
      </Box>
    </FoldableSection>
  );
};

export default SettingsForm;
