import { Plural, Trans } from '@lingui/react/macro';

import Stack from '@/design_system/Stack';
import IconTruck from '@/icons/Truck.svg';
import { BrandRequestMainContent } from '@/layouts/Brand/BrandRequestLayout/BrandRequestLayout';
import { ClientArticleWithRelations, ClientRequestWithRelations } from '@/models/request';
import ClientArticlesTable from '@/routes/Brand/Requests/Request/components/shared/ClientArticlesTable';
import ClientStepper from '@/routes/Brand/Requests/Request/components/shared/ClientStepper';
import { PendingShipmentPreparation } from '@/routes/Brand/Requests/Request/components/shared/PendingShipmentPreparation';

const Transit = ({ request }: { request: ClientRequestWithRelations }) => {
  const activeArticlesInTransit = request.articles.filter(
    (article) => article.step?.step === 'transit' && !article.cancelledAt
  );
  const isPendingTransit = activeArticlesInTransit.some((article) => !!article.atStoreId);

  return (
    <BrandRequestMainContent>
      <Stack gap="1rem">
        <ClientStepper request={request} />
        {isPendingTransit && (
          <PendingShipmentPreparation
            request={request}
            articlesInTransit={activeArticlesInTransit}
          />
        )}
        {!isPendingTransit && <InTransitHeader articlesInTransit={activeArticlesInTransit} />}
        <ClientArticlesTable request={request} showPrice={!!request.client} showArticleComment />
      </Stack>
    </BrandRequestMainContent>
  );
};

const InTransitHeader = ({
  articlesInTransit,
}: {
  articlesInTransit: ClientArticleWithRelations[];
}) => {
  return (
    <>
      <Stack row gap="0.5rem">
        <IconTruck className="color-tertiary-700" style={{ fontSize: '24px' }} />
        <p className="paragraph-50-regular paragraph-100-regular-mobile">
          <Trans id="store.request.transit.in-transit.store-shipment">
            The{' '}
            <Plural
              value={articlesInTransit.length}
              one="item is on its"
              other="items are on their"
            />{' '}
            way to the workshop to be taken care of.
          </Trans>
        </p>
      </Stack>
    </>
  );
};

export default Transit;
