import { useLingui } from '@lingui/react/macro';

import { EmptyState } from '@/components/EmptyState';

import { IllustrationClientCommunicationDrawerEmptyState } from './IllustrationClientCommunicationDrawerEmptyState';

export const ClientCommunicationDrawerEmptyState = () => {
  const { t } = useLingui();

  return (
    <EmptyState
      subtitle={t({
        id: 'request.client-communication-drawer.empty-state.subtitle',
        message: 'No messages to display',
      })}
      illustration={<IllustrationClientCommunicationDrawerEmptyState />}
    />
  );
};
