import { ReactNode } from 'react';
import { Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconTrash from '@/icons/Trash.svg';

export const ArticleMediumDeletedActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_medium_deleted'>;
  displayArticleName: boolean;
}) => {
  const creatorName = activity.creator?.name;

  let articleMediaDeleteMessage: ReactNode | undefined;
  if (activity.data.type === 'article-photo' || activity.data.type === 'defect-photo') {
    articleMediaDeleteMessage = (
      <Trans id="activities.article_medium_deleted.message_defect_photo">
        <strong>{creatorName}</strong> deleted a photo from item{' '}
        <BaseActivityArticleName activity={activity} displayArticleName={displayArticleName} />
      </Trans>
    );
  } else if (activity.data.type === 'proof-of-purchase') {
    articleMediaDeleteMessage = (
      <Trans id="activities.article_medium_deleted.message_proof_of_purchase">
        <strong>{creatorName}</strong> deleted a proof of purchase from item{' '}
        <BaseActivityArticleName activity={activity} displayArticleName={displayArticleName} />
      </Trans>
    );
  }

  if (!articleMediaDeleteMessage) return null;

  return (
    <BaseActivity
      createdAt={activity.createdAt}
      icon={<IconTrash />}
      type="danger"
      message={<BaseActivityText>{articleMediaDeleteMessage}</BaseActivityText>}
    />
  );
};
