import { Plural, Trans } from '@lingui/react/macro';

import Stack from '@/design_system/Stack';
import IconTools from '@/icons/Tools.svg';
import { BrandRequestMainContent } from '@/layouts/Brand/BrandRequestLayout/BrandRequestLayout';
import { ClientRequestWithRelations } from '@/models/request';
import ClientArticlesTable from '@/routes/Brand/Requests/Request/components/shared/ClientArticlesTable';
import ClientStepper from '@/routes/Brand/Requests/Request/components/shared/ClientStepper';

const Repair = ({ request }: { request: ClientRequestWithRelations }) => {
  const activeArticlesInRepair = request.articles.filter(
    (article) => article.step?.step === 'repair' && !article.cancelledAt
  );

  return (
    <BrandRequestMainContent>
      <Stack gap="1.5rem">
        <ClientStepper request={request} />
        <Stack row gap="0.5rem">
          <IconTools className="color-tertiary-700" style={{ fontSize: '24px' }} />
          <p className="paragraph-50-regular paragraph-100-regular-mobile">
            {request.client ? (
              <Trans id="store.request.repair.text.client">
                Our experts are currently working on the{' '}
                <Plural value={activeArticlesInRepair.length} one="item" other="items" />. The
                client will be notified when it is finished.
              </Trans>
            ) : (
              <Trans id="store.request.repair.text">
                Our experts are currently working on the{' '}
                <Plural value={activeArticlesInRepair.length} one="item" other="items" />. You will
                be notified when it is finished.
              </Trans>
            )}
          </p>
        </Stack>
        <ClientArticlesTable request={request} showPrice={!!request.client} showArticleComment />
      </Stack>
    </BrandRequestMainContent>
  );
};

export default Repair;
