import { Trans, useLingui } from '@lingui/react/macro';

import { RadioCardYesNo } from '@/design_system/Radio/RadioCard/RadioCard';
import Stack from '@/design_system/Stack';
import { ClientArticleWithRelations } from '@/models/request';
import ArticleCard from '@/routes/Brand/Requests/New/components/Article/components/ArticleCard';
import { createBEMClasses } from '@/utils/classname';

import './AddAnotherDefect.css';

const { block } = createBEMClasses('client-add-another-defect');

const AddAnotherDefect = ({
  article,
  onEditArticle,
  onDeleteArticle,
  onYes,
  onNo,
}: {
  article: ClientArticleWithRelations;
  onEditArticle: () => void;
  onDeleteArticle: () => void;
  onYes: () => void;
  onNo: () => void;
}) => {
  const { t } = useLingui();

  return (
    <div className={block()}>
      <ArticleCard article={article} onEdit={onEditArticle} onDelete={onDeleteArticle} />
      <Stack gap="1.5rem" padding="32px 0">
        <h3 className="headline-300-bold">
          <Trans id="client.new.add-another-action.title">
            Do you need to add another service?
          </Trans>
        </h3>
        <RadioCardYesNo
          name="add-another-action"
          theme="brand"
          ariaLabel={t({
            id: 'client.new.add-another-action.title',
            message: `Do you need to add another service?`,
          })}
          onChange={(value) => {
            if (value === 'yes') {
              onYes();
            } else {
              onNo();
            }
          }}
          value={null}
          scrollToOnMount
        />
      </Stack>
    </div>
  );
};

export default AddAnotherDefect;
