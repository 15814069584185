import { Link } from 'react-router';
import { Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconComment from '@/icons/Comment.svg';
import IconEmail from '@/icons/Email.svg';

export const CommentAddedActivity = ({
  activity,
  isShipment,
  isArticle,
}: {
  activity: ActivityOfType<'comment_added'>;
  isShipment: boolean;
  isArticle: boolean;
}) => {
  const creatorName = activity.creator?.name;

  if (activity.data.visibility === 'client') {
    if (creatorName) {
      return (
        <BaseActivity
          createdAt={activity.createdAt}
          type="neutral"
          icon={<IconEmail />}
          message={
            <BaseActivityText>
              <Trans id="activities.comment_added.client.message">
                <strong>{creatorName}</strong> sent a new message to the client
              </Trans>
            </BaseActivityText>
          }
        />
      );
    } else if (activity.clientCreator) {
      return (
        <BaseActivity
          createdAt={activity.createdAt}
          type="neutral"
          icon={<IconEmail />}
          message={
            <BaseActivityText>
              <Trans id="activities.comment_added.client.from-client.message">
                <strong>The client</strong> sent a new message
              </Trans>
            </BaseActivityText>
          }
        />
      );
    } else {
      return null;
    }
  }

  if (!creatorName) {
    return null;
  }

  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="neutral"
      icon={<IconComment />}
      message={
        <BaseActivityText>
          {(!activity.shipmentId || isShipment) && (!activity.articleId || isArticle) && (
            <Trans id="activities.comment_added.request">
              <strong>{creatorName}</strong> added a new comment
            </Trans>
          )}
          {activity.shipmentId && !isShipment && (
            <Trans id="activities.comment_added.shipment">
              <strong>{creatorName}</strong> added a new comment on a{' '}
              <Link className="text-link" to={`/shipments/${activity.shipmentId}`}>
                shipment
              </Link>
            </Trans>
          )}
          {activity.articleId && !isArticle && (
            <Trans id="activities.comment_added.article">
              <strong>{creatorName}</strong> added a new comment on the item
              <Link className="text-link" to={`./articles/${activity.articleId}`}>
                <BaseActivityArticleName activity={activity as any} displayArticleName={true} />
              </Link>
            </Trans>
          )}
        </BaseActivityText>
      }
    />
  );
};
