import { Plural, Trans } from '@lingui/react/macro';

import Stack from '@/design_system/Stack';
import IconTruck from '@/icons/Truck.svg';
import { BrandRequestMainContent } from '@/layouts/Brand/BrandRequestLayout/BrandRequestLayout';
import { ClientArticleWithRelations, ClientRequestWithRelations } from '@/models/request';
import ClientArticlesTable from '@/routes/Brand/Requests/Request/components/shared/ClientArticlesTable';
import ClientStepper from '@/routes/Brand/Requests/Request/components/shared/ClientStepper';
import IconSuccess from '@/routes/Brand/Requests/Request/components/shared/IconSuccess';
import { PendingShipmentPreparation } from '@/routes/Brand/Requests/Request/components/shared/PendingShipmentPreparation';
import { PickupPointInfo } from '@/routes/Brand/Requests/Request/components/shared/PickupPointInfo/PickupPointInfo';

const Transit = ({ request }: { request: ClientRequestWithRelations }) => {
  const activeArticlesInTransit = request.articles.filter(
    (article) => article.step?.step === 'transit' && !article.cancelledAt
  );
  const isPendingTransit = activeArticlesInTransit.some(
    (article) => article.atClient || !!article.atStoreId
  );

  return (
    <BrandRequestMainContent>
      <Stack gap="1rem">
        <ClientStepper request={request} />
        {isPendingTransit &&
          (request.store ? (
            <PendingTransitFromStoreHeader
              request={request}
              articlesInTransit={activeArticlesInTransit}
            />
          ) : (
            <PendingShipmentPreparation
              request={request}
              articlesInTransit={activeArticlesInTransit}
            />
          ))}
        {!isPendingTransit && <InTransitHeader articlesInTransit={activeArticlesInTransit} />}
        <PickupPointInfo request={request} />
        <ClientArticlesTable request={request} showPrice showArticleComment />
      </Stack>
    </BrandRequestMainContent>
  );
};

const PendingTransitFromStoreHeader = ({
  request,
  articlesInTransit,
}: {
  request: ClientRequestWithRelations;
  articlesInTransit: ClientArticleWithRelations[];
}) => {
  return (
    <Stack gap="0.25rem">
      <Stack row gap="0.5rem" alignItems="center" flexWrap="nowrap">
        <IconSuccess />
        <p className="headline-200-bold headline-300-bold-mobile color-primary-800">
          <Trans id="client.request.transit.pending.title">
            Your care and repair service is confirmed
          </Trans>
        </p>
      </Stack>
      <p className="paragraph-50-regular paragraph-100-regular-mobile">
        <Trans id="client.request.transit.pending.store-shipment">
          Your <Plural value={articlesInTransit.length} one="item" other="items" /> will be sent to
          the repair workshop by the {request.store?.name} store.
        </Trans>
      </p>
    </Stack>
  );
};

const InTransitHeader = ({
  articlesInTransit,
}: {
  articlesInTransit: ClientArticleWithRelations[];
}) => {
  return (
    <>
      <Stack row gap="0.5rem">
        <IconTruck className="color-tertiary-700" style={{ fontSize: '24px' }} />
        <p className="paragraph-50-regular paragraph-100-regular-mobile">
          <Trans id="client.request.transit.in-transit.store-shipment">
            Your{' '}
            <Plural
              value={articlesInTransit.length}
              one="item is on its"
              other="items are on their"
            />{' '}
            way to the workshop to be taken care of.
          </Trans>
        </p>
      </Stack>
    </>
  );
};

export default Transit;
