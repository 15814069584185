import { useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import { RadioItemYesNo } from '@/design_system/Radio/RadioItem/RadioItem';
import IconEdit from '@/icons/Edit.svg';
import IconTrash from '@/icons/Trash.svg';
import { TEMPORARY_ID_PREFIX, useDeleteAction, useUpdateAction } from '@/models/article';
import { ArticleActionWithRelations } from '@/models/request';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import useViewPort from '@/utils/useViewport';

import { CreateEditActionDialog } from './CreateEditActionDialog';

export const BrandResponsibilityCell = ({
  action,
  previousAction,
  size = 'large',
  isDisabled,
}: {
  action?: ArticleActionWithRelations;
  previousAction?: ArticleActionWithRelations;
  size?: 'small' | 'large';
  isDisabled?: boolean;
}) => {
  const { t } = useLingui();

  const { article, request } = useArticleContext();

  const { mutate: updateAction } = useUpdateAction({
    articleId: article.id,
    requestId: request.id,
  });

  const selectedOptionValue = (action ?? previousAction!).brandResponsibility ? 'yes' : 'no';

  if (isDisabled) {
    return (
      <p className={size === 'small' ? 'paragraph-200-regular' : 'paragraph-100-regular'}>
        {selectedOptionValue === 'yes' && <Trans id="design-system.radio.yes">Yes</Trans>}
        {selectedOptionValue === 'no' && <Trans id="design-system.radio.no">No</Trans>}
      </p>
    );
  }

  return (
    <RadioItemYesNo
      name="brand-responsibility"
      ariaLabel={t({
        id: 'article.form.actions.table.column.brand-responsibility.label',
        message: 'Brand responsibility:',
      })}
      disabled={action?.id.startsWith(TEMPORARY_ID_PREFIX)}
      value={selectedOptionValue}
      onChange={(option) => {
        if (!action) {
          return;
        }

        updateAction({ actionId: action.id, body: { brandResponsibility: option === 'yes' } });
      }}
    />
  );
};

export const DeleteCell = ({ action }: { action: ArticleActionWithRelations }) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();
  const { article, request } = useArticleContext();
  const { mutate: deleteAction } = useDeleteAction({
    articleId: article.id,
    requestId: request.id,
  });

  return (
    <Button
      variant="secondary"
      size={isMobile ? 'small' : 'medium'}
      iconOnly
      tooltip={t({ id: 'components.input-quantity.remove', message: 'Remove' })}
      ariaLabel={t({ id: 'components.input-quantity.remove', message: 'Remove' })}
      disabled={action.id.startsWith(TEMPORARY_ID_PREFIX)}
      onPress={() => {
        if (
          action.media.length > 0 &&
          !confirm(
            t({
              id: 'action-tables.quantity.comfirm-deletion',
              message:
                'Are you sure you want to delete this action? The associated photos will be deleted as well.',
            })
          )
        ) {
          return;
        }

        deleteAction(action.id);
      }}
    >
      <IconTrash />
    </Button>
  );
};

export const DeleteButton = ({ action }: { action: ArticleActionWithRelations }) => {
  const { t } = useLingui();
  const { article, request } = useArticleContext();
  const { mutate: deleteAction } = useDeleteAction({
    articleId: article.id,
    requestId: request.id,
  });

  return (
    <Button
      variant="secondary"
      size="medium"
      ariaLabel={t({ id: 'action-tables.action.deletion', message: 'Remove' })}
      disabled={action.id.startsWith(TEMPORARY_ID_PREFIX)}
      onPress={() => {
        if (
          action.media.length > 0 &&
          !confirm(
            t({
              id: 'action-tables.action.confirm-deletion',
              message:
                'Are you sure you want to delete this action? The associated photos will be deleted as well.',
            })
          )
        ) {
          return;
        }

        deleteAction(action.id);
      }}
    >
      <IconTrash />
      <Trans id="action-tables.action.deletion">Remove</Trans>
    </Button>
  );
};

export const EditButton = ({
  action,
  previousAction,
}: {
  action: ArticleActionWithRelations;
  previousAction?: ArticleActionWithRelations;
}) => {
  const { t } = useLingui();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        variant="secondary"
        size="medium"
        ariaLabel={t({
          id: 'article.form.actions.table.column.edit.label',
          message: 'Edit action',
        })}
        disabled={action.id.startsWith(TEMPORARY_ID_PREFIX)}
        onPress={() => setIsOpen(true)}
      >
        <IconEdit />
        <Trans id="article.form.actions.table.column.edit">Edit</Trans>
      </Button>
      <CreateEditActionDialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        actionToEdit={action}
        previousAction={previousAction}
      />
    </>
  );
};
