import { PaymentStepConfig } from '@/api';

/**
 *
 * @param externalPaymentReference The external reference entered by the user
 * @returns If the value doesn't respect the format eventually provided by the organization
 */
export const isExternalPaymentReferenceInvalid = (
  externalPaymentReference: string | null | undefined,
  stepConfig: Extract<PaymentStepConfig['config'], { externalPayment: true }>
): boolean => {
  if (stepConfig.fields?.reference?.enabled) {
    if (stepConfig.fields?.reference.required && !externalPaymentReference?.length) {
      return true;
    }

    if (stepConfig.fields?.reference.format) {
      if (!externalPaymentReference) {
        return false;
      } else {
        return !externalPaymentReference.match(stepConfig.fields?.reference.format);
      }
    }
  }

  return false;
};
