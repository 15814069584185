import { useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconAdd from '@/icons/Add.svg';
import IconEdit from '@/icons/Edit.svg';
import IconTrash from '@/icons/Trash.svg';
import { useBrandRequestContext } from '@/layouts/Brand/BrandRequestContext';
import { useDeleteDefect } from '@/models/article';
import { getGroupedDefects, useGetDefectName } from '@/models/defectType';
import { ClientArticleWithRelations } from '@/models/request';
import EstimateDisclaimer from '@/routes/Brand/components/EstimateDisclaimer';
import { ArticleSummary } from '@/routes/Brand/Requests/New/components/Article/components/ArticleSummary/ArticleSummary';
import DeleteArticleModal from '@/routes/Brand/Requests/New/components/DeleteArticleModal';
import EmptyCartModal from '@/routes/Brand/Requests/New/components/EmptyCartModal';
import { createBEMClasses } from '@/utils/classname';
import useViewPort from '@/utils/useViewport';

import './CartContent.css';

const CartContent = ({
  onEditArticle,
  onAddDefectsToArticle,
  onEditDefectsOnArticle,
  onDeleteArticle,
  onDeleteAllArticles,
  onEditCart,
  collapseHeader = false,
  recap = false,
}: {
  onAddDefectsToArticle?: (index: number) => void;
  onEditDefectsOnArticle?: (data: { defectIds: string[]; articleIndex: number }) => void;
  onEditArticle?: (index: number) => void;
  onDeleteArticle?: (index: number) => void;
  onDeleteAllArticles?: () => void;
  onEditCart?: () => void;
  collapseHeader?: boolean;
  recap?: boolean;
}) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();
  const { request } = useBrandRequestContext();
  const [showEmptyCartModal, setShowEmptyCartModal] = useState(false);

  const isStoreOnlyRequest = request.store && !request.client;

  return (
    <Stack gap="1rem">
      <Stack
        row
        gap="0.5rem"
        justifyContent={collapseHeader ? 'flex-start' : 'space-between'}
        alignItems="center"
      >
        <Stack row gap="0.25rem" alignItems="baseline">
          <h3 className="headline-300-bold paragraph-50-medium-mobile">
            {recap ? (
              <Trans id="client.new.cart.title.recap">Cart recap</Trans>
            ) : (
              <Trans id="client.new.cart.title">Cart</Trans>
            )}
          </h3>
          {!recap && <span className="paragraph-100-medium">({request.defectQuantity})</span>}
        </Stack>
        {!recap && (
          <Stack row gap="0.5rem">
            <Button
              iconOnly
              variant="secondary-brand"
              size="small"
              tooltip={t({ id: 'client.new.cart.delete-cart', message: 'Delete cart' })}
              ariaLabel={t({ id: 'client.new.cart.delete-cart', message: 'Delete cart' })}
              onPress={() => setShowEmptyCartModal(true)}
            >
              <IconTrash />
            </Button>
            <EmptyCartModal
              isOpen={showEmptyCartModal}
              onOpenChange={setShowEmptyCartModal}
              onDelete={onDeleteAllArticles}
            />
          </Stack>
        )}
        {recap && (
          <Button
            iconOnly
            variant="secondary-brand"
            size="medium"
            tooltip={t({ id: 'client.new.cart.edit-cart', message: 'Edit cart' })}
            ariaLabel={t({ id: 'client.new.cart.edit-cart', message: 'Edit cart' })}
            onPress={onEditCart}
          >
            <IconEdit />
          </Button>
        )}
      </Stack>
      {request.articles.map((article, index) => (
        <Article
          key={article.id}
          article={article}
          onAddDefects={() => onAddDefectsToArticle?.(index)}
          onEdit={() => onEditArticle?.(index)}
          onEditDefects={(defectIds) => {
            onEditDefectsOnArticle?.({
              articleIndex: index,
              defectIds,
            });
          }}
          onDelete={() => {
            if (request.articles.length === 1) {
              onDeleteAllArticles?.();
            } else {
              onDeleteArticle?.(index);
            }
          }}
          recap={recap}
        />
      ))}
      {!isStoreOnlyRequest && !(recap && isMobile) && <EstimateDisclaimer />}
    </Stack>
  );
};

const { block } = createBEMClasses('cart-content-article');

const Article = ({
  article,
  onAddDefects,
  onEdit,
  onEditDefects,
  onDelete,
  recap,
}: {
  article: ClientArticleWithRelations;
  onAddDefects: () => void;
  onEdit: () => void;
  onEditDefects: (defectIds: string[]) => void;
  onDelete: () => void;
  recap?: boolean;
}) => {
  const { t } = useLingui();
  const getDefectName = useGetDefectName();
  const { request } = useBrandRequestContext();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { mutate: deleteDefect } = useDeleteDefect({
    articleId: article.id,
    requestId: request.id,
  });

  const removeDefects = (ids: string[]) => {
    if (groupedDefects.length === 1) {
      setShowDeleteModal(true);
      return;
    }

    ids.map((id) => deleteDefect(id));
  };

  const articleDefects = article.snapshot.articleDefects;
  const totalDefects = articleDefects.length;
  const groupedDefects = getGroupedDefects(articleDefects);

  return (
    <Stack className={block()} gap="0.75rem">
      <Stack row gap="0.5rem" alignItems="center" flexWrap="nowrap" justifyContent="space-between">
        <ArticleSummary article={article} size="small" />
        {!recap && (
          <Stack row gap="0.5rem">
            <Button
              iconOnly
              size="small"
              variant="secondary-brand"
              onPress={onAddDefects}
              tooltip={t({
                id: 'client.new.cart.article.add-action',
                message: 'Add other services',
              })}
              ariaLabel={t({
                id: 'client.new.cart.article.add-action',
                message: 'Add other services',
              })}
            >
              <IconAdd />
            </Button>
            <Button
              iconOnly
              size="small"
              variant="secondary-brand"
              onPress={onEdit}
              tooltip={t({ id: 'client.new.cart.article.edit', message: 'Edit item' })}
              ariaLabel={t({ id: 'client.new.cart.article.edit', message: 'Edit item' })}
            >
              <IconEdit />
            </Button>
            <Button
              iconOnly
              size="small"
              variant="secondary-brand"
              onPress={() => setShowDeleteModal(true)}
              tooltip={t({ id: 'client.new.cart.article.delete', message: 'Delete item' })}
              ariaLabel={t({ id: 'client.new.cart.article.delete', message: 'Delete item' })}
            >
              <IconTrash />
            </Button>
          </Stack>
        )}
      </Stack>
      <hr />
      {totalDefects > 0 ? (
        <>
          {groupedDefects.map((defect) => (
            <Stack key={defect.id} gap="0.25rem">
              <Stack gap="0.5rem" row>
                <p className="paragraph-100-regular" style={{ flex: 1 }}>
                  {getDefectName(defect)} {}
                  (x{defect.quantity})
                </p>
              </Stack>
              {!recap && (
                <ActionButtons
                  onEdit={() => onEditDefects(defect.ids)}
                  onRemove={() => removeDefects(defect.ids)}
                />
              )}
            </Stack>
          ))}
        </>
      ) : (
        <p className="paragraph-100-regular" style={{ paddingLeft: 8 }}>
          <Trans id="client.new.cart.no-actions">Please add a service or delete this item.</Trans>
        </p>
      )}
      <DeleteArticleModal
        lastArticle={request.articles.length === 1}
        articleId={article.id}
        isOpen={showDeleteModal}
        onOpenChange={setShowDeleteModal}
        onDelete={onDelete}
      />
    </Stack>
  );
};

const ActionButtons = ({ onEdit, onRemove }: { onEdit: () => void; onRemove: () => void }) => {
  const { t } = useLingui();

  return (
    <Stack row gap="0.5rem">
      <Button
        iconOnly
        size="small"
        variant="secondary-brand"
        onPress={onEdit}
        tooltip={t({ id: 'client.new.cart.action.edit', message: 'Edit service' })}
        ariaLabel={t({ id: 'client.new.cart.action.edit', message: 'Edit service' })}
      >
        <IconEdit />
      </Button>
      <Button
        iconOnly
        size="small"
        variant="secondary-brand"
        onPress={onRemove}
        tooltip={t({ id: 'client.new.cart.action.delete', message: 'Delete service' })}
        ariaLabel={t({ id: 'client.new.cart.action.delete', message: 'Delete service' })}
      >
        <IconTrash />
      </Button>
    </Stack>
  );
};

export default CartContent;
