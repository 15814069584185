import { Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import { BaseActivity, BaseActivityText } from '@/components/activities/BaseActivity/BaseActivity';
import IconEmail from '@/icons/Email.svg';

export const PendingBillPaymentNotifiedActivity = ({
  activity,
}: {
  activity: ActivityOfType<'pending_bill_payment_notified'>;
}) => {
  const creatorName = activity.creator?.name;

  return (
    <BaseActivity
      createdAt={activity.createdAt}
      icon={<IconEmail />}
      message={
        <BaseActivityText>
          {creatorName ? (
            <Trans id="activities.pending_bill_payment_notified.message">
              <strong>{creatorName}</strong> sent an email reminder about the final quote to the
              client
            </Trans>
          ) : (
            <Trans id="activities.pending_bill_payment_notified.message-no-creator">
              The final quote has been sent by email to the client
            </Trans>
          )}
        </BaseActivityText>
      }
    />
  );
};
