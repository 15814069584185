import { createContext, ReactNode, useContext } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';

import { useLabelContext } from '@/design_system/Label/Label';
import { createBEMClasses } from '@/utils/classname';

import { RadioShape } from '../shared/RadioShape/RadioShape';

import './RadioItem.css';

const { block, element } = createBEMClasses('radio__item');

interface RadioItemGroupContext {
  name: string;
  value: string | null;
  onChange: (value: string) => void;
  disabled?: boolean;
  invalid?: boolean;
  theme?: 'default' | 'brand';
}

const RadioItemGroupContext = createContext<RadioItemGroupContext>({
  name: '',
  value: null,
  onChange: () => {},
});

export function RadioItem<T extends string>({
  value,
  ariaLabel,
  disabled,
  invalid,
  children,
}: {
  value: T;
  ariaLabel: string;
  disabled?: boolean;
  invalid?: boolean;
  children?: ReactNode;
}) {
  const {
    name,
    value: groupValue,
    onChange,
    disabled: groupDisabled,
    invalid: groupInvalid,
    theme,
  } = useContext(RadioItemGroupContext);

  const isChecked = value === groupValue;
  const isDisabled = disabled || groupDisabled;
  const isInvalid = invalid || groupInvalid;

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={block({ checked: isChecked, disabled: isDisabled, invalid: isInvalid })}
        onClick={() => {
          if (!isDisabled) {
            onChange(value);
          }
        }}
      >
        <input
          type="radio"
          name={name}
          value={value}
          checked={isChecked}
          aria-label={ariaLabel}
          onChange={() => onChange(value)}
          disabled={isDisabled}
        />
        <div className={element('content')}>
          <RadioShape checked={isChecked} disabled={isDisabled} invalid={isInvalid} theme={theme} />
          <div className={element('label')}>{children}</div>
        </div>
      </div>
    </>
  );
}

export interface RadioItemGroupProps<T extends string> {
  name: string;
  ariaLabel?: string;
  value?: T | null;
  onChange?: (value: T) => void;
  disabled?: boolean;
  invalid?: boolean;
  direction?: 'row' | 'column';
  alignment?: 'start' | 'end';
  theme?: 'default' | 'brand';
  children?: ReactNode;
}

export function RadioItemGroup<T extends string>({
  name,
  ariaLabel,
  value = null,
  onChange = () => {},
  disabled,
  invalid,
  direction = 'row',
  alignment = 'start',
  theme = 'default',
  children,
}: RadioItemGroupProps<T>) {
  /**
   * Radio inputs cannot have a single html label for all radio inputs.
   * Therefore, we don't use the `id` here, and use the label as aria-label to be selectable by Playwright.
   */
  const { label } = useLabelContext();

  return (
    <div
      className={element('group', { direction, alignment, theme })}
      aria-label={ariaLabel ?? label}
    >
      <RadioItemGroupContext
        value={{
          name,
          value,
          onChange: onChange as (value: string) => void,
          disabled,
          invalid,
          theme,
        }}
      >
        {children}
      </RadioItemGroupContext>
    </div>
  );
}

export const RadioItemYesNo = (props: Omit<RadioItemGroupProps<'yes' | 'no'>, 'children'>) => {
  const { t } = useLingui();

  return (
    <RadioItemGroup {...props}>
      <RadioItem
        value="yes"
        ariaLabel={t({
          id: 'design-system.radio.yes',
          message: 'Yes',
        })}
      >
        <Trans id="design-system.radio.yes">Yes</Trans>
      </RadioItem>
      <RadioItem
        value="no"
        ariaLabel={t({
          id: 'design-system.radio.no',
          message: 'No',
        })}
      >
        <Trans id="design-system.radio.no">No</Trans>
      </RadioItem>
    </RadioItemGroup>
  );
};
