import { Trans } from '@lingui/react/macro';

import Stack from '@/design_system/Stack';
import IconAddToList from '@/icons/AddToList.svg';
import { ActionTypeOrganizationWithRelations } from '@/models/actionType';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import { useCurrentSession } from '@/services/auth';
import { formatCurrency } from '@/utils/number';

import ActionName from './ActionName';

export const ActionDropdownItem = ({ action }: { action: ActionTypeOrganizationWithRelations }) => {
  const { isWorkshop } = useCurrentSession();

  const { view: requestView } = useRequestContext();

  const showWorkshopPrice = isWorkshop ? requestView.price.enabled : requestView.cost.enabled;
  const showOrganizationPrice = isWorkshop ? false : requestView.price.enabled;

  const showWorkshopAmountBeforeTaxes = isWorkshop
    ? requestView.price.showAmountBeforeTaxes
    : requestView.cost.showAmountBeforeTaxes;

  const showOrganizationAmountBeforeTaxes = isWorkshop
    ? false
    : requestView.price.showAmountBeforeTaxes;

  return (
    <Stack
      row
      alignItems="center"
      justifyContent="space-between"
      gap="0.5rem"
      flexWrap="nowrap"
      style={{ flex: 1 }}
    >
      <ActionName
        actionType={action.actionType}
        refashionStatus={!!action.refashionBonus && !isWorkshop ? 'applied' : undefined}
        style={{ textAlign: 'start' }}
      />
      <Stack row alignItems="center" gap="0.5rem" flexWrap="nowrap">
        {showWorkshopPrice && (
          <p className="paragraph-100-medium">
            {formatCurrency(
              showWorkshopAmountBeforeTaxes
                ? action.dynamicCost?.amountBeforeTaxes
                : action.dynamicCost?.amount,
              action.dynamicCost?.currency
            )}
          </p>
        )}
        {showOrganizationPrice && (
          <p className="paragraph-100-medium">
            {formatCurrency(
              showOrganizationAmountBeforeTaxes
                ? action.dynamicPrice?.amountBeforeTaxes
                : action.dynamicPrice?.amount,
              action.dynamicPrice?.currency
            )}
          </p>
        )}
      </Stack>
    </Stack>
  );
};

export const CustomActionDropdownItem = ({ style }: { style?: React.CSSProperties }) => {
  return (
    <Stack row gap="0.5rem" alignItems="center" style={{ flex: 1, ...style }}>
      <IconAddToList style={{ fontSize: '1.25rem' }} />
      <p className="paragraph-100-regular">
        <Trans id="article.form.actions.add-custom-action">Add a custom action</Trans>
      </p>
    </Stack>
  );
};
