import { useLingui } from '@lingui/react/macro';

import FileUpload from '@/components/FileUpload';
import IconReceipt from '@/icons/Receipt.svg';
import { useMedia } from '@/models/medium';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';

const ProofOfPurchase = () => {
  const { t } = useLingui();

  const { article, errors } = useArticleContext();

  const { data: { media } = { media: [] } } = useMedia({
    articleId: article?.id,
    types: ['proof-of-purchase'],
  });

  return (
    <FileUpload
      uploadData={{
        type: 'proof-of-purchase',
        articleId: article.id,
      }}
      ariaLabel={t({
        id: 'article.form.proof-of-purchase.aria-label',
        message: 'Upload proof of purchase',
      })}
      prompt={t({
        id: 'article.form.proof-of-purchase.prompt',
        message: 'Add a proof of purchase',
      })}
      icon={<IconReceipt />}
      type="file"
      label={t({ id: 'article.form.proof-of-purchase.label', message: 'Proof of purchase' })}
      media={media}
      error={
        errors.warranty?.missingProofOfPurchase
          ? t({
              id: 'article.form.proof-of-purchase.error',
              message: 'A proof of purchase is required',
            })
          : undefined
      }
      deleteWithApi
    />
  );
};

export default ProofOfPurchase;
