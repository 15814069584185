import { Medium } from '@/models/medium';
import { hasColorOptions, hasSizeOptions } from '@/models/product';
import { ArticleWithRelations, RequestWithRelations } from '@/models/request';
import { UserWithRelations } from '@/models/user';

export function computeRequestErrors({
  request,
  currentSession,
  proofOfPurchaseMedia,
}: {
  request: RequestWithRelations;
  currentSession: UserWithRelations;
  proofOfPurchaseMedia: Medium[];
}) {
  return {
    articles: {
      // We only need to compute the errors of active articles
      articles: request.articles.map((article) => ({
        id: article.id,
        ...computeArticleErrors({
          article,
          currentSession,
          proofOfPurchaseMedia: proofOfPurchaseMedia.filter(
            (media) => media.articleId === article.id
          ),
        }),
      })),
      noArticles: request.articles.length === 0,
    },
  };
}

export type RequestErrors = ReturnType<typeof computeRequestErrors>;
export type RequestErrorsFilter = ErrorsFilter<RequestErrors>;
export type FilteredRequestErrors = FilteredErrors<RequestErrors>;

export function computeArticleErrors({
  article,
  currentSession,
  proofOfPurchaseMedia,
}: {
  article: ArticleWithRelations;
  currentSession: UserWithRelations;
  proofOfPurchaseMedia: Medium[];
}) {
  return {
    details: {
      product: !article.product && !article.productL1,
      productL2: !article.product && !!article.productL1 && !article.productL2,
      productL3: !article.product && !!article.productL1 && !article.productL3,
      otherBrandEmpty: !!article?.data.brand?.isOther && !article?.data.brand?.name,
      missingColor: hasColorOptions(article.product) && !article?.data.color,
      missingSize: hasSizeOptions(article.product, article.productL1) && !article?.data.size,
      missingArticlePhoto: !article.articlePhoto,
      customs:
        !!article?.areCustomsExpected &&
        !article?.hasRequiredCustomsInfo &&
        currentSession.hasPermission('edit_article', {
          organizationId: article.organizationId,
        }),
    },
    warranty: {
      missingPurchaseDate: article.warranty && !article.purchaseDate,
      invalidPurchaseDate:
        article.warranty && !!article.purchaseDateDate && article.purchaseDateDate > new Date(),
      missingProofOfPurchase: article.warranty && proofOfPurchaseMedia.length === 0,
    },
    services: {
      defects: {
        noDefects: article.snapshot.articleDefects.length === 0,
        defects: article.snapshot.articleDefects.map((defect) => ({
          id: defect.id,
          missingDescription: defect.isCustom && !defect.customDescription,
          missingPhotos: defect.media.length === 0,
          missingActions:
            article.serviceChoice === 'care-repair' &&
            defect.toBeRepaired &&
            defect.articleActions.length === 0,
        })),
      },
      choice: {
        noChoice: !article.serviceChoice,
      },
      actions: {
        noActions: article.snapshot.articleActions.length === 0,
        actions: article.snapshot.articleActions.map((action) => ({
          id: action.id,
          missingDescription: action.isCustom && !action.customDescription,
          missingPhotos: action.media.length === 0,
          missingCost:
            action.isCustom &&
            (currentSession.workshop ? false : action.customWorkshopPrice === null),
          missingCustomPrice:
            action.isCustom &&
            (currentSession.workshop
              ? action.customWorkshopPrice === null
              : action.customOrganizationPrice === null),
        })),
      },
      dispatch: {
        noWorkshopSelected: !article?.workshopId,
      },
    },
  };
}

export type ArticleErrors = ReturnType<typeof computeArticleErrors>;
export type ArticleErrorsFilter = ErrorsFilter<ArticleErrors>;
export type FilteredArticleErrors = FilteredErrors<ArticleErrors>;

type Errors = { [key: string]: string | boolean | Errors | Errors[] };
type ErrorsFilter<T extends Errors> = {
  [K in Exclude<keyof T, 'id'>]?: T[K] extends Errors
    ? ErrorsFilter<T[K]>
    : T[K] extends Array<infer U>
      ? U extends Errors
        ? ErrorsFilter<U> | (ErrorsFilter<U> & { id: string })[]
        : never
      : boolean;
};
type FilteredErrors<T extends Errors> = {
  [K in Exclude<keyof T, 'id'>]?: T[K] extends Errors
    ? FilteredErrors<T[K]>
    : T[K] extends Array<infer U>
      ? U extends Errors
        ? (FilteredErrors<U> & { id: string })[]
        : never
      : boolean;
} & {
  hasError: boolean;
};

export function recursivelyFilterErrors<T extends Errors>(
  errors: T,
  errorsFilter: ErrorsFilter<T>
): FilteredErrors<T> {
  let hasError = false;
  const filteredErrors: any = {};

  for (const key in errorsFilter) {
    if (typeof errors[key] === 'boolean') {
      if (errorsFilter[key] === true && errors[key] === true) {
        filteredErrors[key] = true;
        hasError = true;
      }
    }

    if (typeof errors[key] === 'object') {
      if (Array.isArray(errors[key])) {
        filteredErrors[key] = errors[key].map(({ id, ...subErrors }) => {
          if (Array.isArray(errorsFilter[key])) {
            return {
              id,
              ...recursivelyFilterErrors(
                subErrors,
                (errorsFilter[key].find((filter) => filter.id === id) as ErrorsFilter<T>) ?? {}
              ),
            };
          }

          return {
            id,
            ...recursivelyFilterErrors(subErrors, errorsFilter[key] as ErrorsFilter<T>),
          };
        });
        hasError = filteredErrors[key].some((error: any) => error.hasError as boolean) || hasError;
      } else {
        filteredErrors[key] = recursivelyFilterErrors(
          errors[key],
          errorsFilter[key] as ErrorsFilter<T>
        );
        hasError = filteredErrors[key].hasError || hasError;
      }
    }
  }

  return {
    ...filteredErrors,
    hasError,
  } as FilteredErrors<T>;
}
