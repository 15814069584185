import { createBEMClasses } from '@/utils/classname';

import './RadioShape.css';

const { block, element } = createBEMClasses('radio__shape');

export const RadioShape = ({
  checked,
  disabled,
  invalid,
  theme = 'default',
}: {
  checked?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  theme?: 'default' | 'brand';
}) => {
  return (
    <div className={block({ checked, disabled, invalid, theme })}>
      <svg
        className={element('check')}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.151 3.33945C10.4468 3.62943 10.4515 4.10428 10.1615 4.40006L5.9942 8.65066C5.85315 8.79454 5.66013 8.87561 5.45865 8.87561C5.25716 8.87561 5.06415 8.79454 4.9231 8.65066L2.83945 6.52536C2.54947 6.22958 2.55417 5.75473 2.84994 5.46475C3.14572 5.17477 3.62057 5.17947 3.91055 5.47525L5.45865 7.05429L9.09039 3.34994C9.38037 3.05417 9.85522 3.04947 10.151 3.33945Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
