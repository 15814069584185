import { GridList, GridListItem } from 'react-aria-components';
import { Link } from 'react-router';
import { Trans, useLingui } from '@lingui/react/macro';

import Badge from '@/design_system/Badge';
import Button from '@/design_system/Button';
import Checkbox from '@/design_system/Checkbox';
import InputSearch from '@/design_system/InputSearch';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import { Body, Cell, Column, Footer, Header, Row, Table } from '@/design_system/Table/Table';
import IconCalendarDone from '@/icons/CalendarDone.svg';
import { useGetArticleName } from '@/models/article';
import { InvoiceWithMoreRelations, useUpdateInvoice } from '@/models/invoice';
import { useGetInvoiceLineName, useInvoiceLines } from '@/models/invoiceLine';
import { formatDate } from '@/utils/date';
import { formatCurrency } from '@/utils/number';
import useDebouncedState from '@/utils/useDebouncedState';
import useViewPort from '@/utils/useViewport';

import { useInvoiceContext } from '../Invoice';

export const InvoiceLines = () => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();
  const { invoice, isDisabled, showErrors, errors } = useInvoiceContext();
  const { mutate: updateInvoice } = useUpdateInvoice(invoice.id);

  const isSelectionEnabled =
    !isDisabled && !!invoice.originWorkshopId && !!invoice.destinationOrganizationCountryId;

  const [search, debouncedSearch, setSearch] = useDebouncedState<string>('', 500);

  const { data: { invoiceLines } = { invoiceLines: [] }, isFetching } = useInvoiceLines(
    {
      search: debouncedSearch || undefined,
      invoiceId: invoice.id,
      originWorkshop: invoice.originWorkshopId ?? undefined,
      destinationOrganizationCountry: invoice.destinationOrganizationCountryId ?? undefined,
      paymentStrategy:
        invoice.paymentStrategy === 'handled-by-accounting-team' ||
        invoice.paymentStrategy === 'store-cash-out'
          ? invoice.paymentStrategy
          : undefined,
      limit: 100,
    },
    {
      enabled: isSelectionEnabled,
      keepPreviousData: true,
    }
  );

  return (
    <Stack gap="8px">
      <h2 className="label-100">
        <Trans id="invoice.lines.title">Services associated</Trans>
      </h2>

      {isDisabled &&
        (isMobile ? (
          <InvoiceLinesList lines={invoice.lines} />
        ) : (
          <InvoiceLinesTable lines={invoice.lines} />
        ))}

      {!isDisabled && (
        <>
          {!isSelectionEnabled && (
            <p className="paragraph-100-regular">
              <Trans id="invoice.lines.no-services">
                No services to display yet. Please select an issuer and a receiver first
              </Trans>
            </p>
          )}
          {isSelectionEnabled && (
            <>
              {showErrors && errors.lines && (
                <Message type="error">
                  <Trans id="invoice.lines.no-services-error">
                    Please associate some services to the invoice
                  </Trans>
                </Message>
              )}

              <InputSearch
                ariaLabel={t({
                  id: 'invoice.lines.search',
                  message: 'Search by service or item...',
                })}
                placeholder={t({
                  id: 'invoice.lines.search',
                  message: 'Search by service or item...',
                })}
                value={search}
                onChange={setSearch}
                isLoading={isFetching}
              />

              {isMobile ? (
                <InvoiceLinesList
                  lines={invoiceLines}
                  selectedLines={invoice.lines}
                  onSelect={(lines) =>
                    updateInvoice({
                      invoiceLines: lines,
                    })
                  }
                  isFetching={isFetching}
                />
              ) : (
                <InvoiceLinesTable
                  lines={invoiceLines}
                  selectedLines={invoice.lines}
                  onSelect={(lines) =>
                    updateInvoice({
                      invoiceLines: lines,
                    })
                  }
                  isFetching={isFetching}
                />
              )}
            </>
          )}
        </>
      )}
    </Stack>
  );
};

const InvoiceLinesTable = ({
  lines,
  selectedLines,
  onSelect,
  isFetching,
}: {
  lines: InvoiceWithMoreRelations['lines'];
  selectedLines?: InvoiceWithMoreRelations['lines'];
  onSelect?: (selectedLines: InvoiceWithMoreRelations['lines']) => void;
  isFetching?: boolean;
}) => {
  const { t } = useLingui();

  const getInvoiceLineName = useGetInvoiceLineName();
  const getArticleName = useGetArticleName();

  const isSelectionEnabled = !!selectedLines && !!onSelect;
  const showCheckboxColumn = isSelectionEnabled && !!lines.length;
  const someAreSelected = lines.some((line) => selectedLines?.find(({ id }) => id === line.id));
  const allAreSelected =
    someAreSelected && lines.every((line) => selectedLines?.find(({ id }) => id === line.id));

  return (
    <Table
      ariaLabel={t({ id: 'invoice.lines.table.label', message: 'Services' })}
      columnWidths={[
        showCheckboxColumn ? '40px' : undefined,
        'minmax(200px, 1fr)',
        'minmax(200px, 1fr)',
        'minmax(128px, auto)',
        'minmax(128px, auto)',
      ]}
      bordered
    >
      <Header>
        <Row>
          {showCheckboxColumn && (
            <Column>
              <Checkbox
                aria-label={
                  allAreSelected
                    ? t({
                        id: 'invoice.lines.checkbox.all.unselect',
                        message: `Remove all services`,
                      })
                    : t({
                        id: 'invoice.lines.checkbox.all.select',
                        message: `Select all services`,
                      })
                }
                isSelected={allAreSelected}
                isIndeterminate={someAreSelected && !allAreSelected}
                onChange={(isSelected) => onSelect(isSelected ? lines : [])}
              />
            </Column>
          )}
          <Column>
            <Trans id="invoice.lines.table.service">Service</Trans>
          </Column>
          <Column>
            <Trans id="invoice.lines.table.item">Item</Trans>
          </Column>
          <Column>
            <Trans id="invoice.lines.table.date">Date</Trans>
          </Column>
          <Column justifyContent="flex-end">
            <Trans id="invoice.lines.table.amount">Amount</Trans>
          </Column>
        </Row>
      </Header>
      <Body>
        {lines.map((line) => (
          <Row key={line.id}>
            {isSelectionEnabled && (
              <Cell>
                <Checkbox
                  aria-label={
                    selectedLines.find(({ id }) => id === line.id)
                      ? t({ id: 'invoice.lines.checkbox.unselect', message: `Remove service` })
                      : t({ id: 'invoice.lines.checkbox.select', message: `Select service` })
                  }
                  isSelected={!!selectedLines.find(({ id }) => id === line.id)}
                  onChange={(isSelected) =>
                    onSelect(
                      isSelected
                        ? [...selectedLines, line]
                        : selectedLines.filter(({ id }) => id !== line.id)
                    )
                  }
                />
              </Cell>
            )}
            <Cell>{getInvoiceLineName(line)}</Cell>
            <Cell>
              <Stack alignItems="flex-start">
                {!!line.article && (
                  <Button
                    variant="link-subtle"
                    to={`/requests/${line.request.id}/articles/${line.article.id}`}
                    className="paragraph-100-medium text-primary"
                  >
                    {getArticleName({ article: line.article })}
                  </Button>
                )}
                <Button
                  variant="link-subtle"
                  to={`/requests/${line.request.id}`}
                  className="paragraph-200-light"
                >
                  {line.request.reference}
                </Button>
              </Stack>
            </Cell>
            <Cell>{formatDate(new Date(line.createdAt), { dateStyle: 'medium' })}</Cell>
            <Cell justifyContent="flex-end">
              {formatCurrency(line.price.amount, line.price.currency)}
            </Cell>
          </Row>
        ))}
      </Body>
      {isSelectionEnabled && !lines.length && !isFetching && (
        <Footer>
          <Row>
            <Cell isWholeRow>
              <p className="paragraph-100-regular">
                <Trans id="invoice.lines.no-services-criteria">
                  No services match your search criteria.
                </Trans>
              </p>
            </Cell>
          </Row>
        </Footer>
      )}
    </Table>
  );
};

const InvoiceLinesList = ({
  lines,
  selectedLines,
  onSelect,
  isFetching,
}: {
  lines: InvoiceWithMoreRelations['lines'];
  selectedLines?: InvoiceWithMoreRelations['lines'];
  onSelect?: (selectedLines: InvoiceWithMoreRelations['lines']) => void;
  isFetching?: boolean;
}) => {
  const { t } = useLingui();

  const getInvoiceLineName = useGetInvoiceLineName();
  const getArticleName = useGetArticleName();

  const isSelectionEnabled = !!selectedLines && !!onSelect;

  if (!lines.length) {
    if (isSelectionEnabled && !isFetching) {
      return (
        <p className="paragraph-100-regular">
          <Trans id="invoice.lines.no-services-criteria">
            No services match your search criteria.
          </Trans>
        </p>
      );
    }

    return null;
  }

  return (
    <GridList
      style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
      aria-label={t({ id: 'invoice.lines.table.label', message: 'Services' })}
    >
      {lines.map((line) => (
        <GridListItem
          key={line.id}
          style={{
            padding: '12px',
            border: '1px solid var(--color-neutral-300)',
            borderRadius: '8px',
          }}
          textValue={getInvoiceLineName(line)}
        >
          <Stack row gap="12px" alignItems="center">
            {isSelectionEnabled && (
              <Checkbox
                aria-label={
                  selectedLines.find(({ id }) => id === line.id)
                    ? t({ id: 'invoice.lines.checkbox.unselect', message: `Remove service` })
                    : t({ id: 'invoice.lines.checkbox.select', message: `Select service` })
                }
                isSelected={!!selectedLines.find(({ id }) => id === line.id)}
                onChange={(isSelected) =>
                  onSelect(
                    isSelected
                      ? [...selectedLines, line]
                      : selectedLines.filter(({ id }) => id !== line.id)
                  )
                }
              />
            )}
            <Stack gap="8px" style={{ flex: 1, minWidth: 0 }}>
              <Stack
                row
                alignItems="center"
                justifyContent="space-between"
                flexWrap="nowrap"
                gap="4px"
                style={{ minWidth: 0 }}
              >
                <Stack style={{ minWidth: 0 }}>
                  <span
                    className="paragraph-100-regular"
                    style={{
                      minWidth: 0,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {getInvoiceLineName(line)}
                  </span>
                  <span className="paragraph-200-regular text-secondary">
                    {!!line.article && (
                      <>
                        <Link
                          to={`/requests/${line.request.id}/articles/${line.article.id}`}
                          className="reset-link"
                        >
                          {getArticleName({ article: line.article })}
                        </Link>{' '}
                        •{' '}
                      </>
                    )}
                    <Link to={`/requests/${line.request.id}`} className="reset-link">
                      {line.request.reference}
                    </Link>
                  </span>
                </Stack>
                <span className="paragraph-100-regular">
                  {formatCurrency(line.price.amount, line.price.currency)}
                </span>
              </Stack>
              <div>
                <Badge color="neutral" size="large" hasBorder borderRadius="soft">
                  <Stack
                    row
                    gap="4px"
                    alignItems="center"
                    className="paragraph-200-regular text-primary"
                  >
                    <IconCalendarDone style={{ fontSize: '1rem' }} />
                    <span>{formatDate(new Date(line.createdAt), { dateStyle: 'medium' })}</span>
                  </Stack>
                </Badge>
              </div>
            </Stack>
          </Stack>
        </GridListItem>
      ))}
    </GridList>
  );
};
