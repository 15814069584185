import { Trans, useLingui } from '@lingui/react/macro';
import { addDays, isAfter, sub } from 'date-fns';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import { useShowToast } from '@/design_system/Toast';
import IconEmail from '@/icons/Email.svg';
import { Organization } from '@/models/organization';
import { useActivities, useClientReminder } from '@/models/request';
import { useCurrentOrganization } from '@/services/auth';
import { formatDate } from '@/utils/date';

const activityForType = {
  validation: 'pending_validation_notified',
  payment: 'pending_bill_payment_notified',
  pickup: 'pending_pickup_notified',
} as const;

const reminderConfigForType = {
  validation: 'pendingValidation',
  payment: 'pendingPayment',
  pickup: 'pendingPickup',
} as const;

export const PendingClientActionReminder = ({
  id,
  type,
}: {
  id: string;
  type: 'validation' | 'payment' | 'pickup';
}) => {
  const { t } = useLingui();
  const showToast = useShowToast();
  const [organization] = useCurrentOrganization();
  const { mutateAsync: sendClientReminder, isPending: isLoading } = useClientReminder();

  const { data: { activities } = { activities: [] } } = useActivities({
    requestId: id,
    types: [activityForType[type]],
  });

  const halfAnHourAgo = new Date(Date.now() - 30 * 60 * 1000);

  const latestActivity = activities[0];
  const latestActivityCreationDate = latestActivity ? new Date(latestActivity.createdAt) : null;

  const oldestActivity = activities[activities.length - 1];
  const oldestActivityCreationDate = oldestActivity ? new Date(oldestActivity.createdAt) : null;

  const canSendReminder =
    activities.length === 0 ||
    (latestActivityCreationDate && latestActivityCreationDate < halfAnHourAgo);

  const latestEmailSendingType = latestActivity && latestActivity.creatorId ? 'manual' : 'auto';

  const cannotSendReminderLabel = t({
    id: 'pending-client-action-reminder.send-reminder.too-soon',
    message: 'The last reminder was sent less than 30 minutes ago, please try again later',
  });

  const lastEmailSentOnLabel =
    latestEmailSendingType === 'auto' ? (
      <Trans id="pending-client-action-reminder.last-reminder.auto">
        Last email sent automatically on
      </Trans>
    ) : (
      <Trans id="pending-client-action-reminder.last-reminder.manual">
        Last email sent manually on
      </Trans>
    );

  const nextAutomaticReminderDate = computeNextAutomaticReminderDate({
    organization,
    latestActivityCreationDate,
    oldestActivityCreationDate,
    type,
  });

  const lastReminderSentDate = latestActivityCreationDate
    ? formatDate(latestActivityCreationDate, { dateStyle: 'short' })
    : undefined;
  const lastReminderSentTime = latestActivityCreationDate
    ? formatDate(latestActivityCreationDate, { timeStyle: 'short' })
    : undefined;

  return (
    <Stack alignItems="flex-start" gap="0.5rem">
      <Button
        variant="secondary"
        size="medium"
        onPress={() => {
          if (!canSendReminder) {
            showToast({
              text: cannotSendReminderLabel,
              type: 'warning',
            });
            return;
          }

          sendClientReminder({ id, body: { type } })
            .then(() => {
              showToast({
                text: t({
                  id: 'pending-client-action-reminder.send-reminder.success',
                  message: 'Your email reminder has been successfully sent',
                }),
                type: 'success',
              });
            })
            .catch(() => {
              showToast({
                text: t({
                  id: 'pending-client-action-reminder.send-reminder.error',
                  message: 'An error occurred, please try again',
                }),
                type: 'error',
              });
            });
        }}
        disabled={isLoading}
        isLoading={isLoading}
      >
        <IconEmail />
        <Trans id="pending-client-action-reminder.send-reminder">Send a reminder</Trans>
      </Button>

      <div>
        {!!latestActivityCreationDate && (
          <Stack row gap="4px" className="paragraph-200-regular">
            {lastEmailSentOnLabel}
            <span className="paragraph-200-medium">
              <Trans id="pending-client-action-reminder.last-reminder-date">
                {lastReminderSentDate} at {lastReminderSentTime}
              </Trans>
            </span>
          </Stack>
        )}
        {!!nextAutomaticReminderDate && (
          <Stack row gap="4px" className="paragraph-200-regular">
            <Trans id="pending-client-action-reminder.next-automatic-reminder-date">
              An automatic reminder will be sent on
            </Trans>
            <span className="paragraph-200-medium">
              {formatDate(nextAutomaticReminderDate, { dateStyle: 'short' })}
            </span>
          </Stack>
        )}
      </div>
    </Stack>
  );
};

/**
 *
 * Depending on organization's config and when the last reminder (manual or automatic)
 * was sent, compute the next automatic reminder date
 */
const computeNextAutomaticReminderDate = ({
  organization,
  latestActivityCreationDate,
  oldestActivityCreationDate,
  type,
}: {
  organization: Organization | null;
  latestActivityCreationDate: Date | null;
  oldestActivityCreationDate: Date | null;
  type: 'validation' | 'payment' | 'pickup';
}): Date | undefined => {
  const reminderConfigType = reminderConfigForType[type];
  const orgAutomaticReminderConfigForType =
    organization?.config.automaticReminders?.client?.[reminderConfigType];

  if (
    !orgAutomaticReminderConfigForType?.enabled ||
    !latestActivityCreationDate ||
    !oldestActivityCreationDate
  ) {
    return;
  }

  const { reminderIntervalDays, reminderExpirationDays } = orgAutomaticReminderConfigForType;

  let isOldestActivityYoungEnough = true;

  if (reminderExpirationDays) {
    const reminderExpirationDaysAgo = sub(new Date(), { days: reminderExpirationDays });
    isOldestActivityYoungEnough = isAfter(oldestActivityCreationDate, reminderExpirationDaysAgo);
  }

  if (!isOldestActivityYoungEnough) {
    return;
  }

  const nextAutomaticReminderDate = addDays(latestActivityCreationDate, reminderIntervalDays);

  return nextAutomaticReminderDate;
};
