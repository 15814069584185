import { Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconRepairValidation from '@/icons/RepairValidation.svg';

export const ArticleRepairCompletedActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_repair_completed'>;
  displayArticleName: boolean;
}) => {
  const workshopName = activity.workshop.name;
  const creatorName = activity.creator?.name;

  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="neutral"
      icon={<IconRepairValidation />}
      message={
        <BaseActivityText>
          {activity.data.onBehalfOfWorkshop ? (
            <Trans id="activities.article_repair_completed.message.on-behalf">
              <strong>{creatorName}</strong> completed the care and repair job on behalf of{' '}
              <strong>{workshopName}</strong> for the item{' '}
              <BaseActivityArticleName
                activity={activity}
                displayArticleName={displayArticleName}
              />
            </Trans>
          ) : (
            <Trans id="activities.article_repair_completed.message">
              <strong>{creatorName}</strong> completed the care and repair job of the item{' '}
              <BaseActivityArticleName
                activity={activity}
                displayArticleName={displayArticleName}
              />
            </Trans>
          )}
        </BaseActivityText>
      }
    />
  );
};
