import { Trans } from '@lingui/react/macro';

import type { Permission } from '@/api';
import { UserAvatar } from '@/components/UserAvatar/UserAvatar';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconChevron from '@/icons/Chevron.svg';
import IconHelp from '@/icons/Help.svg';
import IconInvoice from '@/icons/Invoice.svg';
import IconLogout from '@/icons/Logout.svg';
import IconSettings from '@/icons/Settings.svg';
import { useDeleteCurrentSession } from '@/models/user';
import { useCurrentOrganization, useCurrentSession } from '@/services/auth';
import useViewport from '@/utils/useViewport';

export const Menu = () => {
  const { isMobile } = useViewport();
  const { currentSession } = useCurrentSession();
  const {
    mutateAsync: deleteCurrentSession,
    isPending: isDeletingCurrentSession,
    isSuccess: hasDeletedCurrentSession,
  } = useDeleteCurrentSession();

  const canSeeSettings = useCanSeeSettings();
  const canViewInvoice = currentSession?.hasPermissionOnAnyScope('view_invoice');

  const name = currentSession?.name ?? '';

  // We want to have a specific height for all buttons of this screen, that's why we're using a
  // custom height and not the sizes offered by the Design System
  const buttonHeight = '56px';

  return (
    <div className="container">
      <Stack gap="24px">
        <h1 className={isMobile ? 'headline-200-bold-mobile' : 'headline-100-bold'}>
          <Trans id="menu.title">Menu</Trans>
        </h1>
        <Button
          to="/profile"
          variant="secondary"
          size="large"
          // We want to have a custom height for this one
          style={{ height: 'unset' }}
        >
          <Stack row alignItems="center" style={{ width: '100%' }} flexWrap="nowrap" gap="0.5rem">
            <UserAvatar user={currentSession} size="large" />
            <Stack alignItems="flex-start" style={{ minWidth: 0, flex: '1' }}>
              <div
                className="paragraph-100-medium text-ellipsis"
                title={name}
                style={{ maxWidth: '100%' }}
              >
                {name}
              </div>
              <div className="paragraph-300-regular">
                <Trans id="menu.show-profile">Show profile</Trans>
              </div>
            </Stack>
            <span>
              <IconChevron right />
            </span>
          </Stack>
        </Button>
        <Stack gap="0.5rem">
          {canViewInvoice && (
            <Button
              to="/accounting"
              variant="secondary"
              size="medium"
              style={{ height: buttonHeight }}
            >
              <Stack
                row
                gap="0.5rem"
                alignItems="center"
                justifyContent="space-between"
                style={{ width: '100%' }}
              >
                <Stack row gap="0.5rem" alignItems="center">
                  <IconInvoice style={{ fontSize: '1.25rem' }} />
                  <Trans id="menu.accounting">Accounting</Trans>
                </Stack>
                <IconChevron right />
              </Stack>
            </Button>
          )}
          {canSeeSettings && (
            <Button
              to="/settings"
              variant="secondary"
              size="medium"
              style={{ height: buttonHeight }}
            >
              <Stack
                row
                gap="0.5rem"
                alignItems="center"
                justifyContent="space-between"
                style={{ width: '100%' }}
              >
                <Stack row gap="0.5rem" alignItems="center">
                  <IconSettings style={{ fontSize: '1.25rem' }} />
                  <Trans id="menu.settings">Settings</Trans>
                </Stack>
                <IconChevron right />
              </Stack>
            </Button>
          )}
          <Button to="/help" variant="secondary" size="medium" style={{ height: buttonHeight }}>
            <Stack
              row
              gap="0.5rem"
              alignItems="center"
              justifyContent="space-between"
              style={{ width: '100%' }}
            >
              <Stack row gap="0.5rem" alignItems="center">
                <IconHelp style={{ fontSize: '1.25rem' }} />
                <Trans id="menu.help-form">Help & Feedback</Trans>
              </Stack>
              <IconChevron right />
            </Stack>
          </Button>
        </Stack>
        <Button
          variant="secondary-danger"
          size="medium"
          onPress={() => {
            deleteCurrentSession();
          }}
          disabled={isDeletingCurrentSession || hasDeletedCurrentSession}
          style={{ height: buttonHeight }}
        >
          <Stack
            row
            gap="0.5rem"
            alignItems="center"
            justifyContent="space-between"
            style={{ width: '100%' }}
          >
            <Stack
              row
              gap="0.5rem"
              style={{ color: 'var(--color-danger-700)' }}
              alignItems="center"
            >
              <IconLogout style={{ fontSize: '1.25rem' }} />
              <Trans id="menu.log-out">Log out</Trans>
            </Stack>
            <IconChevron right />
          </Stack>
        </Button>
      </Stack>
    </div>
  );
};

export const useCanSeeSettings = () => {
  const { currentSession } = useCurrentSession();
  const [currentOrganization] = useCurrentOrganization();

  const canSeeOrganizationSettings = (
    [
      'manage_user',
      'manage_store',
      'manage_workshop',
      'manage_organization',
      'manage_billing',
    ] satisfies Permission[]
  ).find((permission) =>
    currentSession?.hasPermission(permission, {
      organizationId: currentOrganization?.id,
      storeId: null,
      workshopId: null,
    })
  );
  const canSeeWorkshopSettings = currentSession?.hasPermission('manage_workshop', {
    workshopId: currentSession?.workshop?.id,
  });

  return canSeeOrganizationSettings || canSeeWorkshopSettings;
};
