import { useQuery } from '@tanstack/react-query';

import { Endpoints, OrganizationConfig, ProductL2 } from '@/api';
import { useFetch } from '@/utils/fetch';

import { Model } from './model';
import { Workflow } from './workflow';

export class Organization extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;
  slug!: string;
  brands!: string[];
  name!: string;
  config!: OrganizationConfig;
  enabledProductL2!: ProductL2[];
  createdAt!: string;

  get isColorFieldEnabled(): boolean {
    return this.config?.articleDetailsFields?.color?.enabled ?? true;
  }

  get isColorFieldRequired(): boolean {
    return this.config?.articleDetailsFields?.color?.required ?? false;
  }

  get isSizeFieldEnabled(): boolean {
    return this.config?.articleDetailsFields?.size?.enabled ?? true;
  }

  get isSizeFieldRequired(): boolean {
    return this.config?.articleDetailsFields?.size?.required ?? false;
  }

  get isProductL3FieldEnabled(): boolean {
    return this.config?.articleDetailsFields?.productL3?.enabled ?? true;
  }

  get isProductL3FieldRequired(): boolean {
    return this.config?.articleDetailsFields?.productL3?.required ?? true;
  }

  get isArticlePhotoFieldEnabled(): boolean {
    return this.config?.articleDetailsFields?.articlePhoto?.enabled ?? true;
  }

  get isArticlePhotoFieldRequired(): boolean {
    return this.config?.articleDetailsFields?.articlePhoto?.required ?? true;
  }

  get isDefectPhotoFieldEnabled(): boolean {
    return this.config?.articleDetailsFields?.defectPhoto?.enabled ?? true;
  }

  get isDefectPhotoFieldRequired(): boolean {
    return this.config?.articleDetailsFields?.defectPhoto?.required ?? true;
  }
}

export const useOrganization = (slug?: string) => {
  const fetch = useFetch<Endpoints['GET /organizations/:slug']>();

  return useQuery({
    queryKey: ['organizations', slug],
    queryFn: () =>
      fetch(`/organizations/${slug!}`).then((organization) => new Organization(organization)),
    enabled: !!slug,
  });
};

export const useOrganizationWorkflows = (slug?: string) => {
  const fetch = useFetch<Endpoints['GET /organizations/:slug/workflows']>();

  return useQuery({
    queryKey: ['organizations', slug, 'workflows'],
    queryFn: () =>
      fetch(`/organizations/${slug!}/workflows`).then((workflows) =>
        workflows.map((workflow) => new Workflow(workflow))
      ),
    enabled: !!slug,
  });
};
