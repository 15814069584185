import { useMemo, useState } from 'react';
import { useLingui } from '@lingui/react/macro';
import debounce from 'lodash.debounce';

import { PaymentStepConfig } from '@/api';
import FileUpload from '@/components/FileUpload';
import InputText from '@/design_system/InputText';
import Stack from '@/design_system/Stack';
import { useMedia } from '@/models/medium';
import { RequestWithRelations, useUpdateRequestExternalPaymentReference } from '@/models/request';
import { isExternalPaymentReferenceInvalid } from '@/routes/Requests/Request/isExternalReferenceInvalid';
import { useCurrentSession } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

import './PaymentAction.css';

export const PaymentAction = ({ request }: { request: RequestWithRelations }) => {
  const { currentSession } = useCurrentSession();

  if (
    !currentSession?.hasPermission('validate_external_payment', {
      organizationId: request.organizationId,
      storeId: request.storeId,
    })
  ) {
    return null;
  }

  const stepConfig = request.articles.find((article) => article.step?.step === 'payment')?.step
    ?.config as PaymentStepConfig['config'];

  if (!stepConfig?.externalPayment) {
    return null;
  }

  return <PaymentForm request={request} stepConfig={stepConfig} />;
};

const PaymentForm = ({
  request,
  stepConfig,
}: {
  request: RequestWithRelations;
  stepConfig: Extract<PaymentStepConfig['config'], { externalPayment: true }>;
}) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();

  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const { data: { media } = { media: [] }, isLoading } = useMedia({
    requestId: request.id,
    types: ['proof-of-external-payment'],
  });

  const {
    mutateAsync: updateRequestExternalPaymentReference,
    isPending: isPendingUpdateRequestExternalPaymentReference,
  } = useUpdateRequestExternalPaymentReference(request.id);

  const debouncedMutate = useMemo(() => {
    return debounce(updateRequestExternalPaymentReference, 500);
  }, [updateRequestExternalPaymentReference]);

  if (isLoading) {
    return null;
  }

  return (
    <Stack row mobileColumn gap="24px" flexWrap="nowrap" className="request__payment-action">
      <FileUpload
        variant={isMobile ? 'default' : 'inline'}
        style={{
          flex: 1,
          /**
           * This is needed to allow the file name to be truncated if it's too long.
           * Source: https://css-tricks.com/flexbox-truncated-text/
           */
          minWidth: 0,
        }}
        label={t({
          id: 'request.step-actions.payment.proof-of-payment',
          message: 'Receipt / invoice',
        })}
        media={media}
        uploadData={{
          type: 'proof-of-external-payment',
          requestId: request.id,
        }}
        allowsMultiple={false}
        deleteWithApi
      />
      {stepConfig.fields?.reference?.enabled && (
        <InputText
          style={{ flex: 1 }}
          label={t({
            id: 'request.step-actions.payment.payment-reference.label',
            message: 'Receipt / invoice number',
          })}
          placeholder={t({
            id: 'request.step-actions.payment.payment-reference.placeholder',
            message: 'i.e: 0141490090000',
          })}
          isLoading={isPendingUpdateRequestExternalPaymentReference}
          defaultValue={request.externalPaymentReference ?? ''}
          onChange={(externalPaymentReference) => {
            setIsInvalid(isExternalPaymentReferenceInvalid(externalPaymentReference, stepConfig));
            debouncedMutate({
              externalPaymentReference: externalPaymentReference || null,
            });
          }}
          isInvalid={isInvalid}
          error={
            isInvalid
              ? t({
                  id: 'request.step-actions.payment.payment-reference.invalid-format',
                  message: 'Invalid format',
                })
              : undefined
          }
        />
      )}
    </Stack>
  );
};
