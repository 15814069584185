import { GridList, GridListItem } from 'react-aria-components';
import { useNavigate } from 'react-router';
import { Trans, useLingui } from '@lingui/react/macro';

import {
  ArticleCostCardItem,
  ArticleNameCell,
  ArticlePriceCardItem,
  ArticleWorkshopCell,
  BaseMoneyCell,
} from '@/components/ArticlesTableCells';
import {
  ArticleDefectsCardItems,
  ArticleDefectsCell,
} from '@/components/ArticlesTableCells/ArticleDefectsCell';
import {
  ArticleServiceChoiceCardItems,
  ArticleServiceChoiceCell,
} from '@/components/ArticlesTableCells/ArticleServiceChoiceCell';
import { ArticleWorkshopCardItem } from '@/components/ArticlesTableCells/ArticleWorkshopCell';
import { Card, CardActions, CardContent, CardItems } from '@/components/Card/Card';
import Badge from '@/design_system/Badge';
import Button from '@/design_system/Button/Button';
import Stack from '@/design_system/Stack';
import { Body, Cell, Column, Header, Row, Table } from '@/design_system/Table/Table';
import IconCancel from '@/icons/Cancel.svg';
import IconEdit from '@/icons/Edit.svg';
import { useArticleName } from '@/models/article';
import { ArticleWithRelations, useDeleteDraftRequestArticle } from '@/models/request';
import { recursivelyFilterErrors } from '@/routes/Requests/contexts/errors';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import { creationErrorsFilter } from '@/routes/Requests/New/NewArticle';
import useViewPort from '@/utils/useViewport';

export const CreationArticlesTable = () => {
  const { t } = useLingui();
  const {
    request,
    articles,
    view: { workshop, price, cost },
  } = useRequestContext();

  const displayDefects = articles[0].view.services.defects.shown;
  const displayServiceChoice = articles[0].view.services.choice.shown;

  return (
    <Table
      ariaLabel={t({ id: 'request.articles.table.label', message: 'Items' })}
      columnWidths={[
        'minmax(220px, 2fr)',
        displayDefects && 'minmax(300px, 2fr)',
        displayServiceChoice && 'minmax(300px, 2fr)',
        workshop.enabled && 'minmax(160px, 2fr)',
        !workshop.enabled && cost.enabled && 'minmax(100px, 1fr)',
        price.enabled && 'minmax(100px, 1fr)',
        request.draft ? '100px' : '50px',
      ]}
      variant="grid"
    >
      <CreationArticlesTableHeader />
      <Body>
        {request.articles.map((article) => (
          <CreationArticlesTableRow key={article.id} article={article} />
        ))}
      </Body>
    </Table>
  );
};

const CreationArticlesTableHeader = () => {
  const {
    view: { workshop, price, cost },
    articles,
    workflow,
  } = useRequestContext();

  const displayDefects = articles[0].view.services.defects.shown;
  const displayServiceChoice = articles[0].view.services.choice.shown;
  const displayService = (articles[0].view.services.choice.serviceOptions ?? []).length > 1;

  return (
    <Header>
      <Row>
        <Column>
          {workflow?.config?.maxArticles === 1 ? (
            <Trans id="request.articles.table.label.single">Item</Trans>
          ) : (
            <Trans id="request.articles.table.label">Items</Trans>
          )}
        </Column>
        {displayDefects && (
          <Column>
            <Trans id="request.articles.table.column.defects.label">Defects</Trans>
          </Column>
        )}
        {displayServiceChoice && (
          <Column>
            {displayService ? (
              <Trans id="request.articles.table.column.services-actions.label">
                Services and actions
              </Trans>
            ) : (
              <Trans id="request.articles.table.column.actions.label">Actions</Trans>
            )}
          </Column>
        )}
        {workshop.enabled && (
          <Column>
            <Trans id="request.articles.table.column.workshop.label">Workshop</Trans>
          </Column>
        )}
        {!workshop.enabled && cost.enabled && <Column justifyContent="end">{cost.label}</Column>}
        {price.enabled && <Column justifyContent="end">{price.label}</Column>}
        <Column justifyContent="end"></Column>
      </Row>
    </Header>
  );
};

const CreationArticlesTableRow = ({ article }: { article: ArticleWithRelations }) => {
  const {
    request,
    view: { workshop, price, cost },
    articles,
  } = useRequestContext();
  const navigate = useNavigate();

  const displayDefects = articles[0].view.services.defects.shown;
  const displayServiceChoice = articles[0].view.services.choice.shown;

  return (
    <Row>
      <Cell>
        <ArticleNameCell article={article} badge={<DraftBadge article={article} />} noLink />
      </Cell>
      {displayDefects && (
        <Cell>
          <ArticleDefectsCell article={article} />
        </Cell>
      )}
      {displayServiceChoice && (
        <Cell>
          <ArticleServiceChoiceCell article={article} />
        </Cell>
      )}
      {workshop.enabled && (
        <Cell>
          <ArticleWorkshopCell
            article={article}
            showWorkshopPrice={cost.enabled}
            showAmountBeforeTaxes={cost.showAmountBeforeTaxes}
          />
        </Cell>
      )}
      {!workshop.enabled && cost.enabled && (
        <Cell justifyContent="end">
          <BaseMoneyCell
            price={article.snapshot.cost?.amountPerEntity.find(
              (amount) => amount.entityId === article.workshopId
            )}
            showAmountBeforeTaxes={cost.showAmountBeforeTaxes}
          />
        </Cell>
      )}
      {price.enabled && (
        <Cell justifyContent="end">
          <BaseMoneyCell
            price={article.snapshot.price?.amountPerCurrency[0]}
            showAmountBeforeTaxes={price.showAmountBeforeTaxes}
          />
        </Cell>
      )}
      <Cell justifyContent="center">
        <Stack row gap="0.5rem">
          <EditButton
            onEdit={() =>
              navigate(`/requests/new/${request.id}/articles/${article.id}`, {
                state: { from: 'request', isNew: false, skipRouterBlocker: true },
                replace: true,
              })
            }
          />
          {request.draft && <DeleteButton article={article} />}
        </Stack>
      </Cell>
    </Row>
  );
};

export const CreationArticlesCardList = () => {
  const { t } = useLingui();
  const { request } = useRequestContext();

  return (
    <GridList
      aria-label={t({
        id: 'requests.new.articles.label',
        message: 'Items',
      })}
    >
      {request.articles.map((article) => (
        <CreationArticleCard key={article.id} article={article} />
      ))}
    </GridList>
  );
};

const CreationArticleCard = ({ article }: { article: ArticleWithRelations }) => {
  const navigate = useNavigate();
  const {
    request,
    articles,
    view: { workshop, price, cost },
  } = useRequestContext();

  const articleName = useArticleName({ article });

  const displayDefects = articles[0].view.services.defects.shown;
  const displayServiceChoice = articles[0].view.services.choice.shown;

  return (
    <GridListItem id={article.id} textValue={articleName} style={{ marginBottom: '0.5rem' }}>
      <Card>
        <CardContent>
          <ArticleNameCell
            id={article.id}
            article={article}
            badge={<DraftBadge article={article} />}
            noLink
          />
          <CardItems>
            {displayDefects && <ArticleDefectsCardItems article={article} />}
            {displayServiceChoice && <ArticleServiceChoiceCardItems article={article} />}
            {workshop.enabled && <ArticleWorkshopCardItem article={article} />}
            {cost.enabled && (
              <ArticleCostCardItem
                article={article}
                costLabel={cost.label}
                showAmountBeforeTaxes={cost.showAmountBeforeTaxes}
              />
            )}
            {price.enabled && (
              <ArticlePriceCardItem
                article={article}
                priceLabel={price.label}
                showAmountBeforeTaxes={price.showAmountBeforeTaxes}
              />
            )}
          </CardItems>
        </CardContent>
        <CardActions>
          {request.draft && <DeleteButton article={article} />}
          <EditButton
            onEdit={() =>
              navigate(`/requests/new/${request.id}/articles/${article.id}`, {
                state: { from: 'request', isNew: false, skipRouterBlocker: true },
                replace: true,
              })
            }
          />
        </CardActions>
      </Card>
    </GridListItem>
  );
};

const EditButton = ({ onEdit }: { onEdit: () => void }) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();

  if (isMobile) {
    return (
      <Button style={{ flex: 1 }} variant="secondary" size="medium" onPress={onEdit}>
        <IconEdit />
        <Trans id="request.articles.table.column.actions.edit">Edit</Trans>
      </Button>
    );
  }

  return (
    <Button
      iconOnly
      variant="secondary"
      size="medium"
      tooltip={t({ id: 'request.articles.table.column.actions.edit', message: 'Edit' })}
      ariaLabel={t({ id: 'request.articles.table.column.actions.edit', message: 'Edit' })}
      onPress={onEdit}
    >
      <IconEdit />
    </Button>
  );
};

const DeleteButton = ({ article }: { article: ArticleWithRelations }) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();

  const {
    mutateAsync: deleteArticle,
    isPending: isPendingDeleteArticle,
    isSuccess: isSuccessDeleteArticle,
  } = useDeleteDraftRequestArticle(article.requestId);

  if (isMobile) {
    return (
      <Button
        style={{ flex: 1 }}
        variant="secondary"
        size="medium"
        onPress={() => {
          deleteArticle(article.id);
        }}
        isLoading={isPendingDeleteArticle || isSuccessDeleteArticle}
      >
        <IconCancel />
        <Trans id="request.articles.table.column.actions.remove">Remove</Trans>
      </Button>
    );
  }

  return (
    <Button
      iconOnly
      variant="secondary"
      size="medium"
      tooltip={t({ id: 'request.articles.table.column.actions.remove', message: 'Remove' })}
      ariaLabel={t({ id: 'request.articles.table.column.actions.remove', message: 'Remove' })}
      onPress={() => {
        deleteArticle(article.id);
      }}
      isLoading={isPendingDeleteArticle || isSuccessDeleteArticle}
    >
      <IconCancel />
    </Button>
  );
};

// We are not under an ArticleContext, but we need to know if an article has errors based on the creation filters
const DraftBadge = ({ article }: { article: ArticleWithRelations }) => {
  const { articles, unfilteredErrors, view: requestView } = useRequestContext();

  const articleView = articles.find((art) => art.articleId === article.id)?.view;

  const articleUnfilteredErrors = unfilteredErrors.articles.articles.find(
    (art) => art.id === article.id
  );
  const articleFilteredErrors =
    articleView && articleUnfilteredErrors
      ? recursivelyFilterErrors(
          articleUnfilteredErrors,
          creationErrorsFilter(articleView, requestView)
        )
      : {
          hasError: false,
        };

  const isDraft = articleFilteredErrors.hasError;

  if (!isDraft) {
    return null;
  }

  return (
    <Badge color="neutral">
      <Trans id="request.articles.table.status.draft">Draft</Trans>
    </Badge>
  );
};
