import { PropsWithChildren, ReactNode, useState } from 'react';

import IconChevron from '@/icons/Chevron.svg';
import IconError from '@/icons/Error.svg';
import { createBEMClasses } from '@/utils/classname';

import Button from '../Button';
import Stack from '../Stack';

import './FoldableSection.css';

const { block, element } = createBEMClasses('foldable-section');

const FoldableSection = ({
  label,
  labelOutsideButton,
  startClosed,
  invalid,
  children,
}: PropsWithChildren<{
  label: ReactNode;
  labelOutsideButton?: ReactNode;
  invalid?: boolean;
  startClosed?: boolean;
}>) => {
  const [isOpen, setIsOpen] = useState(!startClosed);

  return (
    <section className={block({ isOpen })}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <header onClick={() => setIsOpen((prev) => !prev)}>
        <Stack row gap="0.5rem" alignItems="center" justifyContent="flex-start" style={{ flex: 1 }}>
          <Button
            variant="style-less"
            onPress={() => setIsOpen((prev) => !prev)}
            className={element('button')}
          >
            <Stack
              row
              gap="0.5rem"
              alignItems="center"
              justifyContent="flex-start"
              style={{ flex: 1 }}
            >
              <IconChevron right className={element('icon')} />
              {invalid && <IconError className="text-danger" fontSize="1.25rem" />}
              {label}
            </Stack>
          </Button>
          {labelOutsideButton}
        </Stack>
      </header>
      <main inert={isOpen ? false : true}>
        <div>{children}</div>
      </main>
    </section>
  );
};

export default FoldableSection;
