import { Trans, useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import { useBrandRequestContext } from '@/layouts/Brand/BrandRequestContext';
import { useDeleteArticle } from '@/models/article';

const EmptyCartModal = ({
  isOpen,
  onOpenChange,
  onDelete,
}: {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onDelete?: () => void;
}) => {
  const { t } = useLingui();
  const { request } = useBrandRequestContext();

  const { mutateAsync: deleteArticle, isPending: isPendingDeleteArticle } = useDeleteArticle();

  const handleDelete = async () => {
    await Promise.all(
      request.articles.map(async (article) => {
        await deleteArticle(article.id);
      })
    );

    onDelete?.();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      title={t({
        id: 'client.new.cart.delete.modal.title',
        message: 'Empty your cart',
      })}
      style={{ maxWidth: '28rem' }}
    >
      <main style={{ paddingTop: 0 }}>
        <p className="paragraph-100-regular">
          <Trans id="client.new.cart.delete.modal.text">
            You&apos;re about to empty your cart. All changes will be lost. Are you sure?
          </Trans>
        </p>
      </main>
      <footer>
        <Button variant="secondary-brand" size="small" onPress={() => onOpenChange(false)}>
          <Trans id="client.new.cart.delete.modal.cancel">Cancel</Trans>
        </Button>
        <Button
          variant="danger"
          size="small"
          onPress={() => {
            handleDelete();
          }}
          isLoading={isPendingDeleteArticle}
        >
          <Trans id="client.new.cart.delete.modal.empty">Empty</Trans>
        </Button>
      </footer>
    </Dialog>
  );
};

export default EmptyCartModal;
