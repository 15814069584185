export const IllustrationNoResults = () => (
  <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40.486 7.57711C40.486 7.57711 40.978 20.2487 61.1721 22.987C79.1064 25.4187 70.9433 46.7863 63.7943 52.9629C54.5333 60.9641 38.6025 56.6989 36.5899 67.0494C34.5774 77.3999 12.2017 70.6408 14.3434 54.9293C17.0077 35.3847 5.93514 40.1134 1.24862 34.2462C-2.11492 30.0355 1.1229 12.7699 13.3328 12.1945C23.6073 11.7102 23.5747 7.23993 24.7397 4.07134C26.42 -0.498488 38.5887 -3.37798 40.486 7.57711Z"
      fill="#D8F8E9"
    />
    <mask id="mask0_955_148424" maskUnits="userSpaceOnUse" x="0" y="0" width="72" height="72">
      <path
        d="M40.486 7.57711C40.486 7.57711 40.978 20.2487 61.1721 22.987C79.1064 25.4187 70.9433 46.7863 63.7943 52.9629C54.5333 60.9641 38.6025 56.6989 36.5899 67.0494C34.5774 77.3999 12.2017 70.6408 14.3434 54.9293C17.0077 35.3847 5.93514 40.1134 1.24862 34.2462C-2.11492 30.0355 1.1229 12.7699 13.3328 12.1945C23.6073 11.7102 23.5747 7.23993 24.7397 4.07134C26.42 -0.498488 38.5887 -3.37798 40.486 7.57711Z"
        fill="#DBE7FE"
      />
    </mask>
    <g mask="url(#mask0_955_148424)">
      <g opacity="0.9">
        <circle cx="5.70118" cy="5.39637" r="0.454839" fill="#3AD98F" />
        <circle cx="13.585" cy="5.39637" r="0.454839" fill="#3AD98F" />
        <circle cx="21.469" cy="5.39637" r="0.454839" fill="#3AD98F" />
        <circle cx="29.3528" cy="5.39637" r="0.454839" fill="#3AD98F" />
        <circle cx="37.2366" cy="5.39637" r="0.454839" fill="#3AD98F" />
        <circle cx="45.1206" cy="5.39637" r="0.454839" fill="#3AD98F" />
        <circle cx="53.0044" cy="5.39637" r="0.454839" fill="#3AD98F" />
        <circle cx="60.8882" cy="5.39637" r="0.454839" fill="#3AD98F" />
        <circle cx="68.7722" cy="5.39637" r="0.454839" fill="#3AD98F" />
        <circle cx="5.70118" cy="14.2568" r="0.454839" fill="#3AD98F" />
        <circle cx="13.585" cy="14.2568" r="0.454839" fill="#3AD98F" />
        <circle cx="21.469" cy="14.2568" r="0.454839" fill="#3AD98F" />
        <circle cx="29.3528" cy="14.2568" r="0.454839" fill="#3AD98F" />
        <circle cx="37.2366" cy="14.2568" r="0.454839" fill="#3AD98F" />
        <circle cx="45.1206" cy="14.2568" r="0.454839" fill="#3AD98F" />
        <circle cx="53.0044" cy="14.2568" r="0.454839" fill="#3AD98F" />
        <circle cx="60.8882" cy="14.2568" r="0.454839" fill="#3AD98F" />
        <circle cx="68.7722" cy="14.2568" r="0.454839" fill="#3AD98F" />
        <circle cx="5.70118" cy="23.1173" r="0.454839" fill="#3AD98F" />
        <circle cx="13.585" cy="23.1173" r="0.454839" fill="#3AD98F" />
        <circle cx="21.469" cy="23.1173" r="0.454839" fill="#3AD98F" />
        <circle cx="29.3528" cy="23.1173" r="0.454839" fill="#3AD98F" />
        <circle cx="37.2366" cy="23.1173" r="0.454839" fill="#3AD98F" />
        <circle cx="45.1206" cy="23.1173" r="0.454839" fill="#3AD98F" />
        <circle cx="53.0044" cy="23.1173" r="0.454839" fill="#3AD98F" />
        <circle cx="60.8882" cy="23.1173" r="0.454839" fill="#3AD98F" />
        <circle cx="68.7722" cy="23.1173" r="0.454839" fill="#3AD98F" />
        <circle cx="5.70118" cy="31.9778" r="0.454839" fill="#3AD98F" />
        <circle cx="13.585" cy="31.9778" r="0.454839" fill="#3AD98F" />
        <circle cx="21.469" cy="31.9778" r="0.454839" fill="#3AD98F" />
        <circle cx="29.3528" cy="31.9778" r="0.454839" fill="#3AD98F" />
        <circle cx="37.2366" cy="31.9778" r="0.454839" fill="#3AD98F" />
        <circle cx="45.1206" cy="31.9778" r="0.454839" fill="#3AD98F" />
        <circle cx="53.0044" cy="31.9778" r="0.454839" fill="#3AD98F" />
        <circle cx="60.8882" cy="31.9778" r="0.454839" fill="#3AD98F" />
        <circle cx="68.7722" cy="31.9778" r="0.454839" fill="#3AD98F" />
        <circle cx="5.70118" cy="40.8383" r="0.454839" fill="#3AD98F" />
        <circle cx="13.585" cy="40.8383" r="0.454839" fill="#3AD98F" />
        <circle cx="21.469" cy="40.8383" r="0.454839" fill="#3AD98F" />
        <circle cx="29.3528" cy="40.8383" r="0.454839" fill="#3AD98F" />
        <circle cx="37.2366" cy="40.8383" r="0.454839" fill="#3AD98F" />
        <circle cx="45.1206" cy="40.8383" r="0.454839" fill="#3AD98F" />
        <circle cx="53.0044" cy="40.8383" r="0.454839" fill="#3AD98F" />
        <circle cx="60.8882" cy="40.8383" r="0.454839" fill="#3AD98F" />
        <circle cx="68.7722" cy="40.8383" r="0.454839" fill="#3AD98F" />
        <circle cx="5.70118" cy="49.6987" r="0.454839" fill="#3AD98F" />
        <circle cx="13.585" cy="49.6987" r="0.454839" fill="#3AD98F" />
        <circle cx="21.469" cy="49.6987" r="0.454839" fill="#3AD98F" />
        <circle cx="29.3528" cy="49.6987" r="0.454839" fill="#3AD98F" />
        <circle cx="37.2366" cy="49.6987" r="0.454839" fill="#3AD98F" />
        <circle cx="45.1206" cy="49.6987" r="0.454839" fill="#3AD98F" />
        <circle cx="53.0044" cy="49.6987" r="0.454839" fill="#3AD98F" />
        <circle cx="60.8882" cy="49.6987" r="0.454839" fill="#3AD98F" />
        <circle cx="68.7722" cy="49.6987" r="0.454839" fill="#3AD98F" />
        <circle cx="5.70118" cy="58.5592" r="0.454839" fill="#3AD98F" />
        <circle cx="13.585" cy="58.5592" r="0.454839" fill="#3AD98F" />
        <circle cx="21.469" cy="58.5592" r="0.454839" fill="#3AD98F" />
        <circle cx="29.3528" cy="58.5592" r="0.454839" fill="#3AD98F" />
        <circle cx="37.2366" cy="58.5592" r="0.454839" fill="#3AD98F" />
        <circle cx="45.1206" cy="58.5592" r="0.454839" fill="#3AD98F" />
        <circle cx="53.0044" cy="58.5592" r="0.454839" fill="#3AD98F" />
        <circle cx="60.8882" cy="58.5592" r="0.454839" fill="#3AD98F" />
        <circle cx="68.7722" cy="58.5592" r="0.454839" fill="#3AD98F" />
        <circle cx="5.70118" cy="67.4197" r="0.454839" fill="#3AD98F" />
        <circle cx="13.585" cy="67.4197" r="0.454839" fill="#3AD98F" />
        <circle cx="21.469" cy="67.4197" r="0.454839" fill="#3AD98F" />
        <circle cx="29.3528" cy="67.4197" r="0.454839" fill="#3AD98F" />
        <circle cx="37.2366" cy="67.4197" r="0.454839" fill="#3AD98F" />
        <circle cx="45.1206" cy="67.4197" r="0.454839" fill="#3AD98F" />
        <circle cx="53.0044" cy="67.4197" r="0.454839" fill="#3AD98F" />
        <circle cx="60.8882" cy="67.4197" r="0.454839" fill="#3AD98F" />
        <circle cx="68.7722" cy="67.4197" r="0.454839" fill="#3AD98F" />
      </g>
    </g>
    <path
      d="M53 38.5C53 47.6127 45.6127 55 36.5 55C27.3873 55 20 47.6127 20 38.5C20 29.3873 27.3873 22 36.5 22C45.6127 22 53 29.3873 53 38.5Z"
      fill="#F2FDF8"
      stroke="#3AD98F"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path d="M59 61L48.5 50.5" stroke="#3AD98F" strokeWidth="2" strokeLinecap="round" />
  </svg>
);
