import { Trans } from '@lingui/react/macro';

import { EmptyState, IllustrationNoResults } from '@/components/EmptyState';
import Stack from '@/design_system/Stack';

export const RequestsNoResults = () => {
  return (
    <EmptyState
      subtitle={
        <Stack>
          <span>
            <Trans id="requests.no-results.1">No results are matching your criteria.</Trans>
          </span>
          <span>
            <Trans id="requests.no-results.2">Please try with different keywords or filters.</Trans>
          </span>
        </Stack>
      }
      illustration={<IllustrationNoResults />}
    />
  );
};
