import { Trans, useLingui } from '@lingui/react/macro';

import IconEmail from '@/icons/Email.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import { type TNotificationOfType } from '@/models/notification';

import { Comment } from './components/Comment';

export const ClientCommentAddedNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'client_comment_added'>;
}) => {
  const { t } = useLingui();

  const { comment, request } = notification;
  const requestReference = request.reference;

  return (
    <BaseNotification
      icon={<IconEmail />}
      notification={notification}
      notificationLabel={t({
        id: 'notification.client-comment-added.label',
        message: `The client added a new message on ${requestReference}`,
      })}
      notificationHeader={
        <Trans id="notification.client-comment-added">
          <span className="paragraph-100-medium">The client</span> added a new message
        </Trans>
      }
      notificationContent={<Comment comment={comment.content} />}
      notificationHref={`/requests/${notification.request?.id}?openClientCommunication=true`}
    />
  );
};
