import { Trans } from '@lingui/react/macro';

import { BrandRequestMainContent } from '@/layouts/Brand/BrandRequestLayout/BrandRequestLayout';

const Archived = () => {
  return (
    <BrandRequestMainContent>
      <p className="headline-200-bold">
        <Trans id="client.request.archived.title">
          Your care and repair service has been archived
        </Trans>
      </p>
    </BrandRequestMainContent>
  );
};

export default Archived;
