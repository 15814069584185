import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Trans, useLingui } from '@lingui/react/macro';

import AlertBar from '@/design_system/AlertBar';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Drawer from '@/design_system/Drawer';
import { DrawerBody, DrawerFooter } from '@/design_system/Drawer/Drawer';
import Stack from '@/design_system/Stack';
import Tooltip from '@/design_system/Tooltip';
import IconClock from '@/icons/Clock.svg';
import IconInfo from '@/icons/Info.svg';
import IconTrash from '@/icons/Trash.svg';
import { useDeleteDraftRequestArticle } from '@/models/request';
import { ArticleServices } from '@/routes/Requests/components/ArticleServices/ArticleServices';
import {
  ArticleContextData,
  ArticleContextProvider,
  useArticleContext,
} from '@/routes/Requests/contexts/ArticleContext';
import { ArticleErrorsFilter } from '@/routes/Requests/contexts/errors';
import { RequestContextData } from '@/routes/Requests/contexts/interfaces';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import {
  ArticleDetails,
  useHasNoAvailableDefectsOrActions,
} from '@/routes/Requests/New/components/ArticleForm';
import Warranty from '@/routes/Requests/Request/Article/Warranty';
import { RequestComments } from '@/routes/Requests/Request/components/RequestComments';
import { ErrorBoundary } from '@/services/sentry';
import { createBEMClasses } from '@/utils/classname';
import { useScrollIntoView } from '@/utils/useScrollIntoView';
import useViewPort from '@/utils/useViewport';

import NewArticleHeader from './components/NewArticleHeader';

import './NewArticle.css';

const { block, element } = createBEMClasses('new-article');

const NewArticle = () => {
  const { id, articleId } = useParams();
  const { request } = useRequestContext();
  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  const article = request.articles.find(({ id }) => id === articleId);

  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (state?.from) {
      // Wait for the Drawer CSS enter animation to finish before resetting state
      setTimeout(
        () =>
          navigate(pathname, {
            state: { ...state, from: undefined, skipRouterBlocker: undefined },
            replace: true,
          }),
        150
      );
    }
  }, [navigate, pathname, state]);

  const articleIsEmpty =
    !!article &&
    !article.productId &&
    !article.productL1 &&
    !article.productL2 &&
    !article.productL3 &&
    !article.data?.color &&
    !article.data?.size &&
    !article.purchaseDate &&
    !article.warranty &&
    !article.snapshot.articleActions.length &&
    !article.articlePhoto;

  const { mutateAsync: deleteArticle } = useDeleteDraftRequestArticle(request.id);

  const deleteAndClose = async () => {
    if (article) {
      await deleteArticle(article.id);
    }

    close();
  };

  const close = () => {
    setIsOpen(false);

    // Wait for the Drawer CSS exit animation to finish before navigating away
    setTimeout(
      () => navigate(`/requests/new/${id}`, { state: { skipRouterBlocker: true }, replace: true }),
      150
    );
  };

  const isEditing = !state?.isNew;

  return (
    <Drawer
      isOpen={isOpen}
      animateEntry={state?.from === 'request'}
      onOpenChange={isEditing ? close : deleteAndClose}
      closeConfirmation={
        articleIsEmpty || isEditing
          ? undefined
          : {
              title: <Trans id="dialog.discard-changes.title">Discard changes</Trans>,
              content: (
                <Trans id="dialog.discard-changes.content">
                  You&apos;re about to leave this page without saving your changes. All unsaved
                  changes will be lost.
                  <br />
                  Are you sure?
                </Trans>
              ),
              confirm: <Trans id="dialog.discard-changes.confirm">Discard changes</Trans>,
            }
      }
    >
      {!request || !article ? null : (
        <ArticleContextProvider article={article}>
          <DrawerContent isEditing={isEditing} close={close} />
        </ArticleContextProvider>
      )}
    </Drawer>
  );
};

const DrawerContent = ({ isEditing, close }: { isEditing: boolean; close: () => void }) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();
  const { request, view: requestView } = useRequestContext();
  const { article, view, state, hasError } = useArticleContext();

  const [alertRef, scrollAlertIntoView] = useScrollIntoView<HTMLDivElement>();

  const { hasNoAvailableDefects, hasNoAvailableActions } = useHasNoAvailableDefectsOrActions();

  // Actions

  const {
    mutateAsync: deleteArticle,
    isPending: isPendingDeleteArticle,
    isSuccess: isSuccessDeleteArticle,
  } = useDeleteDraftRequestArticle(article.requestId);

  const handleDelete = async () => {
    await deleteArticle(article.id);
    close();
  };

  const handleSaveForLater = () => {
    const { hasError } = state.errors.check(saveForLaterErrorsFilter(view));

    if (hasError) {
      scrollAlertIntoView();
      return;
    }

    close();
  };

  const handleSave = () => {
    const { hasError } = state.errors.check(creationErrorsFilter(view, requestView));

    if (hasError) {
      scrollAlertIntoView();
      return;
    }

    close();
  };

  return (
    <>
      <NewArticleHeader request={request} article={article} isEditing={isEditing} />
      <DrawerBody className={block()}>
        <Stack padding={isMobile ? '1rem' : '1.5rem 2rem'}>
          <Stack
            ref={alertRef}
            style={{
              marginBottom:
                hasError || hasNoAvailableDefects || hasNoAvailableActions ? '1rem' : undefined,
            }}
            gap="0.5rem"
          >
            {hasError && (
              <AlertBar
                type="error"
                title={
                  <Trans id="requests.edit.articles.alert.missing-info">
                    Please fill the missing information.
                  </Trans>
                }
              />
            )}
            {hasNoAvailableDefects && (
              <AlertBar
                type="warning"
                title={
                  <Trans id="requests.edit.articles.alert.no-catalog-defects">
                    No default defects available for this item. Please add a custom defect to add
                    the item to the request.
                  </Trans>
                }
              />
            )}
            {hasNoAvailableActions && (
              <AlertBar
                type="warning"
                title={
                  <Trans id="requests.edit.articles.alert.no-catalog-actions">
                    No services available for this item. Please add a custom service to add the item
                    to the request.
                  </Trans>
                }
              />
            )}
          </Stack>
          <Stack gap="2rem">
            <Stack gap="0.5rem">
              <h2 className="headline-400-bold">
                <Trans id="requests.new.articles.drawer.information.title">Information</Trans>
              </h2>

              <Box
                padding={isMobile ? '1rem' : '1rem 1.5rem'}
                gap="1rem"
                className={element('details')}
              >
                <ArticleDetails />
              </Box>
            </Stack>

            <Warranty />

            <Stack gap="0.5rem">
              <h2 className="headline-400-bold">
                <Trans id="requests.new.articles.drawer.services.title">Services</Trans>
              </h2>

              <Box
                padding={isMobile ? '1rem' : '1rem 1.5rem'}
                gap="1rem"
                className={element('services')}
              >
                <ArticleServices />
              </Box>
            </Stack>

            <Stack gap={isMobile ? undefined : '0.5rem'}>
              <Stack row alignItems="center" gap="0.25rem">
                <h2 className="headline-400-bold">
                  <Trans id="article.comment.title">Item comments</Trans>
                </h2>
                <Tooltip
                  content={t({
                    id: 'comments.title.tooltip',
                    message: "These comments won't be visible by the client",
                  })}
                >
                  <Button variant="style-less">
                    <IconInfo className="headline-300-bold text-disabled" />
                  </Button>
                </Tooltip>
              </Stack>
              <ErrorBoundary>
                <Box
                  className={element('comments', { 'no-background': isMobile })}
                  padding="0"
                  removeStyleOnMobile
                >
                  <RequestComments requestId={request.id} articleId={article.id} />
                </Box>
              </ErrorBoundary>
            </Stack>
          </Stack>
        </Stack>
      </DrawerBody>
      <DrawerFooter>
        <Stack gap="0.5rem" row={!isMobile}>
          {request.draft && isEditing && (
            <Button
              variant="secondary-danger"
              size="medium"
              onPress={handleDelete}
              isLoading={isPendingDeleteArticle || isSuccessDeleteArticle}
              dataTrackingId="requests.new.articles.drawer.actions.remove"
            >
              <IconTrash />
              <Trans id="requests.new.articles.drawer.actions.remove">Remove</Trans>
            </Button>
          )}
          <Button
            variant="secondary"
            size="medium"
            onPress={handleSaveForLater}
            dataTrackingId="requests.new.articles.drawer.actions.save-for-later"
          >
            <IconClock />
            <Trans id="requests.new.articles.drawer.actions.save-for-later">Save for later</Trans>
          </Button>
          <Button
            variant="primary"
            size="medium"
            onPress={handleSave}
            dataTrackingId="requests.new.articles.drawer.actions.save"
          >
            {isEditing ? (
              <Trans id="requests.new.articles.drawer.actions.save">Save</Trans>
            ) : (
              <Trans id="requests.new.articles.drawer.actions.add">Add item</Trans>
            )}
          </Button>
        </Stack>
      </DrawerFooter>
    </>
  );
};

export default NewArticle;

export function saveForLaterErrorsFilter(view: ArticleContextData['view']) {
  return {
    details: {
      product: true,
      productL2: true,
      productL3: view.details.productL3.shown && view.details.productL3.required,
    },
  } satisfies ArticleErrorsFilter;
}

export function creationErrorsFilter(
  view: ArticleContextData['view'],
  requestView: RequestContextData['view']
) {
  return {
    details: {
      product: true,
      productL2: true,
      productL3: view.details.productL3.shown && view.details.productL3.required,
      otherBrandEmpty: true,
      missingColor: view.details.color.shown && view.details.color.required,
      missingSize: view.details.size.shown && view.details.size.required,
      missingArticlePhoto: view.details.articlePhoto.shown && view.details.articlePhoto.required,
    },
    warranty: {
      missingPurchaseDate: view.warranty.shown,
      invalidPurchaseDate: view.warranty.shown,
      missingProofOfPurchase: view.warranty.shown,
    },
    services: {
      defects: view.services.defects.shown
        ? {
            noDefects: true,
            defects: {
              missingDescription: true,
              missingPhotos:
                view.services.defects.fields.defectPhoto.shown &&
                view.services.defects.fields.defectPhoto.required,
              missingActions: view.services.actions.shown,
            },
          }
        : undefined,
      choice: view.services.choice.shown
        ? {
            noChoice: true,
          }
        : undefined,
      actions: view.services.actions.shown
        ? {
            noActions: true,
            actions: {
              missingDescription: true,
              missingPhotos:
                view.services.actions.fields.defectPhoto.shown &&
                view.services.actions.fields.defectPhoto.required,
              missingCost: requestView.cost.enabled,
              missingCustomPrice: requestView.price.enabled,
            },
          }
        : undefined,
      dispatch: view.services.dispatch.shown
        ? {
            noWorkshopSelected: true,
          }
        : undefined,
    },
  } satisfies ArticleErrorsFilter;
}
