import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { Endpoints, ShippingOption } from '@/api';
import { Locale } from '@/services/i18n';
import { useFetch } from '@/utils/fetch';

import Phone, { formatPhone } from './partials/phone';
import { Address } from './address';
import { Model } from './model';

export class Client extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;
  organizationId!: string;
  name!: string;
  email!: string;
  address!: Address | null;
  billingAddress!: Address | null;
  deliveryOption!: DeliveryOption | null;
  shippingChoice!: ShippingOption | null;
  sendcloudServicePointId!: string | null;
  phone!: Phone | null;
  locale!: Locale | null;

  addressId!: string | null;

  createdAt!: string;

  get formattedPhone() {
    return formatPhone(this.phone);
  }
}

export type DeliveryOption = 'store' | 'home' | 'pickupPoint_mondialRelay';

export const useClientShippingOptions = ({ requestId }: { requestId: string }) => {
  const fetch = useFetch<Endpoints['GET /requests/:id/client/shipping-options']>();

  return useQuery({
    queryKey: ['request', requestId, 'client', 'shipping-options'],
    queryFn: () => fetch(`/requests/${requestId}/client/shipping-options`),
    placeholderData: keepPreviousData,
  });
};
