import React, { useState } from 'react';
import { Label } from 'react-aria-components';
import { useLingui } from '@lingui/react/macro';
import { Alpha2Code } from 'i18n-iso-countries';
import {
  CountryCode,
  getCountries,
  getCountryCallingCode as _getCountryCallingCode,
  isSupportedCountry,
  isValidPhoneNumber,
  parsePhoneNumberWithError,
} from 'libphonenumber-js';

import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import InputText from '@/design_system/InputText';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import Phone from '@/models/partials/phone';
import { useGeoIpCountry } from '@/services/auth';

const InputPhone = ({
  label,
  value,
  size = 'medium',
  variant = 'default',
  onChange,
  isInvalid,
  isDisabled,
}: {
  label?: React.ReactNode;
  value: Phone;
  size?: 'medium' | 'large';
  variant?: 'default' | 'brand';
  onChange?: (phone: Phone) => void;
  isInvalid?: boolean;
  isDisabled?: boolean;
}) => {
  const { t } = useLingui();

  const onFieldChange = (field: keyof Phone) => (fieldValue: string) => {
    onChange?.({ ...value, [field]: fieldValue });
  };

  const geoIpCountry = useGeoIpCountry();
  const validGeoIpCountry =
    geoIpCountry && isSupportedCountry(geoIpCountry) ? geoIpCountry : undefined;

  const [country, setCountry] = useState(() => {
    try {
      if (isPhoneValid(value)) {
        const parsedValue = parsePhoneNumberWithError(`+${value.countryCode}${value.number}`);
        return (
          parsedValue?.country ?? parsedValue?.getPossibleCountries()?.[0] ?? validGeoIpCountry
        );
      } else {
        return validGeoIpCountry;
      }
    } catch (e) {
      console.error(e);
      return validGeoIpCountry;
    }
  });

  const countryCodeOptions = getCountries().map((countryKey) => {
    const callingCode = _getCountryCallingCode(countryKey);

    return {
      id: countryKey,
      text: `${countryKey} (+${callingCode})`,
    };
  });

  const onSelectCountry = (key: CountryCode) => {
    setCountry(key);
    onFieldChange('countryCode')(_getCountryCallingCode(key));
  };

  return (
    <Stack gap="4px">
      {label && (
        <Label className={size === 'large' ? 'paragraph-50-medium' : 'paragraph-100-medium'}>
          {label}
        </Label>
      )}
      <Stack row gap="8px">
        <InputSelect
          variant="select"
          aria-label={t({ id: 'components.input-phone.code.label', message: 'Phone country code' })}
          placeholder={t({
            id: 'components.input-phone.code.placeholder',
            message: 'Country',
          })}
          isDisabled={isDisabled}
          options={countryCodeOptions}
          value={countryCodeOptions.find((countryCodeOption) => country === countryCodeOption.id)}
          getOptionValue={(countryCodeOption) => countryCodeOption.text}
          getOptionLabel={(countryCodeOption) => countryCodeOption.text}
          onChange={(countryCodeOption) => {
            if (countryCodeOption) {
              onSelectCountry(countryCodeOption?.id);
            }
          }}
          style={{ width: size === 'large' ? '8.5rem' : '7rem' }}
          size={size}
          styleVariant={variant}
          autoComplete="tel-country-code"
          aria-invalid={isInvalid}
        />

        <InputText
          size={size}
          aria-label={t({ id: 'components.input-phone.number.label', message: 'Phone number' })}
          placeholder={t({ id: 'components.input-phone.number.label', message: 'Phone number' })}
          value={value.number}
          onChange={(value) => onFieldChange('number')(value.replace(/[^\d]/g, ''))}
          isRequired
          type="tel"
          inputMode="tel"
          autoComplete="tel-national"
          style={{ flex: 1 }}
          isInvalid={isInvalid}
          isDisabled={isDisabled}
        />
      </Stack>
      {isInvalid && (
        <Message type="error">
          {t({
            id: 'components.input-phone.error',
            message: 'Please provide a valid phone number',
          })}
        </Message>
      )}
    </Stack>
  );
};

export default React.memo(InputPhone);

export const usePhoneState = (initialData?: Phone | null) => {
  const geoIpCountry = useGeoIpCountry();

  return useState<Phone>(
    initialData ?? {
      countryCode: getCountryCallingCode(geoIpCountry),
      number: '',
    }
  );
};

export const isPhoneValid = (phone?: { number?: string; countryCode?: string }): phone is Phone => {
  if (!phone || !phone.number || !phone.countryCode) {
    return false;
  }

  return isValidPhoneNumber(`+${phone.countryCode}${phone.number}`);
};

export const getCountryCallingCode = (country?: Alpha2Code) => {
  if (!country || !isSupportedCountry(country)) {
    return '';
  }

  return _getCountryCallingCode(country);
};
