import { useMemo, useState } from 'react';
import { Plural, Trans } from '@lingui/react/macro';
import { QRCodeSVG } from 'qrcode.react';

import { Choice, Choices } from '@/components/ArticlesTableCells/ArticleChoiceCell';
import config from '@/config';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconArrow from '@/icons/Arrow.svg';
import IconPackage from '@/icons/Package.svg';
import IconPlace from '@/icons/Place.svg';
import {
  BrandRequestMainContent,
  BrandRequestMainFooter,
} from '@/layouts/Brand/BrandRequestLayout/BrandRequestLayout';
import { useSetValidationChoice } from '@/models/article';
import {
  ClientRequestWithRelations,
  useCompleteValidation,
  useUpdateRequestClient,
} from '@/models/request';
import { useWorkflow } from '@/models/workflow';
import { DropOffStoresDialog } from '@/routes/Brand/Requests/Request/components/ClientRequestView/components/DropOffStoresDialog';
import ClientArticlesTable from '@/routes/Brand/Requests/Request/components/shared/ClientArticlesTable';
import { PickupPointInfo } from '@/routes/Brand/Requests/Request/components/shared/PickupPointInfo/PickupPointInfo';
import { useClientToken, useCurrentOrganization } from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';

import ClientStepper from '../../../shared/ClientStepper';
import IconSuccess from '../../../shared/IconSuccess';

import './Validation.css';

const { block, element } = createBEMClasses('client-request-validation');

const Validation = ({ request }: { request: ClientRequestWithRelations }) => {
  const {
    mutateAsync: completeValidation,
    isPending: isPendingCompleteValidation,
    isSuccess: isSuccessCompleteValidation,
  } = useCompleteValidation();

  const articlesInValidation = request.articles.filter(
    (article) => article.step?.step === 'validation'
  );

  const requireShippingChoice =
    !request.store &&
    !!request.client &&
    articlesInValidation[0].step?.step === 'validation' &&
    !!articlesInValidation[0].step.config.requireShippingChoice;

  const choicesFromRequest = useMemo(() => {
    return request.articles
      .filter((article) => article.step?.step === 'validation')
      .reduce((acc, curr) => {
        acc[curr.id] = {
          value: curr.quoteAcceptedAt ? 'accepted' : curr.quoteRefusedAt ? 'refused' : null,
          reason: curr.cancellationDetail?.reason ?? null,
          otherReason: curr.cancellationDetail?.otherReason ?? null,
        };

        return acc;
      }, {} as Choices);
  }, [request.articles]);

  const [choices, setChoices] = useState(choicesFromRequest);

  const { mutateAsync: setValidationChoice } = useSetValidationChoice({ requestId: request.id });

  const onChangeChoice = (articleId: string, choice: Choice) => {
    setChoices({
      ...choices,
      [articleId]: choice,
    });

    if (choice.value) {
      setValidationChoice({
        articleId,
        choice: { ...choice, value: choice.value },
      }).catch(() => setChoices(choicesFromRequest));
    }
  };

  const [showFormError, setShowFormError] = useState(false);

  const choiceMissing = articlesInValidation.some(
    (article) => !article.quoteAcceptedAt && !article.quoteRefusedAt
  );

  const reasonMissing = articlesInValidation.some(
    (article) =>
      !!article.quoteRefusedAt &&
      (!article.cancellationDetail?.reason ||
        (article.cancellationDetail?.reason === 'other' &&
          !article.cancellationDetail?.otherReason))
  );

  const shippingChoiceMissing =
    requireShippingChoice &&
    articlesInValidation.some((article) => article.quoteAcceptedAt) &&
    !request.client!.shippingChoice;

  const [error, setError] = useState<string | null>(null);
  const isValidating = articlesInValidation.some((article) => !article.validationCompletedAt);

  const showShippingOptions =
    isValidating &&
    requireShippingChoice &&
    articlesInValidation.some((article) => article.quoteAcceptedAt);

  const hasValidatedAndIsInStoreDropoff =
    !isValidating &&
    articlesInValidation.some(
      (article) => !!article.quoteAcceptedAt && !!article.validationCompletedAt
    ) &&
    requireShippingChoice &&
    request.client?.shippingChoice === 'store';

  // Legacy - To remove in TECH-1144
  const hasValidatedAndIsChoosingShippingOption =
    !isValidating &&
    articlesInValidation.some(
      (article) => !!article.quoteAcceptedAt && !!article.validationCompletedAt
    ) &&
    requireShippingChoice &&
    !request.client?.shippingChoice;

  function onSubmit() {
    if (choiceMissing || reasonMissing || shippingChoiceMissing) {
      setShowFormError(true);
      return;
    }

    setError(null);

    completeValidation({
      id: request.id,
    }).catch((error) => {
      setError(error.message as string);
    });
  }

  return (
    <>
      <BrandRequestMainContent>
        <Stack gap="1rem" className={block()}>
          <ClientStepper request={request} />
          {isValidating && <ValidationHeader />}
          {hasValidatedAndIsInStoreDropoff && <StoreDropoffHeader request={request} />}
          {hasValidatedAndIsChoosingShippingOption && (
            <LegacyShippingOrDropOffHeader request={request} />
          )}
          <PickupPointInfo request={request} />
          <ClientArticlesTable
            request={request}
            showPrice
            showArticleComment
            choices={isValidating ? choices : undefined}
            setChoice={isValidating ? onChangeChoice : undefined}
            showChoicesError={isValidating ? showFormError : undefined}
            showShippingOptions={showShippingOptions}
          />
        </Stack>
      </BrandRequestMainContent>
      {isValidating && (
        <BrandRequestMainFooter>
          <Stack gap="0.5rem" style={{ flex: 1 }}>
            {((showFormError && (choiceMissing || reasonMissing || shippingChoiceMissing)) ||
              error) && (
              <p className="paragraph-100-medium text-center text-danger">
                {choiceMissing ? (
                  <Trans id="client.request.validation.error.choice-missing">
                    Please accept or refuse the proposal for each item
                  </Trans>
                ) : reasonMissing ? (
                  <Trans id="client.request.validation.error.reason-missing">
                    Please select a reason for each refusal
                  </Trans>
                ) : shippingChoiceMissing ? (
                  <Trans id="client.request.validation.error.shipping-choice-missing">
                    Please select a shipping option
                  </Trans>
                ) : (
                  error
                )}
              </p>
            )}
            <Button
              variant="brand"
              size="large"
              onPress={onSubmit}
              isLoading={isPendingCompleteValidation || isSuccessCompleteValidation}
              disabled={isPendingCompleteValidation || isSuccessCompleteValidation}
            >
              <Trans id="client.request.validation.continue">Confirm & continue</Trans>
              <IconArrow right />
            </Button>
          </Stack>
        </BrandRequestMainFooter>
      )}
    </>
  );
};

const ValidationHeader = () => {
  return (
    <p className="paragraph-50-regular paragraph-100-regular-mobile">
      <Trans id="client.request.validation.entry.description">
        Based on the photos provided, our experts have issued an initial estimate. Please review it:
      </Trans>
    </p>
  );
};

const LegacyShippingOrDropOffHeader = ({ request }: { request: ClientRequestWithRelations }) => {
  const clientToken = useClientToken();
  const [organization] = useCurrentOrganization();
  const { data: workflow } = useWorkflow(request.workflowId);
  const shippingOptions = workflow?.config.shippingOptions ?? [];
  const allowDropOff = shippingOptions.includes('store') ?? false;
  const allowSelfShipment = shippingOptions.includes('home') ?? false;

  const [isOpenStoreListModal, setIsOpenStoreListModal] = useState(false);

  const { mutateAsync: updateRequestClient, isPending: isPendingRequestClient } =
    useUpdateRequestClient();

  return (
    <Stack gap="1rem">
      <Stack row gap="0.5rem" alignItems="center" flexWrap="nowrap">
        <div>
          <IconSuccess />
        </div>
        <p className="headline-200-bold headline-300-bold-mobile color-primary-800">
          <Trans id="client.request.validation.pending.title">
            Your care and repair service is confirmed
          </Trans>
        </p>
      </Stack>
      <Stack row gap="0.25rem">
        <p className="paragraph-50-regular paragraph-100-regular-mobile">
          <Trans id="client.request.validation.pending.shipment-options.title">
            It&apos;s time to ship us your{' '}
            <Plural value={request.articles.length} one="item" other="items" />!
          </Trans>
          {shippingOptions.length > 1 && (
            <>
              {' '}
              <Trans id="client.request.validation.pending.shipment-options.title2">
                You have 2 options:
              </Trans>
            </>
          )}
        </p>
      </Stack>
      <div className={element('shipment-options')}>
        {allowDropOff && (
          <Stack gap="1rem">
            <Stack row gap="1rem" flexWrap="nowrap" alignItems="center">
              <QRCodeSVG
                className={element('shipment-options__qr-code')}
                size={92}
                value={`${config.appUrl}/requests/claim?id=${request.id}&clientToken=${clientToken}`}
              />
              <Stack>
                <div className="paragraph-50-medium paragraph-100-medium-mobile">
                  <Trans id="client.request.validation.pending.shipment-option.store.title">
                    <Plural value={shippingOptions.length} one="" other="Option 1 - " /> Bring it to
                    a store
                  </Trans>
                </div>
                <div className="paragraph-50-regular paragraph-100-regular-mobile">
                  <Trans id="client.request.validation.pending.shipment-option.store.label">
                    Go to the nearest {organization?.name} store with this QR code to arrange
                    shipment.
                  </Trans>
                </div>
              </Stack>
            </Stack>
            <Button variant="brand" size="large" onPress={() => setIsOpenStoreListModal(true)}>
              <IconPlace start />
              <Trans id="client.request.validation.pending.shipment-option.store.action">
                Find a store
              </Trans>
            </Button>
          </Stack>
        )}
        {shippingOptions.length > 1 && (
          <span className="paragraph-50-medium">
            <Trans id="_general.or">or</Trans>
          </span>
        )}
        {allowSelfShipment && (
          <Stack gap="1rem">
            <Stack row gap="1rem" flexWrap="nowrap" alignItems="center">
              <img src="/shipment-label.png" alt="" />
              <Stack>
                <div className="paragraph-50-medium paragraph-100-medium-mobile">
                  <Trans id="client.request.validation.pending.shipment-option.client.title">
                    <Plural value={shippingOptions.length} one="" other="Option 2 - " /> Send it
                    yourself
                  </Trans>
                </div>
                <div className="paragraph-50-regular paragraph-100-regular-mobile">
                  <Trans id="client.request.validation.pending.shipment-option.client.label">
                    Print the shipment label and stick it on the package, visible and affixed to a
                    flat surface.
                  </Trans>
                </div>
              </Stack>
            </Stack>
            <Button
              variant="brand"
              size="large"
              disabled={isPendingRequestClient}
              onPress={() =>
                updateRequestClient({
                  id: request.id,
                  body: {
                    client: {
                      shippingChoice: 'home',
                    },
                  },
                })
              }
            >
              <IconPackage />
              <Trans id="client.request.validation.pending.shipment-option.client.action">
                Send it myself
              </Trans>
            </Button>
          </Stack>
        )}
      </div>
      <DropOffStoresDialog isOpen={isOpenStoreListModal} onOpenChange={setIsOpenStoreListModal} />
    </Stack>
  );
};

const StoreDropoffHeader = ({ request }: { request: ClientRequestWithRelations }) => {
  const clientToken = useClientToken();
  const [isOpenStoreListModal, setIsOpenStoreListModal] = useState(false);

  return (
    <>
      <Stack gap="1rem">
        <p className="paragraph-50-regular text-secondary">
          <Trans id="client.request.validation.pending-store-dropoff.title">
            Please bring your item to the closest authorized {request.organization.name} store.
          </Trans>
        </p>
        <Box padding="16px">
          <Stack row gap="1rem" alignItems="center">
            <QRCodeSVG
              className={element('shipment-options__qr-code')}
              size={96}
              value={`${config.appUrl}/requests/claim?id=${request.id}&clientToken=${clientToken}`}
            />
            <Stack gap="8px" alignItems="flex-start">
              <div className="paragraph-100-regular text-secondary">
                <Trans id="client.request.validation.pending-store-dropoff.description">
                  Drop your item at the nearest authorized {request.organization.name} store.
                  <br />
                  Show this QR code to the store manager to them grant access to the request.
                </Trans>
              </div>
              <Button variant="link" onPress={() => setIsOpenStoreListModal(true)}>
                <IconPlace start />
                <Trans id="client.request.validation.pending-store-dropoff.action">
                  See authorized stores
                </Trans>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
      <DropOffStoresDialog isOpen={isOpenStoreListModal} onOpenChange={setIsOpenStoreListModal} />
    </>
  );
};

export default Validation;
