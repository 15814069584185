import { useQuery } from '@tanstack/react-query';

import type { Endpoints } from '@/api';
import { WorkflowConfig } from '@/api';
import { useClientToken, useCurrentSession, useStoreToken } from '@/services/auth';
import { useFetch } from '@/utils/fetch';

import { getDisplayedStep } from './article';
import { Model } from './model';

export class Workflow extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;
  type!: WorkflowConfig['type'];
  config!: WorkflowConfig['config'];
  nodes!: WorkflowConfig['nodes'];

  get displayedSteps() {
    return this.nodes
      .map((node) => ('step' in node ? getDisplayedStep(node) : null))
      .filter((displayedStep) => !!displayedStep);
  }

  get hasInternalPaymentStep() {
    return !!this.nodes.find(
      (node) => 'step' in node && node.step === 'payment' && !node.config.externalPayment
    );
  }

  get hasTransitStepsExceptClientPickup() {
    return this.nodes.some(
      (node) => 'step' in node && node.step === 'transit' && !node.config.clientPickup
    );
  }
}

export const useWorkflow = (id?: string | null) => {
  const fetch = useFetch<Endpoints['GET /workflows/:id']>();
  const { currentSession } = useCurrentSession();
  const storeToken = useStoreToken();
  const clientToken = useClientToken();

  return useQuery({
    queryKey: ['workflow', id],
    queryFn: () => fetch(`/workflows/${id}`).then((workflow) => new Workflow(workflow)),
    enabled: !!id && (!!currentSession || !!storeToken || !!clientToken),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });
};
