import { useEffect, useRef, useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';
import { Alpha2Code } from 'i18n-iso-countries';
import SimpleBar from 'simplebar-react';

import { EmptyState, IllustrationNoResults } from '@/components/EmptyState';
import { InputCountry } from '@/components/InputCountry/InputCountry';
import Loader from '@/components/Loader';
import Box from '@/design_system/Box';
import Dialog from '@/design_system/Dialog';
import InputSearch from '@/design_system/InputSearch';
import Stack from '@/design_system/Stack';
import IconPhone from '@/icons/Phone.svg';
import IconPlace from '@/icons/Place.svg';
import { useInfiniteStores } from '@/models/store';
import { useCurrentOrganization } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

export const DropOffStoresDialog = ({
  isOpen,
  onOpenChange,
}: {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
}) => {
  const { isMobile } = useViewPort();
  const { t } = useLingui();
  const [organization] = useCurrentOrganization();
  const organizationName = organization?.name;
  const loadNextPageTriggerRef = useRef(null);

  const storesPerPage = 24; // To provide a cool UX

  const [country, setCountry] = useState<Alpha2Code | null>(null);
  const [city, setCity] = useState<string>();

  const { data, fetchNextPage, isLoading } = useInfiniteStores(
    {
      limit: storesPerPage,
      internal: true,
      embedded: true,
      country: country ?? undefined,
      search: city ?? undefined,
    },
    { enabled: isOpen }
  );

  const stores = data?.pages.flatMap((page) => page.stores) ?? [];

  useEffect(() => {
    if (loadNextPageTriggerRef.current === null) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          fetchNextPage();
        }
      },
      {
        // Root is the viewport
        root: null,
        threshold: 1.0,
      }
    );

    observer.observe(loadNextPageTriggerRef.current);

    return () => {
      observer.disconnect();
    };
  }, [loadNextPageTriggerRef, fetchNextPage, isOpen]);

  return (
    <Dialog
      title={<Trans id="client.drop-off-stores.dialog.title">{organizationName} stores</Trans>}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      style={{
        width: '50rem',
      }}
    >
      <main>
        <Stack gap="1rem">
          <div className="paragraph-100-regular">
            <Trans id="client.drop-off-stores.dialog.subtitle">
              Below is a list of authorized {organizationName} stores where you can drop off your
              item for shipment handling
            </Trans>
          </div>
          <Stack row={!isMobile} gap="1rem">
            <InputCountry
              isClearable
              style={{ flex: 1 }}
              aria-label={t({
                id: 'client.drop-off-stores.dialog.country.label',
                message: 'Country',
              })}
              placeholder={t({
                id: 'client.drop-off-stores.dialog.country.placeholder',
                message: 'Country: All',
              })}
              value={country}
              onChange={setCountry}
            />
            <InputSearch
              style={{ flex: 1 }}
              aria-label={t({ id: 'client.drop-off-stores.dialog.city.label', message: 'City' })}
              placeholder={t({
                id: 'client.drop-off-stores.dialog.city.placeholder',
                message: 'Search for a city...',
              })}
              value={city}
              onChange={setCity}
            />
          </Stack>
          <Stack
            style={{
              height: isMobile ? undefined : '30rem',
              minHeight: isMobile ? '20rem' : undefined,
            }}
          >
            {isLoading && (
              <Stack gap="16px" alignItems="center" justifyContent="center" style={{ flex: 1 }}>
                <Loader />
                <div className="paragraph-100-regular">
                  <Trans id="client.drop-off-stores.dialog.loading">Search in progress...</Trans>
                </div>
              </Stack>
            )}
            {!isLoading && stores.length === 0 && (
              <EmptyState
                subtitle={
                  <span>
                    <Trans id="client.drop-off-stores.dialog.no-stores">
                      No results are matching your criteria.
                      <br />
                      Please try with different keywords or filters
                    </Trans>
                  </span>
                }
                illustration={<IllustrationNoResults />}
              />
            )}
            {stores.length > 0 && (
              <SimpleBar
                style={{
                  // eslint-disable-next-line lingui/no-unlocalized-strings
                  margin: isMobile ? undefined : '0 -2rem -1.5rem -2rem',
                  padding: isMobile ? undefined : '0 2rem',
                  height: isMobile ? undefined : '30rem',
                }}
                autoHide={false}
              >
                <Stack
                  gap="1rem"
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(14rem, 1fr))',
                  }}
                >
                  {stores.map((store) => (
                    <Box key={store.id} padding="16px">
                      <Stack gap="0.5rem">
                        <p className="paragraph-100-medium">{store.name}</p>
                        <Stack row gap="0.5rem" flexWrap="nowrap">
                          <IconPlace end />
                          <Stack style={{ overflow: 'auto' }}>
                            <span
                              className="paragraph-200-regular text-ellipsis"
                              title={store.address?.formattedStreet}
                            >
                              {store.address?.formattedStreet}
                            </span>
                            <span
                              className="paragraph-200-regular text-ellipsis"
                              title={store.address?.formattedZip}
                            >
                              {store.address?.formattedZip}
                            </span>
                          </Stack>
                        </Stack>
                        {store.formattedPhone && (
                          <Stack row gap="0.5rem">
                            <IconPhone />
                            <p className="paragraph-200-regular">{store.formattedPhone}</p>
                          </Stack>
                        )}
                      </Stack>
                    </Box>
                  ))}
                </Stack>
                <div ref={loadNextPageTriggerRef} />
              </SimpleBar>
            )}
          </Stack>
        </Stack>
      </main>
    </Dialog>
  );
};
