import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Endpoints } from '@/api';
import { useFetch } from '@/utils/fetch';
import { Currency } from '@/utils/number';

import {
  ActionType,
  ActionTypeOrganization,
  ArticleAction,
  PackActionTypeOrganization,
  PackActionTypeOrganizationAction,
} from './actionType';
import { Article } from './article';
import { Client } from './client';
import { Fee, FeeType } from './fee';
import { InvoiceLine } from './invoiceLine';
import { Medium } from './medium';
import { Model } from './model';
import { Organization } from './organization';
import { OrganizationCountry } from './organizationCountry';
import { Price } from './price';
import { Product } from './product';
import { Translation } from './translation';
import { Workshop } from './workshop';

export class Invoice extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;
  number!: string;

  originOrganizationCountryId!: string | null;
  originWorkshopId!: string | null;
  destinationOrganizationCountryId!: string | null;
  destinationClientId!: string | null;

  amount!: number | null;
  currency!: Currency | null;
  issuingDate!: string | null;
  paymentDueDate!: string | null;
  submittedAt!: string | null;
  validatedAt!: string | null;
  paidAt!: string | null;

  paymentStrategy!: InvoicePaymentStrategy | null;
  issue!: string | null;

  status!: InvoiceStatus;

  transactionId!: string | null;

  creatorId!: string | null;
  createdAt!: string;
}

export const INVOICE_STATUS = [
  'draft',
  'pending-validation',
  'handled-by-accounting',
  'paid',
  'issue',
] as const;

export type InvoiceStatus = (typeof INVOICE_STATUS)[number];

export const INVOICE_PAYMENT_STRATEGY = [
  'store-cash-out',
  'prolong-payout',
  'handled-by-accounting-team',
  'prolong-payin', // Client payment via Prolong (only Stripe for now)
] as const;

export type InvoicePaymentStrategy = (typeof INVOICE_PAYMENT_STRATEGY)[number];

export const instanciateInvoiceWithRelations = (
  invoice: Endpoints['GET /invoices']['response']['invoices'][number]
) =>
  new Invoice(invoice)
    .with(
      'media',
      invoice.media.map((media) => new Medium(media))
    )
    .with('originWorkshop', invoice.originWorkshop ? new Workshop(invoice.originWorkshop) : null)
    .with(
      'originOrganizationCountry',
      invoice.originOrganizationCountry
        ? new OrganizationCountry(invoice.originOrganizationCountry).with(
            'organization',
            new Organization(invoice.originOrganizationCountry.organization)
          )
        : null
    )
    .with(
      'destinationClient',
      invoice.destinationClient ? new Client(invoice.destinationClient) : null
    )
    .with(
      'destinationOrganizationCountry',
      invoice.destinationOrganizationCountry
        ? new OrganizationCountry(invoice.destinationOrganizationCountry).with(
            'organization',
            new Organization(invoice.destinationOrganizationCountry.organization)
          )
        : null
    );

export type InvoiceWithRelations = ReturnType<typeof instanciateInvoiceWithRelations>;

export const instanciateInvoiceWithMoreRelations = (
  invoice: Endpoints['GET /invoices/:id']['response']
) =>
  new Invoice(invoice)
    .with('originWorkshop', invoice.originWorkshop ? new Workshop(invoice.originWorkshop) : null)
    .with(
      'originOrganizationCountry',
      invoice.originOrganizationCountry
        ? new OrganizationCountry(invoice.originOrganizationCountry).with(
            'organization',
            new Organization(invoice.originOrganizationCountry.organization)
          )
        : null
    )
    .with(
      'destinationClient',
      invoice.destinationClient ? new Client(invoice.destinationClient) : null
    )
    .with(
      'destinationOrganizationCountry',
      invoice.destinationOrganizationCountry
        ? new OrganizationCountry(invoice.destinationOrganizationCountry).with(
            'organization',
            new Organization(invoice.destinationOrganizationCountry.organization)
          )
        : null
    )
    .with(
      'lines',
      invoice.lines.map((line) =>
        new InvoiceLine(line)
          .with(
            'price',
            new Price(line.price)
              .with(
                'articleAction',
                line.price.articleAction
                  ? new ArticleAction(line.price.articleAction)
                      .with(
                        'actionTypeOrganization',
                        line.price.articleAction.actionTypeOrganization
                          ? new ActionTypeOrganization(
                              line.price.articleAction.actionTypeOrganization
                            ).with(
                              'actionType',
                              new ActionType(
                                line.price.articleAction.actionTypeOrganization.actionType
                              ).with(
                                'name',
                                new Translation(
                                  line.price.articleAction.actionTypeOrganization.actionType.name
                                )
                              )
                            )
                          : null
                      )
                      .with(
                        'packActionTypeOrganization',
                        line.price.articleAction.packActionTypeOrganization
                          ? new PackActionTypeOrganization(
                              line.price.articleAction.packActionTypeOrganization
                            )
                              .with(
                                'name',
                                new Translation(
                                  line.price.articleAction.packActionTypeOrganization.name
                                )
                              )
                              .with(
                                'actions',
                                line.price.articleAction.packActionTypeOrganization.actions.map(
                                  (subAction) =>
                                    new PackActionTypeOrganizationAction(subAction).with(
                                      'actionType',
                                      new ActionType(subAction.actionType).with(
                                        'name',
                                        new Translation(subAction.actionType.name)
                                      )
                                    )
                                )
                              )
                          : null
                      )
                  : null
              )
              .with(
                'fee',
                line.price.fee
                  ? new Fee(line.price.fee).with(
                      'feeType',
                      new FeeType(line.price.fee.feeType).with(
                        'name',
                        new Translation(line.price.fee.feeType.name)
                      )
                    )
                  : null
              )
          )
          .with(
            'article',
            line.article
              ? new Article(line.article).with(
                  'product',
                  line.article.product ? new Product(line.article.product) : null
                )
              : null
          )
          .with('request', line.request)
      )
    )
    .with(
      'media',
      invoice.media.map((media) => new Medium(media))
    );

export type InvoiceWithMoreRelations = ReturnType<typeof instanciateInvoiceWithMoreRelations>;

export const useInvoice = (id?: string) => {
  const fetch = useFetch<Endpoints['GET /invoices/:id']>();

  return useQuery({
    queryKey: ['invoices', id],
    queryFn: () =>
      fetch(`/invoices/${id}`).then((invoice) => instanciateInvoiceWithMoreRelations(invoice)),
    enabled: !!id,
  });
};

export const useInvoices = (
  params?: Endpoints['GET /invoices']['query'],
  options?: {
    enabled: boolean;
  }
) => {
  const fetch = useFetch<Endpoints['GET /invoices']>();

  return useQuery({
    queryKey: ['invoices', params],
    queryFn: () =>
      fetch('/invoices', params).then(({ invoices, meta }) => ({
        invoices: invoices.map(instanciateInvoiceWithRelations),
        meta,
      })),
    enabled: options?.enabled !== false,
  });
};

export const useInvoicesOrigins = (params?: Endpoints['GET /invoices/origins']['query']) => {
  const fetch = useFetch<Endpoints['GET /invoices/origins']>();

  return useQuery({
    queryKey: ['invoices-origins', params],
    queryFn: () =>
      fetch('/invoices/origins', params).then(({ workshops, organizationCountries }) => ({
        workshops: workshops.map((workshop) => new Workshop(workshop)),
        organizationCountries: organizationCountries.map((organizationCountry) =>
          new OrganizationCountry(organizationCountry).with(
            'organization',
            new Organization(organizationCountry.organization)
          )
        ),
      })),
  });
};

export const useInvoicesDestinations = (
  params?: Endpoints['GET /invoices/destinations']['query']
) => {
  const fetch = useFetch<Endpoints['GET /invoices/destinations']>();

  return useQuery({
    queryKey: ['invoices-destinations', params],
    queryFn: () =>
      fetch('/invoices/destinations', params).then(({ organizationCountries, client }) => ({
        organizationCountries: organizationCountries.map((organizationCountry) =>
          new OrganizationCountry(organizationCountry).with(
            'organization',
            new Organization(organizationCountry.organization)
          )
        ),
        client,
      })),
  });
};

export const useCreateInvoice = () => {
  const fetch = useFetch<Endpoints['POST /invoices']>();

  return useMutation({
    mutationFn: (body: Endpoints['POST /invoices']['body']) =>
      fetch('/invoices', undefined, { method: 'POST', body }),
  });
};

export const useUpdateInvoice = (id: string) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['PATCH /invoices/:id']>();

  const invoiceQueryKey = ['invoices', id];

  return useMutation({
    mutationFn: async ({
      originWorkshop,
      destinationOrganizationCountry,
      invoiceLines,
      ...data
    }: Omit<
      Endpoints['PATCH /invoices/:id']['body'],
      'originWorkshopId' | 'destinationOrganizationCountryId' | 'invoiceLineIds'
    > & {
      originWorkshop?: Workshop | null;
      destinationOrganizationCountry?:
        | (OrganizationCountry & { organization: Organization })
        | null;
      invoiceLines?: InvoiceWithMoreRelations['lines'];
    }) =>
      fetch(`/invoices/${id}`, undefined, {
        method: 'PATCH',
        body: {
          ...data,
          originWorkshopId: originWorkshop === null ? null : originWorkshop?.id,
          destinationOrganizationCountryId:
            destinationOrganizationCountry === null ? null : destinationOrganizationCountry?.id,
          invoiceLineIds: invoiceLines?.map(({ id }) => id),
        },
      }),
    onMutate: async ({ originWorkshop, destinationOrganizationCountry, invoiceLines, ...data }) => {
      await queryClient.cancelQueries({ queryKey: invoiceQueryKey });

      const previousInvoice = queryClient.getQueryData<InvoiceWithMoreRelations>(invoiceQueryKey);

      if (!previousInvoice) {
        return;
      }

      const updatedInvoice = instanciateInvoiceWithMoreRelations({
        ...previousInvoice,
        ...data,
        originWorkshop:
          originWorkshop === null ? null : (originWorkshop ?? previousInvoice.originWorkshop),
        destinationOrganizationCountry:
          destinationOrganizationCountry === null
            ? null
            : (destinationOrganizationCountry ?? previousInvoice.destinationOrganizationCountry),
        lines: invoiceLines ?? previousInvoice.lines,
      });

      queryClient.setQueryData(invoiceQueryKey, updatedInvoice);

      return { previousInvoice, updatedInvoice };
    },
    onError: (_err, _params, context) => {
      if (context?.previousInvoice) {
        queryClient.setQueryData(invoiceQueryKey, context.previousInvoice);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: invoiceQueryKey });
    },
  });
};

export const useSubmitInvoice = (id: string) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /invoices/:id/submit']>();

  return useMutation({
    mutationFn: () => fetch(`/invoices/${id}/submit`, undefined, { method: 'POST' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      queryClient.invalidateQueries({ queryKey: ['invoice-lines'] });
    },
  });
};

export const useValidateInvoice = (id: string) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /invoices/:id/validate']>();

  return useMutation({
    mutationFn: () => fetch(`/invoices/${id}/validate`, undefined, { method: 'POST' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
    },
  });
};

export const useReportIssue = (id: string) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /invoices/:id/report-issue']>();

  return useMutation({
    mutationFn: (body: Endpoints['POST /invoices/:id/report-issue']['body']) =>
      fetch(`/invoices/${id}/report-issue`, undefined, { method: 'POST', body }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
    },
  });
};

export const useDeleteInvoice = (id: string) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['DELETE /invoices/:id']>();

  return useMutation({
    mutationFn: () => fetch(`/invoices/${id}`, undefined, { method: 'DELETE' }),
    onSettled: () => {
      queryClient.removeQueries({ queryKey: ['invoices', id] });
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
    },
  });
};
