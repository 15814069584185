import { GridList, GridListItem } from 'react-aria-components';
import { Trans, useLingui } from '@lingui/react/macro';

import { TableQueryWrapper } from '@/components/TableQueryWrapper';
import Badge from '@/design_system/Badge';
import Box from '@/design_system/Box';
import { InputDateRangeValue } from '@/design_system/InputDate/InputDateRange';
import Pagination, { PaginationFooter } from '@/design_system/Pagination/Pagination';
import Stack from '@/design_system/Stack';
import { Body, Cell, Column, Header, Row, Table } from '@/design_system/Table/Table';
import IconBuilding from '@/icons/Building.svg';
import IconStore from '@/icons/Store.svg';
import IconStoreRepair from '@/icons/StoreRepair.svg';
import { useGetArticleName } from '@/models/article';
import {
  InvoiceLineWithRelations,
  useInvoiceLineName,
  useInvoiceLines,
} from '@/models/invoiceLine';
import { formatDate } from '@/utils/date';
import { formatCurrency } from '@/utils/number';
import useViewPort from '@/utils/useViewport';

import { InvoiceLinePaymentStrategy } from './components/filters/InvoiceLinePaymentStrategySelect';
import { InvoiceLinesEmptyState } from './components/InvoiceLinesEmptyState';
import { InvoiceLinesFilter } from './components/InvoiceLinesFilter';

export const INVOICE_LINES_PER_PAGE = 20;

export const InvoiceLines = ({
  page,
  setPage,
  search,
  debouncedSearch,
  debouncedSetSearch,
  invoiceLineWorkshop,
  setInvoiceLineWorkshop,
  invoiceLineOrganizationCountry,
  setInvoiceLineOrganizationCountry,
  invoiceLinePaymentStrategy,
  setInvoiceLinePaymentStrategy,
  dateRange,
  setDateRange,
}: {
  page: number;
  setPage: (page: number) => void;
  search: string;
  debouncedSearch: string;
  debouncedSetSearch: (value: string) => void;
  invoiceLineWorkshop: string;
  setInvoiceLineWorkshop: (workshop: string) => void;
  invoiceLineOrganizationCountry: string;
  setInvoiceLineOrganizationCountry: (organizationCountry: string) => void;
  invoiceLinePaymentStrategy: InvoiceLinePaymentStrategy | '';
  setInvoiceLinePaymentStrategy: (
    invoiceLinePaymentStrategy: InvoiceLinePaymentStrategy | ''
  ) => void;
  dateRange: InputDateRangeValue;
  setDateRange: (dateRange: InputDateRangeValue) => void;
}) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();

  const {
    data: { invoiceLines, meta } = { invoiceLines: [] },
    isLoading,
    isError,
  } = useInvoiceLines({
    limit: INVOICE_LINES_PER_PAGE,
    offset: (page - 1) * INVOICE_LINES_PER_PAGE,
    search: debouncedSearch || undefined,
    originWorkshop: invoiceLineWorkshop || undefined,
    destinationOrganizationCountry: invoiceLineOrganizationCountry || undefined,
    paymentStrategy: invoiceLinePaymentStrategy || undefined,
    createdAtFrom: dateRange.start,
    createdAtTo: dateRange.end,
  });

  const columnWidths = {
    service: 'minmax(180px, auto)',
    article: 'minmax(180px, 1fr)',
    request: 'minmax(100px, 1fr)',
    workshop: 'minmax(180px, 1fr)',
    organizationCountry: 'minmax(180px, 1fr)',
    date: 'minmax(140px, auto)',
    amount: 'minmax(100px, auto)',
  };

  return (
    <Stack gap="1rem" style={{ height: '100%' }}>
      <InvoiceLinesFilter
        setPage={setPage}
        search={search}
        debouncedSetSearch={debouncedSetSearch}
        invoiceLineWorkshop={invoiceLineWorkshop}
        setInvoiceLineWorkshop={setInvoiceLineWorkshop}
        invoiceLineOrganizationCountry={invoiceLineOrganizationCountry}
        setInvoiceLineOrganizationCountry={setInvoiceLineOrganizationCountry}
        invoiceLinePaymentStrategy={invoiceLinePaymentStrategy}
        setInvoiceLinePaymentStrategy={setInvoiceLinePaymentStrategy}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />

      <TableQueryWrapper isLoading={isLoading} isError={isError}>
        {!invoiceLines.length ? (
          <InvoiceLinesEmptyState />
        ) : (
          <>
            {!isMobile && (
              <Table
                ariaLabel={t({
                  id: 'accounting.invoice-lines.table.label',
                  message: 'Services to associate list',
                })}
                columnWidths={[
                  columnWidths.service,
                  columnWidths.article,
                  columnWidths.request,
                  columnWidths.workshop,
                  columnWidths.organizationCountry,
                  columnWidths.date,
                  columnWidths.amount,
                ]}
              >
                <Header>
                  <Row>
                    <Column>
                      <Trans id="accounting.invoice-lines.table.column.service">Service</Trans>
                    </Column>
                    <Column>
                      <Trans id="accounting.invoice-lines.table.column.article">Item</Trans>
                    </Column>
                    <Column>
                      <Trans id="accounting.invoice-lines.table.column.request">Request</Trans>
                    </Column>
                    <Column>
                      <Trans id="accounting.invoice-lines.table.column.workshop">Workshop</Trans>
                    </Column>
                    <Column>
                      <Trans id="accounting.invoice-lines.table.column.organization-country">
                        Organization
                      </Trans>
                    </Column>
                    <Column>
                      <Trans id="accounting.invoice-lines.table.column.date">Date</Trans>
                    </Column>
                    <Column>
                      <Trans id="accounting.invoice-lines.table.column.amount">Amount</Trans>
                    </Column>
                  </Row>
                </Header>

                <Body>
                  {invoiceLines.map((invoiceLine) => (
                    <InvoiceLineRow key={invoiceLine.id} invoiceLine={invoiceLine} />
                  ))}
                </Body>

                <PaginationFooter
                  page={page}
                  itemsPerPage={INVOICE_LINES_PER_PAGE}
                  count={meta?.count}
                  onPageChange={setPage}
                />
              </Table>
            )}

            {isMobile && (
              <>
                <GridList
                  aria-label={t({
                    id: 'accounting.invoice-lines.table.label',
                    message: 'Services to associate list',
                  })}
                  style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
                >
                  {invoiceLines.map((invoiceLine) => (
                    <InvoiceLineCard key={invoiceLine.id} invoiceLine={invoiceLine} />
                  ))}
                </GridList>

                <Pagination
                  page={page}
                  itemsPerPage={INVOICE_LINES_PER_PAGE}
                  count={meta?.count}
                  onPageChange={setPage}
                />
              </>
            )}
          </>
        )}
      </TableQueryWrapper>
    </Stack>
  );
};

const InvoiceLineRow = ({ invoiceLine }: { invoiceLine: InvoiceLineWithRelations }) => {
  const name = useInvoiceLineName(invoiceLine);
  const getArticleName = useGetArticleName();

  return (
    <Row>
      <Cell>
        <Stack gap="0.125rem" alignItems="flex-start">
          <p className="paragraph-100-regular">{name}</p>
          {invoiceLine.paymentStrategy === 'store-cash-out' && (
            <Badge size="small" color="primary" hasBorder>
              <Stack row gap="4px" alignItems="center" flexWrap="nowrap">
                <IconStore />
                <Trans id="accounting.invoice-lines.table.payment-strategy.store-cash-out">
                  Store payment (cash out)
                </Trans>
              </Stack>
            </Badge>
          )}
        </Stack>
      </Cell>
      <Cell>{invoiceLine.article ? getArticleName({ article: invoiceLine.article }) : '-'}</Cell>
      <Cell>{invoiceLine.request.reference}</Cell>
      <Cell>{invoiceLine.price.destinationWorkshop?.name ?? '-'}</Cell>
      <Cell>{invoiceLine.price.originOrganizationCountry?.name ?? '-'}</Cell>
      <Cell>{formatDate(new Date(invoiceLine.createdAt), { dateStyle: 'medium' })}</Cell>
      <Cell justifyContent="flex-end">
        {formatCurrency(invoiceLine.price.amount, invoiceLine.price.currency)}
      </Cell>
    </Row>
  );
};

const InvoiceLineCard = ({ invoiceLine }: { invoiceLine: InvoiceLineWithRelations }) => {
  const name = useInvoiceLineName(invoiceLine);
  const getArticleName = useGetArticleName();

  return (
    <GridListItem>
      <Box padding="16px" role="row" gap="8px">
        <Stack row alignItems="center" justifyContent="space-between" flexWrap="nowrap" gap="8px">
          <Stack>
            <span className="paragraph-100-regular">{name}</span>
            <span className="paragraph-200-regular text-secondary">
              {!!invoiceLine.article && <>{getArticleName({ article: invoiceLine.article })} • </>}
              {invoiceLine.request.reference}
            </span>
          </Stack>
          <span className="paragraph-100-regular">
            {formatCurrency(invoiceLine.price.amount, invoiceLine.price.currency)}
          </span>
        </Stack>
        <Stack row gap="0.5rem" alignItems="center">
          {!!invoiceLine.price.destinationWorkshop?.name && (
            <Badge color="neutral" size="large" borderRadius="soft" hasBorder>
              <Stack row gap="4px">
                <IconStoreRepair style={{ fontSize: '1rem' }} />
                {invoiceLine.price.destinationWorkshop.name}
              </Stack>
            </Badge>
          )}
          {!!invoiceLine.price.originOrganizationCountry?.name && (
            <Badge color="neutral" size="large" borderRadius="soft" hasBorder>
              <Stack row gap="4px">
                <IconBuilding style={{ fontSize: '1rem' }} />
                {invoiceLine.price.originOrganizationCountry.name}
              </Stack>
            </Badge>
          )}
          {invoiceLine.paymentStrategy === 'store-cash-out' && (
            <Badge color="primary" size="large" borderRadius="soft" hasBorder>
              <Stack row gap="4px">
                <IconStore style={{ fontSize: '1rem' }} />
                <Trans id="accounting.invoice-lines.table.payment-strategy.store-cash-out">
                  Store payment (cash out)
                </Trans>
              </Stack>
            </Badge>
          )}
        </Stack>
      </Box>
    </GridListItem>
  );
};
