import { Article } from '@/models/article';
import { Client } from '@/models/client';
import { Medium } from '@/models/medium';
import { Model } from '@/models/model';
import { Product } from '@/models/product';
import { User } from '@/models/user';

export const COMMENT_VISIBILITY = [
  'public',
  'organization',
  'external-workshop',
  'client',
  // TODO: Handle external-store visibility (for multi-brands stores)
  // 'external-store',
] as const;
export type CommentVisibility = (typeof COMMENT_VISIBILITY)[number];

export class Comment extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);

    this.creator = data.creator ? new User(data.creator) : null;
    this.clientCreator = data.clientCreator ? new Client(data.clientCreator) : null;
    this.article = data.article
      ? new Article(data.article).with('product', data.article.product)
      : null;
    this.media = data.media ? data.media.map((medium: any) => new Medium(medium)) : [];
  }

  id!: string;
  organizationId!: string;
  content!: string;
  visibility!: CommentVisibility;

  creatorId?: string | null;
  creator: User | null;
  clientCreatorId?: string | null;
  clientCreator: Client | null;

  requestId!: string | null;

  articleId!: string | null;
  article?: (Article & { product: Product | null }) | null;

  shipmentId!: string | null;

  media!: Medium[];

  createdAt!: string;
  updatedAt!: string;

  get createdAtDate() {
    return new Date(this.createdAt);
  }

  get updatedAtDate() {
    return new Date(this.updatedAt);
  }
}
