import { PropsWithChildren } from 'react';
import { Trans } from '@lingui/react/macro';

import config from '@/config';
import { RequestInfo } from '@/routes/Brand/Requests/Request/components/shared/RequestInfo';
import { useCurrentOrganization } from '@/services/auth';
import { ErrorBoundary } from '@/services/sentry';
import { createBEMClasses } from '@/utils/classname';
import useViewPort from '@/utils/useViewport';

import { useBrandRequestContext } from '../BrandRequestContext';

import './BrandRequestLayout.css';

const { block, element } = createBEMClasses('brand-request-layout');

export const BrandRequestLayout = ({ children }: PropsWithChildren) => {
  return <div className={block()}>{children}</div>;
};

export const BrandRequestMain = ({ children }: PropsWithChildren) => {
  return <main className={element('main')}>{children}</main>;
};

export const BrandRequestMainHeader = ({ children }: PropsWithChildren) => {
  return (
    <header className={element('main__header')}>
      <Logo />
      {children}
    </header>
  );
};

export const BrandRequestMainContent = ({ children }: PropsWithChildren) => {
  const { isMobile } = useViewPort();
  const { request } = useBrandRequestContext();

  return (
    <ErrorBoundary>
      <main className={element('main__content')}>
        <h1
          className="headline-200-medium headline-300-medium-mobile"
          style={{ marginBottom: isMobile ? 16 : 24 }}
        >
          <Trans id="brand.request.title">Care and repair request</Trans>
          {isMobile && <> [{request.reference}]</>}
        </h1>
        {children}
        {isMobile && <RequestInfo />}
      </main>
    </ErrorBoundary>
  );
};

export const BrandRequestMainFooter = ({ children }: PropsWithChildren) => {
  return (
    <ErrorBoundary>
      <footer className={element('main__footer')}>{children}</footer>
    </ErrorBoundary>
  );
};

export const BrandRequestSidebar = ({ children }: PropsWithChildren) => {
  return (
    <aside className={element('sidebar')}>
      <header className={element('sidebar__header')}>
        <Logo />
      </header>
      {children}
    </aside>
  );
};

export const BrandRequestSidebarContent = ({ children }: PropsWithChildren) => {
  return <main className={element('sidebar__content')}>{children}</main>;
};

export const BrandRequestSidebarFooter = ({ children }: PropsWithChildren) => {
  return <footer className={element('sidebar__footer')}>{children}</footer>;
};

const Logo = () => {
  const [organization] = useCurrentOrganization();

  return (
    <img
      src={`${config.apiUrl}/organizations/${organization?.slug}/logo`}
      alt=""
      style={{
        maxWidth: '10rem',
        maxHeight: '2rem',
      }}
    />
  );
};
