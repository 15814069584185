import { Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconFileVerification from '@/icons/FileVerification.svg';

export const ArticleServiceChoiceCompletedActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_service_choice_completed'>;
  displayArticleName: boolean;
}) => {
  const creatorName = activity.creator?.name;
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="neutral"
      icon={<IconFileVerification />}
      message={
        <BaseActivityText>
          <Trans id="activities.article_service_choice_completed.message">
            <strong>{creatorName}</strong> completed the service choice for the item{' '}
            <BaseActivityArticleName activity={activity} displayArticleName={displayArticleName} />
          </Trans>
        </BaseActivityText>
      }
    />
  );
};
