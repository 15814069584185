import { Trans } from '@lingui/react/macro';

import { BrandRequestMainContent } from '@/layouts/Brand/BrandRequestLayout/BrandRequestLayout';
import { ClientRequestWithRelations } from '@/models/request';

export const Archived = ({ request }: { request: ClientRequestWithRelations }) => {
  return (
    <BrandRequestMainContent>
      <p className="paragraph-50-regular paragraph-100-regular-mobile">
        {!request.client && (
          <Trans id="store.request.archived.message.store">
            Your care and repair service has been archived.
          </Trans>
        )}
        {!!request.client && (
          <Trans id="store.request.archived.message.client">
            The care and repair service of your client <strong>{request.client.name}</strong> has
            been archived.
          </Trans>
        )}
      </p>
    </BrandRequestMainContent>
  );
};
