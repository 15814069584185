import { useLingui } from '@lingui/react';

import { Stepper, StepperProps, StepProps } from '@/design_system/Stepper/Stepper';
import { ARTICLE_DISPLAYED_STEPS, getDisplayedStep } from '@/models/article';
import { ClientArticleWithRelations } from '@/models/request';

export const ClientArticleStepper = ({
  article,
  ...props
}: {
  article: ClientArticleWithRelations;
} & Omit<StepperProps, 'steps'>) => {
  const { _ } = useLingui();

  if (!article.steps) {
    return '-';
  }

  const steps = article.steps
    .map((step) => {
      const displayedStep = getDisplayedStep(step);

      if (!displayedStep) {
        return null;
      }

      return {
        ...step,
        name: _(ARTICLE_DISPLAYED_STEPS.find(({ id }) => id === displayedStep)!.name),
      };
    })
    .filter((step) => !!step)
    .reduce(
      (acc, step, index, steps) => {
        if (acc.length === 0) {
          return [step];
        }

        // Merge analysis steps, and everything in between
        if (
          acc[acc.length - 1].step === 'analysis' &&
          steps.slice(index).some((s) => s.step === 'analysis')
        ) {
          if (acc[acc.length - 1].status === 'done' || acc[acc.length - 1].status === 'cancelled') {
            acc[acc.length - 1].status = step.status;
          }
          return acc;
        }

        return [...acc, step];
      },
      [] as (StepProps & { step: string })[]
    );

  return <Stepper steps={steps} {...props} />;
};
