import { Trans, useLingui } from '@lingui/react/macro';

import Stack from '@/design_system/Stack';
import IconFileValidation from '@/icons/FileValidation.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import type { TNotificationOfType } from '@/models/notification';

export const DispatchProposalRefusedNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'dispatch_proposal_refused'>;
}) => {
  const { t } = useLingui();

  return (
    <BaseNotification
      icon={<IconFileValidation />}
      type="danger"
      notification={notification}
      notificationHref="/requests"
      notificationHeader={
        <Trans id="notification.dispatch_proposal_refused">
          The job acceptance proposal you submitted has been refused
        </Trans>
      }
      notificationLabel={t({
        id: 'notification.dispatch_proposal_refused.label',
        message: `Your job acceptance proposal has been refused`,
      })}
      notificationContent={
        <Stack
          className="bg-neutral-100"
          style={{
            padding: '8px 12px',
            borderRadius: '0.5rem',
          }}
        >
          <p className="paragraph-100-regular">{notification.data.reason}</p>
        </Stack>
      }
    />
  );
};
