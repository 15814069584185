import { useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconCalendar from '@/icons/Calendar.svg';
import IconDownload from '@/icons/Download.svg';
import { useMedia } from '@/models/medium';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { formatDate } from '@/utils/date';

import { Item } from './Item';

export const ArticleWarranty = () => {
  const { article } = useArticleContext();
  const { t } = useLingui();
  const { data: { media: proofOfPurchaseMedia } = { media: [] } } = useMedia({
    articleId: article.id,
    types: ['proof-of-purchase'],
    limit: 1,
  });

  const proofOfPurchase = proofOfPurchaseMedia?.[0];

  return (
    <Stack gap="0.75rem">
      <Item
        label={t({ id: 'article.information.warranty.label', message: 'Warranty' })}
        value={
          article.warranty
            ? t({ id: 'design-system.radio-group.yes-no.yes', message: 'Yes' })
            : t({ id: 'design-system.radio-group.yes-no.no', message: 'No' })
        }
      />
      {article.warranty && (
        <>
          <Item label={t({ id: 'article.information.purchase-date', message: 'Purchase date' })}>
            <Stack row gap="0.25rem" alignItems="center">
              <IconCalendar />
              <span
                className="paragraph-100-medium"
                aria-label={t({
                  id: 'article.information.purchase-date',
                  message: 'Purchase date',
                })}
              >
                {article.purchaseDateDate
                  ? formatDate(article.purchaseDateDate, { dateStyle: 'medium' })
                  : '-'}
              </span>
            </Stack>
          </Item>
          <Item
            label={t({ id: 'article.information.proof-of-purchase', message: 'Proof of purchase' })}
          >
            {proofOfPurchase && (
              <Stack row flexWrap="nowrap" gap="0.25rem">
                <span className="paragraph-100-medium text-primary text-ellipsis">
                  {proofOfPurchase.originalPath}
                </span>
                <Button
                  href={proofOfPurchase.url}
                  target="_blank"
                  rel="noreferrer"
                  variant="secondary"
                  iconOnly
                  size="small"
                  tooltip={t({ id: 'article.information.download', message: 'Download' })}
                >
                  <IconDownload className="text-secondary" />
                </Button>
              </Stack>
            )}
          </Item>
        </>
      )}
    </Stack>
  );
};
