import { useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import { useShowToast } from '@/design_system/Toast';
import IconError from '@/icons/Error.svg';
import { useAcceptDispatch, useRefuseDispatch, useSubmitDispatchProposal } from '@/models/article';
import { ArticleWithRelations } from '@/models/request';
import { useWorkshop } from '@/models/workshop';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import { useCurrentSession, useFlags } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

import { ArticleActionProps } from './ArticleActions';

export const AcceptDispatchAction = ({ onActionDone }: ArticleActionProps) => {
  const { isMobile } = useViewPort();
  const { t } = useLingui();
  const { flags } = useFlags();
  const { request } = useRequestContext();
  const { article, state, hasError } = useArticleContext();
  const { isWorkshop } = useCurrentSession();

  const [isOpenRefuseDialog, setIsOpenRefuseDialog] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);

  const {
    mutateAsync: acceptDispatch,
    isPending: isAcceptDispatchPending,
    isSuccess: isAcceptDispatchSuccess,
  } = useAcceptDispatch({
    articleId: article.id,
  });

  const {
    mutateAsync: submitDispatchProposal,
    isPending: isSubmitDispatchProposalPending,
    isSuccess: isSubmitDispatchProposalSuccess,
  } = useSubmitDispatchProposal({
    articleId: article.id,
  });

  const atLeastOneUpdatedWorkshopPrice = article.snapshot.articleActions.some((action) => {
    const currentAmount = isWorkshop
      ? action.price?.amountPerCurrency[0]?.amount
      : action.cost?.amountPerCurrency[0]?.amount;

    const previousAction = article.previousSnapshot?.articleActions.find(
      (previousAction) => previousAction.stableId === action.stableId
    );

    const previousAmount = isWorkshop
      ? previousAction?.price?.amountPerCurrency[0]?.amount
      : previousAction?.cost?.amountPerCurrency[0]?.amount;

    if (!previousAction) {
      return false;
    }

    return currentAmount !== previousAmount;
  });

  const handleAcceptOrSubmitProposal = async () => {
    const { hasError } = state.errors.check({
      services: {
        actions: {
          actions: {
            missingDescription: true,
            missingCost: !isWorkshop && flags['enable-dispatch-cost-edition'],
            missingCustomPrice: !!isWorkshop && flags['enable-dispatch-cost-edition'],
          },
        },
      },
    });

    setApiError(null);

    if (!hasError) {
      try {
        if (flags['enable-dispatch-cost-edition'] && atLeastOneUpdatedWorkshopPrice) {
          await submitDispatchProposal();
        } else {
          await acceptDispatch();
        }

        onActionDone();
      } catch (err: any) {
        console.error(err);
        setApiError(
          (err.message as string) ?? t({ id: '_general.error.unknown', message: 'Unknown error' })
        );
      }
    }
  };

  const { data: workshop } = useWorkshop(article.workshopId);
  const autoAcceptDispatchProposal = !!workshop?.organizations.find(
    ({ organizationId }) => organizationId === article.organizationId
  )?.autoAcceptDispatchProposal;

  return (
    <Stack gap="0.5rem" style={{ flex: 1 }}>
      {hasError && (
        <p className="paragraph-100-medium text-danger">
          <Trans id="article.actions.accept_dispatch.errors">
            Please fill the missing information in order to accept the job
          </Trans>
        </p>
      )}
      {apiError && <p className="paragraph-100-medium text-danger">{apiError}</p>}
      <Stack row={!isMobile} gap="0.5rem" justifyContent="flex-end">
        <Button
          size="medium"
          variant="secondary-danger"
          onPress={() => setIsOpenRefuseDialog(true)}
        >
          <IconError />
          <Trans id="article.actions.accept_dispatch.refuse">Refuse the job</Trans>
        </Button>
        <Button
          variant="primary"
          size="medium"
          onPress={handleAcceptOrSubmitProposal}
          isLoading={
            isAcceptDispatchPending ||
            isAcceptDispatchSuccess ||
            isSubmitDispatchProposalPending ||
            isSubmitDispatchProposalSuccess
          }
        >
          {flags['enable-dispatch-cost-edition'] &&
          atLeastOneUpdatedWorkshopPrice &&
          !autoAcceptDispatchProposal ? (
            <Trans id="article.actions.accept_dispatch.proposal">Send new quote proposal</Trans>
          ) : (
            <Trans id="article.actions.accept_dispatch.accept">Accept the job</Trans>
          )}
        </Button>
      </Stack>

      <RefuseDispatchDialog
        article={article}
        isOpen={isOpenRefuseDialog}
        setIsOpen={setIsOpenRefuseDialog}
        // If the workshop is refusing the only item of the request, after the refusal it won't
        // be able to see the request anymore.
        onActionDone={() => onActionDone({ navigateToRequests: request.articles.length === 1 })}
      />
    </Stack>
  );
};

const RefuseDispatchDialog = ({
  article,
  isOpen,
  setIsOpen,
  onActionDone,
}: {
  article: ArticleWithRelations;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onActionDone: () => void;
}) => {
  const { t } = useLingui();
  const showToast = useShowToast();
  const { isWorkshop } = useCurrentSession();

  const {
    mutateAsync: refuseDispatch,
    isPending: isRefuseDispatchPending,
    isSuccess: isRefuseDispatchSuccess,
  } = useRefuseDispatch({
    article,
  });

  const [showErrors, setShowErrors] = useState(false);

  const [reason, setReason] = useState('');

  const save = async () => {
    setShowErrors(true);

    if (!reason) {
      return;
    }

    await refuseDispatch({ refusalReason: reason });

    showToast({
      type: 'success',
      text: t({
        id: 'dispatch-refusal-modal.actions.refuse.success',
        message: 'The item has been successfully refused',
      }),
    });

    onActionDone();
  };

  return (
    <Dialog
      title={t({ id: 'dispatch-refusal-modal.title', message: 'Refuse the job' })}
      isOpen={isOpen}
      onOpenChange={setIsOpen}
    >
      <main>
        <Stack gap="1rem">
          <p className="paragraph-100-regular">
            {isWorkshop ? (
              <Trans id="dispatch-refusal-modal.text">
                Once you refuse the job, the item won&apos;t appear anymore on your requests
                dashboard. Please specify the reason for your refusal.
              </Trans>
            ) : (
              <Trans id="dispatch-refusal-modal.on-behalf.text">
                Please specify the reason for the workshop&apos;s refusal.
              </Trans>
            )}
          </p>
          <TextArea
            ariaLabel={t({
              id: 'dispatch-refusal-modal.reason.label',
              message: 'Refusal reason',
            })}
            placeholder={t({
              id: 'dispatch-refusal-modal.reason.placeholder',
              message: 'Write refusal reason',
            })}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            isInvalid={showErrors && !reason}
          />
        </Stack>
      </main>
      <footer>
        <Button variant="secondary" size="medium" onPress={() => setIsOpen(false)}>
          <Trans id="dispatch-refusal-modal.actions.cancel">Cancel</Trans>
        </Button>
        <Button
          variant="danger"
          size="medium"
          onPress={save}
          isLoading={isRefuseDispatchPending || isRefuseDispatchSuccess}
        >
          <Trans id="dispatch-refusal-modal.actions.refuse">Refuse the job</Trans>
        </Button>
      </footer>
    </Dialog>
  );
};
