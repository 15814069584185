import cn from 'classnames';

interface LogoProps {
  size?: 'small' | 'large';
  className?: string;
}

const Logo = ({ size = 'large', className }: LogoProps) => {
  if (size === 'small') {
    return (
      <svg
        className={cn('logo small', className)}
        width="120"
        height="51"
        viewBox="0 0 120 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M94.56 12.5094H25.44C18.2824 12.5094 12.48 18.3255 12.48 25.5C12.48 32.6745 18.2824 38.4906 25.44 38.4906H94.56C101.718 38.4906 107.52 32.6745 107.52 25.5C107.52 18.3255 101.718 12.5094 94.56 12.5094ZM25.44 0C11.3899 0 0 11.4167 0 25.5C0 39.5833 11.3899 51 25.44 51H94.56C108.61 51 120 39.5833 120 25.5C120 11.4167 108.61 0 94.56 0H25.44Z"
          fill="#3AD98F"
        />
      </svg>
    );
  }

  return (
    <svg
      className={cn('logo large', className)}
      width="440"
      height="53"
      viewBox="0 0 440 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M295.656 13H223.982C216.56 13 210.543 19.0441 210.543 26.5C210.543 33.9558 216.56 40 223.982 40H295.656C303.078 40 309.095 33.9558 309.095 26.5C309.095 19.0441 303.078 13 295.656 13ZM223.982 0C209.413 0 197.602 11.8644 197.602 26.5C197.602 41.1355 209.413 53 223.982 53H295.656C310.225 53 322.036 41.1355 322.036 26.5C322.036 11.8644 310.225 0 295.656 0H223.982Z"
        fill="#3AD98F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3C0 2.17157 0.668534 1.5 1.49321 1.5H18.6652C28.9736 1.5 37.3303 9.89465 37.3303 20.25C37.3303 30.6053 28.9736 39 18.6652 39H11.9457V50C11.9457 50.8284 11.2772 51.5 10.4525 51.5H1.49321C0.668534 51.5 0 50.8284 0 50V3ZM11.9457 27H18.6652C22.3762 27 25.3846 23.9779 25.3846 20.25C25.3846 16.5221 22.3762 13.5 18.6652 13.5H11.9457V27Z"
        fill="#131514"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.2851 3C47.2851 2.17157 47.9536 1.5 48.7783 1.5H65.9502C76.2587 1.5 84.6154 9.89465 84.6154 20.25C84.6154 25.6107 82.3759 30.4459 78.7855 33.8631L87.6289 49.25C88.2036 50.25 87.4852 51.5 86.3357 51.5H75.9905C75.457 51.5 74.964 51.2141 74.6973 50.75L67.8868 38.9003C65.0283 39.1963 62.0986 39 59.2308 39V50C59.2308 50.8284 58.5622 51.5 57.7376 51.5H48.7783C47.9536 51.5 47.2851 50.8284 47.2851 50V3ZM59.2308 27H65.9502C69.6613 27 72.6697 23.9779 72.6697 20.25C72.6697 16.5221 69.6613 13.5 65.9502 13.5H59.2308V27Z"
        fill="#131514"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M331.991 3C331.991 2.17157 332.659 1.5 333.484 1.5H343.984C344.918 1.5 345.797 1.93831 346.362 2.68475L369.819 33.6892V3C369.819 2.17157 370.488 1.5 371.312 1.5H380.271C381.096 1.5 381.765 2.17157 381.765 3V50C381.765 50.8284 381.096 51.5 380.271 51.5H369.771C368.838 51.5 367.958 51.0617 367.393 50.3152L343.937 19.3107V50C343.937 50.8284 343.268 51.5 342.443 51.5H333.484C332.659 51.5 331.991 50.8284 331.991 50V3Z"
        fill="#131514"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M155.294 3C155.294 2.17157 155.963 1.5 156.787 1.5H165.747C166.571 1.5 167.24 2.17157 167.24 3V39.5H188.145C188.969 39.5 189.638 40.1715 189.638 41V50C189.638 50.8284 188.969 51.5 188.145 51.5H158.281C156.631 51.5 155.294 50.1568 155.294 48.5V3Z"
        fill="#131514"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.955 41C127.927 41 134.389 34.5081 134.389 26.5C134.389 18.4919 127.927 12 119.955 12C111.983 12 105.52 18.4919 105.52 26.5C105.52 34.5081 111.983 41 119.955 41ZM119.955 53C134.524 53 146.335 41.1355 146.335 26.5C146.335 11.8644 134.524 0 119.955 0C105.385 0 93.5747 11.8644 93.5747 26.5C93.5747 41.1355 105.385 53 119.955 53Z"
        fill="#131514"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M419.455 12.1937C416.48 11.7002 413.425 12.1554 410.72 13.4954C408.015 14.8354 405.796 16.9928 404.374 19.6648C402.952 22.3367 402.4 25.3889 402.793 28.3926C403.187 31.3964 404.507 34.2008 406.569 36.4121C408.631 38.6233 411.33 40.1303 414.288 40.7214C417.246 41.3124 420.314 40.9579 423.061 39.7075C424.965 38.841 426.648 38.0745 428.004 36.5052V33.5H420.538C419.714 33.5 419.045 32.8284 419.045 32V25C419.045 24.1716 419.714 23.5 420.538 23.5H428.004H438.457C439.282 23.5 439.95 24.1716 439.95 25V33.5C439.95 37.5944 439.042 41.7765 436.236 44.7458C433.905 47.2118 431.11 49.2185 427.991 50.6379C422.971 52.923 417.364 53.571 411.958 52.4908C406.552 51.4106 401.618 48.6564 397.85 44.6152C394.082 40.5739 391.669 35.4486 390.95 29.9589C390.23 24.4693 391.241 18.8912 393.839 14.008C396.437 9.12481 400.493 5.18185 405.436 2.7329C410.38 0.283945 415.963 -0.547975 421.401 0.353976C426.347 1.1743 430.948 3.3914 434.672 6.73156C435.288 7.28327 435.285 8.23656 434.702 8.82235L428.367 15.1863C427.783 15.7721 426.842 15.7651 426.202 15.243C424.262 13.6604 421.938 12.6055 419.455 12.1937Z"
        fill="#131514"
      />
    </svg>
  );
};

export default Logo;
