import { useEffect, useState } from 'react';
import { Button as AriaButton } from 'react-aria-components';
import { useSearchParams } from 'react-router';
import { msg } from '@lingui/core/macro';
import { Trans, useLingui } from '@lingui/react/macro';

import ArticlePhoto from '@/components/ArticlePhoto';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Checkbox from '@/design_system/Checkbox';
import Dialog from '@/design_system/Dialog';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import IconStar from '@/icons/Star.svg';
import { BrandRequestMainContent } from '@/layouts/Brand/BrandRequestLayout/BrandRequestLayout';
import { useArticleName } from '@/models/article';
import {
  ClientArticleWithRelations,
  ClientRequestWithRelations,
  Feedback,
  FeedbackRating,
  useSubmitFeedback,
} from '@/models/request';
import ClientArticlesTable from '@/routes/Brand/Requests/Request/components/shared/ClientArticlesTable';
import ClientStepper from '@/routes/Brand/Requests/Request/components/shared/ClientStepper';
import { createBEMClasses } from '@/utils/classname';
import useViewPort from '@/utils/useViewport';

import './Completed.css';

const Completed = ({ request }: { request: ClientRequestWithRelations }) => {
  return (
    <BrandRequestMainContent>
      <Stack gap="1rem">
        <ClientStepper request={request} />
        <FeedbackForm request={request} />
        <ClientArticlesTable request={request} showPrice showArticleComment />
      </Stack>
    </BrandRequestMainContent>
  );
};

const FeedbackForm = ({ request }: { request: ClientRequestWithRelations }) => {
  const { isMobile } = useViewPort();
  const { t } = useLingui();

  const repairedArticles = request.articles.filter((article) => !!article.repairedAt);

  const {
    mutateAsync: submitFeedback,
    isPending: isPendingSubmitFeedback,
    reset: resetSubmitFeedback,
  } = useSubmitFeedback(request.id);

  const [searchParams] = useSearchParams();
  const globalFeedbackFromEmail = (parseInt(searchParams.get('feedback') ?? '0') || undefined) as
    | FeedbackRating
    | undefined;

  const hasGivenFeedback =
    !!request.feedback?.global &&
    request.feedback?.articlesFeedback?.length === repairedArticles.length &&
    !!request.feedback?.speed &&
    !!request.feedback?.communication;

  // Immediately save the global feedback from the star clicked on the email, except if feedback has already been submitted
  useEffect(() => {
    if (
      !hasGivenFeedback &&
      globalFeedbackFromEmail &&
      globalFeedbackFromEmail !== request.feedback?.global
    ) {
      submitFeedback({
        global: globalFeedbackFromEmail,
      }).then(() => {
        resetSubmitFeedback();
      });
    }
  }, [
    hasGivenFeedback,
    globalFeedbackFromEmail,
    request.feedback?.global,
    resetSubmitFeedback,
    submitFeedback,
  ]);

  const [isOpenFeedbackDialog, setIsOpenFeedbackDialog] = useState(false);
  const [feedback, setFeedback] = useState<Feedback>({
    ...request.feedback,
    global: request.feedback?.global ?? globalFeedbackFromEmail,
  } as Feedback);
  const [showErrors, setShowErrors] = useState(false);

  const sendResponse = async () => {
    setShowErrors(true);

    if (
      !feedback.global ||
      feedback.articlesFeedback?.length !== repairedArticles.length ||
      !feedback.speed ||
      !feedback.communication
    ) {
      return;
    }

    await submitFeedback(feedback);
    setIsOpenFeedbackDialog(false);
  };

  const handleArticleFeedback = (articleId: string, newQualityRating: FeedbackRating) => {
    const newArticleFeedback = { articleId, quality: newQualityRating };
    const currentArticleFeedback = feedback.articlesFeedback?.find(
      (articleFeedback) => articleId === articleFeedback.articleId
    );

    setFeedback({
      ...feedback,
      articlesFeedback:
        // If a feedback already exists for this article, find it and update it
        currentArticleFeedback
          ? feedback.articlesFeedback?.map((articleFeedback) =>
              articleFeedback.articleId === articleId ? newArticleFeedback : articleFeedback
            )
          : // Otherwise, simply add it to articlesFeedback
            [...(feedback.articlesFeedback || []), newArticleFeedback],
    });
  };

  const globalFeedbackLabel = t({
    id: 'client.request.completed.feedback.global',
    message: 'Global feedback',
  });

  const qualityFeedbackLabel = t({
    id: 'client.request.completed.feedback.quality',
    message: 'Quality of repair',
  });

  const qualityFeedbackError =
    showErrors && feedback.articlesFeedback?.length !== repairedArticles.length
      ? t({
          id: 'client.request.completed.feedback.quality.error',
          message: 'Please rate the quality',
        })
      : undefined;

  const commentLabel = hasGivenFeedback
    ? t({
        id: 'client.request.completed.feedback.comment.disabled',
        message: 'Comment',
      })
    : t({
        id: 'client.request.completed.feedback.comment',
        message: 'Add a comment',
      });

  const organizationName = request.organization.name;

  return (
    <>
      <p className="paragraph-50-regular paragraph-100-regular-mobile">
        <Trans id="client.request.completed.text">
          Your request has been completed. Thank you for using our service!
        </Trans>
        {!hasGivenFeedback && (
          <>
            {' '}
            <Trans id="client.request.completed.text.ask-feedback">
              We&apos;d love to hear your feedback, could you share your experience with us?
            </Trans>
          </>
        )}
      </p>
      <Box gap="1rem" padding="1rem">
        <Stack row gap="1rem" alignItems="center">
          {!isMobile && (
            <img
              alt=""
              src="/new-request-welcome-header.webp"
              style={{ width: '6rem', height: '6rem', borderRadius: '4px', objectFit: 'cover' }}
            />
          )}
          <div style={{ flex: 1 }}>
            <FeedbackInput
              label={globalFeedbackLabel}
              showLabel={false}
              value={feedback?.global}
              onChange={(global) => {
                setFeedback({ ...feedback, global });
                setIsOpenFeedbackDialog(true);
              }}
              size="large"
              disabled={hasGivenFeedback}
            />
          </div>
        </Stack>
        <Button variant="brand" size="medium" onPress={() => setIsOpenFeedbackDialog(true)}>
          {hasGivenFeedback ? (
            <Trans id="client.request.completed.dialog.trigger.view">View my feedback</Trans>
          ) : (
            <Trans id="client.request.completed.dialog.trigger">Share my feedback</Trans>
          )}
        </Button>
      </Box>
      <Dialog
        title={
          hasGivenFeedback ? (
            <Trans id="client.request.completed.dialog.title.view">My feedback</Trans>
          ) : (
            <Trans id="client.request.completed.dialog.title">Share your feedback</Trans>
          )
        }
        isOpen={isOpenFeedbackDialog}
        onOpenChange={setIsOpenFeedbackDialog}
        style={{ width: '43.75rem' }}
      >
        <main>
          <Stack gap="1.5rem">
            <Stack gap="1rem">
              {!hasGivenFeedback && (
                <p className="paragraph-50-regular paragraph-100-regular-mobile">
                  <Trans id="client.request.completed.text.ask-feedback">
                    We&apos;d love to hear your feedback, could you share your experience with us?
                  </Trans>
                </p>
              )}
              <FeedbackInput
                label={globalFeedbackLabel}
                showLabel={false}
                value={feedback.global}
                onChange={(global) => setFeedback({ ...feedback, global })}
                size="large"
                error={
                  showErrors && !feedback.global
                    ? t({
                        id: 'client.request.completed.feedback.global.error',
                        message: 'Please rate the global experience',
                      })
                    : undefined
                }
                disabled={hasGivenFeedback}
              />
            </Stack>
            <Stack gap="1rem">
              {repairedArticles.length === 1 ? (
                <FeedbackInput
                  label={qualityFeedbackLabel}
                  value={feedback.articlesFeedback?.[0]?.quality}
                  onChange={(quality) =>
                    setFeedback({
                      ...feedback,
                      articlesFeedback: [
                        {
                          articleId: repairedArticles[0].id,
                          quality,
                        },
                      ],
                    })
                  }
                  error={qualityFeedbackError}
                  disabled={hasGivenFeedback}
                />
              ) : (
                <Stack gap="0.25rem" ariaLabel={qualityFeedbackLabel}>
                  <p className="paragraph-50-medium paragraph-100-regular-mobile">
                    {qualityFeedbackLabel}
                  </p>

                  <Stack gap="0.5rem">
                    {repairedArticles.map((article) => (
                      <ArticleQualityFeedbackInput
                        key={article.id}
                        label={qualityFeedbackLabel}
                        article={article}
                        articleQualityRating={
                          feedback.articlesFeedback?.find(
                            (articleFeedback) => article.id === articleFeedback.articleId
                          )?.quality
                        }
                        setArticleFeedback={handleArticleFeedback}
                        error={qualityFeedbackError}
                        disabled={hasGivenFeedback}
                      />
                    ))}
                  </Stack>
                </Stack>
              )}
              <FeedbackInput
                label={t({
                  id: 'client.request.completed.feedback.speed',
                  message: 'Speed of repair',
                })}
                value={feedback.speed}
                onChange={(speed) => setFeedback({ ...feedback, speed })}
                error={
                  showErrors && !feedback.speed
                    ? t({
                        id: 'client.request.completed.feedback.speed.error',
                        message: 'Please rate the speed',
                      })
                    : undefined
                }
                disabled={hasGivenFeedback}
              />
              <FeedbackInput
                label={t({
                  id: 'client.request.completed.feedback.communication',
                  message: `Communication with ${organizationName}`,
                })}
                value={feedback.communication}
                onChange={(communication) => setFeedback({ ...feedback, communication })}
                error={
                  showErrors && !feedback.communication
                    ? t({
                        id: 'client.request.completed.feedback.communication.error',
                        message: 'Please rate the communication',
                      })
                    : undefined
                }
                disabled={hasGivenFeedback}
              />
            </Stack>
            <Stack gap="1rem">
              {(!hasGivenFeedback || !!feedback.comment) && (
                <Stack gap="0.25rem">
                  <p className="paragraph-50-medium paragraph-100-regular-mobile">{commentLabel}</p>
                  <TextArea
                    ariaLabel={commentLabel}
                    placeholder={t({
                      id: 'client.request.completed.feedback.comment.placeholder',
                      message: 'Tell us what you did or did not like...',
                    })}
                    value={feedback.comment}
                    onChange={(e) => setFeedback({ ...feedback, comment: e.target.value })}
                    rows={3}
                    size="large"
                    disabled={hasGivenFeedback}
                  />
                </Stack>
              )}
              {!hasGivenFeedback && (
                <Checkbox
                  isSelected={feedback.acceptContact}
                  onChange={(acceptContact) => setFeedback({ ...feedback, acceptContact })}
                  size="large"
                  variant="brand"
                >
                  <p className="paragraph-50-regular paragraph-100-regular-mobile">
                    <Trans id="client.request.completed.feedback.accept-contact">
                      I agree to be contacted to provide more details
                    </Trans>
                  </p>
                </Checkbox>
              )}
              {hasGivenFeedback && feedback.acceptContact && (
                <p className="paragraph-50-regular paragraph-100-regular-mobile">
                  <Trans id="client.request.completed.feedback.accepted-contact">
                    You agreed to be contacted to provide more details
                  </Trans>
                </p>
              )}
            </Stack>
          </Stack>
        </main>
        {!hasGivenFeedback && (
          <footer>
            <Button
              variant="brand"
              size="large"
              onPress={sendResponse}
              isLoading={isPendingSubmitFeedback}
            >
              <Trans id="client.request.completed.send">Send my feedback</Trans>
            </Button>
          </footer>
        )}
      </Dialog>
    </>
  );
};

const FeedbackInput = ({
  label,
  showLabel = true,
  error,
  value,
  onChange,
  size = 'small',
  disabled,
}: {
  label?: string;
  showLabel?: boolean;
  error?: string;
  value?: FeedbackRating;
  onChange: (value: FeedbackRating) => void;
  size?: 'small' | 'large';
  disabled?: boolean;
}) => {
  return (
    <Stack gap="0.25rem" ariaLabel={label}>
      {!!label && showLabel && (
        <p className="paragraph-50-medium paragraph-100-regular-mobile">{label}</p>
      )}
      <RatingButtons
        error={error}
        value={value}
        onChange={onChange}
        size={size}
        disabled={disabled}
      />
    </Stack>
  );
};

const ArticleQualityFeedbackInput = ({
  label,
  error,
  article,
  articleQualityRating,
  setArticleFeedback,
  disabled,
}: {
  label: string;
  error?: string;
  article: ClientArticleWithRelations;
  articleQualityRating?: FeedbackRating;
  setArticleFeedback: (articleId: string, newQualityRating: FeedbackRating) => void;
  disabled?: boolean;
}) => {
  const articleName = useArticleName({ article });

  return (
    <Stack gap="0.25rem">
      <div className="paragraph-100-regular paragraph-200-regular-mobile text-secondary">
        {articleName}
      </div>

      <Stack row gap="0.75rem">
        <ArticlePhoto photo={article.articlePhoto} showPlaceholder size="small" />

        <Stack gap="0.25rem" ariaLabel={label}>
          <RatingButtons
            error={error}
            value={articleQualityRating}
            onChange={(rating) => setArticleFeedback(article.id, rating)}
            disabled={disabled}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

const { block } = createBEMClasses('rating-buttons');

const RatingButtons = ({
  error,
  value,
  onChange,
  size = 'small',
  disabled,
}: {
  error?: string;
  value?: FeedbackRating;
  onChange: (value: FeedbackRating) => void;
  size?: 'small' | 'large';
  disabled?: boolean;
}) => {
  const { i18n } = useLingui();
  const [hoveredRating, setHoveredRating] = useState<FeedbackRating>();

  return (
    <>
      <div className={block({ size })}>
        {([1, 2, 3, 4, 5] as const).map((rating) => (
          <AriaButton
            key={rating}
            onPress={() => onChange(rating)}
            onHoverChange={(isHovering) => {
              if (isHovering) {
                setHoveredRating(rating);
              } else if (!isHovering && hoveredRating === rating) {
                setHoveredRating(undefined);
              }
            }}
            isDisabled={disabled}
          >
            <IconStar filled={rating <= (value ?? 0) || rating <= (hoveredRating ?? 0)} />
            {size === 'large' && (
              <span className="paragraph-100-medium paragraph-200-medium-mobile text-secondary">
                {i18n._(RATING_LABELS[rating])}
              </span>
            )}
          </AriaButton>
        ))}
      </div>
      {error && <Message type="error">{error}</Message>}
    </>
  );
};

const RATING_LABELS = {
  1: msg({ id: 'request.feedback.1', message: 'Very bad' }),
  2: msg({ id: 'request.feedback.2', message: 'Bad' }),
  3: msg({ id: 'request.feedback.3', message: 'Average' }),
  4: msg({ id: 'request.feedback.4', message: 'Good' }),
  5: msg({ id: 'request.feedback.5', message: 'Very good' }),
};

export default Completed;
