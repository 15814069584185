import { useMemo } from 'react';
import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/core/macro';
import { useLingui } from '@lingui/react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import type {
  Endpoints,
  ProductData,
  ProductDefaultColor,
  ProductL1,
  ProductL2,
  ProductL3,
} from '@/api';
import { useFetch } from '@/utils/fetch';

import { Model } from './model';
import { Organization } from './organization';

export class Product extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;

  externalId!: string;
  name!: string;
  data!: ProductData;
  productL1!: ProductL1;
  productL2!: ProductL2 | null;
  productL3!: ProductL3 | null;

  organizationId!: string;

  createdAt!: string;

  get createdAtDate() {
    return new Date(this.createdAt);
  }

  get externalIdNameCollection() {
    return (
      this.externalId +
      (this.name !== this.externalId ? ` - ${this.name}` : '') +
      (this.data.collection ? ` (${this.data.collection})` : '')
    );
  }
}

export const PRODUCT_MATERIALS = [
  {
    id: 'jeans',
    label: msg({ id: 'product.material.jeans', message: 'Jeans' }),
  },
  {
    id: 'leather',
    label: msg({ id: 'product.material.leather', message: 'Leather' }),
  },
  {
    id: 'silk',
    label: msg({ id: 'product.material.silk', message: 'Silk' }),
  },
  {
    id: 'technical_sport',
    label: msg({ id: 'product.material.technical-sport', message: 'Technical & Sport' }),
  },
  {
    id: 'wool',
    label: msg({ id: 'product.material.wool', message: 'Wool' }),
  },
  {
    id: 'synthetic',
    label: msg({ id: 'product.material.synthetic', message: 'Synthetic' }),
  },
  {
    id: 'cork',
    label: msg({ id: 'product.material.cork', message: 'Cork' }),
  },
  {
    id: 'rubber',
    label: msg({ id: 'product.material.rubber', message: 'Rubber' }),
  },
  {
    id: 'velvet',
    label: msg({ id: 'product.material.velvet', message: 'Velvet' }),
  },
  {
    id: 'full_grain_leather',
    label: msg({ id: 'product.material.full-grain-leather', message: 'Full grain leather' }),
  },
  {
    id: 'split_leather',
    label: msg({ id: 'product.material.split-leather', message: 'Split leather' }),
  },
  {
    id: 'metallic_leather',
    label: msg({ id: 'product.material.metallic-leather', message: 'Metallic leather' }),
  },
  {
    id: 'patent_leather',
    label: msg({ id: 'product.material.patent-leather', message: 'Patent leather' }),
  },
  {
    id: 'velvet_nubuck_leather',
    label: msg({
      id: 'product.material.velvet-nubuck-leather',
      message: 'Velvet & Nubuck leather',
    }),
  },
  {
    id: 'textile',
    label: msg({ id: 'product.material.textile', message: 'Textile' }),
  },
  {
    id: 'pigmented_undressed_leather',
    label: msg({
      id: 'product.material.pigmented-undressed-leather',
      message: 'Pigmented undressed leather',
    }),
  },
  {
    id: 'gold',
    label: msg({ id: 'product.material.gold', message: 'Gold (yellow, pink, white)' }),
  },
  {
    id: 'silver',
    label: msg({ id: 'product.material.silver', message: 'Silver' }),
  },
  {
    id: 'platinum',
    label: msg({ id: 'product.material.platinum', message: 'Platinum' }),
  },
  {
    id: 'stainless_steel',
    label: msg({ id: 'product.material.stainless-steel', message: 'Stainless steel' }),
  },
  {
    id: 'copper',
    label: msg({ id: 'product.material.copper', message: 'Copper' }),
  },
  {
    id: 'precious_stones',
    label: msg({
      id: 'product.material.precious-stones',
      message: 'Precious stones (diamond, sapphire, ruby)',
    }),
  },
  {
    id: 'pearls',
    label: msg({ id: 'product.material.pearls', message: 'Pearls' }),
  },
  {
    id: 'other',
    label: msg({ id: 'product.material.other', message: 'Other (Cotton, Jersey...)' }),
  },
  {
    id: 'all_materials',
    label: msg({ id: 'product.material.all-materials', message: 'All materials' }),
  },
  {
    id: 'chrome_free_leather',
    label: msg({
      id: 'product.material.chrome-free-leather',
      message: 'Chrome-free leather',
    }),
  },
  {
    id: 'ot_leather',
    label: msg({ id: 'product.material.ot-leather', message: 'OT leather' }),
  },
  {
    id: 'fured_leather',
    label: msg({ id: 'product.material.fured-leather', message: 'Fured leather' }),
  },
  {
    id: 'suede',
    label: msg({ id: 'product.material.suede', message: 'Suede' }),
  },
  {
    id: 'nubuck',
    label: msg({ id: 'product.material.nubuck', message: 'Nubuck' }),
  },
  {
    id: 'cwl',
    label: msg({ id: 'product.material.cwl', message: 'CWL' }),
  },
  {
    id: 'alveomesh',
    label: msg({ id: 'product.material.alveomesh', message: 'Alveomesh' }),
  },
  {
    id: 'hexamesh',
    label: msg({ id: 'product.material.hexamesh', message: 'Hexamesh' }),
  },
  {
    id: 'b_mesh',
    label: msg({ id: 'product.material.b-mesh', message: 'B-mesh' }),
  },
  {
    id: 'engineered_mesh',
    label: msg({ id: 'product.material.engineered-mesh', message: 'Engineered mesh' }),
  },
  {
    id: 'j_mesh_jute',
    label: msg({ id: 'product.material.j-mesh-jute', message: 'J-mesh jute' }),
  },
  {
    id: 'aircell',
    label: msg({ id: 'product.material.aircell', message: 'Aircell' }),
  },
  {
    id: 'ripstop',
    label: msg({ id: 'product.material.ripstop', message: 'Ripstop' }),
  },
  {
    id: 'trek_shell',
    label: msg({ id: 'product.material.trek-shell', message: 'Trek shell' }),
  },
  {
    id: 'flannel',
    label: msg({ id: 'product.material.flannel', message: 'Flannel' }),
  },
  {
    id: 'canvas',
    label: msg({ id: 'product.material.canvas', message: 'Canvas' }),
  },
] satisfies { id: ProductL3; label: MessageDescriptor }[];

const getSubcategoriesByIds = (materials: ProductL3[]) => {
  return PRODUCT_MATERIALS.filter((material) => materials.includes(material.id));
};

export const PRODUCT_CATEGORIES = [
  {
    id: 'clothes',
    label: msg({ id: 'product.category.clothes', message: 'Clothes' }),
    color: '#F6F9FE',
    categories: [
      {
        id: 'ski_suit',
        label: msg({ id: 'product.category.clothes.ski-suit', message: 'Ski suits' }),
        labelOne: msg({ id: 'product.category.clothes.ski-suit.one', message: 'Ski suit' }),
      },
      {
        id: 'ski_jacket',
        label: msg({ id: 'product.category.clothes.ski-jacket', message: 'Ski jackets' }),
        labelOne: msg({ id: 'product.category.clothes.ski-jacket.one', message: 'Ski jacket' }),
      },
      {
        id: 'fuseau_ski_pants',
        label: msg({
          id: 'product.category.clothes.fuseau-ski-pants',
          message: 'Fuseau & Ski pants',
        }),
        labelOne: msg({
          id: 'product.category.clothes.fuseau-ski-pants.one',
          message: 'Fuseau/Ski pants',
        }),
      },
      {
        id: 'jacket_coat',
        label: msg({ id: 'product.category.clothes.jacket-coat', message: 'Jackets & Coats' }),
        labelOne: msg({ id: 'product.category.clothes.jacket-coat.one', message: 'Jacket/Coat' }),
      },
      {
        id: 'jacket_waterproof',
        label: msg({
          id: 'product.category.clothes.jacket-waterproof',
          message: 'Waterproof jackets',
        }),
        labelOne: msg({
          id: 'product.category.clothes.jacket-waterproof.one',
          message: 'Waterproof jacket',
        }),
      },
      {
        id: 'jacket_insulated',
        label: msg({
          id: 'product.category.clothes.jacket-insulated',
          message: 'Insulated jackets',
        }),
        labelOne: msg({
          id: 'product.category.clothes.jacket-insulated.one',
          message: 'Insulated jacket',
        }),
      },
      {
        id: 'jacket_softshell',
        label: msg({
          id: 'product.category.clothes.jacket-softshell',
          message: 'Softshell jackets',
        }),
        labelOne: msg({
          id: 'product.category.clothes.jacket-softshell.one',
          message: 'Softshell jacket',
        }),
      },
      {
        id: 'pants_leggings',
        label: msg({ id: 'product.category.clothes.pants-leggings', message: 'Pants & Leggings' }),
        labelOne: msg({
          id: 'product.category.clothes.pants-leggings.one',
          message: 'Pants/Leggings',
        }),
      },
      {
        id: 'pants_waterproof',
        label: msg({
          id: 'product.category.clothes.pants-waterproof',
          message: 'Waterproof pants',
        }),
        labelOne: msg({
          id: 'product.category.clothes.pants-waterproof.one',
          message: 'Waterproof pants',
        }),
      },
      {
        id: 'pants_insulated',
        label: msg({
          id: 'product.category.clothes.pants-insulated',
          message: 'Insulated pants',
        }),
        labelOne: msg({
          id: 'product.category.clothes.pants-insulated.one',
          message: 'Insulated pants',
        }),
      },
      {
        id: 'pants_softshell',
        label: msg({ id: 'product.category.clothes.pants-softshell', message: 'Softshell pants' }),
        labelOne: msg({
          id: 'product.category.clothes.pants-softshell.one',
          message: 'Softshell pants',
        }),
      },
      {
        id: 'triclimate',
        label: msg({ id: 'product.category.clothes.triclimate', message: 'Triclimate' }),
        labelOne: msg({ id: 'product.category.clothes.triclimate.one', message: 'Triclimate' }),
      },
      {
        id: 'sweatshirt_sweater',
        label: msg({
          id: 'product.category.clothes.sweatshirt-sweater',
          message: 'Sweatshirts & Sweaters',
        }),
        labelOne: msg({
          id: 'product.category.clothes.sweatshirt-sweater.one',
          message: 'Sweatshirt/Sweater',
        }),
      },
      {
        id: 't_shirt_polo',
        label: msg({
          id: 'product.category.clothes.t-shirt-polo',
          message: 'T-shirts, Shirts & Polos',
        }),
        labelOne: msg({
          id: 'product.category.clothes.t-shirt-polo.one',
          message: 'T-shirt/Shirt/Polo',
        }),
      },
      {
        id: 'dress_suit',
        label: msg({ id: 'product.category.clothes.dress-suit', message: 'Dresses & Suits' }),
        labelOne: msg({ id: 'product.category.clothes.dress-suit.one', message: 'Dress/Suit' }),
      },
      {
        id: 'skirt_shorts',
        label: msg({ id: 'product.category.clothes.skirt-shorts', message: 'Skirts & Shorts' }),
        labelOne: msg({ id: 'product.category.clothes.skirt-shorts.one', message: 'Skirt/Shorts' }),
      },
      {
        id: 'lingerie_underwear',
        label: msg({
          id: 'product.category.clothes.lingerie-underwear',
          message: 'Lingerie & Underwear',
        }),
        labelOne: msg({
          id: 'product.category.clothes.lingerie-underwear.one',
          message: 'Lingerie/Underwear',
        }),
      },
    ],
    subCategories: getSubcategoriesByIds([
      'jeans',
      'leather',
      'silk',
      'technical_sport',
      'wool',
      'velvet',
      'other',
    ]),
  },
  {
    id: 'shoes',
    label: msg({ id: 'product.category.shoes', message: 'Shoes' }),
    color: '#F2FDF8',
    categories: [
      {
        id: 'city',
        label: msg({ id: 'product.category.shoes.city', message: 'City shoes' }),
        labelOne: msg({ id: 'product.category.shoes.city.one', message: 'City shoes' }),
      },
      {
        id: 'sneakers',
        label: msg({ id: 'product.category.shoes.sneakers', message: 'Sneakers' }),
        labelOne: msg({ id: 'product.category.shoes.sneakers.one', message: 'Sneakers' }),
      },
      {
        id: 'moccasins',
        label: msg({ id: 'product.category.shoes.moccasins', message: 'Moccasins' }),
        labelOne: msg({ id: 'product.category.shoes.moccasins.one', message: 'Moccasins' }),
      },
      {
        id: 'boots',
        label: msg({ id: 'product.category.shoes.boots', message: 'Boots' }),
        labelOne: msg({ id: 'product.category.shoes.boots.one', message: 'Boots' }),
      },
      {
        id: 'ankle_boots',
        label: msg({ id: 'product.category.shoes.ankle_boots', message: 'Ankle boots' }),
        labelOne: msg({ id: 'product.category.shoes.ankle_boots.one', message: 'Ankle boots' }),
      },
      {
        id: 'cowboy_boots',
        label: msg({ id: 'product.category.shoes.cowboy_boots', message: 'Cowboy boots' }),
        labelOne: msg({ id: 'product.category.shoes.cowboy_boots.one', message: 'Cowboy boots' }),
      },
      {
        id: 'ballerinas',
        label: msg({ id: 'product.category.shoes.ballerinas', message: 'Ballerinas' }),
        labelOne: msg({ id: 'product.category.shoes.ballerinas.one', message: 'Ballerinas' }),
      },
      {
        id: 'high_heels',
        label: msg({ id: 'product.category.shoes.high-heels', message: 'High heels' }),
        labelOne: msg({ id: 'product.category.shoes.high-heels.one', message: 'High heels' }),
      },
      {
        id: 'outdoor_hiking',
        label: msg({
          id: 'product.category.shoes.outdoor-hiking',
          message: 'Outdoor & Hiking shoes',
        }),
        labelOne: msg({
          id: 'product.category.shoes.outdoor-hiking.one',
          message: 'Outdoor/Hiking shoes',
        }),
      },
      {
        id: 'climbing',
        label: msg({ id: 'product.category.shoes.climbing', message: 'Climbing shoes' }),
        labelOne: msg({ id: 'product.category.shoes.climbing.one', message: 'Climbing shoes' }),
      },
      {
        id: 'espadrilles_sandals',
        label: msg({
          id: 'product.category.shoes.espadrilles-sandals',
          message: 'Espadrilles & Sandals',
        }),
        labelOne: msg({
          id: 'product.category.shoes.espadrilles-sandals.one',
          message: 'Espadrilles/Sandals',
        }),
      },
      {
        id: 'boat',
        label: msg({ id: 'product.category.shoes.boat', message: 'Boat shoes' }),
        labelOne: msg({ id: 'product.category.shoes.boat.one', message: 'Boat shoes' }),
      },
    ],
    subCategories: getSubcategoriesByIds([
      'jeans',
      'silk',
      'wool',
      'synthetic',
      'cork',
      'rubber',
      'full_grain_leather',
      'split_leather',
      'metallic_leather',
      'patent_leather',
      'velvet_nubuck_leather',
      'leather',
      'chrome_free_leather',
      'ot_leather',
      'fured_leather',
      'suede',
      'nubuck',
      'cwl',
      'alveomesh',
      'hexamesh',
      'b_mesh',
      'engineered_mesh',
      'j_mesh_jute',
      'aircell',
      'ripstop',
      'trek_shell',
      'textile',
      'pigmented_undressed_leather',
      'flannel',
      'canvas',
      'other',
    ]),
  },
  {
    id: 'accessories',
    label: msg({ id: 'product.category.accessories', message: 'Accessories' }),
    color: '#FFF8EB',
    categories: [
      {
        id: 'cap_hat',
        label: msg({ id: 'product.category.accessories.cap-hat', message: 'Caps, Beanies & Hats' }),
        labelOne: msg({
          id: 'product.category.accessories.cap-hat.one',
          message: 'Cap/Beanie/Hat',
        }),
      },
      {
        id: 'scarf',
        label: msg({ id: 'product.category.accessories.scarf', message: 'Scarves & Neckerchiefs' }),
        labelOne: msg({
          id: 'product.category.accessories.scarf.one',
          message: 'Scarf/Neckerchief',
        }),
      },
      {
        id: 'neckwarmer_earmuff',
        label: msg({
          id: 'product.category.accessories.neckwarmer-earmuff',
          message: 'Neckwarmers & Earmuffs',
        }),
        labelOne: msg({
          id: 'product.category.accessories.neckwarmer-earmuff.one',
          message: 'Neckwarmer/Earmuff',
        }),
      },
      {
        id: 'belt',
        label: msg({ id: 'product.category.accessories.belt', message: 'Belts' }),
        labelOne: msg({ id: 'product.category.accessories.belt.one', message: 'Belt' }),
      },
      {
        id: 'socks',
        label: msg({ id: 'product.category.accessories.socks', message: 'Socks & Gaiters' }),
        labelOne: msg({ id: 'product.category.accessories.socks.one', message: 'Socks/Gaiters' }),
      },
      {
        id: 'gloves_mittens',
        label: msg({
          id: 'product.category.accessories.gloves-mittens',
          message: 'Gloves & Mittens',
        }),
        labelOne: msg({
          id: 'product.category.accessories.gloves-mittens.one',
          message: 'Gloves/Mittens',
        }),
      },
      {
        id: 'glasses',
        label: msg({ id: 'product.category.accessories.glasses', message: 'Glasses & Sunglasses' }),
        labelOne: msg({
          id: 'product.category.accessories.glasses.one',
          message: 'Glasses/Sunglasses',
        }),
      },
      {
        id: 'ski_helmet_mask',
        label: msg({
          id: 'product.category.accessories.ski-helmet-mask',
          message: 'Ski helmets & Goggles',
        }),
        labelOne: msg({
          id: 'product.category.accessories.ski-helmet-mask.one',
          message: 'Ski helmet/Goggles',
        }),
      },
      {
        id: 'cape_poncho',
        label: msg({ id: 'product.category.accessories.cape-poncho', message: 'Capes & Ponchos' }),
        labelOne: msg({
          id: 'product.category.accessories.cape-poncho.one',
          message: 'Cape/Poncho',
        }),
      },
      {
        id: 'wallet',
        label: msg({
          id: 'product.category.accessories.wallet',
          message: 'Wallets & Card Holders',
        }),
        labelOne: msg({
          id: 'product.category.accessories.wallet.one',
          message: 'Wallet/Card Holder',
        }),
      },
      {
        id: 'briefcase',
        label: msg({
          id: 'product.category.accessories.briefcase',
          message: 'Pouches and briefcases',
        }),
        labelOne: msg({
          id: 'product.category.accessories.briefcase.one',
          message: 'Pouch/Briefcase',
        }),
      },
      {
        id: 'camp_equipment',
        label: msg({
          id: 'product.category.accessories.camp-equipment',
          message: 'Camp Equipment',
        }),
        labelOne: msg({
          id: 'product.category.accessories.camp-equipment.one',
          message: 'Camp Equipment',
        }),
      },
      {
        id: 'accessories',
        label: msg({ id: 'product.category.accessories.other', message: 'Other' }),
        labelOne: msg({ id: 'product.category.accessories.other.one', message: 'Other' }),
      },
    ],
    subCategories: getSubcategoriesByIds(['all_materials']),
  },
  {
    id: 'bags_luggages',
    label: msg({ id: 'product.category.bags-luggages', message: 'Bags & Luggages' }),
    color: '#F7F8F7',
    categories: [
      {
        id: 'bags_luggages',
        label: msg({
          id: 'product.category.bags-luggages.bags-luggages',
          message: 'Bags & Luggages',
        }),
        labelOne: msg({
          id: 'product.category.bags-luggages.bags-luggages.one',
          message: 'Bag/Luggage',
        }),
      },
    ],
    subCategories: getSubcategoriesByIds(['all_materials']),
  },
] satisfies {
  id: ProductL1;
  label: MessageDescriptor;
  color?: string;
  categories: { id: ProductL2; label: MessageDescriptor; labelOne: MessageDescriptor }[];
  subCategories: { id: ProductL3; label: MessageDescriptor }[];
}[];

export const PRODUCT_CATEGORIES_L2 = PRODUCT_CATEGORIES.flatMap((l1) => [...l1.categories]);

export const DEFAULT_COLORS = [
  {
    id: 'white',
    label: msg({ id: 'product.color.white', message: 'White' }),
  },
  {
    id: 'black',
    label: msg({ id: 'product.color.black', message: 'Black' }),
  },
  {
    id: 'yellow',
    label: msg({ id: 'product.color.yellow', message: 'Yellow' }),
  },
  {
    id: 'green',
    label: msg({ id: 'product.color.green', message: 'Green' }),
  },
  {
    id: 'blue',
    label: msg({ id: 'product.color.blue', message: 'Blue' }),
  },
  {
    id: 'red',
    label: msg({ id: 'product.color.red', message: 'Red' }),
  },
  {
    id: 'orange',
    label: msg({ id: 'product.color.orange', message: 'Orange' }),
  },
  {
    id: 'brown',
    label: msg({ id: 'product.color.brown', message: 'Brown' }),
  },
  {
    id: 'purple',
    label: msg({ id: 'product.color.purple', message: 'Purple' }),
  },
  {
    id: 'pink',
    label: msg({ id: 'product.color.pink', message: 'Pink' }),
  },
  {
    id: 'grey',
    label: msg({ id: 'product.color.grey', message: 'Grey' }),
  },
  {
    id: 'beige',
    label: msg({ id: 'product.color.beige', message: 'Beige' }),
  },
  {
    id: 'kaki',
    label: msg({ id: 'product.color.kaki', message: 'Kaki' }),
  },
  {
    id: 'golden',
    label: msg({ id: 'product.color.golden', message: 'Golden' }),
  },
  {
    id: 'silver',
    label: msg({ id: 'product.color.silver', message: 'Silver' }),
  },
  {
    id: 'bronze',
    label: msg({ id: 'product.color.bronze', message: 'Bronze' }),
  },
] satisfies { id: ProductDefaultColor; label: MessageDescriptor }[];

const DEFAULT_SIZES = {
  clothes: [
    'XS',
    'S',
    'M',
    'L',
    'XL',
    'XXL',
    'OS',
    '28',
    '30',
    '32',
    '34',
    '36',
    '38',
    '40',
    '42',
    '44',
    '46',
    '48',
    '50',
    '52',
    '54',
    '56',
    '58',
    '60',
    '62',
    '64',
    '0-6M',
    '6-24M',
    '0-3M',
    '3-6M',
    '6-12M',
    '12-18M',
    '18-24M',
  ],
  shoes: ['< 35', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '> 46'],
  accessories: ['OS', 'S', 'M', 'L', 'XL'],
  bags_luggages: ['OS', 'S', 'M', 'L', 'XL'],
};

export const useProductOptions = (organization: Organization, productL1?: ProductL1 | null) => {
  const { _ } = useLingui();

  const { enabledProductL2 } = organization;

  const selectedProductCategory = PRODUCT_CATEGORIES.find((category) => category.id === productL1);

  const productL1Options = useMemo(
    () =>
      PRODUCT_CATEGORIES.filter((level1) =>
        level1.categories.some((level2) => enabledProductL2.includes(level2.id))
      ).map((category) => ({
        id: category.id,
        text: _(category.label),
        color: category.color,
      })),
    [_, enabledProductL2]
  );

  const productL2Options = useMemo(
    () =>
      selectedProductCategory?.categories
        .filter((level2) => enabledProductL2.includes(level2.id))
        .map((category) => ({
          type: 'basic',
          id: category.id,
          text: _(category.label),
        })) ?? [],
    [_, selectedProductCategory?.categories, enabledProductL2]
  );

  const productL3Options = useMemo(
    () =>
      selectedProductCategory?.subCategories.map((material) => ({
        type: 'basic',
        id: material.id,
        text: _(material.label),
      })) ?? [],
    [_, selectedProductCategory?.subCategories]
  );

  return { productL1Options, productL2Options, productL3Options };
};

export const hasColorOptions = (product?: Product | null) => {
  return product?.data.colors ? !!product.data.colors.length : true;
};

export const useColorOptions = (product?: Product | null) => {
  const { _ } = useLingui();

  return useMemo(() => {
    if (product?.data.colors) {
      return product.data.colors.map((color) => ({
        id: color,
        text: color,
      }));
    } else {
      return DEFAULT_COLORS.map((color) => ({
        id: color.id,
        text: _(color.label),
      }));
    }
  }, [_, product]);
};

export const hasSizeOptions = (product?: Product | null, productL1?: ProductL1 | null) => {
  if (product?.data.sizes) {
    return !!product.data.sizes.length;
  } else if (productL1) {
    return !!DEFAULT_SIZES[productL1].length;
  } else {
    return false;
  }
};

export const useSizeOptions = (product?: Product | null, productL1?: ProductL1 | null) => {
  return useMemo(() => {
    if (product?.data.sizes) {
      return product.data.sizes
        .map((size) => ({
          id: size,
          text: size,
        }))
        .sort((a, b) => a.text.localeCompare(b.text));
    } else {
      return productL1
        ? DEFAULT_SIZES[productL1].map((size) => ({
            id: size,
            text: size,
          }))
        : [];
    }
  }, [product, productL1]);
};

export const useColorName = (color?: string) => {
  const { _ } = useLingui();

  const colorLabel = DEFAULT_COLORS.find(({ id }) => id === color)?.label;

  return colorLabel ? _(colorLabel) : color;
};

export const useProducts = (
  params: {
    query?: string;
    limit?: number;
    offset?: number;
  },
  options?: {
    enabled?: boolean;
    keepPreviousData?: boolean;
  }
) => {
  const fetch = useFetch<Endpoints['GET /products']>();

  return useQuery({
    queryKey: ['products', params],
    queryFn: () =>
      fetch('/products', params).then(({ products, meta }) => ({
        products: products.map((product) => new Product(product)),
        meta,
      })),
    enabled: options?.enabled,
    placeholderData: options?.keepPreviousData ? keepPreviousData : undefined,
  });
};
