import { Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import { BaseActivity, BaseActivityText } from '@/components/activities/BaseActivity/BaseActivity';
import IconEmail from '@/icons/Email.svg';

export const PendingPickupNotifiedActivity = ({
  activity,
}: {
  activity: ActivityOfType<'pending_pickup_notified'>;
}) => {
  const creatorName = activity.creator?.name;

  return (
    <BaseActivity
      createdAt={activity.createdAt}
      icon={<IconEmail />}
      creatorName={activity.creator ? activity.creator.name : ''}
      message={
        <BaseActivityText>
          {creatorName ? (
            <Trans id="activities.pending_pickup_notified.message">
              <strong>{creatorName}</strong> sent an email reminder about the pickup in store to the
              client
            </Trans>
          ) : (
            <Trans id="activities.pending_pickup_notified.message-no-creator">
              The client has been notified by email about the pickup in store
            </Trans>
          )}
        </BaseActivityText>
      }
    />
  );
};
