import { Plural, Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticlesNames,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconValidation from '@/icons/Validation.svg';

export const JobAutoAcceptedActivity = ({
  activities,
}: {
  activities: ActivityOfType<'job_auto_accepted'>[];
}) => {
  const firstActivity = activities[0];

  const workshopName = firstActivity.workshop.name;

  return (
    <BaseActivity
      createdAt={firstActivity.createdAt}
      type="primary"
      icon={<IconValidation />}
      message={
        <BaseActivityText>
          <Trans id="activities.job_auto_accepted.message">
            <strong>{workshopName}</strong> auto-accepted the job{' '}
            <Plural value={activities.length} other="with items:" one="" />
            {activities.length > 1 && (
              <BaseActivityArticlesNames activities={activities} displayArticleName />
            )}
          </Trans>
        </BaseActivityText>
      }
    />
  );
};
