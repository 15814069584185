import { Trans } from '@lingui/react/macro';

import { PaymentStepConfig } from '@/api';
import AlertBar from '@/design_system/AlertBar';
import Stack from '@/design_system/Stack';
import IconInfo from '@/icons/Info.svg';
import IconPayment from '@/icons/Payment.svg';
import IconUserWaiting from '@/icons/UserWaiting.svg';
import { ArticleWithRelations, RequestWithRelations } from '@/models/request';
import { PendingClientActionReminder } from '@/routes/Requests/Request/components/PendingClientActionReminder/PendingClientActionReminder';
import { useCurrentSession } from '@/services/auth';

export const PaymentStepMessage = ({
  request,
  article: inputArticle,
}: {
  request: RequestWithRelations;
  article?: ArticleWithRelations;
}) => {
  const { currentSession } = useCurrentSession();

  const article =
    inputArticle ?? request.articles.find((article) => article.step?.step === 'payment');

  const stepConfig = article?.step?.config as PaymentStepConfig['config'];

  if (stepConfig?.externalPayment) {
    if (
      !currentSession?.hasPermission('validate_external_payment', {
        organizationId: request.organizationId,
        storeId: request.storeId,
      })
    ) {
      return (
        <AlertBar
          type="info"
          size="large"
          icon={<IconInfo />}
          title={
            <Trans id="request.payment.external-payment-message.unauthorized.title">
              Waiting for payment information
            </Trans>
          }
        >
          <Trans id="request.payment.external-payment-message.unauthorized.text">
            Someone will provide the required payment details to validate the step
          </Trans>
        </AlertBar>
      );
    }

    return (
      <AlertBar
        type="warning"
        size="large"
        icon={<IconPayment />}
        title={
          <Trans id="request.payment.external-payment-message.title">
            Waiting for payment information
          </Trans>
        }
      >
        {!inputArticle ? (
          <Trans id="request.payment.external-payment-message.text">
            Please provide the required payment details to validate the step
          </Trans>
        ) : (
          <Trans id="request.payment.external-payment-message-article.text">
            Please provide the required payment details on the request page to validate the step
          </Trans>
        )}
      </AlertBar>
    );
  }

  const isFree =
    request.price?.amountPerCurrency.reduce((acc, amount) => acc + amount.amount, 0) === 0;

  const canSendPaymentReminder = currentSession?.hasPermission('write_client_communication', [
    {
      organizationId: request.organizationId,
      storeId: null,
    },
    {
      organizationId: request.organizationId,
      storeId: request.storeId,
    },
  ]);

  let messageContent;
  if (isFree) {
    messageContent = (
      <Trans id="request.payment.payment-message.text.free">
        The repair will start once the customer validates the request.
      </Trans>
    );
  } else if (canSendPaymentReminder) {
    messageContent = (
      <Trans id="request.payment.payment-message.text.reminder">
        The final quote has been sent to the client for their payment. If they don&apos;t respond,
        feel free to send them an email reminder.
      </Trans>
    );
  } else {
    messageContent = (
      <Trans id="request.payment.payment-message.text">
        The repair will start once the payment is done.
      </Trans>
    );
  }

  return (
    <AlertBar
      type="info"
      size="large"
      icon={<IconUserWaiting />}
      title={
        <>
          {!isFree && (
            <Trans id="request.payment.payment-message.title">Waiting for customer payment</Trans>
          )}
          {isFree && (
            <Trans id="request.payment.payment-message.title.free">
              Waiting for customer validation
            </Trans>
          )}
        </>
      }
    >
      <Stack gap="0.5rem">
        {messageContent}
        {canSendPaymentReminder && <PendingClientActionReminder id={request.id} type="payment" />}
      </Stack>
    </AlertBar>
  );
};
