import { Trans } from '@lingui/react/macro';

import Stack from '@/design_system/Stack';
import IconPayment from '@/icons/Payment.svg';
import { BrandRequestMainContent } from '@/layouts/Brand/BrandRequestLayout/BrandRequestLayout';
import { ClientRequestWithRelations } from '@/models/request';
import ClientArticlesTable from '@/routes/Brand/Requests/Request/components/shared/ClientArticlesTable';
import ClientStepper from '@/routes/Brand/Requests/Request/components/shared/ClientStepper';

const Payment = ({ request }: { request: ClientRequestWithRelations }) => {
  return (
    <BrandRequestMainContent>
      <Stack gap="1.5rem">
        <ClientStepper request={request} />
        <Stack row gap="0.5rem">
          <IconPayment className="color-tertiary-700" style={{ fontSize: '24px' }} />
          <p className="paragraph-50-regular paragraph-100-regular-mobile">
            <Trans id="store.request.payment.text">
              The client has been notified to proceed to the payment.
            </Trans>
          </p>
        </Stack>
        <ClientArticlesTable request={request} showPrice showArticleComment />
      </Stack>
    </BrandRequestMainContent>
  );
};

export default Payment;
