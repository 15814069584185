import type { DefaultError, QueryKey } from '@tanstack/query-core';
import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { type Endpoints } from '@/api';
import { useFetch } from '@/utils/fetch';

export type TNotification = Endpoints['GET /notifications']['response']['notifications'][number];
export type TNotificationQuery = Endpoints['GET /notifications']['query'];
export type TNotificationQueryFilter = Endpoints['GET /notifications']['query']['filter'];
export type TNotificationOfType<T extends TNotification['type']> = Extract<
  TNotification,
  { type: T }
>;

export const useNotifications = (params: TNotificationQuery) => {
  const fetch = useFetch<Endpoints['GET /notifications']>();

  return useQuery({
    queryKey: ['notifications', params],
    queryFn: () => fetch(`/notifications`, params),
    refetchInterval: () => (document.visibilityState === 'hidden' ? 5 * 60 * 1000 : 30_000), // Refetch notifications every five minutes if the focus is outside, every 30 seconds otherwise
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  });
};

export const useInfiniteNotifications = (params: TNotificationQuery) => {
  const fetch = useFetch<Endpoints['GET /notifications']>();

  return useInfiniteQuery<
    Endpoints['GET /notifications']['response'],
    DefaultError,
    InfiniteData<Endpoints['GET /notifications']['response']>,
    QueryKey,
    string | null
  >({
    queryFn: ({ pageParam }) => {
      const notificationParams = { ...params, ...(pageParam ? { before: pageParam } : undefined) };

      return fetch(`/notifications`, notificationParams);
    },
    queryKey: ['notifications', params],
    initialPageParam: null,
    getNextPageParam: (lastPage) => lastPage.meta.next,
    refetchInterval: () => (document.visibilityState === 'hidden' ? 5 * 60 * 1000 : 30_000), // Refetch notifications every five minutes if the focus is outside, every 30 seconds otherwise
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  });
};

export const useMarkNotificationAsRead = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /notifications/:id/mark-as-read']>();

  return useMutation({
    mutationFn: ({ notificationId }: { notificationId: string }) =>
      fetch(`/notifications/${notificationId}/mark-as-read`, undefined, { method: 'POST' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });
};

export const useMarkNotificationAsUnread = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /notifications/:id/mark-as-unread']>();

  return useMutation({
    mutationFn: ({ notificationId }: { notificationId: string }) =>
      fetch(`/notifications/${notificationId}/mark-as-unread`, undefined, { method: 'POST' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });
};

type MarkAllNotificationsAsReadQuery = Endpoints['POST /notifications/mark-all-as-read']['query'];

export const useMarkAllNotificationsAsRead = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /notifications/mark-all-as-read']>();

  return useMutation({
    mutationFn: (params?: MarkAllNotificationsAsReadQuery) =>
      fetch(`/notifications/mark-all-as-read`, params, { method: 'POST' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });
};

export const useArchiveNotification = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /notifications/:id/archive']>();

  return useMutation({
    mutationFn: ({ notificationId }: { notificationId: string }) =>
      fetch(`/notifications/${notificationId}/archive`, undefined, { method: 'POST' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });
};

export const useUnarchiveNotification = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /notifications/:id/unarchive']>();

  return useMutation({
    mutationFn: ({ notificationId }: { notificationId: string }) =>
      fetch(`/notifications/${notificationId}/unarchive`, undefined, { method: 'POST' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });
};

export const useArchiveAllNotifications = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /notifications/archive-all']>();

  return useMutation({
    mutationFn: () => fetch(`/notifications/archive-all`, undefined, { method: 'POST' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });
};

export const useArchiveAllReadNotifications = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /notifications/archive-read']>();

  return useMutation({
    mutationFn: () => fetch(`/notifications/archive-read`, undefined, { method: 'POST' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });
};

export const useUnarchiveAllNotifications = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /notifications/unarchive-all']>();

  return useMutation({
    mutationFn: () => fetch(`/notifications/unarchive-all`, undefined, { method: 'POST' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });
};
