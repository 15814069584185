import { useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import { Label } from '@/design_system/Label/Label';
import { RadioBox, RadioBoxGroup } from '@/design_system/Radio/RadioBox/RadioBox';
import Stack from '@/design_system/Stack';
import { useShowToast } from '@/design_system/Toast';
import { useRepair } from '@/models/article';
import { InvoicePaymentStrategy } from '@/models/invoice';
import { useWorkshop } from '@/models/workshop';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';

import { ArticleActionProps } from './ArticleActions';

export const RepairArticleAction = ({ article, onActionDone }: ArticleActionProps) => {
  const { t } = useLingui();
  const showToast = useShowToast();

  const hasNonDigitalizedWorkshopAndNoPaymentStrategy =
    !!article.workshop?.nonDigitalized && !article.workshopPaymentStrategy;

  const { data: workshop } = useWorkshop(article.workshopId, {
    enabled: hasNonDigitalizedWorkshopAndNoPaymentStrategy,
  });

  const shouldOpenPaymentStrategyModal =
    hasNonDigitalizedWorkshopAndNoPaymentStrategy &&
    workshop?.organizations.find(({ organizationId }) => organizationId === article.organizationId)
      ?.payoutStrategy === 'handled-by-organization';

  const {
    mutateAsync: repair,
    isPending: isPendingRepair,
    isSuccess: isSuccessRepair,
  } = useRepair({
    articleId: article.id,
  });

  const [isPaymentStrategyModalOpen, setIsPaymentStrategyModalOpen] = useState(false);

  const confirmRepair = async ({
    paymentStrategy,
  }: {
    paymentStrategy?: InvoicePaymentStrategy;
  }) => {
    try {
      await repair({
        paymentStrategy,
      });
      onActionDone();
    } catch {
      showToast({
        type: 'error',
        text: t({
          id: 'article.actions.repair_article.error',
          message:
            'An error occurred while marking the article as repaired. Please try again later.',
        }),
      });
    } finally {
      setIsPaymentStrategyModalOpen(false);
    }
  };

  return (
    <>
      <Button
        variant="primary"
        size="medium"
        onPress={() => {
          if (shouldOpenPaymentStrategyModal) {
            setIsPaymentStrategyModalOpen(true);
          } else {
            confirmRepair({});
          }
        }}
        isLoading={(isPendingRepair || isSuccessRepair) && !isPaymentStrategyModalOpen}
      >
        <Trans id="article.actions.repair_article.validate">Mark as repaired</Trans>
      </Button>
      <WorkshopPaymentStrategy
        isOpen={isPaymentStrategyModalOpen}
        onClose={() => setIsPaymentStrategyModalOpen(false)}
        onConfirm={confirmRepair}
        isLoading={isPendingRepair}
      />
    </>
  );
};

const WorkshopPaymentStrategy = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (data: { paymentStrategy: InvoicePaymentStrategy }) => void;
  isLoading: boolean;
}) => {
  const { article } = useArticleContext();
  const { t } = useLingui();

  const [paymentStrategy, setPaymentStrategy] = useState(article.workshopPaymentStrategy);
  const [showError, setShowError] = useState(false);

  return (
    <Dialog
      isOpen={isOpen}
      title={
        <Trans id="article.actions.repair_article.workshop_payment_strategy.title">
          Payment to workshop information
        </Trans>
      }
      onOpenChange={onClose}
    >
      <main>
        <Label
          label={t({
            id: 'article.actions.repair_article.workshop_payment_strategy.payment_strategy.label',
            message: 'Who is responsible for making the payment?',
          })}
        >
          <RadioBoxGroup
            name="payment-strategy"
            value={paymentStrategy}
            onChange={setPaymentStrategy}
          >
            <RadioBox
              value="store-cash-out"
              ariaLabel={t({
                id: 'article.actions.repair_article.workshop_payment_strategy.payment_strategy.store_cash_out.text',
                message: 'The store',
              })}
              title={
                <Trans id="article.actions.repair_article.workshop_payment_strategy.payment_strategy.store_cash_out.text">
                  The store
                </Trans>
              }
            >
              <Trans id="article.actions.repair_article.workshop_payment_strategy.payment_strategy.store_cash_out.sub_text">
                Via a cash register operation
              </Trans>
            </RadioBox>
            <RadioBox
              value="handled-by-accounting-team"
              ariaLabel={t({
                id: 'article.actions.repair_article.workshop_payment_strategy.payment_strategy.handled_by_accounting_team.text',
                message: 'The accounting department',
              })}
              title={
                <Trans id="article.actions.repair_article.workshop_payment_strategy.payment_strategy.handled_by_accounting_team.text">
                  The accounting department
                </Trans>
              }
            >
              <Trans id="article.actions.repair_article.workshop_payment_strategy.payment_strategy.handled_by_accounting_team.sub_text">
                Via bank transfer at the end of the month
              </Trans>
            </RadioBox>
          </RadioBoxGroup>
        </Label>
      </main>
      <footer>
        <Stack row alignItems="center" justifyContent="space-between" flexWrap="nowrap">
          {showError && (
            <span className="paragraph-100-regular text-danger">
              <Trans id="article.actions.repair_article.workshop_payment_strategy.error">
                Please select the entity responsible for making the payment
              </Trans>
            </span>
          )}
          <Button
            variant="primary"
            size="medium"
            onPress={() => {
              if (!paymentStrategy) {
                setShowError(true);
                return;
              }

              onConfirm({ paymentStrategy });
            }}
            isLoading={isLoading}
          >
            <span style={{ textWrap: 'nowrap' }}>
              <Trans id="article.actions.repair_article.workshop_payment_strategy.confirm">
                Confirm and validate repair
              </Trans>
            </span>
          </Button>
        </Stack>
      </footer>
    </Dialog>
  );
};
