import { msg } from '@lingui/core/macro';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import type { Endpoints, Permission } from '@/api';
import { Locale } from '@/services/i18n';
import { useFetch } from '@/utils/fetch';

import Phone, { formatPhone } from './partials/phone';
import { Article } from './article';
import { Model } from './model';
import { Organization } from './organization';
import { Store } from './store';
import { UserRole, UserRoleScope } from './userRole';
import { Workshop } from './workshop';

type UserSettingsBody = Endpoints['PATCH /users/:id/settings']['body'];
export class User extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;
  name!: string;
  email!: string;
  phone!: Phone | null;
  locale!: Locale | null;

  createdAt!: string;

  get createdAtDate() {
    return new Date(this.createdAt);
  }

  get formattedPhone() {
    return formatPhone(this.phone);
  }

  hasPermission(permission: Permission, scope: UserRoleScope | UserRoleScope[]): boolean {
    if (!('roles' in this)) {
      throw new Error('User has no roles to check permissions');
    }

    return (this.roles as UserRole[]).some((role) => {
      if (Array.isArray(scope)) {
        return scope.some((s) => role.hasPermission(permission, s));
      }
      return role.hasPermission(permission, scope);
    });
  }

  hasPermissionOnAnyScope(permission: Permission): boolean {
    if (!('roles' in this)) {
      throw new Error('User has no roles to check permissions');
    }
    return (this.roles as UserRole[]).some((role) => role.hasPermissionOnAnyScope(permission));
  }

  get organizations() {
    if (!('roles' in this)) {
      throw new Error('User must be fetched with roles');
    }

    return (this.roles as (UserRole & { organization?: Organization })[])
      .filter((userRole) => !!userRole.organization)
      .map((userRole) => userRole.organization) as Organization[];
  }

  get stores() {
    if (!('roles' in this)) {
      throw new Error('User must be fetched with roles to retrieve stores');
    }

    return (this.roles as (UserRole & { store?: Store })[])
      .map((userRole) => userRole.store)
      .filter((x) => x) as Store[];
  }

  getStoresWithPermission(permission: Permission) {
    if (!('roles' in this)) {
      throw new Error('User must be fetched with roles to retrieve stores');
    }

    return (this.roles as (UserRole & { store?: Store })[])
      .filter((userRole) => !!userRole.storeId && userRole.role.permissions.includes(permission))
      .map((userRole) => userRole.store) as Store[];
  }

  get workshop() {
    if (!('roles' in this)) {
      throw new Error('User must be fetched with roles to retrieve workshops');
    }

    return (this.roles as (UserRole & { workshop?: Workshop })[]).find(
      (userRole) => !!userRole.workshopId
    )?.workshop;
  }

  canCreateShipments() {
    if (this.stores.length) {
      return this.stores.some((store) => this.canCreateShipmentFromOrigin({ storeId: store.id }));
    }

    if (this.workshop) {
      return this.canCreateShipmentFromOrigin({ workshopId: this.workshop.id });
    }

    return false;
  }

  canCreateShipmentFromOrigin({ workshopId, storeId }: { workshopId?: string; storeId?: string }) {
    if (storeId) {
      return this.hasPermission('create_shipment_from_origin', { storeId });
    }

    if (workshopId) {
      return this.hasPermission('create_shipment_from_origin', { workshopId });
    }

    return false;
  }

  canVerifyShipmentReception({
    workshopId,
    storeId,
  }: {
    workshopId?: string | null;
    storeId?: string | null;
  }) {
    if (storeId) {
      return this.hasPermission('verify_shipment_reception', { storeId });
    }

    if (workshopId) {
      return this.hasPermission('verify_shipment_reception', { workshopId });
    }

    return false;
  }

  isResponsibleWorkshop(article?: Article) {
    return this.workshop && article?.workshopId === this.workshop.id;
  }
}

export const USER_TYPES = ['admin', 'user', 'api'] as const;
export const USER_TYPE_LABELS = {
  admin: msg({ id: 'user.type.admin.label', message: 'Administrator' }),
  user: msg({ id: 'user.type.user.label', message: 'User' }),
  api: msg({ id: 'user.type.api.label', message: 'API token' }),
};
export type UserType = (typeof USER_TYPES)[number];

export const instanciateUserWithRelations = (user: {
  roles: {
    organization?: any;
    store?: any;
    workshop?: any;
  }[];
}) =>
  new User(user).with(
    'roles',
    (user.roles as any[]).map((userRole: any) =>
      new UserRole(userRole)
        .with(
          'organization',
          userRole.organization ? new Organization(userRole.organization) : undefined
        )
        .with('store', userRole.store ? new Store(userRole.store) : undefined)
        .with('workshop', userRole.workshop ? new Workshop(userRole.workshop) : undefined)
    )
  );

export type UserWithRelations = ReturnType<typeof instanciateUserWithRelations>;

export const useUsers = (
  params: Endpoints['GET /users']['query'] = {},
  options?: {
    enabled?: boolean;
  }
) => {
  const fetch = useFetch<Endpoints['GET /users']>();

  return useQuery({
    queryKey: ['users', params],
    queryFn: () =>
      fetch('/users', params).then(({ users, meta }) => ({
        users: users.map(instanciateUserWithRelations),
        meta,
      })),
    enabled: options?.enabled,
  });
};

export const useUser = (id?: string) => {
  const fetch = useFetch<Endpoints['GET /users/:id']>();

  return useQuery({
    queryKey: ['users', id],
    queryFn: () => fetch(`/users/${id!}`).then(instanciateUserWithRelations),
    enabled: !!id,
  });
};

export type UserNotificationSettings =
  Endpoints['GET /users/:id/settings']['response']['notifications'];

export type NotificationCategory = keyof UserNotificationSettings;

export const useUserSettings = (id: string) => {
  const fetch = useFetch<Endpoints['GET /users/:id/settings']>();

  return useQuery({
    queryKey: ['users', id, 'settings'],
    queryFn: () => fetch(`/users/${id}/settings`),
  });
};

export const useUpdateUserSettings = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['PATCH /users/:id/settings']>();

  return useMutation({
    mutationFn: ({ userId, ...body }: { userId: string } & UserSettingsBody) =>
      fetch(`/users/${userId}/settings`, undefined, { method: 'PATCH', body }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });
};

export type MfaMethod = Extract<
  Endpoints['POST /sessions']['response'],
  { mfaToken: string }
>['mfaMethods'][number];

export const useCreateSession = () => {
  const fetch = useFetch<Endpoints['POST /sessions']>();

  return useMutation({
    mutationFn: (params: { email: string; password: string }) =>
      fetch('/sessions', undefined, {
        method: 'POST',
        body: params,
      }).then((response) =>
        'mfaToken' in response
          ? response
          : {
              user: instanciateUserWithRelations(response.user),
              flags: response.flags,
              publicKeys: response.publicKeys,
            }
      ),
  });
};

export const useDeleteCurrentSession = () => {
  const fetch = useFetch<Endpoints['DELETE /sessions/current']>();

  return useMutation({
    mutationFn: () =>
      fetch('/sessions/current', undefined, {
        method: 'DELETE',
      }).then(() => {
        localStorage.clear();
        // eslint-disable-next-line react-compiler/react-compiler
        window.location.href = '/login';
      }),
  });
};

export const useCompleteMfa = () => {
  const fetch = useFetch<Endpoints['POST /sessions/complete-mfa']>();

  return useMutation({
    mutationFn: (body: { mfaMethod: MfaMethod['type']; mfaToken: string; code: string }) =>
      fetch('/sessions/complete-mfa', undefined, {
        method: 'POST',
        body,
      }).then((response) => ({
        user: instanciateUserWithRelations(response.user),
        flags: response.flags,
        publicKeys: response.publicKeys,
      })),
  });
};

export const useRequestPasswordReset = () => {
  const fetch = useFetch<Endpoints['POST /auth/reset-password']>();

  return useMutation({
    mutationFn: (email: string) =>
      fetch('/auth/reset-password', undefined, {
        method: 'POST',
        body: { email },
      }),
  });
};

export const useSetNewPassword = () => {
  const fetch = useFetch<Endpoints['POST /auth/reset-password']>();

  return useMutation({
    mutationFn: (params: { email: string; token: string; newPassword: string }) =>
      fetch('/auth/reset-password', undefined, {
        method: 'POST',
        body: params,
      }),
  });
};

export const useUpdateUser = () => {
  const fetch = useFetch<Endpoints['PATCH /users/:id']>();

  return useMutation({
    mutationFn: (params: { id: string; data: { name: string } }) =>
      fetch(`/users/${params.id}`, undefined, {
        method: 'PATCH',
        body: params.data,
      }),
  });
};

export const useProfilePhoto = (userId?: string) => {
  const fetch = useFetch<Endpoints['GET /media/profile/:userId']>();

  return useQuery({
    queryKey: ['media', userId],
    queryFn: () =>
      fetch(`/media/profile/${userId}`).then((media) => {
        if (!media) {
          return null;
        }

        return media.url;
      }),
    // Set a cache to 1 hour as the profile photo is not expected to change often
    staleTime: 55 * 60 * 1000,
    gcTime: 60 * 60 * 1000 + 5, // Stale time + 5 minutes like it's done for the default values
    enabled: !!userId,
  });
};
