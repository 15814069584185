import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router';
import { Trans } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import IconBag from '@/icons/Bag.svg';
import {
  BrandCreationLayout,
  BrandCreationLayoutHeader,
} from '@/layouts/Brand/BrandCreationLayout/BrandCreationLayout';
import {
  BrandRequestContextProvider,
  useBrandRequestContext,
} from '@/layouts/Brand/BrandRequestContext';
import { useMediaQueries } from '@/models/medium';
import { useClientRequest } from '@/models/request';
import { useWorkflow } from '@/models/workflow';
import { useClientToken, useCurrentOrganization, useStoreToken } from '@/services/auth';
import { ErrorBoundary } from '@/services/sentry';
import { createBEMClasses } from '@/utils/classname';
import { REQUEST_IN_PROGRESS_LOCAL_STORAGE_KEY } from '@/utils/requestInProgress';

import AddAnotherDefect from './components/Article/components/AddAnotherDefect';
import ArticleForm from './components/Article/components/ArticleForm';
import DefectsForm from './components/Article/components/DefectsForm';
import Cart from './components/Cart';
import Checkout from './components/Checkout';
import SettingsForm from './components/SettingsForm';
import Welcome from './components/Welcome';

import './New.css';

const { block, element } = createBEMClasses('client-new-request');

const New = () => {
  const [organization] = useCurrentOrganization();
  const navigate = useNavigate();
  const clientToken = useClientToken();
  const storeToken = useStoreToken();

  const [searchParams] = useSearchParams();
  const id = searchParams.get('id') ?? '';

  const {
    data: request,
    isLoading: isLoadingRequest,
    isError: isErrorRequest,
  } = useClientRequest(id);
  const { data: workflow } = useWorkflow(request?.workflowId);

  useEffect(() => {
    if (isErrorRequest) {
      navigate('', { replace: true });
    }
  }, [isErrorRequest, navigate]);

  // Redirect to the request page if the request is not a draft
  useEffect(() => {
    if (request?.draft === false) {
      const tokenQueryParam = clientToken
        ? `clientToken=${clientToken}`
        : `storeToken=${storeToken}`;
      navigate(`/brand/${organization!.slug}/requests/${request.id}?${tokenQueryParam}`, {
        replace: true,
      });
    }
  }, [organization, request, navigate, clientToken, storeToken]);

  const [showCart, setShowCart] = useState(false);

  const [globalStep, setGlobalStep] = useState<'create' | 'checkout'>('create');

  return (
    <BrandCreationLayout>
      <BrandCreationLayoutHeader>
        {request && globalStep === 'create' && (
          <div className={element('cart-button')}>
            <Button
              iconOnly
              noBorder
              variant="secondary-brand"
              size="large"
              onPress={() => setShowCart(!showCart)}
            >
              <IconBag />
            </Button>
            {!!request?.defectQuantity && (
              <div className="paragraph-200-medium">{request.defectQuantity}</div>
            )}
          </div>
        )}
        {request && globalStep === 'checkout' && (
          <Button variant="secondary-brand" size="medium" onPress={() => setGlobalStep('create')}>
            <Trans id="client.new.header.cancel">Cancel</Trans>
          </Button>
        )}
      </BrandCreationLayoutHeader>
      <main className={block()}>
        <ErrorBoundary>
          {isLoadingRequest ? null : request ? (
            <BrandRequestContextProvider request={request} workflow={workflow}>
              {globalStep === 'create' && (
                <CreateRequestSteps
                  showCart={showCart}
                  setShowCart={setShowCart}
                  onValidateRequest={() => setGlobalStep('checkout')}
                />
              )}
              {globalStep === 'checkout' && <Checkout onEditCart={() => setGlobalStep('create')} />}
            </BrandRequestContextProvider>
          ) : (
            <Welcome />
          )}
        </ErrorBoundary>
      </main>
    </BrandCreationLayout>
  );
};

const CreateRequestSteps = ({
  showCart,
  setShowCart,
  onValidateRequest,
}: {
  showCart: boolean;
  setShowCart: (showCart: boolean) => void;
  onValidateRequest: () => void;
}) => {
  const navigate = useNavigate();
  const { request } = useBrandRequestContext();

  const [step, setStep] = useState<'settings' | 'article' | 'service' | 'service-added'>(() => {
    if (request.articles.length === 0) {
      return request.storeId ? 'settings' : 'article';
    } else {
      const latestArticleHasNoServices =
        request.articles[request.articles.length - 1].snapshot.articleDefects.length === 0;

      return latestArticleHasNoServices ? 'service' : 'service-added';
    }
  });

  const proofOfPurchaseQueries = useMediaQueries(
    request.articles.map((article) => ({
      types: ['proof-of-purchase'],
      articleId: article?.id,
      limit: 100,
    }))
  );

  const [articleIndex, setArticleIndex] = useState(
    request.articles.length ? request.articles.length - 1 : 0
  );
  const [defectIds, setDefectIds] = useState<string[]>([]);

  const article = request.articles[articleIndex];
  const defects = article?.snapshot.articleDefects.filter((defect) =>
    defectIds.includes(defect.id)
  );

  const proofOfPurchaseQuery = proofOfPurchaseQueries[articleIndex];

  return (
    <>
      {step === 'settings' && <SettingsForm onContinue={() => setStep('article')} />}
      {step === 'article' && !proofOfPurchaseQuery?.isLoading && (
        <ArticleForm
          onSaveArticle={() => {
            if (!article) {
              // User created a new article
              setStep('service');
            } else if (!article.hasDefects) {
              // User edited an article which has no defects yet
              setStep('service');
            } else if (defects.length) {
              // User edited an article and was editing a defect
              setStep('service');
            } else {
              // User edited an article which has defects
              setStep('service-added');
            }
          }}
          onDiscard={() => {
            if (!request.articles.length) {
              // User discarded creating the first article
              if (request.storeId) {
                setStep('settings');
              } else {
                window.localStorage.removeItem(REQUEST_IN_PROGRESS_LOCAL_STORAGE_KEY);
                navigate(`/brand/${request.organization.slug}/requests/new`, { replace: true });
              }
            } else if (!article) {
              // User discarded creating a new article, and there are other articles already
              setArticleIndex(request.articles.length - 1);
              setStep('service-added');
              setShowCart(true);
            } else if (!article.hasDefects) {
              // User discarded editing an article which has no defects yet
              setStep('service');
            } else if (defects.length) {
              // User discarded editing an article and was editing a defect
              setStep('service');
            } else {
              // User discarded editing an article which already has defects
              setStep('service-added');
            }
          }}
          initialArticle={article}
          initialProofOfPurchase={proofOfPurchaseQuery?.data?.media}
          key={article?.id}
        />
      )}
      {step === 'service' && article && (
        <DefectsForm
          initialDefects={defects}
          article={article}
          onEditArticle={() => {
            // User wants to edit the article
            setStep('article');
          }}
          onDeleteArticle={() => {
            setDefectIds([]);

            if (request.articles.length > 1) {
              // User deleted the article, and there are other articles
              setArticleIndex(
                articleIndex === request.articles.length - 1
                  ? request.articles.length - 2
                  : request.articles.length - 1
              );
              setStep('service-added');
              setShowCart(true);
            } else {
              // User deleted the article, and there are no other articles
              setArticleIndex(0);
              setStep('article');
            }
          }}
          onSaveDefects={() => {
            if (defects) {
              // User edited a defect
              setDefectIds([]);
              setStep('service-added');
            } else {
              // User added a new defect
              setStep('service-added');
            }
          }}
          onCancel={() => {
            if (defects) {
              // User canceled editing a defect
              setDefectIds([]);
              setStep('service-added');
            } else {
              // User canceled adding a new defect on an article which already has defects
              setStep('service-added');
            }
          }}
        />
      )}
      {step === 'service-added' && article && (
        <AddAnotherDefect
          article={article}
          onEditArticle={() => {
            // User wants to edit the article
            setStep('article');
          }}
          onDeleteArticle={() => {
            if (request.articles.length > 1) {
              // User deleted the article, and there are other articles
              setArticleIndex(
                articleIndex === request.articles.length - 1
                  ? request.articles.length - 2
                  : request.articles.length - 1
              );
              setStep('service-added');
              setShowCart(true);
            } else {
              // User deleted the article, and there are no other articles
              setArticleIndex(0);
              setStep('article');
            }
          }}
          onYes={() => {
            // User wants to add a new defect
            setDefectIds([]);
            setStep('service');
          }}
          onNo={() => {
            // User doesn't want any more defects
            setShowCart(true);
          }}
        />
      )}
      <Cart
        isOpen={showCart}
        onOpenChange={setShowCart}
        onAddArticle={() => {
          setArticleIndex(request.articles.length);
          setStep('article');
          setShowCart(false);
        }}
        onAddDefectsToArticle={(index) => {
          setArticleIndex(index);
          setStep('service');
          setShowCart(false);
        }}
        onEditDefectsOnArticle={(data) => {
          setStep('service');
          setArticleIndex(data.articleIndex);
          setDefectIds(data.defectIds);
          setShowCart(false);
        }}
        onEditArticle={(index) => {
          setArticleIndex(index);
          setStep('article');
          setShowCart(false);
        }}
        onDeleteAllArticles={() => {
          setArticleIndex(0);
          setStep('article');
          setShowCart(false);
        }}
        onDeleteArticle={(index) => {
          if (index === articleIndex) {
            setStep('service-added');
            setArticleIndex(0);
          }
        }}
        onValidateRequest={onValidateRequest}
      />
    </>
  );
};

export default New;
