import { keepPreviousData, useQuery } from '@tanstack/react-query';

import {
  CustomActionPrice,
  Endpoints,
  PriceAggregate,
  ProductL1,
  ProductL2,
  ProductL3,
} from '@/api';
import { RefashionStatus } from '@/components/RefashionLogo';
import { useFetch } from '@/utils/fetch';
import { Currency } from '@/utils/number';

import { Model } from './model';
import { Organization } from './organization';
import { ArticleActionWithRelations, instanciateArticleAction } from './request';
import { Translation, useDatabaseTranslation } from './translation';

export class ActionType extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);

    this.name = new Translation(data.name);
  }

  id!: string;
  name!: Translation;
  nameId!: string;
  isQuantifiable!: boolean;

  createdAt!: string;

  get createdAtDate() {
    return new Date(this.createdAt);
  }
}

export class ActionTypeOrganization extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;

  productL1!: ProductL1[];
  productL2!: ProductL2[];
  productL3!: ProductL3[];

  actionTypeId!: string;

  refashionId!: string | null;

  createdAt!: string;

  get createdAtDate() {
    return new Date(this.createdAt);
  }
}

export class ActionTypeOrganizationPrice extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;

  amount!: number; // In cents
  currency!: Currency;
}

const getRefashionStatus = (price: PriceAggregate | undefined): RefashionStatus | null => {
  const refashionDiscount = price?.amountPerCurrency
    ?.find((amount) => amount.currency === 'EUR')
    ?.details.find((detail) => detail.type === 'discount' && detail.subType === 'refashion');
  const warrantyDiscount = price?.amountPerCurrency
    ?.find((amount) => amount.currency === 'EUR')
    ?.details.find((detail) => detail.type === 'discount' && detail.subType === 'warranty');

  if (!refashionDiscount) {
    return null;
  } else if (refashionDiscount.amount === 0) {
    if (warrantyDiscount?.amount) {
      return 'not-applied-warranty';
    } else {
      return 'not-applied-discount';
    }
  } else {
    return 'applied';
  }
};

export class ActionTypeOrganizationWorkshop extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  amountBeforeTax!: number; // In cents
  currency!: Currency;
  actionTypeOrganizationId!: string | null;
  packActionTypeOrganizationId!: string | null;

  createdAt!: string;

  get createdAtDate() {
    return new Date(this.createdAt);
  }
}

export class PackActionTypeOrganizationAction extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;

  actionTypeId!: string;

  refashionPrice!: number | null;
  refashionId!: string | null;
}

export class PackActionTypeOrganization extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;

  productL1!: ProductL1[];
  productL2!: ProductL2[];
  productL3!: ProductL3[];

  name!: Translation;
  nameId!: string;

  isQuantifiable!: boolean;
}

export const getPackActionTypeOrganizationWithRefashionStatus = (
  action: ArticleActionWithRelations,
  price: PriceAggregate | undefined
):
  | null
  | (PackActionTypeOrganization & {
      actions: (PackActionTypeOrganizationAction & {
        actionType: ActionType;
        refashionStatus?: RefashionStatus;
      })[];
    }) => {
  const warrantyDiscount = price?.amountPerCurrency
    ?.find((amount) => amount.currency === 'EUR')
    ?.details.find((detail) => detail.type === 'discount' && detail.subType === 'warranty');

  if (!action.packActionTypeOrganization) {
    return null;
  }

  return Object.assign(action.packActionTypeOrganization, {
    actions: action.packActionTypeOrganization.actions.map((subAction) => {
      const actionBonusDetail = action.priceRefashionBonusDetail?.find(
        (detail) => detail.actionId === subAction.id
      );

      const refashionStatus = actionBonusDetail
        ? actionBonusDetail.bonus === 0
          ? warrantyDiscount?.amount
            ? 'not-applied-warranty'
            : 'not-applied-discount'
          : 'applied'
        : undefined;

      return Object.assign(subAction, { refashionStatus });
    }),
  });
};

export class ArticleAction extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;
  stableId!: string;

  brandResponsibility!: boolean;
  context!: string | null;

  actionTypeOrganizationId!: string | null;
  packActionTypeOrganizationId!: string | null;
  customDescription!: string | null;

  customWorkshopPrice!: CustomActionPrice | null;
  customOrganizationPrice!: CustomActionPrice | null;

  priceRefashionBonusDetail!: {
    actionId: string;
    bonus: number;
  }[];

  price?: PriceAggregate;
  cost?: PriceAggregate;

  createdAt!: string;

  articleDefects!: { id: string; stableId: string }[];

  get createdAtDate() {
    return new Date(this.createdAt);
  }

  get priceRefashionStatus(): RefashionStatus | null {
    return getRefashionStatus(this.price);
  }

  get costRefashionStatus(): RefashionStatus | null {
    return getRefashionStatus(this.cost);
  }

  get isCustom() {
    return !this.actionTypeOrganizationId && !this.packActionTypeOrganizationId;
  }
}

type GetActionNameProps = {
  action: ArticleActionWithRelations;
};

export const useGetActionName = () => {
  const { _db } = useDatabaseTranslation();

  return ({ action }: GetActionNameProps) => {
    if (action.actionTypeOrganization) {
      return _db(action.actionTypeOrganization.actionType.name);
    } else if (action.packActionTypeOrganization) {
      return _db(action.packActionTypeOrganization.name);
    } else {
      return action.customDescription ?? '';
    }
  };
};

export const useActionName = (props: GetActionNameProps) => {
  const getActionName = useGetActionName();

  return getActionName(props);
};

/**
 *
 * @returns Returns true if both actions are the same (wether it is an action, a pack of actions, or a custom action)
 */
const areActionsDuplicates = (
  action1: ArticleActionWithRelations,
  action2: ArticleActionWithRelations
): boolean => {
  return (
    (action1.isCustom &&
      action2.isCustom &&
      action1.customDescription === action2.customDescription) ||
    (!!action1.actionTypeOrganizationId &&
      action1.actionTypeOrganizationId === action2.actionTypeOrganizationId) ||
    (!!action1.packActionTypeOrganizationId &&
      action1.packActionTypeOrganizationId === action2.packActionTypeOrganizationId)
  );
};

/**
 * Formats a list of actions so that actions of the same type are merged into a single action for which:
 * - `quantity` is total of merged actions
 * - `ids` is a list of the merged actions' ids
 * - the other fields simply correspond to the first of the merged actions
 *
 * Actions are of the same type if they have the same `customDescription`, `actionTypeOrganizationId` or `packActionTypeOrganizationId`
 */
export const getGroupedActions = (actions: ArticleActionWithRelations[]) => {
  return actions.reduce<(ArticleActionWithRelations & { ids: string[]; quantity: number })[]>(
    (acc, action) => {
      const existingAction = acc.find((accAction) => areActionsDuplicates(action, accAction));

      if (!existingAction) {
        acc.push(
          Object.assign(instanciateArticleAction(action, action.media), {
            ids: [action.id],
            quantity: 1,
          })
        );
      } else {
        existingAction.ids = [...existingAction.ids, action.id];
        existingAction.quantity = existingAction.quantity + 1;
      }

      return acc;
    },
    []
  );
};

/**
 *
 * @param actionToCompare The action we want to attribute a number to (only if it has duplicate actions)
 * @param actions The list of selected actions
 * @returns A number attributed to an action (only if it has duplicate actions)
 */
export const computeDuplicateActionNumber = (
  actionToCompare: ArticleActionWithRelations,
  actions: ArticleActionWithRelations[]
): number | undefined => {
  const currentActionAndDuplicates = actions.filter((action) =>
    areActionsDuplicates(actionToCompare, action)
  );

  if (currentActionAndDuplicates.length === 1) {
    return undefined;
  }

  const indexOfActionToCompare = currentActionAndDuplicates.findIndex(
    (action) => action.id === actionToCompare.id
  );

  return indexOfActionToCompare + 1;
};

export const getCustomPriceAmount = ({
  customPrice,
  showAmountBeforeTaxes,
}: {
  customPrice: CustomActionPrice | null | undefined;
  showAmountBeforeTaxes: boolean;
}) => {
  return customPrice
    ? showAmountBeforeTaxes
      ? 'amountBeforeTaxes' in customPrice
        ? customPrice.amountBeforeTaxes
        : undefined
      : 'amount' in customPrice
        ? customPrice.amount
        : undefined
    : undefined;
};

export const instanciateActionTypeOrganization = (
  actionTypeOrganization: Endpoints['GET /action-types']['response']['actionTypes'][number]
) =>
  new ActionTypeOrganization(actionTypeOrganization)
    .with('actionType', new ActionType(actionTypeOrganization.actionType))
    .with('dynamicCost', actionTypeOrganization.dynamicCost)
    .with('dynamicPrice', actionTypeOrganization.dynamicPrice)
    .with('refashionBonus', actionTypeOrganization.refashionBonus);

export type ActionTypeOrganizationWithRelations = ReturnType<
  typeof instanciateActionTypeOrganization
>;

export const instanciatePackActionTypeOrganization = (
  packActionTypeOrganization: Endpoints['GET /action-types']['response']['packActionTypes'][number]
) =>
  new PackActionTypeOrganization(packActionTypeOrganization)
    .with(
      'actions',
      packActionTypeOrganization.actions.map((action) =>
        new PackActionTypeOrganizationAction(action).with(
          'actionType',
          new ActionType(action.actionType)
        )
      )
    )
    .with('dynamicCost', packActionTypeOrganization.dynamicCost)
    .with('dynamicPrice', packActionTypeOrganization.dynamicPrice)
    .with('refashionBonus', packActionTypeOrganization.refashionBonus);

export type PackActionTypeOrganizationWithRelations = ReturnType<
  typeof instanciatePackActionTypeOrganization
>;

export const useActionTypes = (
  params: Endpoints['GET /action-types']['query'],
  options?: {
    enabled?: boolean;
    keepPreviousData?: boolean;
  }
) => {
  const fetch = useFetch<Endpoints['GET /action-types']>();

  return useQuery({
    queryKey: ['action-types', params],
    queryFn: () =>
      fetch('/action-types', params).then(({ actionTypes, packActionTypes }) => ({
        actionTypes: actionTypes.map(instanciateActionTypeOrganization),
        packActionTypes: packActionTypes.map(instanciatePackActionTypeOrganization),
      })),
    enabled: options?.enabled,
    placeholderData: options?.keepPreviousData ? keepPreviousData : undefined,
  });
};

export const useActionTypesOrganization = () => {
  const fetch = useFetch<Endpoints['GET /action-types/organization']>();

  return useQuery({
    queryKey: ['action-types', 'organization'],
    queryFn: () =>
      fetch('/action-types/organization').then(({ actionTypes, packActionTypes }) => ({
        actionTypes: actionTypes.map((actionTypeOrganization) =>
          new ActionTypeOrganization(actionTypeOrganization)
            .with(
              'actionType',
              new ActionType(actionTypeOrganization.actionType).with(
                'name',
                new Translation(actionTypeOrganization.actionType.name)
              )
            )
            .with(
              'prices',
              actionTypeOrganization.prices.map((price) => new ActionTypeOrganizationPrice(price))
            )
        ),
        packActionTypes: packActionTypes.map((packActionTypeOrganization) =>
          new PackActionTypeOrganization(packActionTypeOrganization)
            .with('name', new Translation(packActionTypeOrganization.name))
            .with(
              'actions',
              packActionTypeOrganization.actions.map((actionTypeOrganization) =>
                new PackActionTypeOrganizationAction(actionTypeOrganization).with(
                  'actionType',
                  new ActionType(actionTypeOrganization.actionType).with(
                    'name',
                    new Translation(actionTypeOrganization.actionType.name)
                  )
                )
              )
            )
            .with(
              'prices',
              packActionTypeOrganization.prices.map(
                (price) => new ActionTypeOrganizationPrice(price)
              )
            )
        ),
      })),
  });
};

export const useActionTypeOrganizationWorkshops = (params: { limit?: number; offset?: number }) => {
  const fetch = useFetch<Endpoints['GET /action-types/workshop']>();

  return useQuery({
    queryKey: ['action-types', 'workshop', params],
    queryFn: () =>
      fetch('/action-types/workshop', params).then(({ actionTypeOrganizationWorkshops, meta }) => ({
        actionTypeOrganizationWorkshops: actionTypeOrganizationWorkshops.map(
          (actionTypeOrganizationWorkshop) =>
            new ActionTypeOrganizationWorkshop(actionTypeOrganizationWorkshop)
              .with(
                'actionTypeOrganization',
                actionTypeOrganizationWorkshop.actionTypeOrganization
                  ? new ActionTypeOrganization(
                      actionTypeOrganizationWorkshop.actionTypeOrganization
                    )
                      .with(
                        'actionType',
                        new ActionType(
                          actionTypeOrganizationWorkshop.actionTypeOrganization.actionType
                        ).with(
                          'name',
                          new Translation(
                            actionTypeOrganizationWorkshop.actionTypeOrganization.actionType.name
                          )
                        )
                      )
                      .with(
                        'organization',
                        new Organization(
                          actionTypeOrganizationWorkshop.actionTypeOrganization.organization
                        )
                      )
                  : undefined
              )
              .with(
                'packActionTypeOrganization',
                actionTypeOrganizationWorkshop.packActionTypeOrganization
                  ? new PackActionTypeOrganization(
                      actionTypeOrganizationWorkshop.packActionTypeOrganization
                    )
                      .with(
                        'name',
                        new Translation(
                          actionTypeOrganizationWorkshop.packActionTypeOrganization.name
                        )
                      )
                      .with(
                        'organization',
                        new Organization(
                          actionTypeOrganizationWorkshop.packActionTypeOrganization.organization
                        )
                      )
                  : undefined
              )
        ),
        meta,
      })),
  });
};
