import { Plural, Trans } from '@lingui/react/macro';

import Stack from '@/design_system/Stack';
import { BrandRequestMainContent } from '@/layouts/Brand/BrandRequestLayout/BrandRequestLayout';
import { ClientRequestWithRelations } from '@/models/request';
import ClientArticlesTable from '@/routes/Brand/Requests/Request/components/shared/ClientArticlesTable';
import { useStoreToken } from '@/services/auth';

export const ArchivedNone = ({ request }: { request: ClientRequestWithRelations }) => {
  const storeToken = useStoreToken();

  return (
    <BrandRequestMainContent>
      <Stack gap="1rem">
        <Stack gap="0.25rem">
          <p className="headline-200-medium headline-300-medium-mobile">
            <Trans id="client.request.archived-none.title">No service can be provided</Trans>
          </p>
          <p className="paragraph-50-regular paragraph-100-regular-mobile">
            {storeToken && !!request.client ? (
              <Trans id="store.request.archived-none.message">
                After analysis, we regret to inform you that no service can be provided for the{' '}
                <Plural value={request.allArticles.length} one="item" other="items" />.
              </Trans>
            ) : (
              <Trans id="client.request.archived-none.message">
                After analysis, we regret to inform you that no service can be provided for your{' '}
                <Plural value={request.allArticles.length} one="item" other="items" />.
              </Trans>
            )}{' '}
          </p>
        </Stack>
        <ClientArticlesTable
          request={request}
          mode="action"
          hideStepper
          showPrice={false}
          showArticleComment
        />
      </Stack>
    </BrandRequestMainContent>
  );
};
