import { ReactNode, useEffect, useMemo } from 'react';
import { getLocalTimeZone, isWeekday, isWeekend, today } from '@internationalized/date';
import { Trans, useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import { Calendar } from '@/design_system/InputDate';
import InputText from '@/design_system/InputText';
import { Label } from '@/design_system/Label/Label';
import { RadioBox, RadioBoxGroup } from '@/design_system/Radio/RadioBox/RadioBox';
import { RadioItem, RadioItemGroup } from '@/design_system/Radio/RadioItem/RadioItem';
import Stack from '@/design_system/Stack';
import Tooltip from '@/design_system/Tooltip';
import IconInfo from '@/icons/Info.svg';
import { AddressWithRelations } from '@/models/address';
import {
  ArticleWithRelations,
  SHIPMENT_CARRIER_SERVICES,
  SHIPMENT_CARRIERS,
  ShipmentCarrierService,
  ShipmentHandover,
  useAvailableCarriers,
} from '@/models/shipment';
import useViewPort from '@/utils/useViewport';

export const CarrierSelect = ({
  articles,
  origin,
  destination,
  carrierService,
  setCarrierService,
  handover,
  setHandover,
  pickupDate,
  setPickupDate,
  trackingId,
  setTrackingId,
}: {
  articles: ArticleWithRelations[];
  origin?: AddressWithRelations;
  destination?: AddressWithRelations;
  carrierService?: ShipmentCarrierService;
  setCarrierService: (carrier: ShipmentCarrierService) => void;
  handover?: ShipmentHandover;
  setHandover: (handover?: ShipmentHandover) => void;
  pickupDate?: string;
  setPickupDate: (pickupDate?: string) => void;
  trackingId?: string;
  setTrackingId: (trackingId?: string) => void;
}) => {
  const { i18n, t } = useLingui();
  const { isMobile } = useViewPort();

  const organizationId =
    origin?.client?.organizationId ??
    destination?.client?.organizationId ??
    articles[0]?.request.organizationId;
  const originId = origin?.id;
  const destinationId = destination?.id;

  const isOriginOrDestinationQNDWorkshop =
    (!!origin?.workshop && origin.workshop.nonDigitalized) ||
    (!!destination?.workshop && destination.workshop.nonDigitalized);

  const { data: { carriers } = { carriers: [] }, isLoading } = useAvailableCarriers(
    {
      organizationId,
      originId,
      destinationId,
    },
    {
      enabled: !isOriginOrDestinationQNDWorkshop,
    }
  );

  useEffect(() => {
    if (carriers.length > 0 && !carrierService) {
      setCarrierService(carriers[0].service);

      const handoverOptions = carriers[0]?.handover ?? [];

      setHandover(handoverOptions.length === 1 ? handoverOptions[0] : undefined);
    }
  }, [carrierService, carriers, setCarrierService, setHandover]);

  // Minimum date for pickup
  // If today is a weekend, the minimum date for pickup is Monday
  const minDate = useMemo(() => {
    const todayLocal = today(getLocalTimeZone());

    if (isWeekday(todayLocal, i18n.locale)) {
      return todayLocal;
    }

    const tomorrowLocal = todayLocal.add({ days: 1 });

    if (isWeekday(tomorrowLocal, i18n.locale)) {
      return tomorrowLocal;
    }

    return tomorrowLocal.add({ days: 1 });
  }, [i18n.locale]);

  if (!originId || !destinationId) {
    return (
      <>
        <p>
          <span className="paragraph-100-medium">
            <Trans id="shipments.new.carrier.label">Carrier</Trans>
          </span>
          <br />
          <span className="paragraph-100-regular text-secondary">
            <Trans id="shipments.new.carrier.select-origin-destination">
              Select an origin and destination before choosing the carrier
            </Trans>
          </span>
        </p>
      </>
    );
  }

  if (!organizationId && articles.length === 0) {
    return (
      <>
        <p>
          <span className="paragraph-100-medium">
            <Trans id="shipments.new.carrier.label">Carrier</Trans>
          </span>
          <br />
          <span className="paragraph-100-regular text-secondary">
            <Trans id="shipments.new.carrier.select-article">
              Select at least one item before choosing the carrier
            </Trans>
          </span>
        </p>
      </>
    );
  }

  if (isLoading) {
    return (
      <p className="paragraph-100-medium">
        <Trans id="shipments.new.carrier.label">Carrier</Trans>
      </p>
    );
  }

  const chosenCarrier = carriers.find((carrier) => carrier.service === carrierService);

  const options: { value: string; title: string; children: ReactNode }[] = carriers.map(
    (carrier) => {
      const carrierName =
        SHIPMENT_CARRIERS.find(({ id }) => id === carrier.name)?.name ?? carrier.name;
      const carrierServiceName =
        SHIPMENT_CARRIER_SERVICES.find(({ id }) => id === carrier.service)?.name ?? carrier.service;

      return {
        value: carrier.service,
        title: `${carrierName} (${carrierServiceName})`,
        children: t({
          id: 'shipment.carrier.carrier-service.description',
          message: 'Automatic shipping label generation with tracking included.',
        }),
      };
    }
  );

  if (isOriginOrDestinationQNDWorkshop) {
    options.push({
      value: 'self-handled',
      title: t({ id: 'shipment.carrier.self-handled', message: 'Self-Handled delivery' }),
      children: t({
        id: 'shipment.carrier.self-handled.description',
        message:
          'Handle the delivery yourself or via a third party (e.g. the recipient picks up the package).',
      }),
    });
  } else {
    options.push({
      value: 'private-carrier',
      title: t({ id: 'shipment.carrier.private-carrier', message: 'Private carrier' }),
      children: t({
        id: 'shipment.carrier.private-carrier.description',
        message:
          'Manually generate the shipping label. Then input the tracking number to follow your package within the app.',
      }),
    });
  }

  return (
    <Stack gap={isMobile ? '1rem' : '1.5rem'}>
      <Label label={t({ id: 'shipments.new.carrier.label', message: 'Carrier' })}>
        <RadioBoxGroup
          name="carrier"
          value={carrierService}
          onChange={(carrierService) => {
            setCarrierService(carrierService as ShipmentCarrierService);

            const handoverOptions =
              carriers.find((carrier) => carrier.service === carrierService)?.handover ?? [];

            setHandover(handoverOptions.length === 1 ? handoverOptions[0] : undefined);
            setPickupDate(undefined);
          }}
        >
          {options.map(({ value, title, children }) => (
            <RadioBox key={value} value={value} ariaLabel={title} title={title}>
              {children}
            </RadioBox>
          ))}
        </RadioBoxGroup>
      </Label>
      {chosenCarrier && chosenCarrier.handover.length > 0 && (
        <Label label={t({ id: 'shipments.new.handover.label', message: 'Handover option' })}>
          <RadioItemGroup
            name="handover"
            value={handover ?? null}
            onChange={(handover) => {
              setHandover(handover);
              setPickupDate(undefined);
            }}
          >
            {chosenCarrier.handover.includes('pickup') && (
              <RadioItem
                value="pickup"
                ariaLabel={t({ id: 'shipment.handover.pickup', message: 'Pickup' })}
              >
                <Trans id="shipment.handover.pickup">Pickup</Trans>
              </RadioItem>
            )}
            {chosenCarrier.handover.includes('dropoff') && (
              <RadioItem
                value="dropoff"
                ariaLabel={t({ id: 'shipment.handover.dropoff', message: 'Drop-off' })}
              >
                <Trans id="shipment.handover.dropoff">Drop-off</Trans>
              </RadioItem>
            )}
          </RadioItemGroup>
        </Label>
      )}
      {chosenCarrier && handover === 'pickup' && (
        <Stack gap="0.5rem">
          <p className="paragraph-100-regular">
            <Trans id="shipments.new.handover.pickup-date">Select a pickup date</Trans>
          </p>
          <Calendar
            minValue={minDate}
            isDateUnavailable={(date) => isWeekend(date, i18n.locale)}
            value={pickupDate}
            onChange={setPickupDate}
          />
        </Stack>
      )}
      {carrierService === 'private-carrier' && (
        <InputText
          label={
            <Stack row gap="4px" alignItems="baseline">
              <Trans id="shipments.new.tracking-number.label">Tracking Number</Trans>
              <span className="paragraph-200-regular">
                <Trans id="shipments.new.tracking-number.optional">(optional)</Trans>
              </span>
              <Tooltip
                content={
                  <Trans id="shipments.new.tracking-number.tooltip">
                    You can add it later if you haven&apos;t already created the shipment on the
                    courier site
                  </Trans>
                }
              >
                <Button variant="style-less">
                  <IconInfo style={{ fontSize: '1rem', bottom: '-3px', position: 'relative' }} />
                </Button>
              </Tooltip>
            </Stack>
          }
          placeholder={t({
            id: 'shipments.new.tracking-number.placeholder',
            message: 'Add courier tracking number',
          })}
          value={trackingId}
          onChange={setTrackingId}
        />
      )}
    </Stack>
  );
};
