import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Alpha2Code } from 'i18n-iso-countries';

import { Endpoints } from '@/api';
import { getCountryName } from '@/utils/country';
import { PaginatedResults, useFetch } from '@/utils/fetch';

import { Client } from './client';
import { Model } from './model';
import { Store } from './store';
import { Workshop } from './workshop';

export class Address extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;
  contactName!: string | null;
  contactEmail!: string | null;
  line1!: string;
  line2!: string | null;
  zipCode!: string;
  city!: string;
  state!: string | null;
  country!: Alpha2Code;

  get formatted() {
    return [
      this.line1,
      this.line2,
      this.zipCode,
      this.city,
      this.state,
      getCountryName(this.country),
    ]
      .filter((el) => !!el)
      .join(' ');
  }

  get formattedStreet() {
    return [this.line1, this.line2].filter((el) => !!el).join(' ');
  }

  get formattedZip() {
    return [this.zipCode, this.city, this.state, getCountryName(this.country)]
      .filter((el) => !!el)
      .join(' ');
  }

  get cityState() {
    return [this.city, this.state].filter((el) => !!el).join(', ');
  }

  get name() {
    if (!('client' in this) || !('store' in this) || !('workshop' in this)) {
      throw new Error('Cannot get name of address without client, store or workshop');
    }

    const client = (this as unknown as AddressWithRelations).client;
    const store = (this as unknown as AddressWithRelations).store;
    const workshop = (this as unknown as AddressWithRelations).workshop;

    return client?.name ?? store?.name ?? workshop?.name ?? undefined;
  }
}

export interface AddressRaw {
  contactName?: string | null;
  contactEmail?: string | null;
  line1: string;
  line2?: string | null;
  zipCode: string;
  city: string;
  state?: string | null;
  country: Alpha2Code;
}

export const instanciateAddressWithRelations = (address: any) =>
  new Address(address)
    .with('client', address.client ? new Client(address.client) : null)
    .with('store', address.store ? new Store(address.store) : null)
    .with('workshop', address.workshop ? new Workshop(address.workshop) : null);

export type AddressWithRelations = ReturnType<typeof instanciateAddressWithRelations>;

export const useAddresses = (
  params: { search?: string; limit?: number; offset?: number },
  options?: {
    keepPreviousData?: boolean;
    enabled?: boolean;
  }
) => {
  const fetch = useFetch<Endpoints['GET /addresses']>();

  return useQuery({
    queryKey: ['addresses', params],
    queryFn: () =>
      fetch('/addresses', params).then(({ addresses, meta }: PaginatedResults<'addresses'>) => ({
        addresses: addresses.map(instanciateAddressWithRelations),
        meta,
      })),
    placeholderData: options?.keepPreviousData ? keepPreviousData : undefined,
    enabled: options?.enabled,
  });
};
