import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-aria-components';
import { Plural, Trans } from '@lingui/react/macro';

import config from '@/config';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconExternalLink from '@/icons/ExternalLink.svg';
import IconPrint from '@/icons/Print.svg';
import { ClientArticleWithRelations, ClientRequestWithRelations } from '@/models/request';
import {
  SHIPMENT_CARRIERS,
  ShipmentWithRelations,
  useShipments,
  useShipShipment,
} from '@/models/shipment';
import { useClientToken, useCurrentOrganization, useStoreToken } from '@/services/auth';
import { formatDate } from '@/utils/date';
import useViewPort from '@/utils/useViewport';

export const PendingShipmentPreparation = ({
  request,
  articlesInTransit,
}: {
  request: ClientRequestWithRelations;
  articlesInTransit: ClientArticleWithRelations[];
}) => {
  const { isMobile } = useViewPort();
  const clientToken = useClientToken();
  const storeToken = useStoreToken();
  const [organization] = useCurrentOrganization();
  const afterSalesServiceEmail = organization?.config.afterSalesServiceEmail;

  const ref = useRef<HTMLButtonElement>(null);
  const [showError, setShowError] = useState(false);

  const { data: { shipments } = { shipments: [] }, isLoading: isLoadingShipment } = useShipments({
    requestId: request.id,
  });

  const shipment = shipments[0] as ShipmentWithRelations | undefined;
  const shipmentCarrier = SHIPMENT_CARRIERS.find((carrier) => carrier.id === shipment?.carrier);
  const shipmentCarrierName = shipmentCarrier?.name;

  const {
    mutateAsync: shipShipment,
    isPending: isPendingShipShipment,
    isSuccess: isSuccessShipShipment,
    reset,
  } = useShipShipment();

  useEffect(() => {
    // Automatically download the shipping label if needed
    if (isSuccessShipShipment && !!shipment?.shippingDeclaredAt) {
      ref.current?.click();
      reset();
    }
  }, [isSuccessShipShipment, shipment?.shippingDeclaredAt, ref, reset]);

  if (!shipment) {
    return null;
  }

  const pickupDate = shipment?.pickupDateDate
    ? formatDate(shipment.pickupDateDate, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    : undefined;

  return (
    <Stack gap="1rem">
      <p className="paragraph-50-regular paragraph-100-regular-mobile">
        {shipment.handover === 'dropoff' && (
          <Trans id="client.request.transit.pending.home.title.dropoff">
            Please prepare the package and take it to a drop-off point.
          </Trans>
        )}
        {shipment.handover === 'pickup' && (
          <Trans id="client.request.transit.pending.home.title.pickup">
            Please prepare the package and wait for the carrier to pick it up.
          </Trans>
        )}
      </p>
      {!!pickupDate && (
        <p className="paragraph-50-regular paragraph-100-regular-mobile">
          <Trans id="store.request.transit.pending.pickup-date">
            The package will be picked up on {pickupDate}.
          </Trans>
        </p>
      )}
      <Box gap="1rem">
        <Stack row gap="1rem" flexWrap="nowrap" alignItems="center">
          {!isMobile && (
            <img
              src="/shipment-label.png"
              alt=""
              style={{ borderRadius: '4px', width: '7rem', height: '7rem', objectFit: 'cover' }}
            />
          )}
          <Stack gap="0.5rem" alignItems="flex-start">
            <ol className="paragraph-100-regular" style={{ paddingLeft: '1rem' }}>
              <li>
                <Trans id="client.request.transit.pending.home.li-1">
                  Print the{' '}
                  <Plural value={articlesInTransit.length} one="item sheet" other="item sheets" />{' '}
                  and shipping label
                </Trans>
              </li>
              <li>
                <Trans id="client.request.transit.pending.home.li-2">
                  Put the{' '}
                  <Plural value={articlesInTransit.length} one="item sheet" other="item sheets" />{' '}
                  inside the package and stick the shipping label on the package
                </Trans>
              </li>
              <li>
                {shipment.handover === 'dropoff' && (
                  <Trans id="client.request.transit.pending.home.li-3.dropoff">
                    Take the package to a {shipmentCarrierName} drop-off point
                  </Trans>
                )}
                {shipment.handover === 'pickup' && (
                  <Trans id="client.request.transit.pending.home.li-3.pickup">
                    Wait for the carrier to pick up the package at your address
                  </Trans>
                )}
              </li>
            </ol>
            {shipment.handover === 'dropoff' && !!shipmentCarrier?.dropoffPoints && (
              <Button variant="link" target="_blank" href={shipmentCarrier.dropoffPoints}>
                <Trans id="client.request.transit.pending.home.drop-off-points">
                  View drop-off points
                </Trans>
                <IconExternalLink />
              </Button>
            )}
          </Stack>
        </Stack>

        <Stack row={!isMobile} gap="1rem">
          <Button
            ref={ref}
            variant="brand"
            size="large"
            disabled={!shipment || isLoadingShipment || isPendingShipShipment}
            href={
              shipment.shippingDeclaredAt
                ? clientToken
                  ? `${config.apiUrl}/shipments/${shipment.id}/media?shippingLabel=1&recap=1&client-token=${clientToken}`
                  : `${config.apiUrl}/shipments/${shipment.id}/media?shippingLabel=1&recap=1&store-token=${storeToken}`
                : undefined
            }
            target="_blank"
            onPress={() => {
              if (!shipment?.shippingDeclaredAt && !!shipment) {
                setShowError(false);
                shipShipment({ id: shipment.id }).catch(() => {
                  setShowError(true);
                });
              }
            }}
            style={{ flex: 1 }}
          >
            <IconPrint />
            <Trans id="client.request.transit.pending.home.action.shipping-sheets">
              Print shipping sheets
            </Trans>
          </Button>
        </Stack>

        {showError && (
          <span className="text-danger paragraph-100-regular">
            {afterSalesServiceEmail ? (
              <Trans id="client.request.transit.error.support-email">
                An error occurred, please contact support at{' '}
                <Link
                  className="color-tertiary-700"
                  href={`mailto:${afterSalesServiceEmail}`}
                  target="_blank"
                >
                  {afterSalesServiceEmail}
                </Link>
              </Trans>
            ) : (
              <Trans id="client.request.transit.error.support">
                An error occurred, please contact support
              </Trans>
            )}
          </span>
        )}
      </Box>
    </Stack>
  );
};
