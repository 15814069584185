import { Trans } from '@lingui/react/macro';

import FoldableSection from '@/design_system/FoldableSection';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import { NewRequestArticles } from '@/routes/Requests/New/components/NewRequestArticles';

const Articles = () => {
  const { errors, workflow } = useRequestContext();

  return (
    <FoldableSection
      label={
        <h2 className="headline-400-bold">
          {workflow?.config.maxArticles === 1 ? (
            <Trans id="requests.new.articles.title.single">Item</Trans>
          ) : (
            <Trans id="requests.new.articles.title">Items</Trans>
          )}
        </h2>
      }
      invalid={errors.articles?.hasError}
    >
      <NewRequestArticles />
    </FoldableSection>
  );
};

export default Articles;
