import { Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityDetailedContent,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import Stack from '@/design_system/Stack';
import IconStar from '@/icons/Star.svg';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import { useCurrentSession } from '@/services/auth';

export const FeedbackSubmittedActivity = ({
  activity,
}: {
  activity: ActivityOfType<'feedback_submitted'>;
}) => {
  const { isWorkshop } = useCurrentSession();
  const { request } = useRequestContext();

  return (
    <BaseActivity
      createdAt={activity.createdAt}
      icon={<IconStar />}
      message={
        <div style={{ display: 'inline-flex', flexDirection: 'row', gap: '0.25rem' }}>
          <BaseActivityText>
            <Trans id="activities.feedback_submitted.message">
              <strong>The client</strong> left a review
            </Trans>
          </BaseActivityText>
          {isWorkshop ? (
            <Stack
              row
              style={{ color: 'var(--color-warning-500)', height: '22px', display: 'inline-flex' }}
              alignItems="center"
            >
              {([1, 2, 3, 4, 5] as const).map((rating) => (
                <IconStar key={rating} filled={rating <= (request.feedback?.quality ?? 0)} />
              ))}
            </Stack>
          ) : (
            <>
              <Stack
                row
                style={{
                  color: 'var(--color-warning-500)',
                  height: '22px',
                  display: 'inline-flex',
                }}
                alignItems="center"
              >
                {([1, 2, 3, 4, 5] as const).map((rating) => (
                  <IconStar key={rating} filled={rating <= (activity.data.global ?? 0)} />
                ))}
              </Stack>
              <BaseActivityText>
                {activity.data.acceptContact ? (
                  <Trans id="activities.feedback_submitted.accept_contact">
                    and has accepted to be contacted
                  </Trans>
                ) : (
                  <Trans id="activities.feedback_submitted.do_not_accept_contact">
                    and doesn&apos;t want to be contacted
                  </Trans>
                )}
              </BaseActivityText>
            </>
          )}
        </div>
      }
    >
      {!isWorkshop && (
        <Stack style={{ flex: 1, paddingTop: '0.25rem' }} gap="0.5rem">
          <BaseActivityText>
            {request.feedback?.quality != null && (
              <span>
                <Trans id="activities.feedback_submitted.quality">Quality:</Trans>{' '}
                {Number(request.feedback.quality.toFixed(2))}/5
                {' • '}
              </span>
            )}
            {activity.data.speed != null && (
              <span>
                <Trans id="activities.feedback_submitted.speed">Speed:</Trans> {activity.data.speed}
                /5
                {' • '}
              </span>
            )}
            {activity.data.communication != null && (
              <span>
                <Trans id="activities.feedback_submitted.communication">Communication:</Trans>{' '}
                {activity.data.communication}/5
              </span>
            )}
          </BaseActivityText>
          {!!activity.data.comment && (
            <BaseActivityDetailedContent>{activity.data.comment}</BaseActivityDetailedContent>
          )}
        </Stack>
      )}
    </BaseActivity>
  );
};
