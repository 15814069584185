import { PropsWithChildren } from 'react';

import config from '@/config';
import Stack from '@/design_system/Stack';
import { useCurrentOrganization } from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';

import './BrandCreationLayout.css';

const { block, element } = createBEMClasses('brand-creation-layout');

export const BrandCreationLayout = ({ children }: PropsWithChildren) => {
  return <div className={block()}>{children}</div>;
};

export const BrandCreationLayoutHeader = ({ children }: PropsWithChildren) => {
  const [organization] = useCurrentOrganization();

  if (!organization) {
    return null;
  }

  return (
    <header className={element('header')}>
      <div
        className={element('header__brand-color')}
        style={{ background: organization.config.appStyle.headerColor }}
      />
      <div className={element('header__content')}>
        <Stack row gap="1rem" alignItems="center" justifyContent="space-between">
          <img
            src={`${config.apiUrl}/organizations/${organization.slug}/logo`}
            alt=""
            style={{
              maxWidth: '5rem',
              maxHeight: '2.5rem',
            }}
          />
          {children}
        </Stack>
      </div>
    </header>
  );
};
