import { Trans } from '@lingui/react/macro';

import Stack from '@/design_system/Stack';
import { BrandRequestMainContent } from '@/layouts/Brand/BrandRequestLayout/BrandRequestLayout';
import { ClientRequestWithRelations } from '@/models/request';
import EstimateDisclaimer from '@/routes/Brand/components/EstimateDisclaimer';
import ClientArticlesTable from '@/routes/Brand/Requests/Request/components/shared/ClientArticlesTable';
import ClientStepper from '@/routes/Brand/Requests/Request/components/shared/ClientStepper';
import IconSuccess from '@/routes/Brand/Requests/Request/components/shared/IconSuccess';

const Estimation = ({ request }: { request: ClientRequestWithRelations }) => {
  const showPrice = !!request.articles[0]?.steps?.find((step) => step.step === 'creation')?.config
    ?.requirePrice;
  const hideStepper = request.isArchivedAndExportedToZendesk;

  return (
    <BrandRequestMainContent>
      <Stack gap="1rem">
        {!hideStepper && <ClientStepper request={request} />}
        <Stack row gap="0.5rem" alignItems="center">
          <IconSuccess />
          <p className="paragraph-50-regular paragraph-100-regular-mobile">
            {!request.client && (
              <Trans id="store.request.estimation.message.store">
                The request has been successfully received by our team. We will update you on the
                next steps of the process.
              </Trans>
            )}
            {!!request.client && (
              <Trans id="store.request.estimation.message.client">
                The request has been successfully received by our team. We will update you and the
                client on the next steps of the process.
              </Trans>
            )}
          </p>
        </Stack>
        <ClientArticlesTable
          request={request}
          mode="defect"
          showPrice={showPrice}
          hideStepper={hideStepper}
        />
        {!request.isArchived && !!request.client && <EstimateDisclaimer />}
      </Stack>
    </BrandRequestMainContent>
  );
};

export default Estimation;
