export const formatList = (list: string[], options?: Intl.ListFormatOptions) => {
  let result: string;

  if ('ListFormat' in Intl) {
    result = new Intl.ListFormat((navigator.languages as string[]) ?? 'en-GB', options).format(
      list
    );
  } else {
    // Fallback for browsers that don't support Intl.ListFormat
    result = list.join(', ');
  }

  return result;
};
