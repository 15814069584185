import { useState } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router';

import { ClientCommunicationDrawer } from '@/components/comments/ClientCommunicationDrawer/ClientCommunicationDrawer';
import { BrandRequestContextProvider } from '@/layouts/Brand/BrandRequestContext';
import {
  BrandRequestLayout,
  BrandRequestMain,
  BrandRequestMainHeader,
  BrandRequestSidebar,
  BrandRequestSidebarContent,
  BrandRequestSidebarFooter,
} from '@/layouts/Brand/BrandRequestLayout/BrandRequestLayout';
import { useClientRequest } from '@/models/request';
import { useWorkflow } from '@/models/workflow';
import { useClientToken, useFlags, useStoreToken } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

import ClientRequestView from './components/ClientRequestView';
import { ContactAfterSales } from './components/shared/ContactAfterSales';
import { RequestInfo } from './components/shared/RequestInfo';
import StoreRequestView from './components/StoreRequestView';

const ClientRequest = () => {
  const [searchParams] = useSearchParams();
  const { isMobile } = useViewPort();
  const { id } = useParams();
  const { flags } = useFlags();

  const { data: request, isLoading } = useClientRequest(id);
  const { data: workflow } = useWorkflow(request?.workflowId);
  const clientToken = useClientToken();
  const storeToken = useStoreToken();
  const [isClientCommunicationDrawerOpen, setIsClientCommunicationDrawerOpen] = useState(
    searchParams.get('openClientCommunication') === 'true'
  );

  if (isLoading) {
    return (
      <BrandRequestLayout>
        {!isMobile && <BrandRequestSidebar />}
        <BrandRequestMain>{isMobile && <BrandRequestMainHeader />}</BrandRequestMain>
      </BrandRequestLayout>
    );
  }

  if (!id || !request || (!clientToken && !storeToken)) {
    return <Navigate to="../error" />;
  }

  const showContactAfterSales = flags['enable-client-communication'] && !!clientToken;

  return (
    <BrandRequestContextProvider request={request} workflow={workflow}>
      <BrandRequestLayout>
        {!isMobile && (
          <>
            <BrandRequestSidebar>
              <BrandRequestSidebarContent>
                <RequestInfo />
              </BrandRequestSidebarContent>
              {showContactAfterSales && (
                <BrandRequestSidebarFooter>
                  <ContactAfterSales
                    onPress={() => {
                      setIsClientCommunicationDrawerOpen(true);
                    }}
                  />
                </BrandRequestSidebarFooter>
              )}
            </BrandRequestSidebar>
          </>
        )}

        <BrandRequestMain>
          {isMobile && (
            <BrandRequestMainHeader>
              {showContactAfterSales && (
                <ContactAfterSales
                  onPress={() => {
                    setIsClientCommunicationDrawerOpen(true);
                  }}
                />
              )}
            </BrandRequestMainHeader>
          )}
          {!!clientToken && <ClientRequestView />}
          {!!storeToken && <StoreRequestView />}
        </BrandRequestMain>

        <ClientCommunicationDrawer
          request={request}
          isOpen={isClientCommunicationDrawerOpen}
          close={() => setIsClientCommunicationDrawerOpen(false)}
        />
      </BrandRequestLayout>
    </BrandRequestContextProvider>
  );
};

export default ClientRequest;
