import { useState } from 'react';
import { getLocalTimeZone, today } from '@internationalized/date';
import { Trans, useLingui } from '@lingui/react/macro';
import { parseISO } from 'date-fns';

import Box from '@/design_system/Box';
import InputDate from '@/design_system/InputDate';
import { Label } from '@/design_system/Label/Label';
import { RadioItemYesNo } from '@/design_system/Radio/RadioItem/RadioItem';
import Stack from '@/design_system/Stack';
import { useUpdateArticle } from '@/models/article';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import ProofOfPurchase from '@/routes/Requests/Request/Article/ProofOfPurchase';
import useViewPort from '@/utils/useViewport';

const Warranty = () => {
  const { t } = useLingui();

  const { isMobile } = useViewPort();

  const { request, article, view, errors } = useArticleContext();

  const { mutate: updateArticle } = useUpdateArticle({
    articleId: article.id,
    requestId: request.id,
  });

  const [purchaseDate, setPurchaseDate] = useState(article.purchaseDate ?? null);

  const handleBlurInputDate = () => {
    if (purchaseDate && parseISO(purchaseDate) <= new Date()) {
      if (purchaseDate !== article.purchaseDate) {
        updateArticle({
          data: {
            purchaseDate,
          },
        });
      }
    } else {
      updateArticle({
        data: {
          purchaseDate: null,
        },
      });
      setPurchaseDate(null);
    }
  };

  if (!view.warranty.shown || !view.warranty.editable) {
    return null;
  }

  return (
    <Stack gap="0.5rem">
      <h2 className="headline-400-bold">
        <Trans id="requests.new.articles.drawer.warranty.title">Warranty</Trans>
      </h2>

      <Box
        padding={isMobile ? '1rem' : '1rem 1.5rem'}
        gap="1rem"
        style={{
          backgroundColor: 'var(--color-neutral-0)',
        }}
      >
        <Stack gap="1rem">
          <Label
            label={t({
              id: 'article.form.warranty.label',
              message: 'Is the item still under warranty?',
            })}
          >
            <RadioItemYesNo
              name="warranty"
              value={article.warranty ? 'yes' : 'no'}
              onChange={(value) =>
                updateArticle({
                  data: {
                    warranty: value === 'yes',
                  },
                })
              }
            />
          </Label>

          {article.warranty && (
            <>
              <InputDate
                label={t({ id: 'article.form.purchase-date.label', message: 'Purchase date' })}
                value={purchaseDate}
                onChange={(date) => setPurchaseDate(date ?? null)}
                maxValue={today(getLocalTimeZone())}
                style={{ flex: 1 }}
                onBlur={handleBlurInputDate}
                error={
                  errors.warranty?.missingPurchaseDate
                    ? t({
                        id: 'article.form.purchase-date.error.missing',
                        message: 'A purchase date is required',
                      })
                    : errors.warranty?.invalidPurchaseDate
                      ? t({
                          id: 'article.form.purchase-date.error.invalid',
                          message: 'The purchase date must be in the past',
                        })
                      : undefined
                }
              />
              <ProofOfPurchase />
            </>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default Warranty;
