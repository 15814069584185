import { useEffect } from 'react';
import { Trans } from '@lingui/react/macro';

import { Comment } from '@/components/comments/Comment/Comment';
import { NewComment } from '@/components/comments/NewComment/NewComment';
import Drawer, { DrawerBody, DrawerFooter, DrawerHeader } from '@/design_system/Drawer';
import Stack from '@/design_system/Stack';
import { Comment as IComment } from '@/models/comment/comment';
import {
  type ClientRequestWithRelations,
  type RequestWithRelations,
  useComments,
  useCreateComment,
} from '@/models/request';
import { useClientToken, useCurrentOrganization, useCurrentSession } from '@/services/auth';
import { useScrollIntoView } from '@/utils/useScrollIntoView';

import { ClientCommunicationDrawerEmptyState } from './ClientCommunicationDrawerEmptyState';

type ClientCommunicationDrawerProps = {
  request: RequestWithRelations | ClientRequestWithRelations;
  isOpen: boolean;
  close: () => void;
};

export const ClientCommunicationDrawer = ({
  request,
  isOpen,
  close,
}: ClientCommunicationDrawerProps) => {
  const { currentSession } = useCurrentSession();
  const [currentOrganization] = useCurrentOrganization();
  const clientToken = useClientToken();
  const { mutateAsync: createComment, isPending: isCreateCommentPending } = useCreateComment({
    requestId: request.id,
  });
  const { data: { comments } = { comments: [] } } = useComments({
    requestId: request.id,
    orderByCreatedAt: 'asc',
    visibility: ['client'],
    enabled: isOpen,
  });

  const isReadonly =
    !clientToken &&
    !currentSession?.hasPermission('write_client_communication', [
      {
        organizationId: currentOrganization?.id,
        storeId: null,
      },
      {
        organizationId: currentOrganization?.id,
        storeId: request.store?.id,
      },
    ]);

  const contactName = clientToken ? request.organization.name : request.client?.name;

  return (
    <Drawer isOpen={isOpen} onOpenChange={close} style={{ maxWidth: '40.5rem' }}>
      <DrawerHeader className="headline-300-bold">
        <span>
          <Trans id="request.client-communication-drawer.title">Contact {contactName}</Trans>
        </span>
      </DrawerHeader>

      <DrawerBody style={{ display: 'flex' }}>
        {comments.length ? (
          <CommentsList comments={comments} />
        ) : (
          <ClientCommunicationDrawerEmptyState />
        )}
      </DrawerBody>

      {!isReadonly && (
        <DrawerFooter>
          <Stack
            style={{
              flex: 1,
              minWidth: 0,
            }}
          >
            <NewComment
              currentOrganization={currentOrganization}
              variant="client-communication"
              onCommentCreated={async (data) => {
                await createComment(data);
              }}
              isLoading={isCreateCommentPending}
            />
          </Stack>
        </DrawerFooter>
      )}
    </Drawer>
  );
};

const CommentsList = ({ comments }: { comments: IComment[] }) => {
  const [lastCommentBottomRef, scrollToLastComment] = useScrollIntoView<HTMLDivElement>();

  // On initial render, scroll to the last comment immediately
  useEffect(() => {
    scrollToLastComment({ behavior: 'instant' });
  }, [scrollToLastComment]);

  // Every time a new comment is added, scroll to the last comment smoothly
  useEffect(() => {
    if (!comments.length) {
      return;
    }
    scrollToLastComment();
  }, [scrollToLastComment, comments.length]);

  return (
    <Stack padding="1.5rem" style={{ flex: 1, width: '100%' }}>
      {comments.map((comment) => {
        return (
          <div key={comment.id}>
            <Comment
              comment={comment}
              isReadOnly
              onCommentDeleted={async () => {}}
              onCommentUpdated={async () => {}}
            />
          </div>
        );
      })}
      <div ref={lastCommentBottomRef}></div>
    </Stack>
  );
};
