import { msg } from '@lingui/core/macro';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { Endpoints, ProductL1, ProductL2, ProductL3 } from '@/api';
import IconBuckle from '@/icons/needs/buckle/Buckle.svg';
import IconFemaleBuckle from '@/icons/needs/buckle/Female.svg';
import IconMaleBuckle from '@/icons/needs/buckle/Male.svg';
import IconSewingButton from '@/icons/needs/button/SewingButton.svg';
import IconSnapButton from '@/icons/needs/button/SnapButton.svg';
import IconFabricMaterials from '@/icons/needs/fabric-materials/FabricMaterials.svg';
import IconPilling from '@/icons/needs/fabric-materials/Pilling.svg';
import IconWaterRepellency from '@/icons/needs/fabric-materials/WaterRepellency.svg';
import IconHoles from '@/icons/needs/holes/Holes.svg';
import IconHook from '@/icons/needs/hooks/Hook.svg';
import IconMagnet from '@/icons/needs/hooks/Magnet.svg';
import IconVelcro from '@/icons/needs/hooks/Velcro.svg';
import IconBrandLabel from '@/icons/needs/label/BrandLabel.svg';
import IconLabel from '@/icons/needs/label/Label.svg';
import IconSizeLabel from '@/icons/needs/label/SizeLabel.svg';
import IconSlider from '@/icons/needs/slider-zip/Slider.svg';
import IconSliderZip from '@/icons/needs/slider-zip/SliderZip.svg';
import IconZip from '@/icons/needs/slider-zip/Zip.svg';
import IconNotWaterproofSeam from '@/icons/needs/unstitched-seam/NotWaterproofSeam.svg';
import IconUnstitchedSeam from '@/icons/needs/unstitched-seam/UnstitchedSeam.svg';
import IconWaterproofSeam from '@/icons/needs/unstitched-seam/WaterproofSeam.svg';
import IconHolePocket from '@/icons/needs/unstitched-seam-on-yoke/HolePocket.svg';
import { useFetch } from '@/utils/fetch';

import { Model } from './model';
import { ArticleDefectWithRelations, instanciateArticleDefect } from './request';
import { Translation, useDatabaseTranslation } from './translation';

export class DefectType extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
    this.name = new Translation(data.name);
  }

  id!: string;
  l1!: NeedL1;
  l2!: NeedL2 | null;
  l3!: NeedL3 | null;
  l4!: NeedL4 | null;
  name!: Translation;
  nameId!: string;
  isQuantifiable!: boolean;
  createdAt!: string;
}

export class DefectTypeOrganization extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;
  productL1!: ProductL1[];
  productL2!: ProductL2[];
  productL3!: ProductL3[];
  defectTypeId!: string;
  createdAt!: string;
}

export class ArticleDefect extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;
  stableId!: string;
  defectTypeOrganizationId!: string | null;
  customDescription!: string | null;
  toBeRepaired!: boolean;
  context!: string | null;
  createdAt!: string;
  articleActions!: { id: string }[];
  addedByOrganizationId!: string | null;
  addedByWorkshopId!: string | null;

  get isCustom() {
    return !this.defectTypeOrganizationId;
  }
}

export const instanciateDefectTypeOrganization = (
  defectTypeOrganization: Endpoints['GET /defect-types']['response'][number]
) =>
  new DefectTypeOrganization(defectTypeOrganization).with(
    'defectType',
    new DefectType(defectTypeOrganization.defectType)
  );

export type DefectTypeOrganizationWithRelations = ReturnType<
  typeof instanciateDefectTypeOrganization
>;

export const useDefectTypes = (
  params: Endpoints['GET /defect-types']['query'],
  options?: {
    enabled?: boolean;
    keepPreviousData?: boolean;
  }
) => {
  const fetch = useFetch<Endpoints['GET /defect-types']>();

  return useQuery({
    queryKey: ['defect-types', params],
    queryFn: () =>
      fetch('/defect-types', params).then((defectTypes) =>
        defectTypes.map(instanciateDefectTypeOrganization)
      ),
    enabled: options?.enabled,
    placeholderData: options?.keepPreviousData ? keepPreviousData : undefined,
  });
};

export const useGetDefectName = () => {
  const { _db } = useDatabaseTranslation();

  return (defect: ArticleDefectWithRelations) => {
    if (defect.defectTypeOrganization) {
      return _db(defect.defectTypeOrganization.defectType.name);
    } else {
      return defect.customDescription ?? '';
    }
  };
};

export const useDefectName = (defect: ArticleDefectWithRelations) => {
  const getDefectName = useGetDefectName();

  return getDefectName(defect);
};

/**
 *
 * @returns Returns true if both defects are of the same type
 */
const areDefectsDuplicates = (
  defect1: ArticleDefectWithRelations,
  defect2: ArticleDefectWithRelations
): boolean => {
  return (
    (defect1.isCustom &&
      defect2.isCustom &&
      defect1.customDescription === defect2.customDescription) ||
    (!!defect1.defectTypeOrganizationId &&
      defect1.defectTypeOrganizationId === defect2.defectTypeOrganizationId)
  );
};

/**
 *
 * @param defectToCompare The defect we want to attribute a number to (only if it has duplicate defects)
 * @param defects The list of selected defects
 * @returns A number attributed to a defect (only if it has duplicate defects)
 */
export const computeDuplicateDefectNumber = (
  defectToCompare: ArticleDefectWithRelations,
  defects: ArticleDefectWithRelations[]
): number | undefined => {
  const currentActionAndDuplicates = defects.filter((defect) =>
    areDefectsDuplicates(defectToCompare, defect)
  );

  if (currentActionAndDuplicates.length === 1) {
    return undefined;
  }

  const indexOfActionToCompare = currentActionAndDuplicates.findIndex(
    (defect) => defect.id === defectToCompare.id
  );

  return indexOfActionToCompare + 1;
};

/**
 * Formats a list of defects so that defects of the same type are merged into a single defect for which:
 * - `quantity` is total of merged defects
 * - `ids` is a list of the merged defects' ids
 * - the other fields simply correspond to the first of the merged defects
 *
 * Defects are of the same type if they have the same `customDescription` or `defectTypeOrganizationId`
 */
export const getGroupedDefects = (defects: ArticleDefectWithRelations[]) => {
  return defects.reduce<(ArticleDefectWithRelations & { ids: string[]; quantity: number })[]>(
    (acc, defect) => {
      const existingDefect = acc.find((accDefect) => areDefectsDuplicates(defect, accDefect));

      if (!existingDefect) {
        acc.push(
          Object.assign(instanciateArticleDefect(defect, defect.media), {
            ids: [defect.id],
            quantity: 1,
          })
        );
      } else {
        existingDefect.ids = [...existingDefect.ids, defect.id];
        existingDefect.quantity = existingDefect.quantity + 1;
      }

      return acc;
    },
    []
  );
};

export const NEED_CATEGORIES = [
  {
    id: 'care',
    label: msg({ id: 'action-type.category.care', message: 'Care' }),
    nextLabel: msg({
      id: 'action-type.category.care.next',
      message: 'What needs to be attended to?',
    }),
    categories: [
      {
        id: 'care-accessories',
        label: msg({ id: 'action-type.category.care.care-accessories', message: 'Accessories' }),
        nextLabel: msg({
          id: 'action-type.category.care.care-accessories.next',
          message: 'What kind of accessory is it?',
        }),
        categories: [
          {
            id: 'care-clasp',
            label: msg({
              id: 'action-type.category.care.care-accessories.care-clasp',
              message: 'Clasp',
            }),
            categories: [],
          },
        ],
      },
    ],
  },
  {
    id: 'repair',
    label: msg({ id: 'action-type.category.repair', message: 'Repair' }),
    nextLabel: msg({
      id: 'action-type.category.repair.next',
      message: 'What needs to be repaired?',
    }),
    categories: [
      {
        id: 'button',
        label: msg({ id: 'action-type.category.repair.button', message: 'Button' }),
        icon: <IconSewingButton />,
        nextLabel: msg({
          id: 'action-type.category.repair.button.next',
          message: 'What kind of button is it?',
        }),
        categories: [
          {
            id: 'snap-button',
            label: msg({
              id: 'action-type.category.repair.button.snap-button',
              message: 'Snap',
            }),
            icon: <IconSnapButton />,
            nextLabel: msg({
              id: 'action-type.category.repair.button.snap-button.next',
              message: 'What kind of snap button is it?',
            }),
            categories: [
              {
                id: 'one-aspect',
                label: msg({
                  id: 'action-type.category.repair.button.snap-button.one-aspect',
                  message: 'One aspect',
                }),
              },
              {
                id: 'double-aspect',
                label: msg({
                  id: 'action-type.category.repair.button.snap-button.double-aspect',
                  message: 'Double aspect',
                }),
              },
            ],
          },
          {
            id: 'sewing-button',
            label: msg({
              id: 'action-type.category.repair.button.sewing-button',
              message: 'Sewing button',
            }),
            icon: <IconSewingButton />,
            categories: [],
          },
        ],
      },
      {
        id: 'hooks',
        label: msg({ id: 'action-type.category.repair.hooks', message: 'Hooks' }),
        icon: <IconHook />,
        nextLabel: msg({
          id: 'action-type.category.repair.hook.next',
          message: 'What kind of hook is it?',
        }),
        categories: [
          {
            id: 'hook',
            label: msg({
              id: 'action-type.category.repair.hooks.hook',
              message: 'Hook',
            }),
            icon: <IconHook />,
            categories: [],
          },
          {
            id: 'velcro',
            label: msg({
              id: 'action-type.category.repair.hooks.velcro',
              message: 'Velcro',
            }),
            icon: <IconVelcro />,
            categories: [],
          },
          {
            id: 'magnet',
            label: msg({
              id: 'action-type.category.repair.hooks.magnet',
              message: 'Magnet',
            }),
            icon: <IconMagnet />,
            categories: [],
          },
        ],
      },
      {
        id: 'buckle',
        label: msg({ id: 'action-type.category.repair.buckle', message: 'Buckle' }),
        icon: <IconBuckle />,
        nextLabel: msg({
          id: 'action-type.category.repair.buckle.next',
          message: 'What kind of buckle is it?',
        }),
        categories: [
          {
            id: 'male-buckle',
            label: msg({
              id: 'action-type.category.repair.buckle.male',
              message: 'Male buckle',
            }),
            icon: <IconMaleBuckle />,
            categories: [],
          },
          {
            id: 'female-buckle',
            label: msg({
              id: 'action-type.category.repair.buckle.female',
              message: 'Female buckle',
            }),
            icon: <IconFemaleBuckle />,
            categories: [],
          },
        ],
      },
      {
        id: 'label',
        label: msg({ id: 'action-type.category.repair.label', message: 'Label' }),
        icon: <IconLabel />,
        nextLabel: msg({
          id: 'action-type.category.repair.label.next',
          message: 'What kind of label is it?',
        }),
        categories: [
          {
            id: 'size-label',
            label: msg({
              id: 'action-type.category.repair.label.size-label',
              message: 'Size label',
            }),
            icon: <IconSizeLabel />,
            categories: [
              {
                id: 'size-label-add-substitution',
                label: msg({
                  id: 'action-type.category.repair.label.size-label.add-substitution',
                  message: 'Add / Substitution',
                }),
              },
            ],
          },
          {
            id: 'brand-label',
            label: msg({
              id: 'action-type.category.repair.label.brand-label',
              message: 'Brand label',
            }),
            icon: <IconBrandLabel />,
            nextLabel: msg({
              id: 'action-type.category.repair.label.brand-label.next',
              message: 'What is the issue?',
            }),
            categories: [
              {
                id: 'brand-label-removal',
                label: msg({
                  id: 'action-type.category.repair.label.brand-label.removal',
                  message: 'Removal needed',
                }),
              },
              {
                id: 'brand-label-unstitched',
                label: msg({
                  id: 'action-type.category.repair.label.brand-label.unstitched',
                  message: 'Unstitched brand label',
                }),
              },
            ],
          },
          {
            id: 'logo',
            label: msg({
              id: 'action-type.category.repair.label.logo',
              message: 'Logo',
            }),
            categories: [
              {
                id: 'logo-unstitched',
                label: msg({
                  id: 'action-type.category.repair.label.logo.unstitched',
                  message: 'Unstitched logo',
                }),
              },
            ],
          },
        ],
      },
      {
        id: 'slider-zip',
        label: msg({ id: 'action-type.category.repair.slider-zip', message: 'Slider / Zip' }),
        icon: <IconSliderZip />,
        nextLabel: msg({
          id: 'action-type.category.repair.slider-zip.next',
          message: 'Slider or zip?',
        }),
        categories: [
          {
            id: 'hidden-zip',
            label: msg({
              id: 'action-type.category.repair.slider-zip.hidden-zip',
              message: 'Hidden zip',
            }),
            categories: [
              {
                id: 'pocket-zip',
                label: msg({
                  id: 'action-type.category.repair.slider-zip.hidden-zip.pocket-zip',
                  message: 'Pocket zip',
                }),
              },
            ],
          },
          {
            id: 'pull-tab',
            label: msg({
              id: 'action-type.category.repair.slider-zip.pull-tab',
              message: 'Pull tab',
            }),
            categories: [],
          },
          {
            id: 'slider',
            label: msg({
              id: 'action-type.category.repair.slider-zip.slider',
              message: 'Slider',
            }),
            icon: <IconSlider />,
            nextLabel: msg({
              id: 'action-type.category.repair.slider-zip.slider.next',
              message: 'What type of slider is it?',
            }),
            categories: [
              {
                id: 'trousers-bottom-slider',
                label: msg({
                  id: 'action-type.category.repair.slider-zip.slider.trousers-bottom-slider',
                  message: 'Trousers bottom slider',
                }),
              },
              {
                id: 'central-slider',
                label: msg({
                  id: 'action-type.category.repair.slider-zip.slider.central-slider',
                  message: 'Central slider',
                }),
              },
              {
                id: 'pocket-slider',
                label: msg({
                  id: 'action-type.category.repair.slider-zip.slider.pocket-slider',
                  message: 'Pocket slider',
                }),
              },
              {
                id: 'lower-sleeve-slider',
                label: msg({
                  id: 'action-type.category.repair.slider-zip.slider.lower-sleeve-slider',
                  message: 'Lower sleeve slider',
                }),
              },
            ],
          },
          {
            id: 'visible-zip',
            label: msg({
              id: 'action-type.category.repair.slider-zip.visible-zip',
              message: 'Visible zip',
            }),
            icon: <IconZip />,
            nextLabel: msg({
              id: 'action-type.category.repair.slider-zip.visible-zip.next',
              message: 'What type of zip is it?',
            }),
            categories: [
              {
                id: 'central-zip-short',
                label: msg({
                  id: 'action-type.category.repair.slider-zip.visible-zip.central-zip-short',
                  message: 'Short central zip',
                }),
                categories: [],
              },
              {
                id: 'lower-sleeve-zip',
                label: msg({
                  id: 'action-type.category.repair.slider-zip.visible-zip.lower-sleeve-zip',
                  message: 'Lower sleeve zip',
                }),
                categories: [],
              },
              {
                id: 'pocket-zip',
                label: msg({
                  id: 'action-type.category.repair.slider-zip.visible-zip.pocket-zip',
                  message: 'Pocket zip',
                }),
                categories: [],
              },
              {
                id: 'trousers-bottom-zip',
                label: msg({
                  id: 'action-type.category.repair.slider-zip.visible-zip.trousers-bottom-zip',
                  message: 'Trousers bottom zip',
                }),
                categories: [],
              },
              {
                id: 'central-zip-long',
                label: msg({
                  id: 'action-type.category.repair.slider-zip.visible-zip.central-zip-long',
                  message: 'Long central zip',
                }),
                categories: [],
              },
            ],
          },
        ],
      },
      {
        id: 'unstitched-seam',
        label: msg({
          id: 'action-type.category.repair.unstitched-seam',
          message: 'Unstitched seam',
        }),
        icon: <IconUnstitchedSeam />,
        nextLabel: msg({
          id: 'action-type.category.repair.unstitched-seam.next',
          message: 'What kind of seam is it?',
        }),
        categories: [
          {
            id: 'not-waterproof-seam',
            label: msg({
              id: 'action-type.category.repair.unstitched-seam.not-waterproof-seam',
              message: 'Not waterproof seam',
            }),
            icon: <IconNotWaterproofSeam />,
            categories: [
              {
                id: 'not-lined-material',
                label: msg({
                  id: 'action-type.category.repair.unstitched-seam.not-waterproof-seam.not-lined-material',
                  message: 'Not lined material',
                }),
              },
              {
                id: 'lined-material',
                label: msg({
                  id: 'action-type.category.repair.unstitched-seam.not-waterproof-seam.lined-material',
                  message: 'Lined material',
                }),
              },
              {
                id: 'smocked-material',
                label: msg({
                  id: 'action-type.category.repair.unstitched-seam.not-waterproof-seam.smocked-material',
                  message: 'Smocked material',
                }),
              },
            ],
          },
          {
            id: 'waterproof-seam',
            label: msg({
              id: 'action-type.category.repair.unstitched-seam.waterproof-seam',
              message: 'Waterproof seam',
            }),
            icon: <IconWaterproofSeam />,
            categories: [
              {
                id: 'not-lined-material',
                label: msg({
                  id: 'action-type.category.repair.unstitched-seam.waterproof-seam.not-lined-material',
                  message: 'Not lined material',
                }),
              },
              {
                id: 'lined-material',
                label: msg({
                  id: 'action-type.category.repair.unstitched-seam.waterproof-seam.lined-material',
                  message: 'Lined material',
                }),
              },
              {
                id: 'smocked-material',
                label: msg({
                  id: 'action-type.category.repair.unstitched-seam.waterproof-seam.smocked-material',
                  message: 'Smocked material',
                }),
              },
            ],
          },
          {
            id: 'stitching',
            label: msg({
              id: 'action-type.category.repair.unstitched-seam.stitching',
              message: 'Unstitched stitching',
            }),
            categories: [],
          },
        ],
      },
      {
        id: 'fabric-materials',
        label: msg({
          id: 'action-type.category.repair.fabric-materials',
          message: 'Fabric materials',
        }),
        icon: <IconFabricMaterials />,
        nextLabel: msg({
          id: 'action-type.category.repair.fabric-materials.next',
          message: 'What is the issue with the fabric material ?',
        }),
        categories: [
          {
            id: 'color-bleeding',
            label: msg({
              id: 'action-type.category.repair.fabric-materials.color-bleeding',
              message: 'Color bleeding',
            }),
            categories: [],
          },
          {
            id: 'deformation',
            label: msg({
              id: 'action-type.category.repair.fabric-materials.deformation',
              message: 'Deformation',
            }),
            categories: [],
          },
          {
            id: 'discoloration',
            label: msg({
              id: 'action-type.category.repair.fabric-materials.discoloration',
              message: 'Discoloration',
            }),
            categories: [],
          },
          {
            id: 'leather',
            label: msg({
              id: 'action-type.category.repair.fabric-materials.leather',
              message: 'Defected leather',
            }),
            categories: [],
          },
          {
            id: 'membrane',
            label: msg({
              id: 'action-type.category.repair.fabric-materials.membrane',
              message: 'Detached membrane',
            }),
            categories: [],
          },
          {
            id: 'pilling',
            label: msg({
              id: 'action-type.category.repair.fabric-materials.pilling',
              message: 'Pilling',
            }),
            icon: <IconPilling />,
            categories: [],
          },
          {
            id: 'stain',
            label: msg({
              id: 'action-type.category.repair.fabric-materials.stain',
              message: 'Stain',
            }),
            categories: [],
          },
          {
            id: 'water-repellency',
            label: msg({
              id: 'action-type.category.repair.fabric-materials.water-repellency',
              message: 'Water repellency',
            }),
            icon: <IconWaterRepellency />,
            categories: [],
          },
        ],
      },
      {
        id: 'holes',
        label: msg({ id: 'action-type.category.repair.holes', message: 'Holes' }),
        icon: <IconHoles />,
        nextLabel: msg({
          id: 'action-type.category.repair.holes.next',
          message: 'What kind of hole is it?',
        }),
        categories: [
          {
            id: 'edge-cut',
            label: msg({
              id: 'action-type.category.repair.holes.edge-cut',
              message: 'Edge cut',
            }),
            categories: [
              {
                id: 'trouser-bottoms',
                label: msg({
                  id: 'action-type.category.repair.holes.edge-cut.trouser-bottoms',
                  message: 'Trouser bottoms',
                }),
              },
            ],
          },
          {
            id: 're-meshing-hole',
            label: msg({
              id: 'action-type.category.repair.holes.stitch-hole',
              message: 'Re-meshing hole',
            }),
            categories: [],
          },
          {
            id: 'tear-or-hole',
            label: msg({
              id: 'action-type.category.repair.holes.tear-or-hole',
              message: 'Tear or hole',
            }),
            categories: [
              {
                id: 'waterproof-logo',
                label: msg({
                  id: 'action-type.category.repair.holes.tear-or-hole.waterproof-logo',
                  message: 'Waterproof logo patch',
                }),
                categories: [],
              },
              {
                id: 'non-waterproof-logo',
                label: msg({
                  id: 'action-type.category.repair.holes.tear-or-hole.non-waterproof-logo',
                  message: 'Non-waterproof logo patch',
                }),
                categories: [],
              },
              {
                id: 'waterproof-yoke',
                label: msg({
                  id: 'action-type.category.repair.holes.tear-or-hole.waterproof-yoke',
                  message: 'Waterproof yoke',
                }),
                categories: [],
              },
              {
                id: 'non-waterproof-yoke',
                label: msg({
                  id: 'action-type.category.repair.holes.tear-or-hole.non-waterproof-yoke',
                  message: 'Non-waterproof yoke',
                }),
                categories: [],
              },
            ],
          },
        ],
      },
      {
        id: 'unstitched-seam-on-yoke',
        label: msg({
          id: 'action-type.category.repair.unstitched-seam-on-yoke',
          message: 'Unstitched seam on yoke',
        }),
        icon: <IconHolePocket />,
        nextLabel: msg({
          id: 'action-type.category.repair.unstitched-seam-on-yoke.next',
          message: 'What is the issue?',
        }),
        categories: [
          {
            id: 'cut-or-torn-sleeve',
            label: msg({
              id: 'action-type.category.repair.unstitched-seam-on-yoke.cut-or-torn-sleeve',
              message: 'Cut or torn sleeve',
            }),
            categories: [],
          },
          {
            id: 'hole-pocket',
            label: msg({
              id: 'action-type.category.repair.unstitched-seam-on-yoke.hole-pocket',
              message: 'Hole pocket',
            }),
            icon: <IconHolePocket />,
            categories: [],
          },
        ],
      },
      {
        id: 'accessories',
        label: msg({
          id: 'action-type.category.repair.accessories',
          message: 'Accessories',
        }),
        nextLabel: msg({
          id: 'action-type.category.repair.accessories.next',
          message: 'What kind of accessory is it?',
        }),
        categories: [
          {
            id: 'clasp',
            label: msg({
              id: 'action-type.category.repair.accessories.clasp',
              message: 'Clasp',
            }),
            categories: [
              {
                id: 'short-zip',
                label: msg({
                  id: 'action-type.category.repair.accessories.clasp.short-zip',
                  message: 'Short zip',
                }),
              },
              {
                id: 'long-zip',
                label: msg({
                  id: 'action-type.category.repair.accessories.clasp.long-zip',
                  message: 'Long zip',
                }),
              },
              {
                id: 'slider-shoes',
                label: msg({
                  id: 'action-type.category.repair.accessories.clasp.slider-shoes',
                  message: 'Slider',
                }),
              },
            ],
          },
        ],
      },
      {
        id: 'inner-shank',
        label: msg({
          id: 'action-type.category.repair.inner-shank',
          message: 'Inner shank',
        }),
        categories: [
          {
            id: 'slide',
            label: msg({
              id: 'action-type.category.repair.inner-shank.slide',
              message: 'Anti-slip',
            }),
            categories: [],
          },
        ],
      },
      {
        id: 'outer-shank',
        label: msg({
          id: 'action-type.category.repair.outer-shank',
          message: 'Outer shank',
        }),
        categories: [
          {
            id: 'elastic',
            label: msg({
              id: 'action-type.category.repair.outer-shank.elastic',
              message: 'Elastic',
            }),
            categories: [],
          },
        ],
      },
      {
        id: 'shape',
        label: msg({
          id: 'action-type.category.repair.shape',
          message: 'Shape',
        }),
        categories: [
          {
            id: 'reshape',
            label: msg({
              id: 'action-type.category.repair.shape.reshape',
              message: 'Reshaping',
            }),
            categories: [],
          },
        ],
      },
      {
        id: 'sole',
        label: msg({
          id: 'action-type.category.repair.sole',
          message: 'Sole',
        }),
        nextLabel: msg({
          id: 'action-type.category.repair.sole.next',
          message: 'What is the issue?',
        }),
        categories: [
          {
            id: 'regluing',
            label: msg({
              id: 'action-type.category.repair.sole.regluing',
              message: 'Sole detached',
            }),
            categories: [],
          },
          {
            id: 'wear-pad',
            label: msg({
              id: 'action-type.category.repair.sole.wear-pad',
              message: 'Worn sole pad',
            }),
            categories: [],
          },
        ],
      },
      {
        id: 'pocket-net',
        label: msg({
          id: 'action-type.category.repair.pocket-net',
          message: 'Pocket net',
        }),
        categories: [],
      },
    ],
  },
  {
    id: 'alteration',
    label: msg({ id: 'action-type.category.alteration', message: 'Alteration' }),
    categories: [],
  },
  {
    id: 'customization',
    label: msg({ id: 'action-type.category.customization', message: 'Customization' }),
    categories: [],
  },
] as const;

export type NeedL1 = (typeof NEED_CATEGORIES)[number]['id'];

export const NEED_CATEGORIES_L2 = NEED_CATEGORIES.flatMap((l1) => [...l1.categories]);
export type NeedL2 = (typeof NEED_CATEGORIES_L2)[number]['id'];

export const NEED_CATEGORIES_L3 = NEED_CATEGORIES_L2.flatMap((l2) => [...l2.categories]);
export type NeedL3 = (typeof NEED_CATEGORIES_L3)[number]['id'];

export const NEED_CATEGORIES_L4 = NEED_CATEGORIES_L3.flatMap((l3) => [...l3.categories]);
export type NeedL4 = (typeof NEED_CATEGORIES_L4)[number]['id'];
