import { Trans } from '@lingui/react/macro';

import AlertBar from '@/design_system/AlertBar';
import Stack from '@/design_system/Stack';
import IconTruck from '@/icons/Truck.svg';
import { RequestWithRelations } from '@/models/request';
import { useCurrentSession } from '@/services/auth';

import { PendingClientActionReminder } from '../../PendingClientActionReminder/PendingClientActionReminder';

export const TransitStepMessage = ({ request }: { request: RequestWithRelations }) => {
  const { currentSession } = useCurrentSession();

  const areAllArticlesInPendingClientPickup = request.articles.every(
    (article) =>
      article.step?.step === 'transit' &&
      !!article.atStoreId &&
      article.toClient &&
      article.step?.config.clientPickup
  );

  const canSendPickupReminder = currentSession?.hasPermission('write_client_communication', [
    {
      organizationId: request.organizationId,
      storeId: null,
    },
    {
      organizationId: request.organizationId,
      storeId: request.storeId,
    },
  ]);

  return (
    <AlertBar
      type="info"
      size="large"
      icon={<IconTruck />}
      title={
        <Trans id="requests.pending-client-delivery-message.title">Waiting for client pickup</Trans>
      }
    >
      <Stack gap="0.5rem">
        <p>
          {areAllArticlesInPendingClientPickup || request.isInLegacyPendingClientDeliveryStep ? (
            <Trans id="requests.pending-client-delivery-message.text">
              The package is at the store and the client has been notified that they can pick up the
              items.
            </Trans>
          ) : (
            <Trans id="requests.pending-client-delivery-message.text.some">
              Some items are at the store and the client has been notified that they can pick them
              up.
            </Trans>
          )}
          {canSendPickupReminder && (
            <>
              {' '}
              <Trans id="requests.pending-client-delivery-message.text.reminder">
                If they don&apos;t come, feel free to send them an email reminder.
              </Trans>
            </>
          )}
        </p>
        {canSendPickupReminder && <PendingClientActionReminder id={request.id} type="pickup" />}
      </Stack>
    </AlertBar>
  );
};
