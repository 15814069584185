import { useState } from 'react';
import { Plural, Trans, useLingui } from '@lingui/react/macro';
import cn from 'classnames';

import { DefectPhotos } from '@/components/DefectPhotos';
import Badge from '@/design_system/Badge';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button/Button';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import {
  Body,
  Cell,
  Column,
  Footer,
  Header,
  Row,
  Table,
  TextAreaCell,
  ToggleCell,
} from '@/design_system/Table/Table';
import IconAdd from '@/icons/Add.svg';
import IconDone from '@/icons/Done.svg';
import IconEdit from '@/icons/Edit.svg';
import IconTools from '@/icons/Tools.svg';
import IconTrash from '@/icons/Trash.svg';
import { TEMPORARY_ID_PREFIX, useDeleteDefect, useUpdateDefect } from '@/models/article';
import { computeDuplicateDefectNumber, useDefectName } from '@/models/defectType';
import { ArticleDefectWithRelations } from '@/models/request';
import { useWorkshop } from '@/models/workshop';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import { useCurrentSession } from '@/services/auth';
import { areSimilar } from '@/utils/array';
import { createBEMClasses } from '@/utils/classname';
import useViewPort from '@/utils/useViewport';

import { CreateDefectDialogs } from './CreateDefectDialogs';
import { CreateEditDefectDialog } from './CreateEditDefectDialog';
import { DefectTypeSearchSelect } from './DefectTypeSearchSelect';

import './DefectsTable.css';

const { element } = createBEMClasses('defects-table');

export const DefectsTable = () => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();

  const [showAddDefectOptions, setShowAddDefectOptions] = useState(false);

  const { article, view } = useArticleContext();

  const defects = article.snapshot.articleDefects;
  const previousDefects = article.previousSnapshot?.articleDefects ?? [];

  const addedDefects = defects.filter(
    (defect) => !previousDefects.some((prevDefect) => prevDefect.stableId === defect.stableId)
  );

  const showContextColumn =
    view.services.defects.editable ||
    defects.some((defect) => !!defect.context) ||
    previousDefects.some((defect) => !!defect.context);

  return (
    <Stack gap="0.25rem">
      <p className="label-100 text-primary">
        {view.services.defects.editable ? (
          <Trans id="defects-table.title.label">What defects have been noted?</Trans>
        ) : (
          <Trans id="defects-table.title.label-readonly">Noted defects</Trans>
        )}
      </p>
      {isMobile ? (
        <Stack gap="0.5rem">
          {[...previousDefects, ...defects].length === 0 ? (
            <span className="paragraph-100-regular text-secondary">
              <Trans id="defects-table.column.header.no-defect-added">No defects added yet</Trans>
            </span>
          ) : (
            <>
              {previousDefects.map((previousDefect) => {
                const defect = defects.find(
                  (defect) => defect.stableId === previousDefect.stableId
                );

                return (
                  <DefectCard
                    key={previousDefect.id}
                    defect={defect}
                    previousDefect={previousDefect}
                    duplicateDefectNumber={
                      defect ? computeDuplicateDefectNumber(defect, defects) : undefined
                    }
                    isDeleted={!defect}
                  />
                );
              })}
              {addedDefects.map((defect) => (
                <DefectCard
                  key={defect.id}
                  defect={defect}
                  duplicateDefectNumber={computeDuplicateDefectNumber(defect, defects)}
                  isNew={previousDefects.length !== 0}
                />
              ))}
            </>
          )}

          {view.services.defects.editable && (
            <>
              <AddDefectButton setShowAddDefectOptions={setShowAddDefectOptions} />
              <CreateDefectDialogs
                isOpen={showAddDefectOptions}
                setIsOpen={setShowAddDefectOptions}
              />
            </>
          )}
        </Stack>
      ) : (
        <Table
          ariaLabel={t({
            id: 'defects-table.title.label',
            message: 'What defects have been noted?',
          })}
          bordered
          variant="grid"
          columnWidths={[
            'minmax(200px, 1fr)',
            showContextColumn && 'minmax(200px, 1fr)',
            'minmax(200px, 1fr)',
            'minmax(auto, 120px)',
            view.services.defects.editable && 'auto',
          ]}
        >
          <Header>
            <Row>
              {[...previousDefects, ...defects].length === 0 ? (
                <Cell isWholeRow>
                  <span className="paragraph-200-regular">
                    <Trans id="defects-table.column.header.no-defect-added">
                      No defects added yet
                    </Trans>
                  </span>
                </Cell>
              ) : (
                <>
                  <Column>
                    <Trans id="defects-table.column.header.defect">Defect</Trans>
                  </Column>
                  {showContextColumn && (
                    <Column>
                      <span>
                        <Trans id="defects-table.column.header.context">Context</Trans>
                        {view.services.defects.editable && (
                          <span className="text-disabled">
                            {' '}
                            <Trans id="defects-table.column.header.context.optional">
                              (optional)
                            </Trans>
                          </span>
                        )}
                      </span>
                    </Column>
                  )}
                  <Column>
                    <Trans id="defects-table.column.header.defect-photos">Defect photos</Trans>
                  </Column>
                  <Column justifyContent="center">
                    <Trans id="defects-table.column.header.to-be-repaird">To be repaired?</Trans>
                  </Column>
                  {view.services.defects.editable && <Column justifyContent="flex-end" />}
                </>
              )}
            </Row>
          </Header>
          <Body>
            {previousDefects.map((previousDefect) => {
              const defect = defects.find((defect) => defect.stableId === previousDefect.stableId);

              return (
                <DefectRow
                  key={previousDefect.id}
                  defect={defect}
                  previousDefect={previousDefect}
                  duplicateDefectNumber={
                    defect ? computeDuplicateDefectNumber(defect, defects) : undefined
                  }
                  isDeleted={!defect}
                  showContextColumn={showContextColumn}
                />
              );
            })}
            {addedDefects.map((defect) => (
              <DefectRow
                key={defect.id}
                defect={defect}
                isNew={previousDefects.length !== 0}
                duplicateDefectNumber={computeDuplicateDefectNumber(defect, defects)}
                showContextColumn={showContextColumn}
              />
            ))}
          </Body>
          {view.services.defects.editable && (
            <Footer>
              <Row>
                <Cell isWholeRow>
                  {!showAddDefectOptions ? (
                    <AddDefectButton setShowAddDefectOptions={setShowAddDefectOptions} />
                  ) : (
                    <DefectTypeSearchSelect onBlur={() => setShowAddDefectOptions(false)} />
                  )}
                </Cell>
              </Row>
            </Footer>
          )}
        </Table>
      )}

      <DefectsTableErrors />
    </Stack>
  );
};

const DefectRow = ({
  defect,
  previousDefect,
  duplicateDefectNumber,
  isNew,
  isDeleted,
  showContextColumn,
}: {
  defect?: ArticleDefectWithRelations;
  previousDefect?: ArticleDefectWithRelations;
  duplicateDefectNumber?: number;
  isNew?: boolean;
  isDeleted?: boolean;
  showContextColumn: boolean;
}) => {
  const { view } = useArticleContext();

  if (!defect && !previousDefect) {
    return null;
  }

  return (
    <Row
      className={element('row', {
        new: isNew,
        deleted: isDeleted,
      })}
    >
      <DefectNameCell
        defect={defect}
        previousDefect={previousDefect}
        duplicateDefectNumber={duplicateDefectNumber}
        isDeleted={isDeleted}
      />
      {showContextColumn && <DefectContextCell defect={defect} previousDefect={previousDefect} />}
      {view.services.defects.fields.defectPhoto.shown && (
        <DefectPhotosCell defect={defect} previousDefect={previousDefect} />
      )}
      <DefectToBeRepairedCell defect={defect} previousDefect={previousDefect} />
      {view.services.defects.editable && (defect ? <DeleteDefectCell defect={defect} /> : <Cell />)}
    </Row>
  );
};

const DefectCard = ({
  defect,
  previousDefect,
  duplicateDefectNumber,
  isNew,
  isDeleted,
}: {
  defect?: ArticleDefectWithRelations;
  previousDefect?: ArticleDefectWithRelations;
  duplicateDefectNumber?: number;
  isNew?: boolean;
  isDeleted?: boolean;
}) => {
  const { view: articleView, errors } = useArticleContext();

  const defectOrPreviousDefect = defect ?? previousDefect!;

  const defectName = useDefectName(defectOrPreviousDefect);

  const hasError = !!errors.services?.defects?.defects?.find((def) => defect?.id === def.id)
    ?.hasError;

  return (
    <Box
      padding="0"
      ariaLabel={defectName}
      style={
        hasError
          ? {
              border: '1px solid var(--color-danger-700)',
            }
          : undefined
      }
      className={element('card', {
        new: isNew,
        deleted: isDeleted,
      })}
    >
      <Stack padding="16px" gap="0.75rem" style={{ borderRadius: '8px' }}>
        {isDeleted && (
          <span className="visually-hidden">
            <Trans id="article.form.actions.deleted-defect">Deleted defect</Trans>
          </span>
        )}

        <CardDefectName
          defect={defect}
          previousDefect={previousDefect}
          duplicateDefectNumber={duplicateDefectNumber}
        />
        <CardDefectContext defect={defect} previousDefect={previousDefect} />
        <hr />
        <Stack gap="0.5rem">
          {articleView.services.defects.fields.defectPhoto.shown && (
            <CardDefectPhotos defect={defect} previousDefect={previousDefect} />
          )}
          <Stack gap="0.5rem" row>
            <CardDefectToBeRepaired defect={defect} previousDefect={previousDefect} />
          </Stack>
        </Stack>

        {articleView.services.defects.editable && defect && (
          <Stack gap="0.5rem">
            <EditDefectButton defect={defect} />
            <DeleteDefectButton defect={defect} />
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

const AddDefectButton = ({
  setShowAddDefectOptions,
}: {
  setShowAddDefectOptions: (show: boolean) => void;
}) => {
  return (
    <Button
      size="medium"
      variant="secondary"
      style={{ flex: '0 auto' }}
      onPress={() => setShowAddDefectOptions(true)}
    >
      <IconAdd />
      <Trans id="defects-table.column.footer.add-defect">Add a defect</Trans>
    </Button>
  );
};

const DefectNameCell = ({
  defect,
  previousDefect,
  duplicateDefectNumber,
  isDeleted,
}: {
  defect?: ArticleDefectWithRelations;
  previousDefect?: ArticleDefectWithRelations;
  duplicateDefectNumber?: number;
  isDeleted?: boolean;
}) => {
  const { t } = useLingui();
  const { request, article, view, errors } = useArticleContext();

  const defectOrPreviousDefect = defect ?? previousDefect!;

  const { mutateAsync: updateDefect } = useUpdateDefect({
    requestId: request.id,
    articleId: article.id,
  });

  const [customDescription, setCustomDescription] = useState(
    defectOrPreviousDefect.customDescription ?? ''
  );
  const trimmedCustomDescription = customDescription.trim();

  return (
    <TextAreaCell
      cellProps={{
        isReadonly:
          !defect ||
          !defect.isCustom ||
          !view.services.defects.editable ||
          defect.id.startsWith(TEMPORARY_ID_PREFIX),
        isInvalid: errors.services?.defects?.defects?.find((def) => def.id === defect?.id)
          ?.missingDescription,
        className: element('cell', {
          edited:
            defect &&
            previousDefect &&
            defect.customDescription !== previousDefect.customDescription,
        }),
      }}
      ariaLabel={t({
        id: 'defects-table.column.description.custom-defect',
        message: 'Description of custom defect',
      })}
      onChange={(event) => setCustomDescription(event.target.value)}
      value={customDescription}
      onBlur={() => {
        if (!defect) {
          return;
        }

        const currentDescription = defect.customDescription;
        updateDefect({
          defectId: defect.id,
          body: {
            description: trimmedCustomDescription,
          },
        }).catch(() => setCustomDescription(currentDescription ?? ''));
      }}
    >
      <div>
        {isDeleted && (
          <span className="visually-hidden">
            <Trans id="article.form.actions.deleted-defect">Deleted defect</Trans>
          </span>
        )}
        <AddedByBadge defect={defect} previousDefect={previousDefect} />
        <DefectName
          defect={defect}
          previousDefect={previousDefect}
          duplicateDefectNumber={duplicateDefectNumber}
        />
      </div>
    </TextAreaCell>
  );
};

const CardDefectName = ({
  defect,
  previousDefect,
  duplicateDefectNumber,
}: {
  defect?: ArticleDefectWithRelations;
  previousDefect?: ArticleDefectWithRelations;
  duplicateDefectNumber?: number;
}) => {
  const isEdited =
    defect?.isCustom &&
    previousDefect?.isCustom &&
    defect.customDescription !== previousDefect.customDescription;

  return (
    <div
      className={element('card-name', {
        edited: !!isEdited,
      })}
    >
      <AddedByBadge defect={defect} previousDefect={previousDefect} />
      <DefectName
        defect={defect}
        previousDefect={previousDefect}
        duplicateDefectNumber={duplicateDefectNumber}
      />
    </div>
  );
};

const CardDefectContext = ({
  defect,
  previousDefect,
}: {
  defect?: ArticleDefectWithRelations;
  previousDefect?: ArticleDefectWithRelations;
}) => {
  const isEdited = defect && previousDefect && defect.context !== previousDefect.context;

  const defectOrPreviousDefect = defect ?? previousDefect!;

  if (!defectOrPreviousDefect.context) {
    return null;
  }

  return (
    <>
      <hr />
      <div
        className={element('card-context', {
          edited: !!isEdited,
        })}
      >
        &ldquo;{defectOrPreviousDefect.context}&rdquo;
      </div>
    </>
  );
};

const DefectName = ({
  defect,
  previousDefect,
  duplicateDefectNumber,
}: {
  defect?: ArticleDefectWithRelations;
  previousDefect?: ArticleDefectWithRelations;
  duplicateDefectNumber?: number;
}) => {
  const { isMobile } = useViewPort();
  const { errors } = useArticleContext();

  const defectOrPreviousDefect = defect ?? previousDefect!;

  const defectName = useDefectName(defectOrPreviousDefect);

  const isMissingDescription = !!errors.services?.defects?.defects?.find(
    (def) => defect?.id === def.id
  )?.missingDescription;

  const trimmedCustomDescription = (defectOrPreviousDefect.customDescription || '').trim();

  const nbAssociatedActions = defect?.articleActions.length ?? 0;

  return defectOrPreviousDefect.isCustom && !trimmedCustomDescription ? (
    isMobile ? (
      <p
        className="paragraph-100-regular"
        style={isMissingDescription ? { color: 'var(--color-danger-700)' } : undefined}
      >
        -
      </p>
    ) : (
      <span className="text-disabled">
        <Trans id="defects-table.column.defect.custom-placeholder">Write a description...</Trans>
      </span>
    )
  ) : (
    <Stack className="paragraph-100-regular">
      <div className="defects-table__custom-description">
        {defectOrPreviousDefect.isCustom ? trimmedCustomDescription : defectName}{' '}
        {!!duplicateDefectNumber && `#${duplicateDefectNumber}`}
      </div>

      {nbAssociatedActions > 0 && (
        <Stack row gap="0.25rem" alignItems="center" className="text-success">
          <IconDone />
          <span>
            <Trans id="defects-table.column.defect.actions-associated">
              {nbAssociatedActions}{' '}
              <Plural
                value={nbAssociatedActions}
                one="action associated"
                other="actions associated"
              />
            </Trans>
          </span>
        </Stack>
      )}
    </Stack>
  );
};

const AddedByBadge = ({
  defect,
  previousDefect,
}: {
  defect?: ArticleDefectWithRelations;
  previousDefect?: ArticleDefectWithRelations;
}) => {
  const { isWorkshop } = useCurrentSession();
  const { request } = useRequestContext();

  const defectOrPreviousDefect = defect ?? previousDefect!;

  const { data: workshop } = useWorkshop(defectOrPreviousDefect.addedByWorkshopId);

  if (isWorkshop) {
    return null;
  }

  if (!defectOrPreviousDefect.addedByOrganizationId && !defectOrPreviousDefect.addedByWorkshopId) {
    return null;
  }

  return (
    <div style={{ paddingBottom: '4px' }}>
      <Badge color="secondary" size="large" hasBorder borderRadius="soft">
        {defectOrPreviousDefect.addedByOrganizationId && (
          <Trans id="defects-table.column.description.added-by">
            Added by {request.organization.name}
          </Trans>
        )}
        {defectOrPreviousDefect.addedByWorkshopId &&
          (workshop?.name ? (
            <Trans id="defects-table.column.description.added-by">Added by {workshop.name}</Trans>
          ) : (
            <Trans id="defects-table.column.description.added-by-workshop">
              Added by the workshop
            </Trans>
          ))}
      </Badge>
    </div>
  );
};

const DefectPhotosCell = ({
  defect,
  previousDefect,
}: {
  defect?: ArticleDefectWithRelations;
  previousDefect?: ArticleDefectWithRelations;
}) => {
  const { errors, view } = useArticleContext();

  const isEdited =
    defect && previousDefect && !areSimilar(defect.media, previousDefect.media, 'id');

  return (
    <Cell
      stretch
      className={element('cell', {
        edited: !!isEdited,
      })}
    >
      <DefectPhotos
        defect={defect ?? previousDefect}
        size="small"
        isDisabled={!view.services.defects.editable || !defect}
        isInvalid={
          defect &&
          errors.services?.defects?.defects?.find((def) => def.id === defect.id)?.missingPhotos
        }
      />
    </Cell>
  );
};

const CardDefectPhotos = ({
  defect,
  previousDefect,
}: {
  defect?: ArticleDefectWithRelations;
  previousDefect?: ArticleDefectWithRelations;
}) => {
  const { errors } = useArticleContext();

  const isEdited =
    defect && previousDefect && !areSimilar(defect.media, previousDefect.media, 'id');

  return (
    <div
      className={element('card-photos', {
        edited: isEdited,
      })}
    >
      <DefectPhotos
        defect={defect ?? previousDefect}
        size="medium"
        isDisabled
        isInvalid={
          defect &&
          errors.services?.defects?.defects?.find((def) => def.id === defect?.id)?.missingPhotos
        }
      />
    </div>
  );
};

const DefectToBeRepairedCell = ({
  defect,
  previousDefect,
}: {
  defect?: ArticleDefectWithRelations;
  previousDefect?: ArticleDefectWithRelations;
}) => {
  const { article, request, view, errors } = useArticleContext();

  const { mutate: updateDefect } = useUpdateDefect({
    requestId: request.id,
    articleId: article.id,
  });

  if (!view.services.defects.editable || !defect) {
    return (
      <Cell
        justifyContent="center"
        className={element('cell', {
          edited: defect && previousDefect && defect.toBeRepaired !== previousDefect.toBeRepaired,
        })}
      >
        {(defect || previousDefect!).toBeRepaired ? (
          <Trans id="defects-table.column.to-be-repaired.yes">Yes</Trans>
        ) : (
          <Trans id="defects-table.column.to-be-repaired.no">No</Trans>
        )}
      </Cell>
    );
  }

  return (
    <ToggleCell
      isSelected={defect.toBeRepaired}
      onChange={(toBeRepaired) => {
        updateDefect({
          defectId: defect.id,
          body: {
            toBeRepaired,
          },
        });
      }}
      isDisabled={defect.id.startsWith(TEMPORARY_ID_PREFIX)}
      cellProps={{
        className: element('cell', {
          edited: defect && previousDefect && defect.toBeRepaired !== previousDefect.toBeRepaired,
        }),
        isInvalid: errors.services?.defects?.defects?.find((def) => def.id === defect.id)
          ?.missingActions,
      }}
    />
  );
};

const CardDefectToBeRepaired = ({
  defect,
  previousDefect,
}: {
  defect?: ArticleDefectWithRelations;
  previousDefect?: ArticleDefectWithRelations;
}) => {
  const { errors } = useArticleContext();

  const missingActions = errors.services?.defects?.defects?.find(
    (def) => def.id === defect?.id
  )?.missingActions;
  const isEdited = defect && previousDefect && defect.toBeRepaired !== previousDefect.toBeRepaired;

  return (
    <Badge
      hasBorder
      color="neutral"
      borderRadius="soft"
      size="large"
      className={element('card-badge', {
        edited: isEdited,
        error: missingActions,
      })}
    >
      <Stack row alignItems="center" gap="4px">
        <IconTools
          className={cn({
            'text-secondary': !missingActions,
            'text-danger': missingActions,
          })}
          style={{ fontSize: '1rem' }}
        />
        <span
          className={cn('paragraph-200-regular', {
            'text-primary': !missingActions,
            'text-danger': missingActions,
          })}
        >
          {(defect ?? previousDefect!).toBeRepaired ? (
            <Trans id="defects-table.card.to-be-repaired.yes">To be repaired: Yes</Trans>
          ) : (
            <Trans id="defects-table.card.to-be-repaired.no">To be repaired: No</Trans>
          )}
        </span>
      </Stack>
    </Badge>
  );
};

const EditDefectButton = ({ defect }: { defect: ArticleDefectWithRelations }) => {
  const { t } = useLingui();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        variant="secondary"
        size="medium"
        ariaLabel={t({ id: 'defects-table.column.edit', message: 'Edit' })}
        disabled={defect.id.startsWith(TEMPORARY_ID_PREFIX)}
        onPress={() => setIsOpen(true)}
      >
        <IconEdit />
        <Trans id="defects-table.column.edit">Edit</Trans>
      </Button>
      <CreateEditDefectDialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        defectToEdit={defect}
      />
    </>
  );
};

const DeleteDefectCell = ({ defect }: { defect: ArticleDefectWithRelations }) => {
  return (
    <Cell>
      <DeleteDefectButton defect={defect} iconOnly />
    </Cell>
  );
};

const DeleteDefectButton = ({
  defect,
  iconOnly,
}: {
  defect: ArticleDefectWithRelations;
  iconOnly?: boolean;
}) => {
  const { article, request } = useArticleContext();

  const { t } = useLingui();

  const { mutateAsync: deleteDefect } = useDeleteDefect({
    requestId: request.id,
    articleId: article.id,
  });

  return (
    <Button
      variant="secondary"
      size="medium"
      iconOnly={iconOnly}
      tooltip={t({ id: 'defects-table.column.deletion', message: 'Remove' })}
      ariaLabel={t({ id: 'defects-table.column.deletion', message: 'Remove' })}
      disabled={defect.id.startsWith(TEMPORARY_ID_PREFIX)}
      onPress={() => {
        if (
          defect.media.length > 0 &&
          !confirm(
            t({
              id: 'defects-table.column.deletion.confirmation',
              message:
                'Are you sure you want to delete this defect? The associated photos will be deleted as well.',
            })
          )
        ) {
          return;
        }

        deleteDefect(defect.id);
      }}
    >
      <IconTrash />
      {!iconOnly && <Trans id="defects-table.column.deletion">Remove</Trans>}
    </Button>
  );
};

const DefectsTableErrors = () => {
  const { errors } = useArticleContext();

  const tableErrors = [];

  if (errors.services?.defects?.noDefects) {
    tableErrors.push(
      <Trans id="defects-table.errors.no-defects">Please select at least one defect.</Trans>
    );
  }

  if (errors.services?.defects?.defects?.some((defect) => defect.missingDescription)) {
    tableErrors.push(
      <Trans id="defects-table.errors.missing-description">
        Please add a description for each defect.
      </Trans>
    );
  }

  if (errors.services?.defects?.defects?.some((defect) => defect.missingPhotos)) {
    tableErrors.push(
      <Trans id="defects-table.errors.missing-photos">
        Please add at least one photo per defect.
      </Trans>
    );
  }

  if (errors.services?.defects?.defects?.some((defect) => defect.missingActions)) {
    tableErrors.push(
      <Trans id="defects-table.errors.missing-actions">
        Please associate an action to each defect or mark them as not to be repaired.
      </Trans>
    );
  }

  if (tableErrors.length === 0) {
    return null;
  }

  return (
    <Stack gap="0.25rem" style={{ paddingTop: '0.5rem' }}>
      {tableErrors.map((error, index) => (
        <Message key={index} type="error">
          {error}
        </Message>
      ))}
    </Stack>
  );
};

const DefectContextCell = ({
  defect,
  previousDefect,
}: {
  defect?: ArticleDefectWithRelations;
  previousDefect?: ArticleDefectWithRelations;
}) => {
  const { t } = useLingui();
  const { request, article, view } = useArticleContext();

  const defectOrPreviousDefect = defect ?? previousDefect!;

  const { mutateAsync: updateDefect } = useUpdateDefect({
    requestId: request.id,
    articleId: article.id,
  });

  const [context, setContext] = useState(defectOrPreviousDefect.context ?? '');
  const trimmedContext = context.trim();

  return (
    <TextAreaCell
      cellProps={{
        isReadonly:
          !defect || !view.services.defects.editable || defect.id.startsWith(TEMPORARY_ID_PREFIX),
        className: element('cell', {
          edited: defect && previousDefect && defect.context !== previousDefect.context,
        }),
      }}
      ariaLabel={t({
        id: 'defects-table.column.context.label',
        message: 'Context of the defect',
      })}
      placeholder={t({
        id: 'defects-table.column.context.placeholder',
        message: 'Describe how the defect appeared...',
      })}
      onChange={(event) => setContext(event.target.value)}
      value={context}
      onBlur={() => {
        if (!defect) {
          return;
        }

        const currentContext = defect.context;
        updateDefect({
          defectId: defect.id,
          body: {
            context: trimmedContext,
          },
        }).catch(() => setContext(currentContext ?? ''));
      }}
    >
      {context && <>&ldquo;{context}&rdquo;</>}
      {!context && view.services.defects.editable && (
        <span className="text-disabled">
          <Trans id="defects-table.column.context.placeholder">
            Describe how the defect appeared...
          </Trans>
        </span>
      )}
    </TextAreaCell>
  );
};
