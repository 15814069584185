import { Trans, useLingui } from '@lingui/react/macro';

import Accordion from '@/design_system/Accordion';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import { Separator } from '@/design_system/Menu/Menu';
import Stack from '@/design_system/Stack';
import IconCloth from '@/icons/Cloth.svg';
import IconEdit from '@/icons/Edit.svg';
import IconInvoice from '@/icons/Invoice.svg';
import IconValidation from '@/icons/Validation.svg';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { useCurrentSession } from '@/services/auth';

import { ArticleInformationFormModal } from '../../ArticleInformationFormModal';
import { ArticlePriceWithTitle } from '../../ArticlePrice';

import { ArticleDetails } from './ArticleDetails';
import { ArticleWarranty } from './ArticleWarranty';
import { ArticleWorkshopPayment } from './ArticleWorkshopPayment';

export const ArticleInfoTopPanel = () => {
  const { t } = useLingui();
  const { currentSession } = useCurrentSession();
  const { article, view, state } = useArticleContext();

  const canEditArticleInfo =
    currentSession!.hasPermission('edit_article', {
      organizationId: article.organizationId,
    }) && !article.archivedAt;

  const showWorkshopPaymentSection =
    article.repairedAt !== null &&
    !!article.workshop?.nonDigitalized &&
    (article.workshopPaymentStrategy === 'store-cash-out' ||
      article.workshopPaymentStrategy === 'handled-by-accounting-team');

  return (
    <Stack gap="1rem" className="paragraph-100-regular">
      {article.task?.type !== 'choose_article_service' &&
        article.task?.type !== 'accept_dispatch_proposal' &&
        article.task?.type !== 'accept_requalification' && (
          <Stack>
            <ArticlePriceWithTitle allowDiscount={false} />
          </Stack>
        )}

      <Box padding="0">
        <Stack ariaLabel={t({ id: 'article.information.title', message: 'Information' })}>
          <Accordion
            trigger={
              <Stack row gap="0.5rem" alignItems="center" className="paragraph-100-regular">
                <IconCloth />
                <Trans id="article.information.title">Information</Trans>
              </Stack>
            }
          >
            <Stack gap="1rem">
              <ArticleDetails />
              {canEditArticleInfo && (
                <Button
                  variant="secondary"
                  size="small"
                  onPress={() => state.infoPanel.setIsOpen(true)}
                  ariaLabel={t({ id: 'article.information.edit', message: 'Edit' })}
                >
                  <IconEdit />
                  <Trans id="article.information.edit">Edit</Trans>
                </Button>
              )}
            </Stack>
          </Accordion>
        </Stack>

        <ArticleInformationFormModal />

        {view.warranty.shown && !view.warranty.editable && (
          <>
            <Separator />

            <Accordion
              trigger={
                <Stack row gap="0.5rem" alignItems="center" className="paragraph-100-regular">
                  <IconValidation />
                  <Trans id="article.warranty.title">Warranty</Trans>
                </Stack>
              }
            >
              <Stack gap="1rem">
                <ArticleWarranty />
              </Stack>
            </Accordion>
          </>
        )}

        {showWorkshopPaymentSection && (
          <>
            <Separator />
            <Accordion
              trigger={
                <Stack row gap="0.5rem" alignItems="center" className="paragraph-100-regular">
                  <IconInvoice />
                  <Trans id="article.workshop-payment.title">Workshop payment</Trans>
                </Stack>
              }
            >
              <ArticleWorkshopPayment />
            </Accordion>
          </>
        )}
      </Box>
    </Stack>
  );
};
