import { useNavigate } from 'react-router';
import { Trans } from '@lingui/react/macro';

import { RequestOrganizationPrice } from '@/components/RequestOrganizationPrice';
import { RequestWorkshopPrice } from '@/components/RequestWorkshopPrice';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button/Button';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import IconAdd from '@/icons/Add.svg';
import { useCreateDraftRequestArticle } from '@/models/request';
import {
  CreationArticlesCardList,
  CreationArticlesTable,
} from '@/routes/Requests/components/ArticlesTable';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import useViewPort from '@/utils/useViewport';

export const NewRequestArticles = () => {
  const { isMobile, isWidescreen } = useViewPort();
  const {
    request,
    view: { price, cost },
    errors,
    workflow,
  } = useRequestContext();

  const hasArticles = request.articles.length > 0;
  const navigate = useNavigate();

  const {
    mutateAsync: createArticle,
    isPending: isPendingCreateArticle,
    isSuccess: isSuccessCreateArticle,
    reset: resetCreateArticle,
  } = useCreateDraftRequestArticle(request.id);

  const handleCreateArticle = async () => {
    const { id } = await createArticle();

    navigate(`/requests/new/${request.id}/articles/${id}`, {
      state: { from: 'request', isNew: true, skipRouterBlocker: true },
      replace: true,
    });
    resetCreateArticle();
  };

  const hasReachedMaxArticles =
    !!workflow?.config.maxArticles && request.articles.length >= workflow.config.maxArticles;
  const showAddArticleButton = request.draft && !hasReachedMaxArticles;

  return (
    <Stack gap="1rem">
      <Box padding="0" removeStyleOnMobile>
        {hasArticles && (isMobile ? <CreationArticlesCardList /> : <CreationArticlesTable />)}

        {(!hasArticles || showAddArticleButton || errors.articles?.hasError) && (
          <Stack
            padding={isMobile ? undefined : '1rem'}
            alignItems={isMobile ? 'stretch' : 'flex-start'}
            gap={isMobile ? '0.5rem' : '1rem'}
            style={
              isMobile || !hasArticles
                ? undefined
                : // eslint-disable-next-line lingui/no-unlocalized-strings
                  { borderTop: '1px solid var(--color-neutral-300)' }
            }
          >
            {!hasArticles && (
              <p className="paragraph-100-regular paragraph-200-regular-mobile text-secondary">
                <Trans id="requests.new.articles.no-article">No items added yet</Trans>
              </p>
            )}
            {showAddArticleButton && (
              <Button
                style={{ flex: isMobile ? 1 : undefined }}
                variant="secondary"
                size="medium"
                onPress={handleCreateArticle}
                isLoading={isPendingCreateArticle || isSuccessCreateArticle}
                dataTrackingId="requests.new.articles.add"
              >
                <IconAdd />
                <Trans id="requests.new.articles.add">Add an item</Trans>
              </Button>
            )}
            {errors.articles?.hasError && (
              <Message type="error">
                {errors.articles?.noArticles ? (
                  workflow?.config.maxArticles === 1 ? (
                    <Trans id="requests.new.articles.error.no-article.single">
                      Please add an item
                    </Trans>
                  ) : (
                    <Trans id="requests.new.articles.error.no-article">
                      Please add at least one item
                    </Trans>
                  )
                ) : workflow?.config.maxArticles === 1 ? (
                  <Trans id="requests.new.articles.error.incomplete-article.single">
                    Please complete the item
                  </Trans>
                ) : (
                  <Trans id="requests.new.articles.error.incomplete-article">
                    Please complete all the items
                  </Trans>
                )}
              </Message>
            )}
          </Stack>
        )}
      </Box>
      {hasArticles && (
        <Stack row={isWidescreen} gap="1rem">
          {cost.enabled && (
            <Stack style={{ flex: 1 }} gap="1rem">
              {request.cost?.amountPerCurrency.map((amount) => (
                <RequestWorkshopPrice
                  key={amount.currency}
                  currency={amount.currency}
                  label={
                    request.cost!.amountPerCurrency.length > 1
                      ? `${cost.labelWithoutVATInfo} ${amount.currency}`
                      : cost.labelWithoutVATInfo
                  }
                  priceAggregate={request.cost!}
                  articles={request.allArticles}
                />
              ))}
            </Stack>
          )}
          {price.enabled && (
            <div style={{ flex: 1 }}>
              <RequestOrganizationPrice
                price={request.price?.amountPerCurrency[0]}
                label={price.labelWithoutVATInfo}
              />
            </div>
          )}
        </Stack>
      )}
    </Stack>
  );
};
