import { useBrandRequestContext } from '@/layouts/Brand/BrandRequestContext';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';

export const useBrandOrAppRequestContext = () => {
  const { request: requestFromRequestContext, workflow: workflowFromRequestContext } =
    useRequestContext();
  const { request: requestFromBrandRequestContext, workflow: workflowFromBrandRequestContext } =
    useBrandRequestContext();

  const request = requestFromRequestContext ?? requestFromBrandRequestContext;
  const workflow = workflowFromRequestContext ?? workflowFromBrandRequestContext;

  return {
    request,
    workflow,
  };
};
