import { useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import { useUpdateRequestClient } from '@/models/request';
import { useClientToken } from '@/services/auth';
import { useBrandOrAppRequestContext } from '@/utils/useBrandOrAppRequestContext';

import ClientForm, { ClientFormProps, getClientError, useClientState } from './ClientForm';

const ClientFormModal = ({
  isOpen,
  setIsOpen,
  ...props
}: Omit<ClientFormProps, 'state'> & {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const clientToken = useClientToken();

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      style={{ maxWidth: '37.5rem' }}
      title={
        clientToken ? (
          <Trans id="client.request.my-info.edit.title">Edit my information</Trans>
        ) : (
          <Trans id="client.request.my-info.edit.title.client">
            Edit client&apos;s information
          </Trans>
        )
      }
    >
      <ClientFormModalContent setIsOpen={setIsOpen} {...props} />
    </Dialog>
  );
};

const ClientFormModalContent = ({
  setIsOpen,
  ...props
}: Omit<ClientFormProps, 'state'> & {
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const { t } = useLingui();
  const { request } = useBrandOrAppRequestContext();
  const clientState = useClientState();
  const [showErrors, setShowErrors] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);

  const { mutateAsync: updateRequestClient, isPending: isPendingUpdateRequestClient } =
    useUpdateRequestClient();

  const saveClient = async () => {
    setShowErrors(true);
    setApiError(null);

    if (getClientError(clientState, props.hideBillingAddress).hasError) {
      return;
    }

    try {
      await updateRequestClient({
        id: request.id,
        body: {
          client: {
            name: clientState.name,
            email: clientState.email,
            address: clientState.address ?? undefined,
            billingAddress: props.hideBillingAddress
              ? undefined
              : (clientState.billingAddress ?? undefined),
            deliveryOption: props.hideDeliveryOption ? undefined : clientState.deliveryOption,
            sendcloudServicePointId: clientState.sendcloudServicePointId,
            phone: clientState.phone,
            locale: props.hideLanguage ? undefined : clientState.locale,
          },
          vip: props.hideVip ? undefined : clientState.vip,
        },
      });

      setIsOpen(false);
    } catch (err: any) {
      console.error(err);
      setApiError(
        (err.message as string) ?? t({ id: '_general.error.unknown', message: 'Unknown error' })
      );
    }
  };

  return (
    <>
      <main>
        <ClientForm state={clientState} {...props} showErrors={showErrors} />
      </main>
      <footer>
        {!!apiError && <span className="paragraph-100-regular text-danger">{apiError}</span>}
        <Button
          variant={props.variant === 'brand' ? 'secondary-brand' : 'secondary'}
          size={props.size}
          onPress={() => setIsOpen(false)}
        >
          <Trans id="client.request.my-info.edit.cancel">Cancel</Trans>
        </Button>
        <Button
          variant={props.variant === 'brand' ? 'brand' : 'primary'}
          size={props.size}
          onPress={saveClient}
          isLoading={isPendingUpdateRequestClient}
        >
          <Trans id="client.request.my-info.edit.save">Save</Trans>
        </Button>
      </footer>
    </>
  );
};

export default ClientFormModal;
