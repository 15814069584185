import { useLingui } from '@lingui/react/macro';

import Stack from '@/design_system/Stack';
import IconPackage from '@/icons/Package.svg';
import IconPlace from '@/icons/Place.svg';
import IconTruck from '@/icons/Truck.svg';
import { ShipmentWithRelations } from '@/models/shipment';
import { createBEMClasses } from '@/utils/classname';
import useViewPort from '@/utils/useViewport';

import './ShipmentOverview.css';

const { block, element } = createBEMClasses('shipment-overview');

export const ShipmentOverview = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { isMobile } = useViewPort();

  return (
    <Stack className={block()}>
      <Stack row gap="0.25rem" alignItems="center" justifyContent="stretch">
        {!isMobile && <IconPlace start style={{ fontSize: '1.25rem' }} />}
        <Stack row style={{ flex: 1 }} alignItems="center" gap="0.5rem">
          <LocationName name={shipment.origin?.name} origin />
          <Stack row style={{ flex: 1 }} gap="0.5rem" alignItems="center">
            {shipment.step === 'handover' && <IconPackage style={{ fontSize: '1.5rem' }} />}
            <div
              className={element('transit', {
                completed:
                  shipment.step === 'in-transit' ||
                  shipment.step === 'verification' ||
                  shipment.step === 'validated',
              })}
            />
            {shipment.step === 'in-transit' && (
              <>
                <IconTruck style={{ fontSize: '1.5rem' }} />
                <div className={element('transit')} />
              </>
            )}
            {shipment.step === 'verification' && <IconPackage style={{ fontSize: '1.5rem' }} />}
          </Stack>
          <LocationName name={shipment.destination?.name} />
        </Stack>
        {!isMobile && <IconPlace end style={{ fontSize: '1.25rem' }} />}
      </Stack>
      <Stack
        style={{
          padding: isMobile ? undefined : '0 1.5rem',
        }}
        row
        justifyContent="space-between"
      >
        <LocationCity
          city={shipment.origin?.address?.city}
          state={shipment.origin?.address?.state}
        />
        <LocationCity
          city={shipment.destination?.address?.city}
          state={shipment.destination?.address?.state}
        />
      </Stack>
    </Stack>
  );
};

const LocationName = ({ name, origin }: { name?: string; origin?: boolean }) => {
  const { t } = useLingui();

  if (!name) {
    name = origin
      ? t({ id: 'shipment.origin.title', message: 'Origin' })
      : t({ id: 'shipment.destination.title', message: 'Destination' });
  }

  return (
    <h3
      className={element(
        'location-name',
        {},
        'headline-400-bold headline-500-bold-mobile text-ellipsis'
      )}
      title={name}
    >
      {name}
    </h3>
  );
};

const LocationCity = ({ city, state }: { city?: string; state?: string | null }) => {
  const cityState = [city, state].filter((el) => !!el).join(', ');

  if (!cityState) {
    return null;
  }

  return (
    <span
      className={element('location-city', {}, 'paragraph-200-regular text-ellipsis')}
      title={cityState}
    >
      {cityState}
    </span>
  );
};
