import { Trans } from '@lingui/react/macro';

import { ArticleWithRelations } from '@/models/request';

import { BaseMessage } from './BaseMessage';

export const AcceptDispatchProposalTaskMessage = ({
  article,
}: {
  article: ArticleWithRelations;
}) => {
  return (
    <BaseMessage
      article={article}
      title={
        <Trans id="article.task.accept-dispatch-proposal.title">Review the quote proposal</Trans>
      }
      message={
        <Trans id="article.task.accept-dispatch-proposal.message">
          Please review the prices changes made by the workshop.
        </Trans>
      }
    />
  );
};
