import { useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Drawer, { DrawerBody, DrawerFooter, DrawerHeader } from '@/design_system/Drawer';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import IconUndo from '@/icons/Undo.svg';
import { useDiscardRequalification, useRequalify } from '@/models/article';
import { ArticleServices } from '@/routes/Requests/components/ArticleServices/ArticleServices';
import {
  ArticleContextProvider,
  useArticleContext,
} from '@/routes/Requests/contexts/ArticleContext';
import { useCurrentSession } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

const RequalificationDrawer = ({
  isOpen,
  setIsOpen,
  onRequalified,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onRequalified: () => void;
}) => {
  const { article } = useArticleContext();

  const {
    mutateAsync: discardRequalification,
    isPending: isPendingDiscardRequalification,
    isSuccess: isSuccessDiscardRequalification,
    reset: resetDiscardRequalification,
  } = useDiscardRequalification({ articleId: article.id });

  const discard = async () => {
    if (isPendingDiscardRequalification) {
      return;
    }

    await discardRequalification();
    setIsOpen(false);

    // Wait for the Drawer CSS exit animation to finish
    setTimeout(() => {
      resetDiscardRequalification();
    }, 150);
  };

  return (
    <Drawer
      isOpen={isOpen}
      onOpenChange={(newIsOpen) => {
        if (newIsOpen) {
          setIsOpen(true);
        } else {
          discard();
        }
      }}
    >
      <DrawerHeader>
        <h1 className="headline-200-bold">
          <Trans id="requalification-drawer.title">Propose actions re-qualification</Trans>
        </h1>
      </DrawerHeader>
      {/* Special case, we want to have a different view & errors for this drawer */}
      <ArticleContextProvider article={article} isRequalification>
        <RequalificationDrawerContent
          setIsOpen={setIsOpen}
          onRequalified={onRequalified}
          discard={discard}
          isPendingDiscard={isPendingDiscardRequalification || isSuccessDiscardRequalification}
        />
      </ArticleContextProvider>
    </Drawer>
  );
};

const RequalificationDrawerContent = ({
  setIsOpen,
  onRequalified,
  discard,
  isPendingDiscard,
}: {
  setIsOpen: (isOpen: boolean) => void;
  onRequalified: () => void;
  discard: () => Promise<void>;
  isPendingDiscard: boolean;
}) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();
  const { isWorkshop } = useCurrentSession();

  const { article, view, state, hasError } = useArticleContext();

  const [comment, setComment] = useState(article?.requalificationComment ?? '');
  const [apiError, setApiError] = useState<string | null>(null);

  const {
    mutateAsync: requalify,
    isPending: isPendingRequalify,
    isSuccess: isSuccessRequalify,
  } = useRequalify({
    articleId: article.id,
  });

  const submit = async () => {
    const { hasError } = state.errors.check({
      services: {
        defects: view.services.defects.shown
          ? {
              defects: {
                missingDescription: true,
                missingPhotos: true,
                missingActions: view.services.actions.shown,
              },
              noDefects: true,
            }
          : undefined,
        actions: view.services.actions.shown
          ? {
              actions: {
                missingDescription: true,
                missingPhotos: !view.services.defects.shown,
                missingCost: !isWorkshop,
                missingCustomPrice: !!isWorkshop,
              },
              noActions: true,
            }
          : undefined,
      },
    });

    if (hasError) {
      return;
    }

    try {
      await requalify({
        comment: comment ?? null,
      });
      setIsOpen(false);

      // Wait for the Drawer CSS exit animation to finish
      setTimeout(() => {
        onRequalified();
      }, 150);
    } catch (err: any) {
      console.error(err);
      setApiError(
        (err.message as string) ?? t({ id: '_general.error.unknown', message: 'Unknown error' })
      );
    }
  };

  const requalificationModalIntro = view.services.defects.shown
    ? t({
        id: 'requalification-modal.intro',
        message:
          "You can adjust the initial noted defects and associated actions if they don't meet the item's needs. Once submitted, they will be reviewed, and approved or refused.",
      })
    : t({
        id: 'requalification-modal.intro.actions-only',
        message:
          "You can adjust the initial actions if they don't meet the item's needs. Once submitted, they will be reviewed, and approved or refused.",
      });

  return (
    <>
      <DrawerBody>
        <Stack gap="1rem" padding={isMobile ? '1rem' : '1.5rem 2rem'}>
          <p className="paragraph-100-regular text-secondary">{requalificationModalIntro}</p>

          <ArticleServices />

          <TextArea
            label={
              <Stack row gap="0.25rem" alignItems="center">
                <Trans id="requalification-modal.comment.label.on-behalf">
                  Note <span className="text-disabled paragraph-200-regular">(optional)</span>
                </Trans>
              </Stack>
            }
            placeholder={t({
              id: 'requalification-modal.comment.placeholder.on-behalf',
              message: 'Write a note to explain the re-qualification...',
            })}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Stack>
      </DrawerBody>
      <DrawerFooter>
        <Stack gap="1rem" style={{ flex: 1 }}>
          {hasError && (
            <p className="paragraph-100-medium text-danger">
              <Trans id="article.actions.submit-requalification.errors">
                Please fill the missing information in order to submit the re-qualification proposal
              </Trans>
            </p>
          )}
          {apiError && <p className="paragraph-100-medium text-danger">{apiError}</p>}
          <Stack
            gap="0.5rem"
            row={!isMobile}
            style={{ alignSelf: isMobile ? 'stretch' : 'flex-end' }}
          >
            <Button
              variant="secondary"
              size="medium"
              onPress={discard}
              isLoading={isPendingDiscard}
              disabled={isPendingRequalify || isSuccessRequalify}
            >
              <IconUndo />
              <Trans id="requalification-drawer.reset">Reset changes</Trans>
            </Button>
            <Button
              variant="primary"
              size="medium"
              onPress={submit}
              isLoading={isPendingRequalify || isSuccessRequalify}
              disabled={isPendingDiscard}
            >
              <Trans id="requalification-drawer.submit">Submit re-qualification proposal</Trans>
            </Button>
          </Stack>
        </Stack>
      </DrawerFooter>
    </>
  );
};

export default RequalificationDrawer;
