import { Trans, useLingui } from '@lingui/react/macro';

import { Label } from '@/design_system/Label/Label';
import { RadioBox, RadioBoxGroup } from '@/design_system/Radio/RadioBox/RadioBox';
import { useUpdateInvoice } from '@/models/invoice';

import { useInvoiceContext } from '../Invoice';

export const InvoicePaymentStrategy = () => {
  const { t } = useLingui();

  const { invoice, isDisabled } = useInvoiceContext();
  const { mutate: updateInvoice } = useUpdateInvoice(invoice.id);

  if (invoice.destinationClient) {
    return null;
  }

  const options = [
    {
      value: 'store-cash-out',
      title: t({ id: 'invoice.form.payment-strategy.store-cash-out.text', message: 'The store' }),
      children: (
        <Trans id="invoice.form.payment-strategy.store-cash-out.subtext">
          Via a cash register operation
        </Trans>
      ),
    },
    {
      value: 'handled-by-accounting-team',
      title: t({
        id: 'invoice.form.payment-strategy.handled-by-accounting-team.text',
        message: 'The accounting department',
      }),
      children: (
        <Trans id="invoice.form.payment-strategy.handled-by-accounting-team.subtext">
          Via bank transfer at the end of the month
        </Trans>
      ),
    },
    {
      value: 'prolong-payout',
      title: t({ id: 'invoice.form.payment-strategy.prolong-payout.text', message: 'Prolong' }),
      children: <Trans id="invoice.form.payment-strategy.prolong-payout.subtext">Via Stripe</Trans>,
    },
  ] as const;

  if (isDisabled) {
    const option = options.find(({ value }) => value === invoice.paymentStrategy);

    return (
      <Label label={t({ id: 'invoice.form.payment-strategy', message: 'Payment responsible' })}>
        <RadioBoxGroup name="payment-strategy" value={invoice.paymentStrategy} disabled>
          {option && (
            <RadioBox value={option.value} ariaLabel={option.title} title={option.title}>
              {option.children}
            </RadioBox>
          )}
        </RadioBoxGroup>
      </Label>
    );
  }

  return (
    <Label label={t({ id: 'invoice.form.payment-strategy', message: 'Payment responsible' })}>
      <RadioBoxGroup
        name="payment-strategy"
        value={invoice.paymentStrategy}
        onChange={(paymentStrategy) =>
          updateInvoice({
            paymentStrategy: paymentStrategy as 'store-cash-out' | 'handled-by-accounting-team',
          })
        }
        disabled={!!invoice.lines.length}
      >
        {options
          .filter(({ value }) => value !== 'prolong-payout')
          .map(({ value, title, children }) => (
            <RadioBox key={value} value={value} ariaLabel={title} title={title}>
              {children}
            </RadioBox>
          ))}
      </RadioBoxGroup>
    </Label>
  );
};
