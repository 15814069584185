import { Trans } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import IconInvoice from '@/icons/Invoice.svg';
import { useBrandRequestContext } from '@/layouts/Brand/BrandRequestContext';
import { useRequestInvoice } from '@/models/request';

const Invoice = () => {
  const { request } = useBrandRequestContext();
  const hasBeenPaid = request.articles.some((article) => article.paidAt);

  const {
    data: invoice,
    isLoading,
    isError,
  } = useRequestInvoice(request.id, { enabled: hasBeenPaid });

  if (!hasBeenPaid) {
    return null;
  }

  // We do not show the download button if this is not an invoice (e.g: external payment proof)
  if (isLoading || invoice?.type !== 'invoice') {
    return null;
  }

  return (
    <Button
      size="small"
      variant="secondary-brand"
      isLoading={isLoading}
      disabled={isLoading || isError}
      href={invoice?.url}
      download="invoice.pdf"
      target="_blank"
    >
      <IconInvoice download />
      <Trans id="client.request.repair.download-invoice">Download invoice</Trans>
    </Button>
  );
};

export default Invoice;
