import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router';
import { Trans, useLingui } from '@lingui/react/macro';

import DiscardChangesDialog from '@/components/DiscardChangesDialog';
import {
  PageLayout,
  PageLayoutContent,
  PageLayoutLeftPart,
  PageLayoutLeftPartContent,
  PageLayoutLeftPartFooter,
  PageLayoutSimpleHeader,
} from '@/components/PageLayout';
import AlertBar from '@/design_system/AlertBar';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import FoldableSection from '@/design_system/FoldableSection';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import Tooltip from '@/design_system/Tooltip';
import IconClock from '@/icons/Clock.svg';
import IconInfo from '@/icons/Info.svg';
import { useRequest, useSendDraftRequest } from '@/models/request';
import { useWorkflow } from '@/models/workflow';
import { ArchiveRequestButton } from '@/routes/Requests/components/ArchiveRequestButton';
import {
  RequestContextProvider,
  useRequestContext,
} from '@/routes/Requests/contexts/RequestContext';
import Articles from '@/routes/Requests/New/components/Articles';
import ClientStoreForm, {
  getClientStoreError,
} from '@/routes/Requests/New/components/ClientStoreForm';
import {
  PrintDropOffButton,
  PrintDropOffModal,
} from '@/routes/Requests/New/components/PrintDropOff';
import SettingsForm from '@/routes/Requests/New/components/SettingsForm';
import { RequestComments } from '@/routes/Requests/Request/components/RequestComments';
import { type Locale } from '@/services/i18n';
import { useShowWarningBeforeLeavingApp, useSimpleBlocker } from '@/utils/navigation';
import { useScrollIntoView } from '@/utils/useScrollIntoView';
import useViewPort from '@/utils/useViewport';

import { creationErrorsFilter, saveForLaterErrorsFilter } from './NewArticle';

import './New.css';

const New = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: request, isLoading: isRequestLoading, isError } = useRequest(id);
  const { data: workflow, isLoading: isWorkflowLoading } = useWorkflow(request?.workflowId);

  useEffect(() => {
    if (isError) {
      navigate('/requests', { state: { skipRouterBlocker: true, replace: true } });
    }
  }, [navigate, isError]);

  // Block going away
  const blocker = useSimpleBlocker();
  useShowWarningBeforeLeavingApp();

  if (isRequestLoading || isWorkflowLoading) {
    return null;
  }

  if (!request) {
    return null;
  }

  return (
    <RequestContextProvider request={request} workflow={workflow}>
      <RequestForm />
      <Outlet context={request} />
      {blocker.state === 'blocked' && (
        <DiscardChangesDialog
          onConfirm={() => blocker.proceed()}
          onCancel={() => blocker.reset()}
        />
      )}
    </RequestContextProvider>
  );
};

export default New;

const RequestForm = () => {
  const { t } = useLingui();
  const navigate = useNavigate();
  const { isMobile } = useViewPort();
  const { request, articles, view, state, hasError } = useRequestContext();

  const [showPrintDropOffModal, setShowPrintDropOffModal] = useState(false);

  const goToRequests = ({ skipRouterBlocker }: { skipRouterBlocker: boolean }) =>
    navigate('/requests', { state: { skipRouterBlocker }, replace: true });
  const goToRequest = ({ skipRouterBlocker }: { skipRouterBlocker: boolean }) =>
    navigate(`/requests/${request.id}`, { state: { skipRouterBlocker }, replace: true });

  // Errors
  const hasClientStoreError = getClientStoreError(request, !view.client.billingAddress);
  const [showClientStoreError, setShowClientStoreError] = useState(false);

  const [alertRef, scrollAlertIntoView] = useScrollIntoView<HTMLDivElement>();
  const [apiError, setApiError] = useState('');

  // Actions

  const handleApiError = (err: any) => {
    console.error(err);
    setApiError(
      (err.message as string) ?? t({ id: '_general.error.unknown', message: 'Unknown error' })
    );
  };

  const {
    mutateAsync: saveForLater,
    isPending: isPendingSaveForLater,
    isSuccess: isSuccessSaveForLater,
  } = useSendDraftRequest(request.id);

  const {
    mutateAsync: create,
    isPending: isPendingCreate,
    isSuccess: isSuccessCreate,
  } = useSendDraftRequest(request.id);

  const handleSaveForLater = () => {
    const { hasError } = state.errors.check({
      articles: {
        articles: articles.map((article) => ({
          ...saveForLaterErrorsFilter(article.view),
          id: article.articleId,
        })),
        noArticles: true,
      },
    });
    setShowClientStoreError(true);

    if (hasClientStoreError || hasError) {
      scrollAlertIntoView();
      return;
    }

    const isDraft = request.draft;

    saveForLater({ saveForLater: true })
      .then(() => {
        if (request.client && request.store && isDraft) {
          setShowPrintDropOffModal(true);
        } else {
          goToRequests({ skipRouterBlocker: true });
        }
      })
      .catch(handleApiError);
  };

  const handleSaveRequest = () => {
    const { hasError } = state.errors.check({
      articles: {
        articles: articles.map((article) => ({
          ...creationErrorsFilter(article.view, view),
          id: article.articleId,
        })),
        noArticles: true,
      },
    });
    setShowClientStoreError(true);

    if (hasClientStoreError || hasError) {
      scrollAlertIntoView();
      return;
    }

    create({ saveForLater: false })
      .then(() => {
        goToRequest({ skipRouterBlocker: true });
      })
      .catch(handleApiError);
  };

  const saveForLaterLabel = t({
    id: 'requests.edit.actions.save-for-later',
    message: 'Save for later',
  });
  // Only draft requests here
  if (!request.draft && !request.isInDraftStep) {
    goToRequest({ skipRouterBlocker: true });
    return null;
  }

  return (
    <PageLayout>
      <PageLayoutSimpleHeader
        title={
          request.draft
            ? t({ id: 'requests.new.title', message: 'New request' })
            : t({ id: 'requests.edit.title', message: 'Edit request' })
        }
        onClose={() => goToRequests({ skipRouterBlocker: !request.draft })}
      />

      <PageLayoutContent>
        <PageLayoutLeftPart>
          <PageLayoutLeftPartContent>
            <Stack padding={isMobile ? '1rem' : undefined} gap="1rem" ref={alertRef}>
              <NewRequestInfoBanners />

              {(hasError || (showClientStoreError && hasClientStoreError)) && (
                <AlertBar
                  type="error"
                  title={
                    request.draft
                      ? t({
                          id: 'requests.new.error',
                          message:
                            'Please fill the missing information in order to be able to create a request',
                        })
                      : t({
                          id: 'requests.edit.error',
                          message:
                            'Please fill the missing information in order to be able to edit the request',
                        })
                  }
                />
              )}

              <Stack gap="2rem">
                <SettingsForm />
                <ClientStoreForm showErrors={showClientStoreError && hasClientStoreError} />
                <Articles />
                <FoldableSection
                  label={
                    <h2 className="headline-400-bold">
                      <Trans id="comments.title">Comments</Trans>
                    </h2>
                  }
                  labelOutsideButton={
                    <Tooltip
                      content={t({
                        id: 'comments.title.tooltip',
                        message: "These comments won't be visible by the client",
                      })}
                    >
                      <Button variant="style-less">
                        <IconInfo className="headline-300-bold text-disabled" />
                      </Button>
                    </Tooltip>
                  }
                >
                  <Box padding="0" removeStyleOnMobile>
                    <RequestComments requestId={request.id} />
                  </Box>
                </FoldableSection>
              </Stack>
            </Stack>
          </PageLayoutLeftPartContent>

          <PageLayoutLeftPartFooter>
            {apiError && (
              <Stack alignItems="center">
                <Message type="error">{apiError}</Message>
              </Stack>
            )}
            {request.draft ? (
              <>
                <Button
                  variant="secondary"
                  size="medium"
                  onPress={handleSaveForLater}
                  isLoading={isPendingSaveForLater || isSuccessSaveForLater}
                  dataTrackingId="requests.new.actions.save-for-later"
                >
                  <IconClock />
                  {saveForLaterLabel}
                </Button>
              </>
            ) : (
              <>
                <Stack row gap={isMobile ? '0.5rem' : '1rem'}>
                  <ArchiveRequestButton
                    request={request}
                    onArchive={() => goToRequests({ skipRouterBlocker: true })}
                    onArchiveError={(reason) => {
                      setApiError(
                        ((reason as any).body?.message as string) ??
                          t({ id: '_general.error.unknown', message: 'Unknown error' })
                      );
                    }}
                  />
                  {request.client && request.store && <PrintDropOffButton request={request} />}
                  <Button
                    variant="secondary"
                    size="medium"
                    onPress={handleSaveForLater}
                    isLoading={isPendingSaveForLater || isSuccessSaveForLater}
                    style={isMobile ? { flex: 1 } : undefined}
                    ariaLabel={isMobile ? saveForLaterLabel : undefined}
                    dataTrackingId="requests.new.actions.save-for-later"
                  >
                    <IconClock />
                    {!isMobile && saveForLaterLabel}
                  </Button>
                </Stack>
              </>
            )}
            <Button
              variant="primary"
              size="medium"
              onPress={handleSaveRequest}
              isLoading={isPendingCreate || isSuccessCreate}
              dataTrackingId="requests.new.actions.create"
            >
              {request.draft ? (
                <Trans id="requests.new.actions.create">Create request</Trans>
              ) : (
                <Trans id="requests.edit.actions.save-and-create">Save and create</Trans>
              )}
            </Button>
          </PageLayoutLeftPartFooter>
        </PageLayoutLeftPart>
      </PageLayoutContent>
      {showPrintDropOffModal && (
        <PrintDropOffModal
          request={request}
          onClose={() => goToRequests({ skipRouterBlocker: true })}
        />
      )}
    </PageLayout>
  );
};

export const NewRequestInfoBanners = () => {
  const { i18n } = useLingui();
  const { workflow } = useRequestContext();

  if (!workflow?.config.newRequestInfoBanners?.length) {
    return null;
  }

  return (
    <>
      {workflow.config.newRequestInfoBanners.map((newRequestInfoBanner, key) => (
        <AlertBar
          key={key}
          type={newRequestInfoBanner.type}
          title={newRequestInfoBanner.message[i18n.locale as Locale]}
        />
      ))}
    </>
  );
};
