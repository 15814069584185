import { ReactNode } from 'react';
import { Plural, Trans } from '@lingui/react/macro';

import Stack from '@/design_system/Stack';
import IconPackage from '@/icons/Package.svg';
import IconShipmentDone from '@/icons/ShipmentDone.svg';
import IconTruck from '@/icons/Truck.svg';
import { BrandRequestMainContent } from '@/layouts/Brand/BrandRequestLayout/BrandRequestLayout';
import { ClientRequestWithRelations } from '@/models/request';
import ClientArticlesTable from '@/routes/Brand/Requests/Request/components/shared/ClientArticlesTable';
import ClientStepper from '@/routes/Brand/Requests/Request/components/shared/ClientStepper';

export const Delivery = ({ request }: { request: ClientRequestWithRelations }) => {
  let messageContent: ReactNode | null = null;

  const areAllArticlesPendingClientPickup = request.articles.every(
    (article) =>
      article.step?.step === 'transit' &&
      !!article.atStoreId &&
      article.toClient &&
      article.step.config.clientPickup
  );

  if (areAllArticlesPendingClientPickup && request.isCancelled) {
    const paymentRefused = request.articles.find((article) => !!article.paymentRefusedAt);
    const validationRefused = request.articles.find((article) => !!article.quoteRefusedAt);

    return (
      <BrandRequestMainContent>
        <Stack gap="1.5rem">
          <Stack row gap="0.5rem">
            <p className="headline-200-bold headline-300-bold-mobile">
              <Trans id="store.request.delivery.quote-refused.title">
                The request has been archived
              </Trans>
            </p>
            <p className="paragraph-50-regular paragraph-100-regular-mobile">
              {paymentRefused ? (
                <Trans id="store.request.delivery.final-quote-refused">
                  The client refused the final quote.
                </Trans>
              ) : validationRefused ? (
                <Trans id="store.request.delivery.initial-quote-refused">
                  The client refused the estimate.
                </Trans>
              ) : null}{' '}
              <Trans id="store.request.delivery.store-pickup">
                They have been notified to come pick-up their{' '}
                <Plural value={request.articles.length} one="item" other="items" /> in your store.
              </Trans>
            </p>
          </Stack>
          <ClientArticlesTable request={request} showArticleComment />
        </Stack>
      </BrandRequestMainContent>
    );
  }

  const articlesNotInPendingClientPickup = request.articles.filter(
    (article) =>
      article.step?.step === 'transit' &&
      !(!!article.atStoreId && article.toClient && article.step.config.clientPickup)
  );

  const areAllArticlesInTransit = articlesNotInPendingClientPickup.every(
    (article) => article.step?.step === 'transit' && article.inTransit
  );

  if (areAllArticlesPendingClientPickup) {
    messageContent = (
      <>
        <Stack row gap="0.5rem" flexWrap="nowrap">
          <IconShipmentDone className="color-primary-700" style={{ fontSize: '24px' }} />
          <p className="paragraph-50-regular paragraph-100-regular-mobile">
            <Trans id="store.request.delivery.pending-client">
              The <Plural value={request.articles.length} one="item has" other="items have" /> been
              successfully delivered to your store. The client has been notified to come pick{' '}
              <Plural value={request.articles.length} one="it" other="them" /> up.
            </Trans>
          </p>
        </Stack>
      </>
    );
  } else if (!areAllArticlesInTransit) {
    messageContent = (
      <Stack row gap="0.5rem" flexWrap="nowrap">
        <IconPackage className="color-tertiary-700" style={{ fontSize: '24px' }} />
        <p className="paragraph-50-regular paragraph-100-regular-mobile">
          {(!request.client || request.client.deliveryOption === 'store') && (
            <Trans id="store.request.delivery.pending.store">
              The workshop is preparing the{' '}
              <Plural
                value={articlesNotInPendingClientPickup.length}
                one="item for its"
                other="items for their"
              />{' '}
              shipment back to your store.
            </Trans>
          )}
          {request.client?.deliveryOption !== 'store' && (
            <Trans id="store.request.delivery.pending.client">
              The workshop is preparing the{' '}
              <Plural
                value={articlesNotInPendingClientPickup.length}
                one="item for its"
                other="items for their"
              />{' '}
              shipment back to the client.
            </Trans>
          )}
        </p>
      </Stack>
    );
  } else {
    messageContent = (
      <Stack row gap="0.5rem" flexWrap="nowrap">
        <IconTruck className="color-tertiary-700" style={{ fontSize: '24px' }} />
        <p className="paragraph-50-regular paragraph-100-regular-mobile">
          {(!request.client || request.client.deliveryOption === 'store') && (
            <Trans id="store.request.delivery.in-transit.store">
              The{' '}
              <Plural
                value={articlesNotInPendingClientPickup.length}
                one="item is on its"
                other="items are on their"
              />{' '}
              way back to your store.
            </Trans>
          )}
          {request.client?.deliveryOption !== 'store' && (
            <Trans id="store.request.delivery.in-transit.client">
              The{' '}
              <Plural
                value={articlesNotInPendingClientPickup.length}
                one="item is on its"
                other="items are on their"
              />{' '}
              way back to the client.
            </Trans>
          )}
        </p>
      </Stack>
    );
  }

  return (
    <BrandRequestMainContent>
      <Stack gap="1.5rem">
        <ClientStepper request={request} />
        {messageContent}
        <ClientArticlesTable request={request} showPrice={!!request.client} showArticleComment />
      </Stack>
    </BrandRequestMainContent>
  );
};
