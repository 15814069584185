import { useEffect, useState } from 'react';
import { msg } from '@lingui/core/macro';
import { Trans, useLingui } from '@lingui/react/macro';

import FileUpload from '@/components/FileUpload';
import InputQuantity from '@/components/InputQuantity';
import Button from '@/design_system/Button';
import { Label } from '@/design_system/Label/Label';
import { RadioCard, RadioCardGroup } from '@/design_system/Radio/RadioCard/RadioCard';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import { useBrandRequestContext } from '@/layouts/Brand/BrandRequestContext';
import { useCreateDefect, useUpdateDefect } from '@/models/article';
import { NEED_CATEGORIES, NeedL1, NeedL2, NeedL3, NeedL4 } from '@/models/defectType';
import { useDefectTypes } from '@/models/defectType';
import { Medium, useDeleteMedium } from '@/models/medium';
import { ArticleDefectWithRelations, ClientArticleWithRelations } from '@/models/request';
import ArticleCard from '@/routes/Brand/Requests/New/components/Article/components/ArticleCard';
import { ProgressBar } from '@/routes/Brand/Requests/New/components/ProgressBar/ProgressBar';
import { createBEMClasses } from '@/utils/classname';
import { useScrollIntoView } from '@/utils/useScrollIntoView';
import useViewPort from '@/utils/useViewport';

import './DefectsForm.css';

const { block, element } = createBEMClasses('client-defects-form');

const DefectsForm = ({
  initialDefects,
  article,
  onEditArticle,
  onDeleteArticle,
  onSaveDefects,
  onCancel,
}: {
  initialDefects: ArticleDefectWithRelations[];
  article: ClientArticleWithRelations;
  onEditArticle: () => void;
  onDeleteArticle: () => void;
  onSaveDefects: () => void;
  onCancel: () => void;
}) => {
  const { request, workflow } = useBrandRequestContext();

  const isEdit = !!initialDefects.length;
  const initialDefectData = initialDefects[0] as ArticleDefectWithRelations | undefined;

  const { t, i18n } = useLingui();
  const { isMobile } = useViewPort();

  const { data: defectTypes = [], isSuccess } = useDefectTypes({
    articleId: article.id,
  });

  const [needL1, setNeedL1] = useState<NeedL1 | undefined>(
    initialDefectData?.defectTypeOrganization?.defectType.l1
  );
  const [_needL2, setNeedL2] = useState<NeedL2 | 'custom-defect' | undefined>(
    initialDefectData
      ? (initialDefectData.defectTypeOrganization?.defectType.l2 ?? 'custom-defect')
      : undefined
  );

  // If there is no defect type, we just want to display the custom defect input
  const needL2 = !_needL2 && isSuccess && defectTypes.length === 0 ? 'custom-defect' : _needL2;

  const [needL3, setNeedL3] = useState<NeedL3 | undefined>(
    initialDefectData?.defectTypeOrganization?.defectType.l3 ?? undefined
  );
  const [needL4, setNeedL4] = useState<NeedL4 | undefined>(
    initialDefectData?.defectTypeOrganization?.defectType.l4 ?? undefined
  );

  const [needL2Ref, scrollToNeedL2] = useScrollIntoView<HTMLDivElement>();
  const [needL3Ref, scrollToNeedL3] = useScrollIntoView<HTMLDivElement>();
  const [needL4Ref, scrollToNeedL4] = useScrollIntoView<HTMLDivElement>();

  const [defectPhotosByDefect, setDefectPhotosByDefect] = useState<Medium[][]>(
    initialDefects.length ? initialDefects.map((defect) => defect.media) : [[]]
  );

  const [contextByDefect, setContextByDefect] = useState<string[]>(
    initialDefects.length ? initialDefects.map((defect) => defect.context ?? '') : ['']
  );

  const [defect, setDefect] = useState(initialDefectData?.defectTypeOrganization);

  const [customDefectDescription, setCustomDefectDescription] = useState(
    initialDefectData?.customDescription ?? ''
  );

  const [quantity, setQuantity] = useState(initialDefects.length ? initialDefects.length : 1);

  const [mediaToDelete, setMediaToDelete] = useState<string[]>([]);
  const { mutate: deleteMedium } = useDeleteMedium();

  const handleSetQuantity = (newQuantity: number) => {
    if (newQuantity > defectPhotosByDefect.length) {
      setDefectPhotosByDefect([...defectPhotosByDefect, []]);
      setContextByDefect([...contextByDefect, '']);
    }

    setQuantity(newQuantity);
  };

  const allowCustomDefects = !!workflow?.config.allowCustomDefects && !initialDefectData;
  const allowDefectType = !initialDefectData?.isCustom;

  const allDefects = defectTypes;
  const defectsAfterL1 = allDefects.filter(({ defectType }) => defectType.l1 === needL1);
  const defectsAfterL2 = defectsAfterL1.filter(({ defectType }) => defectType.l2 === needL2);
  const defectsAfterL3 = defectsAfterL2.filter(({ defectType }) => defectType.l3 === needL3);

  const wordingsL1 =
    NEED_CATEGORIES.map((l1) => ({
      ...l1,
      defects: allDefects.filter(({ defectType }) => defectType.l1 === l1.id),
    })).filter(({ defects }) => defects.length > 0) ?? [];
  const needL1Wording = wordingsL1.find((l1) => l1.id === needL1);

  const wordingsL2AfterL1 =
    needL1Wording?.categories
      .map((l2) => ({
        ...l2,
        defects: defectsAfterL1.filter(({ defectType }) => defectType.l2 === l2.id),
      }))
      .filter(({ defects }) => defects.length > 0) ?? [];
  const needL2Wording = wordingsL2AfterL1.find((l2) => l2.id === needL2);

  const wordingsL3AfterL2 =
    needL2Wording?.categories
      .map((l3) => ({
        ...l3,
        defects: defectsAfterL2.filter(({ defectType }) => defectType.l3 === l3.id),
      }))
      .filter(({ defects }) => defects.length > 0) ?? [];
  const needL3Wording = wordingsL3AfterL2.find((l3) => l3.id === needL3);

  const wordingsL4AfterL3 =
    needL3Wording?.categories
      .map((l4) => ({
        ...l4,
        defects: defectsAfterL3.filter(({ defectType }) => defectType.l4 === l4.id),
      }))
      .filter(({ defects }) => defects.length > 0) ?? [];

  const needL1Options = wordingsL1.map((l1) => ({
    value: l1.id,
    text: i18n._(l1.label),
  }));

  const baseNeedL2Options = allowDefectType
    ? wordingsL2AfterL1.map((l2) => ({
        value: l2.id,
        text: i18n._(l2.label),
        icon: 'icon' in l2 ? l2.icon : undefined,
      }))
    : [];

  const needL2Options = allowCustomDefects
    ? [
        ...baseNeedL2Options,
        {
          value: 'custom-defect',
          text: t({ id: 'client.new.actions.form.custom-action.text', message: 'A doubt?' }),
          subText: t({
            id: 'client.new.actions.form.custom-action.sub-text',
            message: 'Describe your issue',
          }),
          icon: undefined,
        },
      ]
    : baseNeedL2Options;

  const needL3Options = wordingsL3AfterL2.map((l3) => ({
    value: l3.id,
    text: i18n._(l3.label),
    icon: 'icon' in l3 ? l3.icon : undefined,
  }));

  const needL4Options = wordingsL4AfterL3.map((l4) => ({
    value: l4.id,
    text: i18n._(l4.label),
  }));

  const openQuestionLabel = msg({
    id: 'client.new.actions.form.open-question',
    message: 'Could you specify?',
  });

  const quantityQuestionLabel = msg({
    id: 'client.new.actions.form.quantity-question',
    message: 'Please specify the quantity of this defect',
  });

  const areDefectPhotosValid =
    !request.organization.isDefectPhotoFieldRequired ||
    (defectPhotosByDefect.length >= quantity &&
      defectPhotosByDefect.slice(0, quantity).every((defectPhotos) => defectPhotos.length > 0));

  const disableSave = (!defect && !customDefectDescription) || !areDefectPhotosValid;

  const { mutateAsync: createDefect, isPending: isPendingCreateDefect } = useCreateDefect({
    articleId: article.id,
    requestId: request.id,
  });

  const { mutateAsync: updateDefect, isPending: isPendingUpdateDefect } = useUpdateDefect({
    articleId: article.id,
    requestId: request.id,
  });

  const saveDefects = async () => {
    if (!defect && !customDefectDescription) {
      return;
    }

    if (defect) {
      for (let i = 0; i < quantity; i++) {
        const existingDefect = initialDefects[i];

        if (existingDefect) {
          await updateDefect({
            defectId: existingDefect.id,
            body: {
              defectTypeOrganizationId: defect.id,
              defectPhotoIds: defectPhotosByDefect[i]?.map(({ id }) => id) ?? [],
              context: contextByDefect[i] ?? null,
            },
          });
        } else {
          await createDefect({
            defectTypeOrganization: defect,
            defectPhotoIds: defectPhotosByDefect[i]?.map(({ id }) => id) ?? [],
            context: contextByDefect[i] ?? null,
          });
        }
      }
    } else if (customDefectDescription) {
      for (let i = 0; i < quantity; i++) {
        const existingDefect = initialDefects[i];

        if (existingDefect) {
          await updateDefect({
            defectId: existingDefect.id,
            body: {
              description: customDefectDescription,
              defectPhotoIds: defectPhotosByDefect[i]?.map(({ id }) => id) ?? [],
              context: contextByDefect[i] ?? null,
            },
          });
        } else {
          await createDefect({
            description: customDefectDescription,
            defectPhotoIds: defectPhotosByDefect[i]?.map(({ id }) => id) ?? [],
            context: contextByDefect[i] ?? null,
          });
        }
      }
    }

    const extraMediaToDelete = defectPhotosByDefect
      .slice(quantity)
      .flatMap((media) => media.map(({ id }) => id));

    [...mediaToDelete, ...extraMediaToDelete].forEach((id) => deleteMedium(id));
    onSaveDefects();
  };

  const discard = () => {
    const initialDefectPhotoIds = initialDefects.flatMap((defect) =>
      defect.media.map(({ id }) => id)
    );
    const currentDefectPhotoIds = defectPhotosByDefect.flatMap((media) =>
      media.map(({ id }) => id)
    );

    currentDefectPhotoIds
      .filter((id) => !initialDefectPhotoIds.includes(id))
      .forEach((id) => deleteMedium(id));

    onCancel();
  };

  useEffect(() => {
    if (needL1Options.length === 1) {
      setNeedL1(needL1Options[0].value);
    }
  }, [needL1Options]);

  const progress =
    needL1 || needL2 === 'custom-defect'
      ? needL2
        ? needL3 || needL2 === 'custom-defect' || !!defect
          ? needL4 || needL2 === 'custom-defect' || !!defect
            ? areDefectPhotosValid
              ? isPendingCreateDefect || isPendingUpdateDefect
                ? 100
                : 95
              : 85
            : 75
          : 65
        : 55
      : 50;

  return (
    <div className={block()}>
      <main>
        <div className={element('article')}>
          <ArticleCard article={article} onEdit={onEditArticle} onDelete={onDeleteArticle} />
        </div>
        <Stack className={element('defects')} gap="3rem">
          {needL1Options.length > 1 && (
            <Label
              label={t({
                id: 'client.new.actions.form.title',
                message: 'What type of service do you need?',
              })}
              size="large"
            >
              <RadioCardGroup
                name="need-l1"
                theme="brand"
                value={needL1}
                onChange={(value) => {
                  setNeedL1(value);
                  setNeedL2(undefined);
                  setNeedL3(undefined);
                  setNeedL4(undefined);
                  setDefect(undefined);
                  handleSetQuantity(1);
                  scrollToNeedL2();
                }}
                scrollToOnMount
              >
                {needL1Options.map((l1) => (
                  <RadioCard key={l1.value} value={l1.value} ariaLabel={l1.text}>
                    {l1.text}
                  </RadioCard>
                ))}
              </RadioCardGroup>
            </Label>
          )}
          {!!needL1 && needL2Options.length > 0 && (
            <Label
              label={i18n._(
                needL1Wording && 'nextLabel' in needL1Wording
                  ? needL1Wording.nextLabel
                  : openQuestionLabel
              )}
              size="large"
            >
              <RadioCardGroup
                name="need-l2"
                theme="brand"
                direction="column"
                value={needL2}
                onChange={(value) => {
                  setNeedL2(value);
                  setNeedL3(undefined);
                  setNeedL4(undefined);
                  handleSetQuantity(1);

                  const option = wordingsL2AfterL1.find((l2) => l2.id === value);

                  if (option?.categories.length === 0) {
                    setDefect(option.defects[0]);
                  } else {
                    setDefect(undefined);
                    scrollToNeedL3();
                  }
                }}
                ref={needL2Ref}
                scrollToOnMount
              >
                {needL2Options.map((l2) => (
                  <RadioCard key={l2.value} value={l2.value} ariaLabel={l2.text} picture={l2.icon}>
                    {l2.text}
                  </RadioCard>
                ))}
              </RadioCardGroup>
            </Label>
          )}
          {!!needL2 && needL3Options.length > 0 && (
            <Label
              label={i18n._(
                needL2Wording && 'nextLabel' in needL2Wording
                  ? needL2Wording.nextLabel
                  : openQuestionLabel
              )}
              size="large"
            >
              <RadioCardGroup
                name="need-l3"
                theme="brand"
                direction="column"
                value={needL3}
                onChange={(value) => {
                  setNeedL3(value);
                  setNeedL4(undefined);
                  handleSetQuantity(1);

                  const option = wordingsL3AfterL2.find((l3) => l3.id === value);

                  if (option?.categories.length === 0) {
                    setDefect(option.defects[0]);
                  } else {
                    setDefect(undefined);
                    scrollToNeedL4();
                  }
                }}
                ref={needL3Ref}
                scrollToOnMount
              >
                {needL3Options.map((l3) => (
                  <RadioCard key={l3.value} value={l3.value} ariaLabel={l3.text} picture={l3.icon}>
                    {l3.text}
                  </RadioCard>
                ))}
              </RadioCardGroup>
            </Label>
          )}
          {!!needL3 && needL4Options.length > 0 && (
            <Label
              label={i18n._(
                needL3Wording && 'nextLabel' in needL3Wording
                  ? needL3Wording.nextLabel
                  : openQuestionLabel
              )}
              size="large"
            >
              <RadioCardGroup
                name="need-l4"
                theme="brand"
                value={needL4}
                onChange={(value) => {
                  setNeedL4(value);

                  const option = wordingsL4AfterL3.find((l4) => l4.id === value)!;

                  setDefect(option.defects[0]);
                  handleSetQuantity(1);
                }}
                ref={needL4Ref}
                scrollToOnMount
              >
                {needL4Options.map((l4) => (
                  <RadioCard key={l4.value} value={l4.value} ariaLabel={l4.text}>
                    {l4.text}
                  </RadioCard>
                ))}
              </RadioCardGroup>
            </Label>
          )}
          {needL2 === 'custom-defect' && (
            <TextArea
              label={t({
                id: 'client.new.actions.form.custom-action.label',
                message: "Describe the item's issues",
              })}
              value={customDefectDescription}
              onChange={(e) => setCustomDefectDescription(e.target.value)}
              rows={6}
              size="large"
              scrollToAndFocusOnRender
            />
          )}
          {defect?.defectType.isQuantifiable && (
            <Stack gap="0.5rem">
              <p className="paragraph-50-medium">{i18n._(quantityQuestionLabel)}</p>
              <InputQuantity
                quantity={quantity}
                onDecrement={() => handleSetQuantity(quantity - 1)}
                onIncrement={() => handleSetQuantity(quantity + 1)}
                disableDelete
                size="large"
                variant="brand"
              />
            </Stack>
          )}
          {(!!defect || needL2 === 'custom-defect') && (
            <DefectsPhotosAndContexts
              defectPhotosByDefect={defectPhotosByDefect}
              setDefectPhotosByDefect={setDefectPhotosByDefect}
              mediaToDelete={mediaToDelete}
              setMediaToDelete={setMediaToDelete}
              contextByDefect={contextByDefect}
              setContextByDefect={setContextByDefect}
              quantity={quantity}
            />
          )}
        </Stack>
      </main>
      <footer>
        <ProgressBar progress={progress} />
        <Stack row gap={isMobile ? '1rem' : '2rem'}>
          {(article.hasDefects || request.articles.length > 1) && (
            <Button
              size="large"
              variant="secondary-brand"
              style={{ flex: 1 }}
              onPress={() => {
                if (article.hasDefects) {
                  discard();
                } else {
                  onDeleteArticle();
                }
              }}
              disabled={isPendingUpdateDefect || isPendingCreateDefect}
            >
              <Trans id="client.new.actions.form.cancel">Cancel</Trans>
            </Button>
          )}
          <Button
            size="large"
            variant="brand"
            style={{ flex: 1 }}
            disabled={disableSave}
            onPress={saveDefects}
            isLoading={isPendingUpdateDefect || isPendingCreateDefect}
          >
            {isEdit ? (
              <Trans id="client.new.actions.form.save">Save</Trans>
            ) : (
              <Trans id="client.new.actions.form.add-to-cart">Add to my cart</Trans>
            )}
          </Button>
        </Stack>
      </footer>
    </div>
  );
};

export default DefectsForm;

const DefectsPhotosAndContexts = ({
  defectPhotosByDefect,
  setDefectPhotosByDefect,
  mediaToDelete,
  setMediaToDelete,
  contextByDefect,
  setContextByDefect,
  quantity,
}: {
  defectPhotosByDefect: Medium[][];
  setDefectPhotosByDefect: (param: (defectPhotosByDefect: Medium[][]) => Medium[][]) => void;
  mediaToDelete: string[];
  setMediaToDelete: (mediaToDelete: string[]) => void;
  contextByDefect: string[];
  setContextByDefect: (param: (contexts: string[]) => string[]) => void;
  quantity: number;
}) => {
  const { t } = useLingui();
  const { request } = useBrandRequestContext();

  return [...new Array(quantity)].map((_x, index) => (
    <Stack gap="1rem" key={index}>
      {request.organization.isDefectPhotoFieldEnabled && (
        <FileUpload
          uploadData={{ type: 'defect-photo' }}
          type="photo"
          label={
            quantity > 1
              ? t({
                  id: 'client.new.actions.form.photo.label.multiple',
                  message: `Add photo(s) of defect #${index + 1}`,
                })
              : t({
                  id: 'client.new.actions.form.photo.label.single',
                  message: `Add photo(s) of the defect`,
                })
          }
          media={defectPhotosByDefect[index]}
          onDelete={(idToDelete) => {
            setMediaToDelete([...mediaToDelete, idToDelete]);
            setDefectPhotosByDefect((prevDefectPhotosByDefect) =>
              prevDefectPhotosByDefect.map((prevDefectPhotos, _index) =>
                _index === index
                  ? prevDefectPhotos.filter(({ id }) => id !== idToDelete)
                  : prevDefectPhotos
              )
            );
          }}
          onUpload={(newMedium) => {
            setDefectPhotosByDefect((prevDefectPhotosByDefect) =>
              prevDefectPhotosByDefect.map((prevDefectPhotos, _index) =>
                _index === index ? [...prevDefectPhotos, newMedium] : prevDefectPhotos
              )
            );
          }}
          size="xxlarge"
          theme="brand"
          onMount={() => {
            window.scroll({
              top: document.body.scrollHeight,
              behavior: 'smooth',
            });
          }}
        />
      )}
      <TextArea
        label={
          <span>
            {quantity > 1
              ? t({
                  id: 'client.new.actions.form.context.label.multiple',
                  message: `Defect context #${index + 1}`,
                })
              : t({
                  id: 'client.new.actions.form.context.label.single',
                  message: `Defect context`,
                })}{' '}
            <span className="paragraph-100-regular text-disabled">
              <Trans id="client.new.actions.form.context.optional">(optional)</Trans>
            </span>
          </span>
        }
        placeholder={t({
          id: 'client.new.actions.form.context.placeholder',
          message: 'Describe how the defect appeared...',
        })}
        value={contextByDefect[index]}
        onChange={(e) => {
          setContextByDefect((prevContexts) =>
            prevContexts.map((prevContext, _index) =>
              _index === index ? e.target.value : prevContext
            )
          );
        }}
        size="large"
      />
    </Stack>
  ));
};
