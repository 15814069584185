import { useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import IconComment from '@/icons/Comment.svg';
import { useCurrentOrganization } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

export const ContactAfterSales = ({ onPress }: { onPress: () => void }) => {
  const { isMobile } = useViewPort();
  const { t } = useLingui();
  const [organization] = useCurrentOrganization();

  const organizationName = organization?.name;

  const label = t({
    id: 'brand.request.contact-after-sales',
    message: `Contact ${organizationName}`,
  });

  if (isMobile) {
    return (
      <Button
        iconOnly
        variant="secondary-brand"
        size="large"
        ariaLabel={label}
        tooltip={label}
        onPress={onPress}
      >
        <IconComment />
      </Button>
    );
  }

  return (
    <Button variant="secondary-brand" size="medium" onPress={onPress} style={{ flex: 1 }}>
      <IconComment />
      {label}
    </Button>
  );
};
