import { useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';

import WorkshopList, { WorkshopListEmptyState } from '@/components/WorkshopList';
import { WorkshopListItem } from '@/components/WorkshopList/WorkshopList';
import AlertBar from '@/design_system/AlertBar';
import { Label } from '@/design_system/Label/Label';
import Message from '@/design_system/Message';
import { RadioItem, RadioItemGroup } from '@/design_system/Radio/RadioItem/RadioItem';
import Stack from '@/design_system/Stack';
import { useActivities, useUpdateServiceChoice } from '@/models/article';
import { useWorkshops } from '@/models/workshop';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { useCurrentSession } from '@/services/auth';

export const ArticleDispatch = () => {
  const { view } = useArticleContext();

  const {
    data: { workshops: internalWorkshops } = { workshops: [] },
    isLoading: isLoadingInternalWorkshops,
  } = useWorkshops(
    {
      limit: 1,
      internal: true,
    },
    {
      enabled: view.services.dispatch.editable,
    }
  );

  const {
    data: { workshops: externalWorkshops } = { workshops: [] },
    isLoading: isLoadingExternalWorkshops,
  } = useWorkshops(
    {
      limit: 1,
      internal: false,
    },
    {
      enabled: view.services.dispatch.editable,
    }
  );

  const availableWorkshopTypes: ('internal' | 'external')[] = [];

  if (internalWorkshops.length > 0) {
    availableWorkshopTypes.push('internal');
  }

  if (externalWorkshops.length > 0) {
    availableWorkshopTypes.push('external');
  }

  if (isLoadingInternalWorkshops || isLoadingExternalWorkshops) {
    return null;
  }

  return <WorkshopSelector availableWorkshopTypes={availableWorkshopTypes} />;
};

const WorkshopSelector = ({
  availableWorkshopTypes,
}: {
  availableWorkshopTypes: ('internal' | 'external')[];
}) => {
  const { request, article, view, errors } = useArticleContext();

  const { t } = useLingui();

  const [workshopType, setWorkshopType] = useState<'internal' | 'external' | undefined>(
    article.workshop
      ? article.workshop.external
        ? 'external'
        : 'internal'
      : availableWorkshopTypes.length === 1
        ? availableWorkshopTypes[0]
        : undefined
  );

  const { data: { workshops } = { workshops: [] }, isFetching: isFetchingWorkshops } = useWorkshops(
    {
      articleId: article.id,
      limit: 100,
    },
    {
      enabled: article.hasActions,
      keepPreviousData: true,
    }
  );

  const { data: { activities: jobRefusedActivities } = { activities: [] } } = useActivities({
    articleId: article.id,
    types: ['job_refused'],
  });

  const { mutateAsync: updateServiceChoice } = useUpdateServiceChoice({
    requestId: request.id,
    articleId: article.id,
  });

  const externalWorkshops = workshops.filter((workshop) => workshop.external);
  const internalWorkshops = workshops.filter((workshop) => !workshop.external);

  const listedWorkshops =
    workshopType === 'external'
      ? externalWorkshops
      : workshopType === 'internal'
        ? internalWorkshops
        : [];
  const selectedWorkshop = listedWorkshops.find((workshop) => workshop.id === article.workshopId);

  if (!view.services.dispatch.editable) {
    return (
      <Stack gap="0.25rem" className="text-primary">
        <p className="label-100">
          <Trans id="article.service-choice.dispatch.assigned.label">Assigned workshop</Trans>
        </p>
        {selectedWorkshop && <WorkshopListItem workshop={selectedWorkshop} isDisabled />}
        {!selectedWorkshop && (
          <p className="paragraph-100-regular">
            <Trans id="article.service-choice.dispatch.assigned.none">None</Trans>
          </p>
        )}
      </Stack>
    );
  }

  return (
    <Stack gap="2rem">
      <Stack gap="0.25rem">
        {availableWorkshopTypes.length > 1 && (
          <Label
            label={t({
              id: 'article.service-choice.dispatch.selector.label',
              message: 'Select a workshop to handle the job',
            })}
          >
            <RadioItemGroup
              name="workshopType"
              value={workshopType}
              onChange={setWorkshopType}
              disabled={!article.hasActions}
            >
              <RadioItem
                value="internal"
                ariaLabel={t({
                  id: 'workshop.internal',
                  message: 'Internal',
                })}
              >
                <Trans id="workshop.internal">Internal</Trans>
              </RadioItem>
              <RadioItem
                value="external"
                ariaLabel={t({
                  id: 'workshop.external',
                  message: 'External',
                })}
              >
                <Trans id="workshop.external">External</Trans>
              </RadioItem>
            </RadioItemGroup>
          </Label>
        )}
        {availableWorkshopTypes.length === 1 && (
          <p className="label-100 text-primary">
            <Trans id="article.service-choice.dispatch.selector.label">
              Select a workshop to handle the job
            </Trans>
          </p>
        )}

        {!article.hasActions && (
          <Message type="info">
            <Trans id="article.service-choice.dispatch.no-actions">
              Please set the repair actions before selecting the workshop.
            </Trans>
          </Message>
        )}

        {!!workshopType && article.hasActions && listedWorkshops.length > 0 && (
          <WorkshopList
            selected={article.workshopId ?? undefined}
            workshops={listedWorkshops}
            jobRefusedActivities={jobRefusedActivities}
            onSelect={(workshop) => {
              updateServiceChoice({
                workshopId: workshop.id,
              });
            }}
            isFetchingWorkshops={isFetchingWorkshops}
          />
        )}

        {!!workshopType && article.hasActions && listedWorkshops.length === 0 && (
          <WorkshopListEmptyState
            emptyStateText={t({
              id: 'article.service-choice.dispatch.no-workshop-found',
              message: "We couldn't find any workshop for this kind of care and repair job needs.",
            })}
          />
        )}

        {article.hasActions && errors.services?.dispatch?.noWorkshopSelected && (
          <Message type="error">
            <Trans id="article.service-choice.dispatch.error.no-workshop-selected">
              Please select a workshop to handle the job.
            </Trans>
          </Message>
        )}
      </Stack>

      {!!selectedWorkshop?.nonDigitalized && <NonDigitalizedWorkshopMessage />}
    </Stack>
  );
};

const NonDigitalizedWorkshopMessage = () => {
  const { currentSession } = useCurrentSession();

  const isStoreThatWillActOnBehalf = currentSession?.hasPermissionOnAnyScope(
    'act_on_behalf_of_non_digitalized_workshop'
  );

  if (isStoreThatWillActOnBehalf) {
    return null;
  }

  return (
    <AlertBar type="warning" size="large">
      <Trans id="article.service-choice.dispatch.warning.non-digitalized">
        By choosing a non-digitalized workshop, the store will need to validate the completion of
        the tasks on its behalf.
      </Trans>
    </AlertBar>
  );
};
