import Stack from '@/design_system/Stack';
import { PackActionTypeOrganizationWithRelations } from '@/models/actionType';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import { useCurrentSession } from '@/services/auth';
import { formatCurrency } from '@/utils/number';

import PackActionName from './PackActionName';

export const PackActionDropdownItem = ({
  packAction,
}: {
  packAction: PackActionTypeOrganizationWithRelations;
}) => {
  const { currentSession, isWorkshop } = useCurrentSession();

  const { view: requestView } = useRequestContext();

  const showWorkshopPrice = isWorkshop ? requestView.price.enabled : requestView.cost.enabled;
  const showOrganizationPrice = isWorkshop ? false : requestView.price.enabled;

  const showWorkshopAmountBeforeTaxes = isWorkshop
    ? requestView.price.showAmountBeforeTaxes
    : requestView.cost.showAmountBeforeTaxes;

  const showOrganizationAmountBeforeTaxes = isWorkshop
    ? false
    : requestView.price.showAmountBeforeTaxes;

  return (
    <Stack
      row
      alignItems="center"
      justifyContent="space-between"
      gap="0.5rem"
      flexWrap="nowrap"
      style={{ flex: 1 }}
    >
      <PackActionName
        packActionType={
          !currentSession?.workshop && !!packAction.refashionBonus
            ? Object.assign(packAction, {
                actions: packAction.actions.map((action) =>
                  Object.assign(action, {
                    refashionStatus: action.refashionId ? 'applied' : undefined,
                  })
                ),
              })
            : packAction
        }
        alwaysShowActions
        style={{ textAlign: 'start' }}
      />
      <Stack row alignItems="center" gap="0.5rem" flexWrap="nowrap">
        {showWorkshopPrice && (
          <p className="paragraph-100-regular">
            {formatCurrency(
              showWorkshopAmountBeforeTaxes
                ? packAction.dynamicCost?.amountBeforeTaxes
                : packAction.dynamicCost?.amount,
              packAction.dynamicCost?.currency
            )}
          </p>
        )}
        {showOrganizationPrice && (
          <p className="paragraph-100-medium">
            {formatCurrency(
              showOrganizationAmountBeforeTaxes
                ? packAction.dynamicPrice?.amountBeforeTaxes
                : packAction.dynamicPrice?.amount,
              packAction.dynamicPrice?.currency
            )}
          </p>
        )}
      </Stack>
    </Stack>
  );
};
