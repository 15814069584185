import { useMemo, useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';
import debounce from 'lodash.debounce';

import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import { RadioBox, RadioBoxGroup } from '@/design_system/Radio/RadioBox/RadioBox';
import { RadioItem, RadioItemGroup } from '@/design_system/Radio/RadioItem/RadioItem';
import Stack from '@/design_system/Stack';
import { Cell, Row } from '@/design_system/Table/Table';
import TextArea from '@/design_system/TextArea';
import { ARTICLE_CANCELLATION_REASONS, ArticleCancellationDetail } from '@/models/article';

export type Choice = {
  value: 'accepted' | 'refused' | null;
  reason: ArticleCancellationDetail['reason'] | null;
  otherReason: string | null;
};

export type Choices = Record<string, Choice>;

export const ArticleChoiceCell = ({
  choice,
  setChoice,
  showChoicesError,
}: {
  choice: Choice;
  setChoice: (choice: Choice) => void;
  showChoicesError?: boolean;
}) => {
  const { t } = useLingui();

  return (
    <RadioItemGroup
      name="article-choice"
      value={choice.value}
      onChange={(value) => setChoice({ ...choice, value })}
      direction="column"
      theme="brand"
      invalid={showChoicesError && !choice.value}
    >
      <RadioItem
        value="accepted"
        ariaLabel={t({ id: 'article-choice-cell.option.accept', message: 'Accept' })}
      >
        <Trans id="article-choice-cell.option.accept">Accept</Trans>
      </RadioItem>
      <RadioItem
        value="refused"
        ariaLabel={t({ id: 'article-choice-cell.option.refuse', message: 'Refuse' })}
      >
        <Trans id="article-choice-cell.option.refuse">Refuse</Trans>
      </RadioItem>
    </RadioItemGroup>
  );
};

const ChoiceRefusedInputs = ({
  choice,
  setChoice,
  showChoicesError,
}: {
  choice: Choice;
  setChoice: (choice: Choice) => void;
  showChoicesError?: boolean;
}) => {
  const { t, i18n } = useLingui();

  const missingReason = showChoicesError && !choice.reason;
  const missingOtherReason = showChoicesError && choice.reason === 'other' && !choice.otherReason;

  const [otherReason, setOtherReason] = useState<string | undefined>(
    choice.otherReason ?? undefined
  );

  const debouncedSetChoice = useMemo(() => {
    return debounce((choice: Choice) => {
      setChoice(choice);
    }, 500);
  }, [setChoice]);

  return (
    <Stack gap="0.5rem">
      <InputSelect
        aria-label={t({
          id: 'client.request.refusal.reason.label',
          message: 'Refusal reason',
        })}
        placeholder={t({
          id: 'client.request.refusal.reason.placeholder',
          message: 'Select a reason...',
        })}
        options={ARTICLE_CANCELLATION_REASONS.map(({ id }) => ({ id }))}
        variant="select"
        value={choice.reason ? { id: choice.reason } : null}
        isSearchable={false}
        onChange={(reason) => setChoice({ ...choice, reason: reason?.id ?? null })}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) =>
          i18n._(ARTICLE_CANCELLATION_REASONS.find(({ id }) => id === option.id)!.label)
        }
      />
      {choice.reason === 'other' && (
        <>
          <TextArea
            ariaLabel={t({
              id: 'client.request.refusal.other.placeholder',
              message: 'Specify the reason...',
            })}
            placeholder={t({
              id: 'client.request.refusal.other.placeholder',
              message: 'Specify the reason...',
            })}
            value={otherReason}
            onChange={(evt) => {
              setOtherReason(evt.target.value);
              debouncedSetChoice({ ...choice, otherReason: evt.target.value });
            }}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            isInvalid={missingOtherReason}
            // Because the TextArea is in a Table, we need to use a native textarea to avoid
            // the focus trap issue when trying to add characters in the middle of a sentence.
            isNativeTextarea
          />
        </>
      )}
      {(missingReason || missingOtherReason) && (
        <p className="text-danger paragraph-100-regular">
          {missingReason ? (
            <Trans id="article-choice-cell.error.missing-reason">Please select a reason</Trans>
          ) : missingOtherReason ? (
            <Trans id="article-choice-cell.error.missing-other-reason">
              Please specify the reason
            </Trans>
          ) : null}
        </p>
      )}
    </Stack>
  );
};

export const ChoiceRefusedRow = ({
  choice,
  setChoice,
  showChoicesError,
}: {
  choice: Choice;
  setChoice: (choice: Choice) => void;
  showChoicesError?: boolean;
}) => {
  return (
    <Row isExtensionRow>
      <Cell isWholeRow>
        <ChoiceRefusedInputs
          choice={choice}
          setChoice={setChoice}
          showChoicesError={showChoicesError}
        />
      </Cell>
    </Row>
  );
};

export const ArticleChoiceSection = ({
  choice,
  setChoice,
  showChoicesError,
}: {
  choice: Choice;
  setChoice: (choice: Choice) => void;
  showChoicesError?: boolean;
}) => {
  const { t } = useLingui();

  return (
    <Stack gap="0.75rem">
      <RadioBoxGroup
        name="article-choice"
        ariaLabel={t({ id: 'article-choice-cell.label', message: 'Choice' })}
        value={choice.value}
        onChange={(value) => setChoice({ ...choice, value })}
        theme="brand"
        style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(8rem, 1fr))' }}
      >
        <RadioBox
          value="accepted"
          ariaLabel={t({ id: 'article-choice-cell.option.accept', message: 'Accept' })}
          title={<Trans id="article-choice-cell.option.accept">Accept</Trans>}
        />
        <RadioBox
          value="refused"
          ariaLabel={t({ id: 'article-choice-cell.option.refuse', message: 'Refuse' })}
          title={<Trans id="article-choice-cell.option.refuse">Refuse</Trans>}
        />
      </RadioBoxGroup>
      {choice.value === 'refused' && (
        <ChoiceRefusedInputs
          choice={choice}
          setChoice={setChoice}
          showChoicesError={showChoicesError}
        />
      )}
    </Stack>
  );
};
