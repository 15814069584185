import { ReactNode } from 'react';
import { Button as AriaButton } from 'react-aria-components';
import { Trans, useLingui } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import Loader from '@/components/Loader';
import Badge from '@/design_system/Badge';
import Button from '@/design_system/Button/Button';
import Stack from '@/design_system/Stack';
import Tooltip from '@/design_system/Tooltip';
import IconInfo from '@/icons/Info.svg';
import IconNoConnection from '@/icons/NoConnection.svg';
import IconPhone from '@/icons/Phone.svg';
import IconPlace from '@/icons/Place.svg';
import IconRefusal from '@/icons/Refusal.svg';
import IconValidation from '@/icons/Validation.svg';
import { ArticleActivityOfType, JOB_REFUSED_REASONS } from '@/models/article';
import { WorkshopFee, WorkshopWithRelations } from '@/models/workshop';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { useFlags } from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';
import { formatCurrency } from '@/utils/number';
import useViewPort from '@/utils/useViewport';

import './WorkshopList.css';

type WorkshopListProps = {
  selected?: string;
  workshops: WorkshopWithRelations[];
  jobRefusedActivities: ArticleActivityOfType<'job_refused'>[];
  onSelect: (workshop: WorkshopWithRelations) => void;
  isFetchingWorkshops?: boolean;
};

const { block, element } = createBEMClasses('workshop-list');

const WorkshopList = ({
  workshops,
  jobRefusedActivities,
  selected,
  onSelect,
  isFetchingWorkshops,
}: WorkshopListProps) => {
  const { t } = useLingui();

  const otherRegion = t({ id: 'workshop.region.other', message: 'Other' });

  const workshopsByRegion = workshops.reduce<Record<string, WorkshopWithRelations[]>>(
    (result, workshop) => {
      result[workshop.region ?? otherRegion] ??= [];
      result[workshop.region ?? otherRegion].push(workshop);

      return result;
    },
    {}
  );

  return (
    <Stack gap="8px" className={block({}, 'inner-shadow-on-scroll')}>
      {Object.entries(workshopsByRegion)
        .sort(([regionA], [regionB]) => (regionA > regionB ? 1 : -1))
        .map(([region, workshops]) => (
          <Stack
            key={region}
            gap="8px"
            ariaLabel={t({ id: 'workshop-list.region.label', message: `Workshops in ${region}` })}
          >
            <p className="paragraph-100-medium">{region}</p>
            {workshops.map((workshop) => {
              const jobRefusedActivity = jobRefusedActivities.find(
                (activity) => activity.workshopId === workshop.id
              );

              return (
                <WorkshopListItem
                  key={workshop.id}
                  workshop={workshop}
                  jobRefusedActivity={jobRefusedActivity}
                  onSelect={() => onSelect(workshop)}
                  isFetchingWorkshops={isFetchingWorkshops}
                  selected={selected}
                />
              );
            })}
          </Stack>
        ))}
    </Stack>
  );
};

export const WorkshopListItem = ({
  workshop,
  jobRefusedActivity,
  isDisabled,
  onSelect,
  isFetchingWorkshops,
  selected,
}: {
  workshop: WorkshopWithRelations;
  jobRefusedActivity?: ActivityOfType<'job_refused'>;
  onSelect?: () => void;
  selected?: string;
  isFetchingWorkshops?: boolean;
  isDisabled?: boolean;
}) => {
  const { isMobile } = useViewPort();
  const { t } = useLingui();

  const cancellationFee = workshop.organizationConfig?.fees.find(
    (fee) => fee.type === 'cancellation'
  );

  const shippingManagementFee = workshop.organizationConfig?.fees.find(
    (fee) => fee.type === 'shipping-management'
  );
  const shippingManagementFeeAmount = shippingManagementFee
    ? Math.round(
        (shippingManagementFee.amountBeforeTax * (100 + shippingManagementFee.taxRate)) / 100
      )
    : null;

  const descriptionItems = [
    workshop.areCustomsExpected ? (
      <p key="city" className="text-warning">
        <IconPlace warning />
        {workshop.address?.cityState} <Trans id="workshop-list.customs-expected">(Customs)</Trans>
      </p>
    ) : (
      <p key="city">
        <IconPlace />
        {workshop.address?.cityState}
      </p>
    ),
    <p key="phone">
      <IconPhone />
      {workshop.formattedPhone}
    </p>,
    workshop.organizationConfig?.autoAcceptDispatch ? <AutoAcceptBadge key="auto-accept" /> : null,
    cancellationFee ? (
      <CancellationFeeBadge key="cancellation-fee" cancellationFee={cancellationFee} />
    ) : null,
    isMobile && !!jobRefusedActivity ? <RefusedBadge activity={jobRefusedActivity} /> : null,
    isMobile && !!workshop.nonDigitalized ? <NonDigitalizedBadge /> : null,
  ]
    .filter(Boolean)
    .reduce((acc, item, index) => {
      if (index !== 0) {
        acc.push(
          <span key={`separator-${index}`} className="separator">
            •
          </span>
        );
      }

      acc.push(item);

      return acc;
    }, [] as ReactNode[]);

  const base = (
    <>
      <img src={workshop.logoSquare ?? workshop.logo ?? '/workshop.png'} alt="" />
      <div className={element('item__header')}>
        <div className={element('item__header__title')}>
          {workshop.name}
          {!isMobile && !!jobRefusedActivity && <RefusedBadge activity={jobRefusedActivity} />}
          {!isMobile && !!workshop.nonDigitalized && <NonDigitalizedBadge />}
        </div>
        <Stack row alignItems="center" gap="0.5rem">
          <p className={element('item__header__pricing')}>
            {isFetchingWorkshops && <Loader style={{ fontSize: '0.75rem' }} />}
            {formatCurrency(workshop.cost, workshop.currency)}
          </p>
          {!!shippingManagementFee && (
            <Stack row alignItems="center" gap="0.25rem">
              <p className={element('item__header__shipping-management-fee')}>
                <Trans id="components.workshop-list.shipping-management">
                  + {formatCurrency(shippingManagementFeeAmount, shippingManagementFee?.currency)}
                  /item
                </Trans>
              </p>
              <Tooltip
                content={t({
                  id: 'components.workshop-list.shipping-management.detail',
                  message: 'Per item sent by the workshop',
                })}
              >
                <Button variant="style-less">
                  <IconInfo style={{ fontSize: '1rem' }} className="text-disabled" />
                </Button>
              </Tooltip>
            </Stack>
          )}
        </Stack>
      </div>
      <div className={element('item__description')}>{descriptionItems}</div>
    </>
  );

  const selectedLabel = t({
    id: 'components.workshop-list.workshop-selected',
    message: `Workshop ${workshop.name} selected`,
  });

  if (isDisabled) {
    return (
      <div className={element('item')} aria-label={selectedLabel}>
        {base}
      </div>
    );
  }

  return (
    <AriaButton
      onPress={onSelect}
      className={element('item', { selected: workshop.id === selected })}
      aria-label={
        workshop.id === selected
          ? selectedLabel
          : t({
              id: 'components.workshop-list.select-workshop',
              message: `Select workshop ${workshop.name}`,
            })
      }
    >
      {base}
    </AriaButton>
  );
};

const AutoAcceptBadge = () => {
  const { flags } = useFlags();
  const { article } = useArticleContext();

  const isAutoAcceptDispatchPrevented = article.snapshot.articleActions.some(
    (action) => action.isCustom || !!action.customWorkshopPrice
  );

  if (isAutoAcceptDispatchPrevented && flags['enable-dispatch-cost-edition']) {
    return null;
  }

  return (
    <Badge size="small" color="secondary" borderRadius="soft" hasBorder>
      <Stack row alignItems="center" gap="4px">
        <IconValidation />
        <Trans id="components.workshop-list.auto-accept">Auto-accept</Trans>
      </Stack>
    </Badge>
  );
};

const NonDigitalizedBadge = () => (
  <Badge size="small" color="secondary" borderRadius="soft" hasBorder>
    <Stack row alignItems="center" gap="4px">
      <IconNoConnection />
      <Trans id="components.workshop-list.non-digitalized">Non-digitalized</Trans>
    </Stack>
  </Badge>
);

const RefusedBadge = ({ activity }: { activity: ActivityOfType<'job_refused'> }) => {
  const { i18n } = useLingui();

  const reasonDetail = JOB_REFUSED_REASONS.find(({ id }) => id === activity.data.reason);
  const reason =
    !reasonDetail || reasonDetail.id === 'custom'
      ? undefined
      : i18n._(activity.data.onBehalfOfWorkshop ? reasonDetail.onBehalfLabel : reasonDetail.label);
  const comment = activity.data.comment;

  if (!reason && !comment) {
    return (
      <Badge size="small" color="danger" borderRadius="soft" hasBorder>
        <Trans id="components.workshop-list.refused">Refused the job</Trans>
      </Badge>
    );
  }

  return (
    <Tooltip
      textAlign="start"
      content={
        <>
          {!!reason && <p className="paragraph-200-medium">{reason}</p>}
          {!!comment && (
            <p className="paragraph-200-regular text-disabled">&quot;{comment}&quot;</p>
          )}
        </>
      }
    >
      <Badge size="small" color="danger" borderRadius="soft" hasBorder onClick={() => {}}>
        <Trans id="components.workshop-list.refused">Refused the job</Trans>
      </Badge>
    </Tooltip>
  );
};

const CancellationFeeBadge = ({ cancellationFee }: { cancellationFee: WorkshopFee }) => {
  const amount = Math.round(
    (cancellationFee.amountBeforeTax * (100 + cancellationFee.taxRate)) / 100
  );

  return (
    <Badge size="small" color="warning" borderRadius="soft" hasBorder>
      <Stack row alignItems="center" gap="4px" className="paragraph-200-regular">
        <IconRefusal />
        <Trans id="components.workshop-list.cancellation-cost">
          Cancellation cost:{' '}
          <span className="paragraph-200-medium">
            {formatCurrency(amount, cancellationFee.currency)}/item
          </span>
        </Trans>
      </Stack>
    </Badge>
  );
};

export default WorkshopList;
