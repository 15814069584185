import { Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticlesNames,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconUserRefusal from '@/icons/UserRefusal.svg';
import IconUserValidation from '@/icons/UserValidation.svg';

export const ArticlePaymentChoiceActivity = ({
  activities,
  displayArticleName,
}: {
  activities: ActivityOfType<'article_payment_choice'>[];
  displayArticleName: boolean;
}) => {
  const firstActivity = activities[0];

  if (firstActivity.data.value === 'accepted') {
    return (
      <BaseActivity
        createdAt={firstActivity.createdAt}
        type="primary"
        icon={<IconUserValidation />}
        message={
          <BaseActivityText>
            {activities.length > 1 ? (
              <Trans id="activities.payment-choice.accepted.message.multi">
                <strong>The client</strong> accepted the final quote for the items{' '}
                <BaseActivityArticlesNames
                  activities={activities}
                  displayArticleName={displayArticleName}
                />
              </Trans>
            ) : (
              <Trans id="activities.payment-choice.accepted.message.single">
                <strong>The client</strong> accepted the final quote for the item{' '}
                <BaseActivityArticlesNames
                  activities={activities}
                  displayArticleName={displayArticleName}
                />
              </Trans>
            )}
          </BaseActivityText>
        }
      />
    );
  }

  return (
    <BaseActivity
      createdAt={firstActivity.createdAt}
      type="danger"
      icon={<IconUserRefusal />}
      message={
        <BaseActivityText>
          {activities.length > 1 ? (
            <Trans id="activities.payment-choice.refused.message.multi">
              <strong>The client</strong> refused the final quote for the items{' '}
              <BaseActivityArticlesNames
                activities={activities}
                displayArticleName={displayArticleName}
              />
            </Trans>
          ) : (
            <Trans id="activities.payment-choice.refused.message.single">
              <strong>The client</strong> refused the final quote for the item{' '}
              <BaseActivityArticlesNames
                activities={activities}
                displayArticleName={displayArticleName}
              />
            </Trans>
          )}
        </BaseActivityText>
      }
    />
  );
};
