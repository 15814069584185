import { Plural, Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import { BaseActivity, BaseActivityText } from '@/components/activities/BaseActivity/BaseActivity';
import IconCloth from '@/icons/Cloth.svg';

export const ClientPickupCompletedActivity = ({
  activity,
  nbOfArticles,
}: {
  activity: ActivityOfType<'client_pickup_completed'>;
  nbOfArticles: number;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="primary"
      icon={<IconCloth />}
      message={
        <BaseActivityText>
          <Trans id="activities.client_pickup_completed.picked-up">
            <strong>The client</strong> picked up the{' '}
            <Plural value={nbOfArticles ?? 0} one="item" other="items" />
          </Trans>
        </BaseActivityText>
      }
    />
  );
};
