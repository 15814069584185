import { ClientRequestWithRelations } from '@/models/request';
import { ClientArticleStepper } from '@/routes/Brand/Requests/Request/components/shared/ClientArticleStepper';
import useViewPort from '@/utils/useViewport';

const ClientStepper = ({ request }: { request: ClientRequestWithRelations }) => {
  const { isMobile } = useViewPort();

  if (!request.workflowId || request.articles.length !== 1) {
    return null;
  }

  return (
    <ClientArticleStepper
      size={isMobile ? 'small' : 'large'}
      article={request.articles[0]}
      showStepCount={isMobile}
    />
  );
};

export default ClientStepper;
