import { CardItem } from '@/components/Card/Card';
import IconBandage from '@/icons/Bandage.svg';
import { getGroupedDefects, useGetDefectName } from '@/models/defectType';
import { ArticleWithRelations } from '@/models/request';

export const ArticleDefectsCell = ({ article }: { article: ArticleWithRelations }) => {
  const getDefectName = useGetDefectName();
  const defects = getGroupedDefects(article.snapshot.articleDefects);

  if (!defects.length) {
    return <span className="paragraph-100-regular text-secondary">-</span>;
  }

  return (
    <ul className="paragraph-100-regular">
      {defects.map((defect) => (
        <li key={defect.id}>
          {getDefectName(defect)}
          {defect.quantity > 1 && <> (x{defect.quantity})</>}
        </li>
      ))}
    </ul>
  );
};

export const ArticleDefectsCardItems = ({ article }: { article: ArticleWithRelations }) => {
  const getDefectName = useGetDefectName();
  const defects = getGroupedDefects(article.snapshot.articleDefects);

  return (
    <>
      {defects.map((defect) => (
        <CardItem key={defect.id}>
          <IconBandage style={{ fontSize: '1rem' }} />
          {getDefectName(defect)}
          {defect.quantity > 1 && <> (x{defect.quantity})</>}
        </CardItem>
      ))}
    </>
  );
};
