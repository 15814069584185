import { createContext, PropsWithChildren, useContext } from 'react';

import { ClientRequestWithRelations } from '@/models/request';
import { Workflow } from '@/models/workflow';

type BrandRequestContextData = {
  request: ClientRequestWithRelations;
  workflow: Workflow | undefined;
};

const BrandRequestContext = createContext({} as BrandRequestContextData);

export const useBrandRequestContext = () => useContext(BrandRequestContext);

export const BrandRequestContextProvider = ({
  request,
  workflow,
  children,
}: PropsWithChildren<{ request: ClientRequestWithRelations; workflow: Workflow | undefined }>) => {
  return <BrandRequestContext value={{ request, workflow }}>{children}</BrandRequestContext>;
};
