import { Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityDetailedContent,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconComment from '@/icons/Comment.svg';

export const ArticleClientCommentUpdatedActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_client_comment_updated'>;
  displayArticleName: boolean;
}) => {
  const creatorName = activity.creator.name;

  return (
    <BaseActivity
      key={activity.id}
      createdAt={activity.createdAt}
      type="neutral"
      icon={<IconComment />}
      message={
        <BaseActivityText>
          {activity.data.comment ? (
            <Trans id="activities.article_client_comment_updated.message">
              <strong>{creatorName}</strong> added a client communication comment on the item{' '}
              <BaseActivityArticleName
                activity={activity}
                displayArticleName={displayArticleName}
              />
            </Trans>
          ) : (
            <Trans id="activities.article_client_comment_updated.message-no-comment">
              <strong>{creatorName}</strong> removed a client communication comment on the item{' '}
              <BaseActivityArticleName
                activity={activity}
                displayArticleName={displayArticleName}
              />
            </Trans>
          )}
        </BaseActivityText>
      }
    >
      {!!activity.data.comment && (
        <BaseActivityDetailedContent>{activity.data.comment}</BaseActivityDetailedContent>
      )}
    </BaseActivity>
  );
};
