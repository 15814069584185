import { useState } from 'react';
import { useLingui } from '@lingui/react';

import { ClientCommunicationDrawer } from '@/components/comments/ClientCommunicationDrawer/ClientCommunicationDrawer';
import { GoToCommentsMenuItem } from '@/components/comments/GoToCommentsButton/GoToCommentsButton';
import Button from '@/design_system/Button';
import Menu from '@/design_system/Menu';
import { Section } from '@/design_system/Menu/Menu';
import Stack from '@/design_system/Stack';
import IconArrow from '@/icons/Arrow.svg';
import IconMore from '@/icons/More.svg';
import { useArticleName } from '@/models/article';
import { useMarkAllNotificationsAsRead, useNotifications } from '@/models/notification';
import {
  ArticleWithRelations,
  getRequestorTypeLabel,
  RequestWithRelations,
  useComments,
} from '@/models/request';
import {
  PrintItemSheetMenuItem,
  usePrintActions,
} from '@/routes/Requests/Request/components/RequestHeader/PrintsButton';
import { useCurrentOrganization, useCurrentSession, useFlags } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

import { ClientCommunicationButton } from '../components/RequestHeader/ClientCommunicationButton';

export const ArticleHeader = ({
  request,
  article,
  onCommentButtonPress,
}: {
  request: RequestWithRelations;
  article: ArticleWithRelations;
  onCommentButtonPress: () => void;
}) => {
  const { isMobile } = useViewPort();
  const { _ } = useLingui();
  const { flags } = useFlags();
  const { currentSession } = useCurrentSession();
  const [currentOrganization] = useCurrentOrganization();
  const isOrganizationUser = currentSession?.workshop?.external !== true;

  const articleName = useArticleName({ article });
  const { data: { comments } = { comments: [] } } = useComments({
    requestId: request.id,
  });
  const [isClientCommunicationDrawerOpen, setIsClientCommunicationDrawerOpen] = useState(false);

  const { printItemSheet } = usePrintActions({ request, article });

  const hasViewClientCommunicationPermission = !!currentSession?.hasPermission(
    'view_client_communication',
    [
      {
        organizationId: currentOrganization?.id,
        storeId: null,
      },
      {
        organizationId: currentOrganization?.id,
        storeId: request.store?.id,
      },
    ]
  );

  const showClientCommunication =
    flags['enable-client-communication'] && request.client && hasViewClientCommunicationPermission;

  return (
    <Stack row gap={isMobile ? '0.5rem' : undefined} style={{ flex: 1, minWidth: 0 }}>
      {isMobile && (
        <Stack row>
          <Button variant="secondary" size="large" to={`/requests/${request.id}`} iconOnly noBorder>
            <IconArrow left />
          </Button>

          {article.articlePhoto && (
            <img
              src={article.articlePhoto.url}
              alt="article"
              style={{
                height: '40px',
                width: '40px',
                objectFit: 'contain',
                backgroundColor: 'var(--color-neutral-100)',
                borderRadius: '8px',
              }}
            />
          )}
        </Stack>
      )}

      <Stack gap={isMobile ? undefined : '0.25rem'} style={{ flex: 1, minWidth: 0 }}>
        <h2 className="headline-300-bold sentry-mask paragraph-200-medium-mobile text-ellipsis">
          [{request.reference}]{' '}
          {isOrganizationUser ? request.name.major : request.organization.name}
        </h2>
        {isMobile && <p className="headline-500-bold-mobile">{articleName}</p>}
        {!isMobile && (
          <p className="paragraph-50-regular">
            {isOrganizationUser
              ? request.name.minor
              : _(getRequestorTypeLabel(request.requestorType))}
          </p>
        )}
      </Stack>

      {!isMobile && showClientCommunication && (
        <ArticleHeaderClientCommunicationButton
          request={request}
          setIsClientCommunicationDrawerOpen={setIsClientCommunicationDrawerOpen}
        />
      )}

      {isMobile && (
        <Stack row alignItems="center">
          {showClientCommunication && (
            <ArticleHeaderClientCommunicationButton
              request={request}
              setIsClientCommunicationDrawerOpen={setIsClientCommunicationDrawerOpen}
            />
          )}
          <Menu
            placement="bottom end"
            trigger={
              <Button variant="secondary" size="large" iconOnly noBorder>
                <IconMore />
              </Button>
            }
            onAction={(id) => {
              switch (id) {
                case 'go-to-comments':
                  onCommentButtonPress();
                  break;
                case 'print-item-sheet':
                  printItemSheet();
                  break;
                default:
                  break;
              }
            }}
          >
            <Section>
              <GoToCommentsMenuItem nbOfComments={comments.length} />
            </Section>
            {!!article.serviceChosenAt && !article.archived && (
              <Section>
                <PrintItemSheetMenuItem singleItem />
              </Section>
            )}
          </Menu>
        </Stack>
      )}

      <ClientCommunicationDrawer
        request={request}
        isOpen={isClientCommunicationDrawerOpen}
        close={() => setIsClientCommunicationDrawerOpen(false)}
      />
    </Stack>
  );
};

const ArticleHeaderClientCommunicationButton = ({
  request,
  setIsClientCommunicationDrawerOpen,
}: {
  request: RequestWithRelations;
  setIsClientCommunicationDrawerOpen: (isOpen: boolean) => void;
}) => {
  const { mutateAsync: markAllNotificationsAsRead } = useMarkAllNotificationsAsRead();
  const { data } = useNotifications({
    requestId: request.id,
    filter: 'unreadOnly',
    types: ['client_comment_added'],
  });

  return (
    <ClientCommunicationButton
      hasUnreadNotifications={!!data?.notifications?.length}
      onPress={() => {
        setIsClientCommunicationDrawerOpen(true);
        markAllNotificationsAsRead({
          requestId: request.id,
          types: ['client_comment_added'],
        });
      }}
    />
  );
};
