import { Fragment, ReactNode, useState } from 'react';
import { Plural, Trans, useLingui } from '@lingui/react/macro';

import {
  ArticleActionsCell,
  ArticleNameCell,
  ClientArticleCommentCell,
} from '@/components/ArticlesTableCells';
import {
  ArticleChoiceCell,
  ArticleChoiceSection,
  Choice,
  ChoiceRefusedRow,
  Choices,
} from '@/components/ArticlesTableCells/ArticleChoiceCell';
import BaseMoneyCell from '@/components/ArticlesTableCells/ArticleMoneyCell/ArticleMoneyCell';
import ContactDetails from '@/components/ContactDetails';
import AlertBar from '@/design_system/AlertBar';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import { Label } from '@/design_system/Label/Label';
import { RadioBox, RadioBoxGroup } from '@/design_system/Radio/RadioBox/RadioBox';
import Stack from '@/design_system/Stack';
import { Body, Cell, Column, Header, Row, Table } from '@/design_system/Table/Table';
import IconExternalLink from '@/icons/ExternalLink.svg';
import IconStoreDeposit from '@/icons/StoreDeposit.svg';
import IconWarning from '@/icons/Warning.svg';
import { useGetArticleName } from '@/models/article';
import { useClientShippingOptions } from '@/models/client';
import {
  ClientArticleWithRelations,
  ClientRequestWithRelations,
  useUpdateRequestClient,
} from '@/models/request';
import { SHIPMENT_CARRIERS } from '@/models/shipment';
import { ClientRequestPrice } from '@/routes/Brand/components/ClientRequestPrice/ClientRequestPrice';
import { ClientArticleStepper } from '@/routes/Brand/Requests/Request/components/shared/ClientArticleStepper';
import { useClientToken, useStoreToken } from '@/services/auth';
import { formatDate } from '@/utils/date';
import { formatCurrency } from '@/utils/number';
import useViewPort from '@/utils/useViewport';

import { DropOffStoresDialog } from '../../ClientRequestView/components/DropOffStoresDialog';

import './ClientArticlesTable.css';

const ClientArticlesTable = ({
  request,
  mode = 'action',
  hideStepper = false,
  showPrice = false,
  showArticleComment = false,
  choices,
  setChoice,
  showChoicesError,
  showShippingOptions,
}: {
  request: ClientRequestWithRelations;
  mode?: 'defect' | 'action';
  hideStepper?: boolean;
  showPrice?: boolean;
  showArticleComment?: boolean;
  choices?: Choices;
  setChoice?: (articleId: string, choice: Choice) => void;
  showChoicesError?: boolean;
  showShippingOptions?: boolean;
}) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();
  const clientToken = useClientToken();

  const showDepositedOn = !!request.store && !!request.client;

  let articlesToDisplay: ClientArticleWithRelations[] = [];
  let articlesToPickup: ClientArticleWithRelations[] = [];

  if (request.isArchived) {
    articlesToDisplay = request.allArticles;
  } else {
    const noneOrExchangeOrRefundArticles = request.archivedArticles.filter(
      (article) =>
        article.serviceChoice === 'none' ||
        article.serviceChoice === 'exchange' ||
        article.serviceChoice === 'refund'
    );

    articlesToPickup = request.articles.filter(
      (article) =>
        article.quoteRefusedAt &&
        article.atStoreId &&
        article.toClient &&
        article.step?.step === 'transit' &&
        article.step.config.clientPickup
    );

    articlesToDisplay = [
      ...request.articles.filter((article) => !articlesToPickup.find((a) => a.id === article.id)),
      ...noneOrExchangeOrRefundArticles,
    ];
  }

  const showStepper = articlesToDisplay.length + articlesToPickup.length > 1 && !hideStepper;

  let list: ReactNode;

  if (isMobile) {
    list = (
      <>
        <Box padding="0" className="client-articles-list">
          <div className="client-articles-list__title">
            {clientToken ? (
              <Trans id="client.articles.list.title">My items</Trans>
            ) : (
              <Trans id="client.articles.list.title.store">The items</Trans>
            )}
          </div>
          {articlesToDisplay.map((article) => (
            <ArticleCard
              key={article.id}
              article={article}
              mode={mode}
              showPrice={showPrice}
              showStepper={showStepper}
              choice={choices?.[article.id]}
              setChoice={(choice) => setChoice?.(article.id, choice)}
              showChoicesError={showChoicesError}
            />
          ))}
        </Box>
      </>
    );
  } else {
    list = (
      <Table
        theme="brand"
        ariaLabel={t({ id: 'client.articles.table.label', message: 'Items' })}
        columnWidths={[
          'minmax(240px, 1fr)',
          showStepper && '250px',
          '1fr',
          showPrice && '140px',
          choices && '110px',
        ]}
        className="client-articles-table"
        bordered
      >
        <Header>
          <Row>
            <Column>
              <Trans id="client.articles.table.column.article.title">Item</Trans>
            </Column>
            {showStepper && (
              <Column>
                <Trans id="client.articles.table.column.status.title">Status</Trans>
              </Column>
            )}
            <Column>
              {mode === 'action' && (
                <Trans id="client.articles.table.column.services.title">Service required</Trans>
              )}
              {mode === 'defect' && (
                <Trans id="client.articles.table.column.defects.title">Noted defect</Trans>
              )}
            </Column>
            {showPrice && (
              <Column justifyContent="flex-end">
                <Trans id="client.articles.table.column.price.title">Price</Trans>
              </Column>
            )}
            {choices && (
              <Column
                justifyContent="flex-end"
                ariaLabel={t({
                  id: 'client.articles.table.column.choices.title',
                  message: 'Choices',
                })}
              />
            )}
          </Row>
        </Header>
        <Body>
          {articlesToDisplay.map((article) => (
            <Fragment key={article.id}>
              <ArticleRow
                key={article.id}
                article={article}
                mode={mode}
                showStepper={showStepper}
                showPrice={showPrice}
                showChoices={!!choices}
                choice={choices?.[article.id]}
                setChoice={(choice) => setChoice?.(article.id, choice)}
                showChoicesError={showChoicesError}
              />
              {!!choices?.[article.id] && choices[article.id].value === 'refused' && (
                <ChoiceRefusedRow
                  choice={choices[article.id]}
                  setChoice={(choice) => setChoice?.(article.id, choice)}
                  showChoicesError={showChoicesError}
                />
              )}
              {showArticleComment && article.clientComment && (
                <ClientCommentRow comment={article.clientComment} />
              )}
            </Fragment>
          ))}
        </Body>
      </Table>
    );
  }

  return (
    <Stack gap="2rem">
      {!request.isArchived && articlesToPickup.length > 0 && (
        <RefusedArticlesToPickup request={request} articles={articlesToPickup} />
      )}
      <Stack gap="0.5rem">
        {list}
        {showDepositedOn && (
          <Stack row flexWrap="initial" gap="0.25rem" className="text-secondary">
            <IconStoreDeposit style={{ fontSize: '1rem' }} />
            <p className="paragraph-200-regular">
              <Trans id="client.articles.deposited-on">
                Your items have been deposited on{' '}
                <span className="paragraph-200-bold text-primary">
                  {formatDate(request.articles[0]?.createdAtDate, { dateStyle: 'medium' })}
                </span>{' '}
                in the{' '}
                <span className="paragraph-200-bold text-primary">{request.store?.name}</span>{' '}
                store.
              </Trans>
            </p>
          </Stack>
        )}
        {showShippingOptions && <ClientShippingOption request={request} />}
        {showPrice && <ClientRequestPrice price={request.cost?.amountPerCurrency[0]} />}
      </Stack>
    </Stack>
  );
};

const ArticleCard = ({
  article,
  mode,
  showPrice,
  showStepper,
  choice,
  setChoice,
  showChoicesError,
}: {
  article: ClientArticleWithRelations;
  mode: 'defect' | 'action';
  showPrice: boolean;
  showStepper?: boolean;
  choice?: Choice;
  setChoice?: (choice: Choice) => void;
  showChoicesError?: boolean;
}) => {
  return (
    <Stack padding="16px" gap="0.75rem" className="client-articles-list__card">
      <Stack row gap="8px" alignItems="center" justifyContent="space-between">
        <ArticleNameCell article={article} />
        {showPrice && (
          <BaseMoneyCell
            price={article.snapshot.cost?.amountPerCurrency[0]}
            showAmountBeforeTaxes={false}
          />
        )}
      </Stack>
      {showStepper && <ClientArticleStepper size="medium" article={article} />}
      <ArticleActionsCell article={article} mode={mode} showBonus={showPrice} />
      {article.clientComment && <ClientArticleCommentCell comment={article.clientComment} />}
      {choice && setChoice && (
        <ArticleChoiceSection
          choice={choice}
          setChoice={setChoice}
          showChoicesError={showChoicesError}
        />
      )}
    </Stack>
  );
};

const ArticleRow = ({
  article,
  mode,
  showStepper,
  showPrice,
  showChoices,
  choice,
  setChoice,
  showChoicesError,
}: {
  article: ClientArticleWithRelations;
  mode: 'defect' | 'action';
  showStepper: boolean;
  showPrice: boolean;
  showChoices: boolean;
  choice?: Choice;
  setChoice?: (choice: Choice) => void;
  showChoicesError?: boolean;
}) => {
  const { t } = useLingui();

  return (
    <Row>
      <Cell>
        <ArticleNameCell article={article} />
      </Cell>
      {showStepper && (
        <Cell>
          <div style={{ width: '218px' }}>
            <ClientArticleStepper size="medium" article={article} />
          </div>
        </Cell>
      )}
      <Cell>
        <ArticleActionsCell article={article} mode={mode} showBonus={showPrice} />
      </Cell>
      {showPrice && (
        <Cell justifyContent="flex-end">
          <BaseMoneyCell
            price={article.snapshot.cost?.amountPerCurrency[0]}
            cancelledPriceTooltip={
              article.cancellationDetail?.type === 'requestor_refusal'
                ? t({
                    id: 'client.articles.service-refused',
                    message: 'Service refused',
                  })
                : undefined
            }
            showAmountBeforeTaxes={false}
          />
        </Cell>
      )}
      {showChoices && (
        <Cell justifyContent="flex-end">
          {choice && setChoice && (
            <ArticleChoiceCell
              choice={choice}
              setChoice={setChoice}
              showChoicesError={showChoicesError}
            />
          )}
        </Cell>
      )}
    </Row>
  );
};

const ClientCommentRow = ({ comment }: { comment: string }) => {
  return (
    <Row isExtensionRow>
      <Cell isWholeRow>
        <ClientArticleCommentCell comment={comment} />
      </Cell>
    </Row>
  );
};

const RefusedArticlesToPickup = ({
  request,
  articles,
}: {
  request: ClientRequestWithRelations;
  articles: ClientArticleWithRelations[];
}) => {
  const getArticleName = useGetArticleName();
  const storeToken = useStoreToken();

  if (storeToken) {
    return (
      <AlertBar
        title={
          <Trans id="client.articles.refused-articles-to-pickup.message.non-embedded">
            The client refused the services for{' '}
            <Plural value={articles.length} one="# item" other="# items" /> -{' '}
            {articles.map((article) => getArticleName({ article })).join(',')} - of the initial
            request. They have been notified to come pick{' '}
            <Plural value={articles.length} one="it" other="them" /> up at your store.
          </Trans>
        }
        type="warning"
        icon={<IconWarning />}
        size="large"
      />
    );
  }

  return (
    <AlertBar
      title={
        <Trans id="client.articles.refused-articles-to-pickup.message">
          You refused the services for{' '}
          <Plural value={articles.length} one="# item" other="# items" /> -{' '}
          {articles.map((article) => getArticleName({ article })).join(',')} - of your initial
          request. Please, come pick <Plural value={articles.length} one="it" other="them" /> up at
          the {request.store?.name} store:
        </Trans>
      }
      type="warning"
      icon={<IconWarning />}
      size="large"
    >
      <ContactDetails
        type="store"
        email={request.store?.address.contactEmail}
        address={request.store?.address}
        phone={request.store?.phone}
        openingHours={request.store?.openingHours}
      />
    </AlertBar>
  );
};

const ClientShippingOption = ({ request }: { request: ClientRequestWithRelations }) => {
  const { t } = useLingui();

  const [shippingOption, setShippingOption] = useState<'home' | 'store' | null>(
    request.client?.shippingChoice ?? null
  );
  const [isOpenStoreListModal, setIsOpenStoreListModal] = useState(false);

  const { data: shippingOptions = [] } = useClientShippingOptions({ requestId: request.id });

  const { mutateAsync } = useUpdateRequestClient();

  const onChange = async (value: 'home' | 'store') => {
    setShippingOption(value);

    await mutateAsync({
      id: request.id,
      body: {
        client: {
          shippingChoice: value,
        },
      },
    }).catch(() => {
      setShippingOption(null);
    });
  };

  const options = [];

  const storeOption = shippingOptions.find((option) => option.type === 'store');
  const homeOption = shippingOptions.find((option) => option.type === 'home');

  if (storeOption) {
    options.push(
      <RadioBox
        key="store"
        value="store"
        ariaLabel={t({
          id: 'client.articles.shipping-option.store',
          message: 'Bring it to a store',
        })}
        title={<Trans id="client.articles.shipping-option.store">Bring it to a store</Trans>}
        info={
          storeOption.fee.amount > 0 ? (
            formatCurrency(storeOption.fee.amount, storeOption.fee.currency)
          ) : (
            <Trans id="client.articles.shipping-option.free">Free</Trans>
          )
        }
        actions={
          <Button variant="link" onPress={() => setIsOpenStoreListModal(true)}>
            <Trans id="client.articles.shipping-option.store.action">See authorized stores</Trans>
          </Button>
        }
      >
        <Trans id="client.articles.shipping-option.store.description">
          Drop your item at the nearest authorized {request.organization.name} store and let them
          handle the shipment.
        </Trans>
      </RadioBox>
    );
  }

  if (homeOption) {
    const carrier = SHIPMENT_CARRIERS.find(({ id }) => id === homeOption.carrier.name);

    options.push(
      <RadioBox
        key="home"
        value="home"
        ariaLabel={t({
          id: 'client.articles.shipping-option.home',
          message: 'Via a carrier',
        })}
        title={<Trans id="client.articles.shipping-option.home">Via a carrier</Trans>}
        info={
          homeOption.fee.amount > 0 ? (
            formatCurrency(homeOption.fee.amount, homeOption.fee.currency)
          ) : (
            <Trans id="client.articles.shipping-option.free">Free</Trans>
          )
        }
        actions={
          !!carrier &&
          !!carrier.dropoffPoints && (
            <div>
              <Button variant="link" href={carrier.dropoffPoints} target="_blank">
                <Trans id="client.articles.shipping-option.home.action">View drop-off points</Trans>
                <IconExternalLink />
              </Button>
            </div>
          )
        }
      >
        <Trans id="client.articles.shipping-option.home.description">
          Print and stick the shipment label we&apos;ll provide you on the package. Then go to a
          carrier drop-off point to send it.
        </Trans>
      </RadioBox>
    );
  }

  if (options.length === 0) {
    return null;
  }

  return (
    <Label
      label={t({
        id: 'client.articles.shipping-option.label',
        message: 'Choose your shipping option',
      })}
    >
      <RadioBoxGroup
        name="shipping-option"
        theme="brand"
        value={shippingOption}
        onChange={onChange}
      >
        {options}
      </RadioBoxGroup>
      <DropOffStoresDialog isOpen={isOpenStoreListModal} onOpenChange={setIsOpenStoreListModal} />
    </Label>
  );
};

export default ClientArticlesTable;
