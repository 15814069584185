import { Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconAnalysisValidation from '@/icons/AnalysisValidation.svg';

export const ArticleAnalysisCompletedActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_analysis_completed'>;
  displayArticleName: boolean;
}) => {
  const workshopName = activity.workshop.name;
  const creatorName = activity.creator?.name;

  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="neutral"
      icon={<IconAnalysisValidation />}
      message={
        <BaseActivityText>
          {activity.data?.onBehalfOfWorkshop ? (
            <Trans id="activities.article_analysis_completed.message.on-behalf">
              <strong>{creatorName}</strong> completed the analysis of the item{' '}
              <BaseActivityArticleName
                activity={activity}
                displayArticleName={displayArticleName}
              />{' '}
              on behalf of <strong>{workshopName}</strong>
            </Trans>
          ) : (
            <Trans id="activities.article_analysis_completed.message">
              <strong>{creatorName}</strong> completed the analysis of the item{' '}
              <BaseActivityArticleName
                activity={activity}
                displayArticleName={displayArticleName}
              />
            </Trans>
          )}
        </BaseActivityText>
      }
    />
  );
};
