import { Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityDetailedContent,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconAnalysisIssue from '@/icons/AnalysisIssue.svg';

export const ArticleRequalifiedActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_requalified'>;
  displayArticleName: boolean;
}) => {
  const workshopName = activity.workshop.name;
  const creatorName = `${activity.creator.name}${!activity.data?.onBehalfOfWorkshop ? ` (${workshopName})` : ''}`;

  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="warning"
      icon={<IconAnalysisIssue />}
      message={
        <BaseActivityText>
          {activity.data?.onBehalfOfWorkshop ? (
            <Trans id="activities.article_requalified.message.on-behalf">
              <strong>{creatorName}</strong> submitted a re-qualification for the item{' '}
              <BaseActivityArticleName
                activity={activity}
                displayArticleName={displayArticleName}
              />{' '}
              on behalf of {workshopName}
            </Trans>
          ) : (
            <Trans id="activities.article_requalified.message">
              <strong>{creatorName}</strong> submitted a re-qualification for the item{' '}
              <BaseActivityArticleName
                activity={activity}
                displayArticleName={displayArticleName}
              />
            </Trans>
          )}
        </BaseActivityText>
      }
    >
      {activity.data.comment && activity.data.comment.trim() !== '' && (
        <BaseActivityDetailedContent>{activity.data.comment}</BaseActivityDetailedContent>
      )}
    </BaseActivity>
  );
};
