import { Dispatch, ReactNode, SetStateAction, useEffect, useRef } from 'react';
import { getLocalTimeZone, today } from '@internationalized/date';
import { Trans, useLingui } from '@lingui/react/macro';
import { parseISO } from 'date-fns';

import { ProductL1 } from '@/api';
import FileUpload from '@/components/FileUpload';
import AlertBar from '@/design_system/AlertBar';
import InputDate from '@/design_system/InputDate';
import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import { Label } from '@/design_system/Label/Label';
import { RadioCardYesNo } from '@/design_system/Radio/RadioCard/RadioCard';
import Stack from '@/design_system/Stack';
import IconValidation from '@/icons/Validation.svg';
import { useBrandRequestContext } from '@/layouts/Brand/BrandRequestContext';
import { Medium } from '@/models/medium';
import { Product, useColorOptions, useSizeOptions } from '@/models/product';
import FusalpWarrantyModal from '@/routes/Brand/Requests/New/components/Article/components/ArticleForm/components/FusalpWarrantyModal';
import { useCurrentOrganization } from '@/services/auth';

export const AdditionalInfo = ({
  product,
  productL1,
  color,
  setColor,
  size,
  setSize,
  purchaseDate,
  setPurchaseDate,
  warranty,
  setWarranty,
  proofOfPurchase,
  setProofOfPurchase,
  onDeleteMedium,
}: {
  product?: Product;
  productL1?: ProductL1;
  color?: string;
  setColor: (color?: string) => void;
  size?: string;
  setSize: (size?: string) => void;
  purchaseDate?: string;
  setPurchaseDate: (purchaseDate?: string) => void;
  warranty: boolean;
  setWarranty: (warranty: boolean) => void;
  proofOfPurchase: Medium[];
  setProofOfPurchase: Dispatch<SetStateAction<Medium[]>>;
  onDeleteMedium: (mediumId: string) => void;
}) => {
  const { t } = useLingui();
  const [organization] = useCurrentOrganization();
  const { request } = useBrandRequestContext();

  const colorOptions = useColorOptions(product);
  const sizeOptions = useSizeOptions(product, productL1);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <Stack gap="1.5rem" ref={ref}>
      {organization?.isColorFieldEnabled && colorOptions.length > 0 && (
        <InputSelect
          variant="select"
          label={
            <>
              {t({ id: 'client.new.article.form.color.label', message: 'Color' })}{' '}
              {!organization.isColorFieldRequired && (
                <span className="text-disabled paragraph-100-regular">
                  {t({ id: 'client.new.article.form.optional', message: '(optional)' })}
                </span>
              )}
            </>
          }
          placeholder={t({
            id: 'client.new.article.form.color.placeholder',
            message: 'Select a color...',
          })}
          isSearchable={false}
          options={colorOptions}
          value={colorOptions.find((colorOption) => color === colorOption.id)}
          getOptionValue={(colorOption) => colorOption.text}
          getOptionLabel={(colorOption) => colorOption.text}
          onChange={(colorOption) => {
            if (colorOption) {
              setColor(colorOption.id);
            }
          }}
          styleVariant="brand"
          size="large"
          style={{ flex: 1 }}
        />
      )}

      {organization?.isSizeFieldEnabled && sizeOptions.length > 0 && (
        <InputSelect
          variant="select"
          label={
            <>
              {t({ id: 'client.new.article.form.size.label', message: 'Size' })}{' '}
              {!organization.isSizeFieldRequired && (
                <span className="text-disabled paragraph-100-regular">
                  {t({ id: 'client.new.article.form.optional', message: '(optional)' })}
                </span>
              )}
            </>
          }
          placeholder={t({
            id: 'client.new.article.form.size.placeholder',
            message: 'Select a size...',
          })}
          isSearchable={false}
          options={sizeOptions}
          value={sizeOptions.find((sizeOption) => size === sizeOption.id)}
          getOptionValue={(sizeOption) => sizeOption.text}
          getOptionLabel={(sizeOption) => sizeOption.text}
          onChange={(sizeOption) => {
            if (sizeOption) {
              setSize(sizeOption.id);
            }
          }}
          styleVariant="brand"
          size="large"
          style={{ flex: 1 }}
        />
      )}
      {!!request.client && (
        <>
          <Label
            label={t({
              id: 'client.new.article.form.is-under-warranty.label',
              message: 'Is your item still under warranty?',
            })}
            size="large"
          >
            <RadioCardYesNo
              name="is-under-warranty"
              theme="brand"
              value={warranty ? 'yes' : 'no'}
              onChange={(value) => {
                setWarranty(value === 'yes');
              }}
            />
          </Label>
          {warranty && (
            <>
              <AlertBar icon={<IconValidation />} type="warning" size="large">
                <Stack gap="0.5rem" alignItems="flex-start">
                  <p>
                    <Trans id="client.new.article.form.warranty.label">
                      To benefit from the warranty, please provide the purchase date and proof of
                      purchase. Without this information, the warranty won&apos;t apply.
                    </Trans>
                  </p>
                  {organization && warrantyInfoForOrganization[organization.slug] && (
                    <> {warrantyInfoForOrganization[organization.slug]}</>
                  )}
                </Stack>
              </AlertBar>
              <InputDate
                label={
                  <Trans id="client.new.article.form.purchase-date.label">Purchase date</Trans>
                }
                value={purchaseDate}
                onChange={(date) => {
                  setPurchaseDate(date);
                }}
                maxValue={today(getLocalTimeZone())}
                style={{ flex: 1 }}
                size="large"
                variant="brand"
                error={
                  !!purchaseDate && parseISO(purchaseDate) > new Date()
                    ? t({
                        id: 'client.new.article.form.purchase-date.error',
                        message: 'The purchase date must be in the past',
                      })
                    : undefined
                }
              />

              <FileUpload
                uploadData={{ type: 'proof-of-purchase' }}
                type="file"
                ariaLabel={t({
                  id: 'client.new.article.form.proof-of-purchase.label',
                  message: 'Proof of purchase',
                })}
                label={
                  <Trans id="client.new.article.form.proof-of-purchase.label">
                    Proof of purchase
                  </Trans>
                }
                media={proofOfPurchase}
                onChange={setProofOfPurchase}
                onDelete={onDeleteMedium}
                size="large"
                theme="brand"
                onMount={() => {
                  window.scroll({
                    top: document.body.scrollHeight,
                    behavior: 'smooth',
                  });
                }}
              />
            </>
          )}
        </>
      )}
    </Stack>
  );
};

const warrantyInfoForOrganization: Record<string, ReactNode> = {
  fusalp: <FusalpWarrantyModal />,
};
