import { Trans } from '@lingui/react/macro';

import {
  BrandCreationLayout,
  BrandCreationLayoutHeader,
} from '@/layouts/Brand/BrandCreationLayout/BrandCreationLayout';

const Error = () => {
  return (
    <BrandCreationLayout>
      <BrandCreationLayoutHeader />
      <main style={{ paddingTop: '1rem', textAlign: 'center' }}>
        <p className="paragraph-100-regular">
          <Trans id="brand.error">Something went wrong. Please try again later.</Trans>
        </p>
      </main>
    </BrandCreationLayout>
  );
};

export default Error;
