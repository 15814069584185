/**
 * Reloads the page when a preload script fails to load.
 * This happens when a deployed app is updated and a user is still using the old version.
 *
 * We use `sessionStorage` to avoid an infinite reload loop in case the file is actually missing.
 * `sessionStorage` is cleared when the tab is closed.
 *
 * Source: https://vite.dev/guide/build#load-error-handling
 */
export function reloadOnPreloadError() {
  window.addEventListener('vite:preloadError', (event) => {
    const sessionKey = `vite:preloadError:${event.payload.message}`;

    if (sessionStorage.getItem(sessionKey)) {
      // Already tried to reload, something else is wrong
      return;
    }

    console.log(`Reloading due to preload error: ${event.payload.message}`);
    sessionStorage.setItem(sessionKey, `reload:${Date.now()}`);
    window.location.reload();
  });
}
