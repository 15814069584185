import { useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import { useShowToast } from '@/design_system/Toast';
import IconError from '@/icons/Error.svg';
import { useRefuseDispatchProposal, useValidateDispatchProposal } from '@/models/article';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import ArticlePrice from '@/routes/Requests/Request/Article/ArticlePrice';
import { ArticleActionProps } from '@/routes/Requests/Request/Article/components/ArticleActions/ArticleActions';
import useViewPort from '@/utils/useViewport';

export const AcceptDispatchProposalAction = ({ onActionDone }: ArticleActionProps) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();
  const showToast = useShowToast();

  const { view: requestView } = useRequestContext();
  const { article, view, state } = useArticleContext();
  const [isOpenRefuseDialog, setIsOpenRefuseDialog] = useState(false);

  const { mutateAsync: validateDispatchProposal, isPending: isPendingValidateDispatchProposal } =
    useValidateDispatchProposal({
      articleId: article.id,
    });

  const { mutateAsync: refuseDispatchProposal, isPending: isPendingRefuseDispatchProposal } =
    useRefuseDispatchProposal({
      articleId: article.id,
    });

  const handleDispatchProposal = async ({
    validate,
    reason,
  }:
    | {
        validate: true;
        reason?: undefined;
      }
    | {
        validate: false;
        reason: string;
      }) => {
    const { hasError } = state.errors.check({
      services: {
        actions:
          validate && view.services.actions.shown
            ? {
                actions: {
                  missingCustomPrice: requestView.price.enabled,
                },
              }
            : undefined,
      },
    });

    setApiError(null);

    if (!hasError) {
      try {
        if (validate) {
          await validateDispatchProposal();
        } else {
          await refuseDispatchProposal(reason);
        }
        onActionDone();
      } catch (err: any) {
        console.error(err);
        setApiError(
          (err.message as string) ?? t({ id: '_general.error.unknown', message: 'Unknown error' })
        );
        setIsOpenRefuseDialog(false);
        showToast({
          type: 'error',
          text: t({
            id: '_general.error.please-retry',
            message: 'An error occurred. Please try again later.',
          }),
        });
      }
    }
  };

  const [apiError, setApiError] = useState<string | null>(null);

  return (
    <Stack gap="1rem" row alignItems="center" justifyContent="space-between" style={{ flex: 1 }}>
      {apiError && <p className="paragraph-100-medium text-danger">{apiError}</p>}
      <Stack
        row={!isMobile}
        gap={isMobile ? '0.5rem' : '1.5rem'}
        alignItems={isMobile ? 'stretch' : 'center'}
        style={{ flex: '1' }}
        justifyContent="flex-end"
        flexWrap="nowrap"
      >
        <Stack row justifyContent={isMobile ? 'flex-end' : 'center'}>
          <ArticlePrice allowDiscount />
        </Stack>
        {!isMobile && (
          <div
            style={{ backgroundColor: 'var(--color-neutral-300)', width: '1px', height: '24px' }}
          ></div>
        )}
        <Button
          variant="secondary"
          size="medium"
          onPress={() => setIsOpenRefuseDialog(true)}
          isLoading={isPendingRefuseDispatchProposal}
          className="text-no-wrap"
        >
          <IconError />
          <Trans id="article.actions.dispatch-proposal.refuse">Refuse proposal</Trans>
        </Button>
        <Button
          variant="primary"
          size="medium"
          onPress={() => handleDispatchProposal({ validate: true })}
          isLoading={isPendingValidateDispatchProposal}
          className="text-no-wrap"
        >
          <Trans id="article.actions.dispatch-proposal.validate">Validate quote proposal</Trans>
        </Button>
      </Stack>

      <RefuseDispatchProposalDialog
        isOpen={isOpenRefuseDialog}
        setIsOpen={setIsOpenRefuseDialog}
        refuse={(reason) => {
          handleDispatchProposal({ validate: false, reason });
        }}
        isPendingRefuseDispatch={isPendingRefuseDispatchProposal}
      />
    </Stack>
  );
};

const RefuseDispatchProposalDialog = ({
  isOpen,
  setIsOpen,
  refuse,
  isPendingRefuseDispatch,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  refuse: (reason: string) => void;
  isPendingRefuseDispatch: boolean;
}) => {
  const { t } = useLingui();
  const [reason, setReason] = useState('');
  const [showErrors, setShowErrors] = useState(false);

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      title={t({ id: 'refuse-dispatch-proposal-modal.title', message: 'Refuse proposal' })}
    >
      <main>
        <Stack gap="1rem">
          <p className="paragraph-100-regular">
            <Trans id="refuse-dispatch-proposal-modal.text">
              Once the proposal is refused, you&apos;ll need to choose a new workshop to handle the
              job. Please provide a refusal reason:
            </Trans>
          </p>
          <TextArea
            ariaLabel={t({
              id: 'refuse-dispatch-proposal-modal.reason.label',
              message: 'Refusal reason',
            })}
            placeholder={t({
              id: 'refuse-dispatch-proposal-modal.reason.placeholder',
              message: 'Write refusal reason',
            })}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            isInvalid={showErrors && reason.length === 0}
          />
        </Stack>
      </main>
      <footer>
        <Button variant="secondary" size="medium" onPress={() => setIsOpen(false)}>
          <Trans id="refuse-dispatch-proposal-modal.actions.cancel">Cancel</Trans>
        </Button>
        <Button
          variant="danger"
          size="medium"
          onPress={() => {
            if (reason.length === 0) {
              setShowErrors(true);
            } else {
              refuse(reason);
            }
          }}
          isLoading={isPendingRefuseDispatch}
        >
          <Trans id="refuse-dispatch-proposal-modal.actions.refuse">Refuse</Trans>
        </Button>
      </footer>
    </Dialog>
  );
};
