import { Key } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';

import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import { useUpdateArticle } from '@/models/article';
import { useColorOptions, useSizeOptions } from '@/models/product';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';

const ColorSize = () => {
  const { t } = useLingui();
  const { request, article, view, errors } = useArticleContext();

  const { mutate: updateArticle } = useUpdateArticle({
    articleId: article.id,
    requestId: request.id,
  });

  const handleSelectionChange = (property: 'size' | 'color', key: Key) => {
    updateArticle({
      data: {
        data: {
          ...article.data,
          [property]: key,
        },
      },
    });
  };

  const colorOptions = useColorOptions(article.product);
  const sizeOptions = useSizeOptions(article.product, article.productL1);

  return (
    <>
      {view.details.color.shown && (
        <InputSelect
          variant="select"
          label={
            <>
              <Trans id="article.form.color.label">Color</Trans>{' '}
              {!view.details.color.required && (
                <span className="text-disabled paragraph-100-regular">
                  <Trans id="article.form.color.label.optional">(optional)</Trans>
                </span>
              )}
            </>
          }
          placeholder={
            colorOptions.length > 0
              ? t({ id: 'article.form.color.placeholder', message: 'Select the item color...' })
              : t({ id: '_general.na', message: 'N/A' })
          }
          isDisabled={(!article.product && !article.productL1) || colorOptions.length === 0}
          value={colorOptions.find((colorOption) => article.data.color === colorOption.id) ?? null}
          isSearchable={false}
          options={colorOptions}
          getOptionValue={(colorOption) => colorOption.id}
          getOptionLabel={(colorOption) => colorOption.text}
          onChange={(colorOption) => {
            if (!colorOption) {
              return;
            }

            handleSelectionChange('color', colorOption.id);
          }}
          style={{ flex: 1 }}
          error={
            errors?.details?.missingColor
              ? t({ id: 'article.form.color.error', message: 'Please select a color' })
              : undefined
          }
        />
      )}

      {view.details.size.shown && (
        <InputSelect
          variant="select"
          label={
            <>
              <Trans id="article.form.size.label">Size</Trans>{' '}
              {!view.details.size.required && (
                <span className="text-disabled paragraph-100-regular">
                  <Trans id="article.form.size.label.optional">(optional)</Trans>
                </span>
              )}
            </>
          }
          placeholder={
            (!article.product && !article.productL1) || sizeOptions.length > 0
              ? t({ id: 'article.form.size.placeholder', message: 'Select the item size...' })
              : t({ id: '_general.na', message: 'N/A' })
          }
          isDisabled={sizeOptions.length === 0}
          value={sizeOptions.find((sizeOption) => article.data.size === sizeOption.id) ?? null}
          isSearchable={false}
          options={sizeOptions}
          getOptionValue={(sizeOption) => sizeOption.id}
          getOptionLabel={(sizeOption) => sizeOption.text}
          onChange={(sizeOption) => {
            if (!sizeOption) {
              return;
            }

            handleSelectionChange('size', sizeOption.id);
          }}
          style={{ flex: 1 }}
          error={
            errors?.details?.missingSize
              ? t({ id: 'article.form.size.error', message: 'Please select a size' })
              : undefined
          }
        />
      )}
    </>
  );
};

export default ColorSize;
