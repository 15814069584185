import { useState } from 'react';
import { FileTrigger } from 'react-aria-components';
import { Trans, useLingui } from '@lingui/react/macro';

import { VisibilitySelector } from '@/components/comments/VisibilitySelector/VisibilitySelector';
import { useFileUploader } from '@/components/FileUpload/FileUpload';
import { UserAvatar } from '@/components/UserAvatar/UserAvatar';
import Button from '@/design_system/Button';
import FileCard from '@/design_system/FileCard';
import { PhotoCardGrid } from '@/design_system/PhotoCard';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import IconAttach from '@/icons/Attach.svg';
import IconSend from '@/icons/Send.svg';
import { CommentVisibility } from '@/models/comment/comment';
import { Medium } from '@/models/medium';
import { Organization } from '@/models/organization';
import { User } from '@/models/user';
import { createBEMClasses } from '@/utils/classname';
import useViewPort from '@/utils/useViewport';

import './NewComment.css';

const { block, element } = createBEMClasses('new-comment');

const ACCEPTED_FILE_TYPES = ['application/pdf', 'image/jpeg', 'image/png', 'image/webp'];

export const NewComment = ({
  creator,
  currentOrganization,
  variant = 'default',
  onCommentCreated,
  isLoading,
}: {
  creator?: User | null;
  currentOrganization: Organization | null;
  variant?: 'default' | 'client-communication';
  onCommentCreated: (data: {
    content: string;
    visibility: CommentVisibility;
    media?: string[];
  }) => Promise<void>;
  isLoading?: boolean;
}) => {
  const { isMobile } = useViewPort();
  const { t } = useLingui();
  const [content, setContent] = useState('');
  const [media, setMedia] = useState<Medium[]>([]);
  const [hasInputBeenFocused, setHasInputBeenFocused] = useState<boolean>(false);

  const shouldDisplayActionsBar = variant === 'default' ? hasInputBeenFocused : true;
  const externalWorkshop = creator?.workshop?.external ? creator.workshop : null;

  const [visibility, setVisibility] = useState<CommentVisibility>(
    externalWorkshop ? 'public' : 'organization'
  );

  const {
    images,
    loadingImageUploads,
    otherFiles,
    handleUpload,
    handleDelete,
    loadingUploads,
    failedUploads,
    handleCloseFailedUpload,
    loadingFileUploads,
  } = useFileUploader({
    uploadData: { type: 'comment' },
    media,
    deleteWithApi: true,
    onUpload: (newMedia) => {
      setMedia((prev) => [...prev, newMedia]);
    },
    onDelete: (idToDelete) => {
      setMedia((prev) => prev.filter(({ id }) => id !== idToDelete));
    },
  });

  return (
    <Stack className={block()} row gap="0.75rem" flexWrap="nowrap">
      {!isMobile && variant === 'default' && <UserAvatar user={creator} />}
      <Stack gap="0.5rem" className={element('container')}>
        <Stack
          gap="0.5rem"
          style={{
            flexDirection: variant === 'default' ? 'column-reverse' : 'column',
          }}
        >
          {images.length > 0 && (
            <PhotoCardGrid
              loadingUploads={loadingImageUploads.map(
                (loadingImageUpload) => loadingImageUpload.file
              )}
              media={images}
              onDelete={(id) => {
                handleDelete(id);
              }}
              size={variant === 'client-communication' ? 'large' : 'medium'}
              imgStyle={{ objectFit: 'cover' }}
              persistentDeleteIcon
            />
          )}
          {failedUploads.map((file, index) => (
            <FileCard
              key={index}
              name={file.name}
              size={file.size}
              error={true}
              onDelete={() => handleCloseFailedUpload(index)}
              ariaLabel={t({
                id: 'components.file-upload.failed',
                message: `Upload failed for file ${file.name}`,
              })}
            />
          ))}
          {loadingFileUploads.map((loadingFileUpload) => (
            <FileCard
              key={loadingFileUpload.file.name}
              name={loadingFileUpload.file.name}
              size={loadingFileUpload.file.size}
              loading={true}
            />
          ))}
          {otherFiles.map((medium) => (
            <FileCard
              key={medium.id}
              name={medium.originalPath}
              url={medium.url}
              size={medium.size}
              onDelete={() => {
                handleDelete(medium.id);
              }}
            />
          ))}
          <TextArea
            textAreaStyle={{
              padding: '8px 12px',
            }}
            ariaLabel={t({ id: 'new-comment.write-comment', message: 'Write a comment...' })}
            value={content ?? ''}
            rows={3}
            onChange={(event) => setContent(event.target.value)}
            placeholder={t({ id: 'new-comment.write-comment', message: 'Write a comment...' })}
            onFocus={() => {
              setHasInputBeenFocused(true);
            }}
          />
        </Stack>

        {shouldDisplayActionsBar && (
          <Stack
            row
            gap="0.5rem"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="nowrap"
          >
            <Stack
              row
              gap="0.5rem"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="nowrap"
            >
              <FileTrigger
                onSelect={(fileList) => {
                  if (fileList) {
                    handleUpload(Array.from(fileList));
                  }
                }}
                acceptedFileTypes={ACCEPTED_FILE_TYPES}
                allowsMultiple
              >
                <Button
                  ariaLabel={t({ id: 'new-comment.attach', message: 'Attach files' })}
                  tooltip={t({ id: 'new-comment.attach', message: 'Attach files' })}
                  variant={variant === 'default' ? 'secondary' : 'secondary-brand'}
                  size={variant === 'default' ? 'medium' : 'large'}
                  iconOnly
                  isLoading={loadingUploads.length > 0}
                >
                  <IconAttach />
                </Button>
              </FileTrigger>

              {variant === 'default' && (
                <VisibilitySelector
                  visibility={visibility}
                  setVisibility={setVisibility}
                  currentOrganization={currentOrganization}
                  currentExternalWorkshop={externalWorkshop}
                />
              )}
            </Stack>

            <Button
              size={variant === 'default' ? 'small' : 'medium'}
              variant={variant === 'default' ? 'primary' : 'brand'}
              onPress={async () => {
                await onCommentCreated({
                  content,
                  visibility: variant === 'default' ? visibility : 'client',
                  media: media?.map(({ id }) => id),
                });

                setContent('');
                setMedia([]);
              }}
              disabled={content.length === 0 && media.length === 0}
              isLoading={isLoading}
            >
              <IconSend />
              <Trans id="new-comment.send">Send</Trans>
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
