import { Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityDetailedContent,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import Stack from '@/design_system/Stack';
import IconFileValidation from '@/icons/FileValidation.svg';

export const ArticleDispatchProposalRefusedActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_dispatch_proposal_refused'>;
  displayArticleName: boolean;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="danger"
      icon={<IconFileValidation />}
      creatorName={activity.creator.name}
      message={
        <BaseActivityText>
          <Trans id="activities.article_dispatch_proposal_refused.message">
            refused the quote proposal for the item{' '}
            <BaseActivityArticleName activity={activity} displayArticleName={displayArticleName} />
          </Trans>
        </BaseActivityText>
      }
    >
      <Stack style={{ flex: 1, paddingTop: '0.25rem' }}>
        <BaseActivityDetailedContent>{activity.data.reason}</BaseActivityDetailedContent>
      </Stack>
    </BaseActivity>
  );
};
