import { useEffect } from 'react';
import { Outlet } from 'react-router';

import config from '@/config';
import { useCurrentOrganization } from '@/services/auth';
import { ErrorBoundary } from '@/services/sentry';

const BrandLayout = () => {
  const [currentOrganization] = useCurrentOrganization();

  useEffect(() => {
    if (currentOrganization) {
      document.title = `${currentOrganization.name} - powered by Prolong`; // eslint-disable-line lingui/no-unlocalized-strings
      const favicon = document.querySelector<HTMLLinkElement>('link[rel="icon"]');
      if (favicon) {
        favicon.href = `${config.apiUrl}/organizations/${currentOrganization.slug}/logo?type=square`;
      }
    }
  }, [currentOrganization]);

  if (!currentOrganization) {
    return null;
  }

  return (
    <ErrorBoundary>
      <Outlet />
    </ErrorBoundary>
  );
};

export default BrandLayout;
