import { useLingui } from '@lingui/react';

import Stack from '@/design_system/Stack';
import { createBEMClasses } from '@/utils/classname';
import { Currency, formatCurrency } from '@/utils/number';

import { Row } from '../PriceWithDetails/usePriceDetailsRows';

const { block, element } = createBEMClasses('price-details');

import './PriceDetails.css';

export const PriceDetails = ({
  priceDetailsRows,
  currency,
}: {
  priceDetailsRows: Row[][];
  currency: Currency;
}) => {
  const { _ } = useLingui();

  return (
    <Stack className={block()} gap="0.5rem">
      {priceDetailsRows.map((section, sectionIndex) => {
        const isTotalSection = sectionIndex === priceDetailsRows.length - 1;

        return (
          <Stack gap="0.125rem" key={sectionIndex}>
            {priceDetailsRows.length > 1 && isTotalSection && (
              <div className={element('separator')} />
            )}
            {section.map((row) => {
              return (
                <Stack
                  key={row.label.id}
                  ariaLabel={_(row.label)}
                  row
                  flexWrap="nowrap"
                  justifyContent="space-between"
                  className={element(
                    'row',
                    { negative: row.amount < 0 },
                    `text-secondary ${row.highlight ? (isTotalSection ? 'paragraph-100-regular' : 'paragraph-200-regular') : 'paragraph-200-regular'}`
                  )}
                >
                  <p>{_(row.label)}</p>
                  <p>{formatCurrency(row.amount, currency)}</p>
                </Stack>
              );
            })}
          </Stack>
        );
      })}
    </Stack>
  );
};
