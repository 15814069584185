import { useState } from 'react';
import { Separator } from 'react-aria-components';
import { Trans, useLingui } from '@lingui/react/macro';

import { ClientFormModal } from '@/components/ClientForm';
import ContactDetails from '@/components/ContactDetails';
import Accordion from '@/design_system/Accordion';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconCalendar from '@/icons/Calendar.svg';
import IconEdit from '@/icons/Edit.svg';
import IconFile from '@/icons/File.svg';
import IconStore from '@/icons/Store.svg';
import IconPerson from '@/icons/User.svg';
import { useBrandRequestContext } from '@/layouts/Brand/BrandRequestContext';
import { useClientToken } from '@/services/auth';
import { formatDate } from '@/utils/date';
import useViewPort from '@/utils/useViewport';

import Invoice from './Invoice';

export const RequestInfo = () => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();
  const { request } = useBrandRequestContext();
  const clientToken = useClientToken();

  const requestInfoLabel = t({ id: 'brand.request-info.info', message: 'Request information' });
  const clientInfoLabel = clientToken
    ? t({ id: 'brand.request-info.my-info', message: 'My information' })
    : t({ id: 'brand.request-info.client-info', message: 'Client information' });
  const storeInfoLabel = t({ id: 'brand.request-info.store', message: 'Store' });

  if (isMobile) {
    return (
      <Box padding="0" style={{ marginTop: '1rem' }}>
        <div aria-label={requestInfoLabel}>
          <Accordion
            trigger={
              <Stack row gap="0.25rem" alignItems="center" className="paragraph-100-regular">
                <IconFile style={{ fontSize: '1.25rem' }} />
                {requestInfoLabel}
              </Stack>
            }
          >
            <RequestDetails />
          </Accordion>
        </div>

        {request.client && (
          <>
            <Separator />
            <div aria-label={clientInfoLabel}>
              <Accordion
                trigger={
                  <Stack row gap="0.25rem" alignItems="center" className="paragraph-100-regular">
                    <IconPerson style={{ fontSize: '1.25rem' }} />
                    {clientInfoLabel}
                  </Stack>
                }
              >
                <ClientInfo />
              </Accordion>
            </div>
          </>
        )}

        {request.store && (
          <>
            <Separator />
            <div aria-label={storeInfoLabel}>
              <Accordion
                trigger={
                  <Stack row gap="0.25rem" alignItems="center" className="paragraph-100-regular">
                    <IconStore style={{ fontSize: '1.25rem' }} />
                    <Trans id="brand.request-info.store">Store</Trans>
                  </Stack>
                }
              >
                <StoreInfo />
              </Accordion>
            </div>
          </>
        )}
      </Box>
    );
  }

  return (
    <Stack gap="0.5rem">
      <div style={{ padding: '1.5rem 0 0.5rem' }} aria-label={requestInfoLabel}>
        <RequestDetails />
      </div>

      {request.client && (
        <>
          <Separator />
          <div aria-label={clientInfoLabel}>
            <Accordion
              openByDefault
              trigger={<p className="paragraph-100-medium">{clientInfoLabel}</p>}
            >
              <ClientInfo />
            </Accordion>
          </div>
        </>
      )}

      {request.store && (
        <>
          <Separator />
          <div aria-label={storeInfoLabel}>
            <Accordion
              openByDefault
              trigger={<p className="paragraph-100-medium">{storeInfoLabel}</p>}
            >
              <StoreInfo />
            </Accordion>
          </div>
        </>
      )}
    </Stack>
  );
};

const RequestDetails = () => {
  const { request } = useBrandRequestContext();
  const clientToken = useClientToken();

  return (
    <Stack gap="0.75rem">
      <Stack>
        <span className="paragraph-200-regular text-secondary">
          <Trans id="brand.request-info.reference">Reference</Trans>
        </span>
        <span className="paragraph-100-medium">{request.reference}</span>
      </Stack>

      <Stack>
        <span className="paragraph-200-regular text-secondary">
          <Trans id="brand.request-info.creation-date">Creation date</Trans>
        </span>
        <Stack row className="paragraph-100-medium" alignItems="center" gap="0.25rem">
          <IconCalendar style={{ fontSize: '1rem' }} />
          {formatDate(request.createdAtDate, { dateStyle: 'medium' })}
        </Stack>
      </Stack>

      {!!clientToken && <Invoice />}
    </Stack>
  );
};

const ClientInfo = () => {
  const clientToken = useClientToken();
  const { request, workflow } = useBrandRequestContext();

  const [isOpenClientFormModal, setIsOpenClientFormModal] = useState(false);

  const canEdit =
    !request.isArchived && !(request.isInTransitStep && !request.isInInitialTransitStep);

  if (!request.client) {
    return null;
  }

  return (
    <>
      <Stack gap="1rem">
        <ContactDetails
          type="client"
          name={request.client.name}
          email={request.client.email}
          address={request.client.address}
          phone={request.client.phone}
          deliveryChoice={request.client.deliveryOption}
          size="small"
        />
        {canEdit && (
          <Button
            variant="secondary-brand"
            size="small"
            onPress={() => setIsOpenClientFormModal(true)}
          >
            <IconEdit />
            <Trans id="brand.request-info.client-info.edit">Edit</Trans>
          </Button>
        )}
      </Stack>
      <ClientFormModal
        isOpen={isOpenClientFormModal}
        setIsOpen={setIsOpenClientFormModal}
        disableEmailEdit={!!clientToken}
        hideVip
        hideLanguage={!!clientToken}
        hideDeliveryOption={!!clientToken}
        hideBillingAddress={!workflow?.hasInternalPaymentStep}
        variant="brand"
        size="large"
      />
    </>
  );
};

const StoreInfo = () => {
  const { request } = useBrandRequestContext();

  if (!request.store) {
    return null;
  }

  return (
    <ContactDetails
      type="store"
      name={request.store.name}
      email={request.store.address.contactEmail}
      address={request.store.address}
      phone={request.store.phone}
      openingHours={request.store.openingHours}
      size="small"
    />
  );
};
