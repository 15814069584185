import { Trans, useLingui } from '@lingui/react/macro';

import PhotoCard from '@/design_system/PhotoCard';
import Stack from '@/design_system/Stack';
import {
  PRODUCT_CATEGORIES,
  PRODUCT_CATEGORIES_L2,
  PRODUCT_MATERIALS,
  useColorName,
  useColorOptions,
  useSizeOptions,
} from '@/models/product';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { useCountries } from '@/services/i18n';
import { formatCurrency } from '@/utils/number';
import useViewPort from '@/utils/useViewport';

import { Item } from './Item';

export const ArticleDetails = () => {
  const { i18n, t } = useLingui();
  const { isMobile } = useViewPort();
  const countries = useCountries();

  const { request, article, errors, view } = useArticleContext();

  const l1Label = PRODUCT_CATEGORIES.find(({ id }) => id === article.productL1)?.label;
  const l2Label = PRODUCT_CATEGORIES_L2.find(({ id }) => id === article.productL2)?.label;
  const l3Label = PRODUCT_MATERIALS.find(({ id }) => id === article.productL3)?.label;
  const colorLabel = useColorName(article.data.color);

  const productName = article.product ? article.product.externalIdNameCollection : '-';

  const colorOptions = useColorOptions(article.product);
  const sizeOptions = useSizeOptions(article.product, article.productL1);

  const showCustomsFields = article.areCustomsExpected || article.wentThroughCustoms;
  const madein = article.product?.data.madein ?? article.data.madein;
  const customsValue = article.product?.data.customsValue ?? article.data.customsValue;

  return (
    <Stack gap="0.75rem">
      <Stack row gap="1rem" flexWrap="nowrap">
        {!!article.articlePhoto && !isMobile && (
          <PhotoCard
            url={article.articlePhoto.url}
            name={t({ id: 'article.information.article-photo', message: 'Item photo' })}
            alt={t({ id: 'article.information.article-photo', message: 'Item photo' })}
            size="large"
          />
        )}
        {!!article.product?.data.image && (
          <div>
            {isMobile && (
              <span className="paragraph-200-regular text-secondary">
                <Trans id="article.information.product-photo">Product photo</Trans>
              </span>
            )}
            <PhotoCard
              url={article.product.data.image}
              name={article.product.data.image}
              alt={article.product.data.image}
              size={isMobile ? 'xxlarge' : 'large'}
            />
          </div>
        )}
      </Stack>

      <Item
        label={t({ id: 'article.information.article-reference', message: 'Item reference' })}
        value={productName}
        error={
          errors.details?.product
            ? t({
                id: 'article.form.external-id.error',
                message: 'Please select a product reference or type',
              })
            : undefined
        }
      />
      <Item
        label={t({ id: 'article.information.product-reference', message: 'Product reference' })}
        value={article.product?.data.reference}
      />
      <Item
        label={t({ id: 'article.information.type', message: 'Type' })}
        value={l1Label ? i18n._(l1Label) : undefined}
        error={
          errors.details?.product
            ? t({
                id: 'article.form.external-id.error',
                message: 'Please select a product reference or type',
              })
            : undefined
        }
      />
      <Item
        label={t({ id: 'article.information.category', message: 'Category' })}
        value={l2Label ? i18n._(l2Label) : undefined}
        error={
          errors.details?.productL2
            ? t({
                id: 'article.form.category.error',
                message: 'Please select a category',
              })
            : undefined
        }
      />
      <Item
        label={t({ id: 'article.information.collection', message: 'Collection' })}
        value={article.product?.data.collection}
      />
      {!!request.organization.brands.length && (
        <Item
          label={t({ id: 'article.information.brand', message: 'Brand' })}
          value={article.product?.data.brand ?? article.data.brand?.name}
          error={
            errors.details?.otherBrandEmpty
              ? t({
                  id: 'article.form.brand.other.specify-name.error',
                  message: 'Please select a brand name',
                })
              : undefined
          }
        />
      )}
      {view.details.productL3.shown && (
        <Item
          label={t({ id: 'article.information.material', message: 'Material' })}
          value={l3Label ? i18n._(l3Label) : undefined}
          error={
            errors.details?.productL3
              ? t({
                  id: 'requests.articles.form.error.productL3',
                  message: 'Please select a material',
                })
              : undefined
          }
        />
      )}
      {view.details.color.shown && (
        <Item
          label={t({ id: 'article.information.color', message: 'Color' })}
          value={colorOptions.length > 0 ? colorLabel : t({ id: '_general.na', message: 'N/A' })}
        />
      )}
      {view.details.size.shown && (
        <Item
          label={t({ id: 'article.information.size', message: 'Size' })}
          value={
            sizeOptions.length > 0 ? article.data.size : t({ id: '_general.na', message: 'N/A' })
          }
        />
      )}
      {showCustomsFields && (
        <>
          <Item
            label={t({ id: 'article.information.customs.tariff-code', message: 'Tariff code' })}
            value={article.product?.data.tariffCode ?? article.data.tariffCode}
            error={!(article.product?.data.tariffCode ?? article.data.tariffCode)}
          />
          <Item
            label={t({ id: 'article.information.customs.made-in', message: 'Made in' })}
            value={countries.find((country) => country.code === madein)?.name ?? '-'}
            error={!countries.find((country) => country.code === madein)}
          />
          <Item
            label={t({
              id: 'article.information.customs.manufacturer-code',
              message: 'Manufacturer code (MID)',
            })}
            value={article.product?.data.mid ?? article.data.mid}
            error={!(article.product?.data.mid ?? article.data.mid)}
          />
          <Item
            label={t({ id: 'article.information.customs.customs-value', message: 'Customs value' })}
            value={customsValue ? formatCurrency(customsValue.amount, customsValue.currency) : '-'}
            error={!customsValue}
          />
        </>
      )}
    </Stack>
  );
};
