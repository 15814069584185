import { createContext, ReactNode, useContext, useId } from 'react';
import { Trans } from '@lingui/react/macro';

import IconInfo from '@/icons/Info.svg';
import { createBEMClasses } from '@/utils/classname';

import Button from '../Button';
import Stack from '../Stack';
import Tooltip from '../Tooltip';

import './Label.css';

const { block, element } = createBEMClasses('label');

type LabelProps = {
  size?: 'medium' | 'large';
  optional?: boolean;
  tooltip?: string;
  label: string;
  children: ReactNode;
};

type LabelContext = {
  id?: string;
  label?: string;
};
const LabelContext = createContext<LabelContext>({});

export const Label = ({ size = 'medium', optional, tooltip, label, children }: LabelProps) => {
  const id = useId();

  return (
    <LabelContext value={{ id, label }}>
      <Stack gap="0.5rem">
        <label htmlFor={id} className={block({ size })}>
          {label}
          {optional && (
            <span className={element('optional')}>
              {' '}
              <Trans id="design-system.label.optional">(optional)</Trans>
            </span>
          )}
          {tooltip && (
            <>
              {' '}
              <Tooltip content={tooltip}>
                <Button variant="style-less" className={element('tooltip-trigger')} size="small">
                  <IconInfo />
                </Button>
              </Tooltip>
            </>
          )}
        </label>
        {children}
      </Stack>
    </LabelContext>
  );
};

export const useLabelContext = () => useContext(LabelContext);
