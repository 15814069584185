import { Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconFileValidation from '@/icons/FileValidation.svg';

export const ArticleDispatchProposalValidatedActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_dispatch_proposal_validated'>;
  displayArticleName: boolean;
}) => {
  const creatorName = activity.creator?.name;

  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="primary"
      icon={<IconFileValidation />}
      message={
        creatorName ? (
          <BaseActivityText>
            <Trans id="activities.article_dispatch_proposal_validated.message">
              <strong>{creatorName}</strong> accepted the quote proposal for the item{' '}
              <BaseActivityArticleName
                activity={activity}
                displayArticleName={displayArticleName}
              />
            </Trans>
          </BaseActivityText>
        ) : (
          <BaseActivityText>
            <Trans id="activities.article_dispatch_proposal_validated.message.auto">
              The quote proposal for the item{' '}
              <BaseActivityArticleName
                activity={activity}
                displayArticleName={displayArticleName}
              />{' '}
              was automatically accepted
            </Trans>
          </BaseActivityText>
        )
      }
    />
  );
};
