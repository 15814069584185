import { Trans } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconExternalLink from '@/icons/ExternalLink.svg';
import { ArticleWithRelations } from '@/models/request';
import { SHIPMENT_CARRIERS, useArticleOngoingShipment, useCarrierName } from '@/models/shipment';

import { BaseMessage } from './BaseMessage';
import { GoToShipment } from './GoToShipment';

export const DropoffShipmentTaskMessage = ({ article }: { article: ArticleWithRelations }) => {
  const { shipment } = useArticleOngoingShipment(article);
  const carrierName = useCarrierName(shipment);

  const carrier = SHIPMENT_CARRIERS.find(({ id }) => id === shipment?.carrier);

  return (
    <BaseMessage
      article={article}
      title={<Trans id="article.task.dropoff-shipment.title">Drop the package</Trans>}
      message={
        <Stack gap="0.5rem" alignItems="flex-start">
          {!!carrierName && (
            <p>
              <Trans id="article.task.dropoff-shipment.text">
                Go to the nearest {carrierName} pickup point and drop the package
              </Trans>
            </p>
          )}
          <Stack row flexWrap="wrap" gap="0.5rem">
            {!!carrier && !!carrier.dropoffPoints && (
              <Button
                variant="secondary"
                size="medium"
                href={carrier.dropoffPoints}
                target="_blank"
              >
                <Trans id="article.task.dropoff-shipment.text.drop-off-points">
                  View drop-off points
                </Trans>
                <IconExternalLink />
              </Button>
            )}
            <GoToShipment article={article} />
          </Stack>
        </Stack>
      }
    />
  );
};
