import { useState } from 'react';
import { Trans } from '@lingui/react/macro';

import RefashionLogo, { RefashionStatus } from '@/components/RefashionLogo';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconChevron from '@/icons/Chevron.svg';
import {
  ActionType,
  PackActionTypeOrganization,
  PackActionTypeOrganizationAction,
} from '@/models/actionType';
import { useDatabaseTranslation } from '@/models/translation';

const PackActionName = ({
  packActionType,
  duplicateActionNumber,
  alwaysShowActions = false,
  style,
}: {
  packActionType: PackActionTypeOrganization & {
    actions: (PackActionTypeOrganizationAction & {
      actionType: ActionType;
      refashionStatus?: RefashionStatus;
    })[];
  };
  duplicateActionNumber?: number;
  alwaysShowActions?: boolean;
  style?: React.CSSProperties;
}) => {
  const { _db } = useDatabaseTranslation();

  const [showActions, setShowActions] = useState(alwaysShowActions);

  return (
    <Stack style={style}>
      <p className="paragraph-100-medium">
        {_db(packActionType.name)}
        {!!duplicateActionNumber && ` #${duplicateActionNumber}`}
      </p>
      {!alwaysShowActions && (
        <Button
          variant="link-subtle"
          size="small"
          style={{ justifyContent: 'flex-start' }}
          onPress={() => setShowActions((prev) => !prev)}
        >
          {showActions ? (
            <>
              <Trans id="components.action-type-search-select.pack-action.hide">
                Hide action details
              </Trans>
              <IconChevron up />
            </>
          ) : (
            <>
              <Trans id="components.action-type-search-select.pack-action.show">
                Show action details
              </Trans>
              <IconChevron down />
            </>
          )}
        </Button>
      )}
      {showActions && (
        <ul className="paragraph-200-regular">
          {packActionType.actions.map((action) => (
            <li key={action.id}>
              {_db(action.actionType.name)}
              {!!action.refashionStatus && (
                <>
                  {' '}
                  <RefashionLogo status={action.refashionStatus} />
                </>
              )}
            </li>
          ))}
        </ul>
      )}
    </Stack>
  );
};

export default PackActionName;
