import { Trans } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconAddArticle from '@/icons/AddArticle.svg';
import { useBrandRequestContext } from '@/layouts/Brand/BrandRequestContext';

const CartActions = ({
  onAddArticle,
  onValidateRequest,
}: {
  onAddArticle: () => void;
  onValidateRequest: () => void;
}) => {
  const { request, workflow } = useBrandRequestContext();

  const hasReachedMaxArticles =
    !!workflow?.config.maxArticles && request.articles.length >= workflow.config.maxArticles;

  return (
    <Stack gap="1rem" alignItems="stretch">
      {!hasReachedMaxArticles && (
        <Button size="large" variant="secondary-brand" onPress={onAddArticle}>
          <IconAddArticle />
          <Trans id="client.new.cart.add-article">Add another item</Trans>
        </Button>
      )}
      <Button size="large" variant="brand" onPress={onValidateRequest}>
        <Trans id="client.new.card.confirm-estimate-request">Confirm my request</Trans>
      </Button>
    </Stack>
  );
};

export default CartActions;
