import { useState } from 'react';
import { useLingui } from '@lingui/react/macro';

import { UserAvatar } from '@/components/UserAvatar/UserAvatar';
import { UserPicker } from '@/components/UserPicker/UserPicker';
import Box from '@/design_system/Box';
import { RequestWithRelationsFromRequestsList, useUpdateRequestSupervisor } from '@/models/request';
import { UserWithRelations } from '@/models/user';

export const SupervisorPicker = ({
  request,
  users,
  disabled,
  variant,
}: {
  request: RequestWithRelationsFromRequestsList;
  users?: UserWithRelations[];
  disabled?: boolean;
  variant: 'card' | 'row';
}) => {
  const { t } = useLingui();
  const { mutate: updateRequestSupervisor } = useUpdateRequestSupervisor(request.id);

  const [filteredUsers, setFilteredUsers] = useState<UserWithRelations[]>(users ?? []);

  const label = request.supervisor
    ? t({
        id: 'requests.supervisor-picker.label.assigned',
        message: `Request ${request.reference} is supervised by ${request.supervisor.name} - Edit`,
      })
    : t({
        id: 'requests.supervisor-picker.label.unassigned',
        message: `Request ${request.reference} isn't supervised - Edit`,
      });

  const userAvatarContent = (
    <UserAvatar
      user={request.supervisor}
      size={variant === 'row' ? 'small' : 'xx-small'}
      tooltip={request.supervisor ? <p className="sentry-mask">{request.supervisor.name}</p> : null}
      disabled={disabled}
    />
  );

  if (variant === 'card' && disabled && !request.supervisor) {
    return;
  }

  if (disabled) {
    if (variant === 'row') {
      return userAvatarContent;
    }

    return (
      <Box padding="0.25rem 0.5rem" style={{ flex: 'none' }}>
        {userAvatarContent}
      </Box>
    );
  }

  const userPickerContent = (
    <UserPicker
      size={variant === 'row' ? 'small' : 'xx-small'}
      ariaLabel={label}
      selectedUser={request.supervisor}
      users={filteredUsers ?? []}
      onSelectUser={(user) => {
        if ((user?.id ?? null) !== request.supervisor?.id) {
          updateRequestSupervisor({
            supervisor: user ?? null,
          });
        }
      }}
      onSearchChange={(searchTerm) => {
        // To replace by calls to the API when we'll have an organization with a lot of potential responsables/supervisors
        setFilteredUsers(
          users?.filter((user) =>
            user.name.toLowerCase().includes(searchTerm?.toLowerCase() ?? '')
          ) ?? []
        );
      }}
    />
  );

  if (variant === 'row') {
    return userPickerContent;
  }

  return (
    <Box padding="0.25rem 0.5rem" style={{ flex: 'none' }}>
      {userPickerContent}
    </Box>
  );
};
