import { Plural, Trans } from '@lingui/react/macro';

import Stack from '@/design_system/Stack';
import { BrandRequestMainContent } from '@/layouts/Brand/BrandRequestLayout/BrandRequestLayout';
import { ClientRequestWithRelations } from '@/models/request';
import ClientArticlesTable from '@/routes/Brand/Requests/Request/components/shared/ClientArticlesTable';
import ClientStepper from '@/routes/Brand/Requests/Request/components/shared/ClientStepper';
import IconSuccess from '@/routes/Brand/Requests/Request/components/shared/IconSuccess';
import { PickupPointInfo } from '@/routes/Brand/Requests/Request/components/shared/PickupPointInfo/PickupPointInfo';
import useViewPort from '@/utils/useViewport';

const Repair = ({ request }: { request: ClientRequestWithRelations }) => {
  const { isMobile } = useViewPort();

  const activeArticlesInRepair = request.articles.filter(
    (article) => article.step?.step === 'repair' && !article.cancelledAt
  );

  return (
    <BrandRequestMainContent>
      <Stack gap="1.5rem">
        <ClientStepper request={request} />
        <Stack gap="1rem">
          <Stack row gap="0.5rem" alignItems="center">
            <IconSuccess />
            <p className="headline-200-bold headline-300-bold-mobile color-primary-800">
              <Trans id="client.request.repair.title.without-payment">
                Your request is ready to be processed
              </Trans>
            </p>
          </Stack>
          <p className="paragraph-50-regular paragraph-100-regular-mobile">
            <Trans id="client.request.repair.description">
              Our experts will start working on your{' '}
              <Plural value={activeArticlesInRepair.length} one="item" other="items" />. You will be
              notified when this is complete.
            </Trans>
          </p>
        </Stack>
        <Stack gap={isMobile ? '1rem' : '1.5rem'}>
          <PickupPointInfo request={request} />
          <ClientArticlesTable request={request} showPrice showArticleComment />
        </Stack>
      </Stack>
    </BrandRequestMainContent>
  );
};

export default Repair;
