import { Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconPackageValidation from '@/icons/PackageValidation.svg';

export const ArticleTransitAutoValidatedActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_transit_auto_validated'>;
  displayArticleName: boolean;
}) => {
  const creatorName = activity.creator?.name;

  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="primary"
      icon={<IconPackageValidation />}
      message={
        <>
          <BaseActivityText>
            <Trans id="activities.article_transit_auto_validated.message">
              <strong>{creatorName}</strong> auto-validated the transit of the item{' '}
              <BaseActivityArticleName
                activity={activity}
                displayArticleName={displayArticleName}
              />
            </Trans>
          </BaseActivityText>
        </>
      }
    />
  );
};
