import { Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import { BaseActivity, BaseActivityText } from '@/components/activities/BaseActivity/BaseActivity';
import IconPaymentValidation from '@/icons/PaymentValidation.svg';

export const PaymentCompletedActivity = ({
  activity,
}: {
  activity: ActivityOfType<'payment_completed'>;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="primary"
      icon={<IconPaymentValidation />}
      message={
        <BaseActivityText>
          <Trans id="activities.payment_completed.message">
            <strong>The client</strong> paid the quote
          </Trans>
        </BaseActivityText>
      }
    />
  );
};
