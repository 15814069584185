import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Endpoints, PickupPoints, WorkshopOrganizationPayoutStrategy } from '@/api';
import { useFetch } from '@/utils/fetch';
import { Currency } from '@/utils/number';

import Phone, { formatPhone } from './partials/phone';
import { ActionTypeOrganizationWorkshop } from './actionType';
import { Address, AddressRaw } from './address';
import { Model } from './model';

export class Workshop extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;
  name!: string;
  logo!: string | null;
  logoSquare!: string | null;
  address!: Address | null;
  phone!: Phone | null;
  external!: boolean;
  data!: Record<string, any>;
  addressId!: string | null;
  currency!: Currency;
  pickupPoints!: PickupPoints;
  areCustomsExpected?: boolean;
  nonDigitalized?: boolean;
  createdAt!: string;

  get createdAtDate() {
    return new Date(this.createdAt);
  }

  get formattedPhone() {
    return formatPhone(this.phone);
  }

  get region() {
    if ('organizations' in this) {
      return (this.organizations as WorkshopOrganization[])?.[0]?.region;
    }
  }

  get organizationConfig() {
    if ('organizations' in this) {
      return (this.organizations as WorkshopOrganization[])?.[0];
    }
  }

  // Can be added by GET /workshops
  cost?: number;
}

class WorkshopOrganization extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  organizationId!: string;

  region!: string;
  autoAcceptDispatch!: boolean;
  autoAcceptDispatchProposal!: boolean;
  autoAcceptRequalification!: boolean;
  payoutStrategy!: WorkshopOrganizationPayoutStrategy;
  fees!: WorkshopFee[];
}

export const useWorkshop = (
  id?: string | null,
  options?: {
    enabled?: boolean;
  }
) => {
  const fetch = useFetch<Endpoints['GET /workshops/:id']>();

  return useQuery({
    queryKey: ['workshops', id],
    queryFn: () =>
      fetch(`/workshops/${id!}`).then((workshop) =>
        new Workshop(workshop)
          .with('address', workshop.address ? new Address(workshop.address) : null)
          .with(
            'organizations',
            (workshop.organizations as any[]).map(
              (workshopOrganization) => new WorkshopOrganization(workshopOrganization)
            )
          )
      ),
    enabled: !!id && options?.enabled !== false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });
};

const instanciateWorkshopWithRelations = (workshop: any) =>
  new Workshop(workshop)
    .with(
      'actionTypes',
      (workshop.actionTypes as any[]).map((action) => new ActionTypeOrganizationWorkshop(action))
    )
    .with('address', workshop.address ? new Address(workshop.address) : null)
    .with(
      'organizations',
      (workshop.organizations as any[]).map(
        (workshopOrganization) => new WorkshopOrganization(workshopOrganization)
      )
    );

export type WorkshopFee =
  Endpoints['GET /workshops']['response']['workshops'][number]['organizations'][number]['fees'][number];

export type WorkshopWithRelations = ReturnType<typeof instanciateWorkshopWithRelations>;

export const useWorkshops = (
  params: Endpoints['GET /workshops']['query'],
  options?: {
    enabled?: boolean;
    keepPreviousData?: boolean;
  }
) => {
  const fetch = useFetch<Endpoints['GET /workshops']>();

  return useQuery({
    queryKey: ['workshops', params],
    queryFn: () =>
      fetch('/workshops', params).then(({ workshops, meta }) => ({
        workshops: workshops.map(instanciateWorkshopWithRelations),
        meta,
      })),
    enabled: options?.enabled,
    placeholderData: options?.keepPreviousData ? keepPreviousData : undefined,
    refetchOnWindowFocus: !!params.articleId,
    refetchOnReconnect: !!params.articleId,
    refetchOnMount: !!params.articleId,
  });
};

export const useUpdateWorkshop = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['PATCH /workshops/:id']>();

  return useMutation({
    mutationFn: ({
      workshopId,
      ...body
    }: {
      workshopId: string;
      name: string;
      address: AddressRaw;
      phone: Phone;
    }) => fetch(`/workshops/${workshopId}`, undefined, { method: 'PATCH', body }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['workshops'] });
    },
  });
};
