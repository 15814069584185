import { ArticleWithRelations, RequestWithRelations } from '@/models/request';
import { UserWithRelations } from '@/models/user';
import { Workflow } from '@/models/workflow';
import {
  RequestArticleContextData,
  RequestContextData,
} from '@/routes/Requests/contexts/interfaces';

export const computeAcceptDispatchProposalContext = ({
  workflow,
  article,
  view,
}: {
  request: RequestWithRelations;
  requestView: RequestContextData['view'];
  workflow?: Workflow;
  article: ArticleWithRelations;
  view: RequestArticleContextData['view'];
  currentSession: UserWithRelations;
}) => {
  /**
   * SERVICES
   */
  view.services.defects.shown = !!workflow?.config.defectsEnabled;
  view.services.defects.editable = false;

  view.services.actions.shown = article.serviceChoice === 'care-repair';
  view.services.actions.editable = true;
  view.services.actions.canAddRemoveAction = false;
  view.services.actions.canEditDefects = false;
  view.services.actions.canEditCost = false;
  view.services.actions.canEditPrice = true;

  view.services.dispatch.shown = article.serviceChoice === 'care-repair';
  view.services.dispatch.editable = false;
};
