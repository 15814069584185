import { useState } from 'react';
import { Separator } from 'react-aria-components';
import { Trans, useLingui } from '@lingui/react/macro';

import { ClientFormModal } from '@/components/ClientForm';
import ContactDetails from '@/components/ContactDetails';
import { RequestDueDate } from '@/components/DueDate';
import { UserAvatar } from '@/components/UserAvatar/UserAvatar';
import { UserAvatarGroup } from '@/components/UserAvatarGroup/UserAvatarGroup';
import Accordion from '@/design_system/Accordion';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconCalendar from '@/icons/Calendar.svg';
import IconEdit from '@/icons/Edit.svg';
import IconFile from '@/icons/File.svg';
import IconStore from '@/icons/Store.svg';
import IconPerson from '@/icons/User.svg';
import { RequestWithRelations, useActivities } from '@/models/request';
import { useUser } from '@/models/user';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import { useCurrentSession } from '@/services/auth';
import { formatDate } from '@/utils/date';

export const RequestInfoSidePanel = () => {
  const { t } = useLingui();
  const { currentSession } = useCurrentSession();
  const { request, view } = useRequestContext();
  const [isOpenClientFormModal, setIsOpenClientFormModal] = useState(false);
  const canEditClient =
    !request.isArchived &&
    currentSession?.hasPermission('edit_client', {
      organizationId: request.organizationId,
    });

  const isExternalWorkshopUser = currentSession?.workshop?.external ?? false;
  const { data } = useActivities(
    {
      requestId: request.id,
      types: ['article_service_choice_completed'],
      limit: 1,
    },
    { enabled: isExternalWorkshopUser }
  );

  const { data: afterSalesManager } = useUser(data?.activities?.[0]?.creatorId);

  return (
    <Stack gap="0.5rem">
      <Stack gap="0.5rem" ariaLabel={t({ id: 'request.information', message: 'Information' })}>
        <Accordion
          openByDefault
          trigger={
            <p className="paragraph-100-medium">
              <Trans id="request.information">Information</Trans>
            </p>
          }
        >
          <RequestDetails request={request} />
        </Accordion>
      </Stack>
      {request.client && !isExternalWorkshopUser && (
        <>
          <Separator />
          <Stack ariaLabel={t({ id: 'request.contact-details.client', message: 'Client' })}>
            <Accordion
              openByDefault
              trigger={
                <Stack row alignItems="center" gap="0.5rem">
                  <p className="paragraph-100-medium">
                    <Trans id="request.contact-details.client">Client</Trans>
                  </p>
                </Stack>
              }
            >
              <Stack gap="1rem">
                <ContactDetails
                  type="client"
                  name={request.client.name}
                  email={request.client.email}
                  address={request.client.address}
                  phone={request.client.phone}
                  deliveryChoice={request.client.deliveryOption}
                  isVIP={request.vip}
                  size="small"
                />
                {canEditClient && (
                  <Button
                    variant="secondary"
                    onPress={() => setIsOpenClientFormModal(true)}
                    size="small"
                    ariaLabel={t({ id: 'request.edit.actions.edit-client', message: 'Edit' })}
                  >
                    <IconEdit />
                    <Trans id="request.edit.actions.edit-client">Edit</Trans>
                  </Button>
                )}
              </Stack>
            </Accordion>
          </Stack>
          <ClientFormModal
            isOpen={isOpenClientFormModal}
            setIsOpen={setIsOpenClientFormModal}
            hideDeliveryOption={!request.storeId}
            hideBillingAddress={!view.client.billingAddress}
            variant="default"
            size="medium"
          />
        </>
      )}
      {request.store && !isExternalWorkshopUser && (
        <>
          <Separator />
          <Accordion
            openByDefault
            trigger={
              <p className="paragraph-100-medium">
                <Trans id="request.contact-details.store">Store</Trans>
              </p>
            }
          >
            <Stack ariaLabel={t({ id: 'request.contact-details.store', message: 'Store' })}>
              <ContactDetails
                type="store"
                name={request.store.name}
                email={request.store.address.contactEmail}
                address={request.store.address}
                phone={request.store.phone}
                openingHours={request.store.openingHours}
                size="small"
              />
            </Stack>
          </Accordion>
        </>
      )}
      {afterSalesManager && (
        <>
          <Separator />
          <Accordion
            openByDefault
            trigger={
              <p className="paragraph-100-medium">
                <Trans id="request.contact-details.store.asm">Contact</Trans>
              </p>
            }
          >
            <Stack ariaLabel={t({ id: 'request.contact-details.store.asm', message: 'Contact' })}>
              <ContactDetails
                type="client"
                name={afterSalesManager.name}
                email={afterSalesManager.email}
                phone={afterSalesManager.phone}
                size="small"
              />
            </Stack>
          </Accordion>
        </>
      )}
    </Stack>
  );
};

export const RequestInfoTopPanel = () => {
  const { currentSession } = useCurrentSession();
  const { t } = useLingui();
  const { request, view } = useRequestContext();
  const [isOpenClientFormModal, setIsOpenClientFormModal] = useState(false);
  const canEditClient =
    !request.isArchived &&
    currentSession?.hasPermission('edit_client', {
      organizationId: request.organizationId,
    });
  const isExternalWorkshopUser = currentSession?.workshop?.external ?? false;
  const { data } = useActivities(
    {
      requestId: request.id,
      types: ['article_service_choice_completed'],
      limit: 1,
    },
    { enabled: isExternalWorkshopUser }
  );

  const { data: afterSalesManager } = useUser(data?.activities?.[0]?.creatorId);

  return (
    <Box padding="0">
      <Stack ariaLabel={t({ id: 'request.information.title', message: 'Information' })}>
        <Accordion
          trigger={
            <Stack row gap="0.5rem" alignItems="center" className="paragraph-100-regular">
              <IconFile />
              <Trans id="request.information">Information</Trans>
            </Stack>
          }
        >
          <RequestDetails request={request} />
        </Accordion>
      </Stack>

      {request.client && !isExternalWorkshopUser && (
        <>
          <Separator />
          <Stack ariaLabel={t({ id: 'request.contact-details.client', message: 'Client' })}>
            <Accordion
              trigger={
                <Stack
                  row
                  gap="0.5rem"
                  alignItems="center"
                  className="paragraph-100-regular"
                  flexWrap="nowrap"
                >
                  <IconPerson />
                  {request.client.name}
                </Stack>
              }
            >
              <Stack gap="1rem">
                <ContactDetails
                  type="client"
                  email={request.client.email}
                  address={request.client.address}
                  phone={request.client.phone}
                  deliveryChoice={request.client.deliveryOption}
                  size="small"
                />
                {canEditClient && (
                  <Button
                    variant="secondary"
                    onPress={() => setIsOpenClientFormModal(true)}
                    size="small"
                    ariaLabel={t({ id: 'request.edit.actions.edit-client', message: 'Edit' })}
                  >
                    <IconEdit />
                    <Trans id="request.edit.actions.edit-client">Edit</Trans>
                  </Button>
                )}
              </Stack>
            </Accordion>
          </Stack>
          <ClientFormModal
            isOpen={isOpenClientFormModal}
            setIsOpen={setIsOpenClientFormModal}
            hideDeliveryOption={!request.storeId}
            hideBillingAddress={!view.client.billingAddress}
            variant="default"
            size="medium"
          />
        </>
      )}
      {request.store && !isExternalWorkshopUser && (
        <>
          <Separator />
          <Stack ariaLabel={t({ id: 'request.contact-details.store', message: 'Store' })}>
            <Accordion
              trigger={
                <Stack row gap="0.5rem" alignItems="center" className="paragraph-100-regular">
                  <IconStore />
                  {request.store.name}
                </Stack>
              }
            >
              <ContactDetails
                type="store"
                email={request.store.address.contactEmail}
                address={request.store.address}
                phone={request.store.phone}
                openingHours={request.store.openingHours}
                size="small"
              />
            </Accordion>
          </Stack>
        </>
      )}
      {afterSalesManager && (
        <>
          <Separator />
          <Stack ariaLabel={t({ id: 'request.contact-details.store.asm', message: 'Contact' })}>
            <Accordion
              trigger={
                <Stack row gap="0.5rem" alignItems="center" className="paragraph-100-regular">
                  <IconPerson />
                  <Trans id="request.contact-details.store.asm">Contact</Trans>
                </Stack>
              }
            >
              <ContactDetails
                type="client"
                name={afterSalesManager.name}
                email={afterSalesManager.email}
                phone={afterSalesManager.phone}
                size="small"
              />
            </Accordion>
          </Stack>
        </>
      )}
    </Box>
  );
};

const RequestDetails = ({ request }: { request: RequestWithRelations }) => {
  const { currentSession } = useCurrentSession();

  return (
    <Stack gap="0.75rem">
      <RequestRef request={request} />
      {request.externalReference && <RequestExternalReference request={request} />}
      <CreationDate request={request} />
      {!currentSession?.workshop?.external && request.dueAtDate && (
        <Stack>
          <RequestDueDate
            date={request.dueAtDate}
            variant="info"
            estimatedDate={request.isArchived ? null : request.estimatedDueAtDate}
          />
        </Stack>
      )}
      {request.externalPaymentReference && <PaymentRef request={request} />}
      {request.isArchived && request.archivedAtDate && <ArchiveDate request={request} />}
      <Supervisor request={request} />
      <Collaborators request={request} />
    </Stack>
  );
};

export const RequestRef = ({ request }: { request: RequestWithRelations }) => {
  const { t } = useLingui();

  return (
    <Stack>
      <span className="paragraph-200-regular text-secondary">
        <Trans id="request.reference.long">Reference</Trans>
      </span>
      <span
        className="paragraph-100-medium"
        aria-label={t({ id: 'request.reference.long', message: 'Reference' })}
      >
        {request.reference}
      </span>
    </Stack>
  );
};

export const RequestExternalReference = ({ request }: { request: RequestWithRelations }) => {
  const { t } = useLingui();

  return (
    <Stack>
      <span className="paragraph-200-regular text-secondary">
        <Trans id="request.external-reference">Internal reference</Trans>
      </span>
      <span
        className="paragraph-100-medium"
        aria-label={t({ id: 'request.external-reference', message: 'Internal reference' })}
      >
        {request.externalReference}
      </span>
    </Stack>
  );
};

export const CreationDate = ({ request }: { request: RequestWithRelations }) => {
  return (
    <Stack>
      <span className="paragraph-200-regular text-secondary">
        <Trans id="request.side-menu.request-details.creation-date">Creation date</Trans>
      </span>
      <Stack row className="paragraph-100-medium" alignItems="center" gap="0.25rem">
        <IconCalendar style={{ fontSize: '1rem' }} />
        {formatDate(request.createdAtDate, { dateStyle: 'medium' })}
      </Stack>
    </Stack>
  );
};

export const PaymentRef = ({ request }: { request: RequestWithRelations }) => {
  const { t } = useLingui();

  return (
    <Stack>
      <span className="paragraph-200-regular text-secondary">
        <Trans id="request.payment-ref">Receipt / invoice no.</Trans>
      </span>
      <span
        className="paragraph-100-medium"
        aria-label={t({ id: 'request.payment-ref', message: 'Receipt / invoice no.' })}
      >
        {request.externalPaymentReference}
      </span>
    </Stack>
  );
};

export const ArchiveDate = ({ request }: { request: RequestWithRelations }) => {
  const { t } = useLingui();

  if (!request.archivedAtDate) {
    return null;
  }

  return (
    <Stack>
      <span className="paragraph-200-regular text-secondary">
        <Trans id="request.archived-date">Archived date</Trans>
      </span>
      <Stack
        row
        className="paragraph-100-medium"
        alignItems="center"
        gap="0.25rem"
        aria-label={t({ id: 'request.archived-date', message: 'Archived date' })}
      >
        <IconCalendar style={{ fontSize: '1rem' }} />
        {formatDate(request.archivedAtDate, { dateStyle: 'medium' })}
      </Stack>
    </Stack>
  );
};

export const Supervisor = ({ request }: { request: RequestWithRelations }) => {
  const { t } = useLingui();
  const label = t({ id: 'request.supervisor', message: 'Supervisor' });
  return (
    <Stack>
      <span className="paragraph-200-regular text-secondary">{label}</span>
      <Stack row gap="0.25rem" alignItems="center">
        {!!request.supervisor && <UserAvatar user={request.supervisor} size="x-small" />}
        <span className="paragraph-100-medium sentry-mask" aria-label={label}>
          {request.supervisor?.name ?? '-'}
        </span>
      </Stack>
    </Stack>
  );
};

export const Collaborators = ({ request }: { request: RequestWithRelations }) => {
  const { t } = useLingui();
  const label = t({ id: 'request.collaborators', message: 'Collaborators' });
  return (
    <Stack>
      <span className="paragraph-200-regular text-secondary">{label}</span>
      <Stack row gap="0.25rem" alignItems="center">
        {request.collaborators.length > 0 ? (
          <UserAvatarGroup users={request.collaborators} size="x-small" />
        ) : (
          <span className="paragraph-100-medium">- </span>
        )}
      </Stack>
    </Stack>
  );
};
