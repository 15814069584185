import { Trans, useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button/Button';
import IconEmail from '@/icons/Email.svg';
import useViewPort from '@/utils/useViewport';

export const ClientCommunicationButton = ({
  hasUnreadNotifications,
  onPress,
}: {
  hasUnreadNotifications: boolean;
  onPress: () => void;
}) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();

  return (
    <Button
      ariaLabel={
        isMobile
          ? t({
              id: 'requests.actions.client-communication.button.label',
              message: 'Client communication',
            })
          : undefined
      }
      iconOnly={isMobile ? true : false}
      variant={isMobile ? 'tertiary' : 'secondary'}
      size={isMobile ? 'large' : 'medium'}
      onPress={onPress}
      style={{ position: 'relative' }}
    >
      {hasUnreadNotifications && (
        <div
          style={{
            display: 'flex',
            backgroundColor: 'var(--color-danger-700)',
            borderRadius: '50%',
            outline: isMobile ? '2px solid white' : undefined,
            width: '0.625rem',
            height: '0.625rem',
            position: 'absolute',
            top: isMobile ? '0.325rem' : '-0.225rem',
            right: isMobile ? '0.175rem' : '-0.225rem',
          }}
        />
      )}
      <IconEmail />
      {!isMobile && (
        <Trans id="requests.actions.client-communication.button.label">Client communication</Trans>
      )}
    </Button>
  );
};
